export enum EventName {
  // A list of products (or brands) tiles was rendered on page load 
  SectionCreated = 'Section Created',
  // A validation error was displayed to the user
  ValidationErrorDisplayed = 'Validation Error Displayed'
}

export default abstract class AnalyticsEvent {
  // The name of the event
  public abstract readonly name: EventName;
  // The properties attached to the event
  public abstract properties: Record<string, unknown> ;
}