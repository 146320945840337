<template>
  <div
    class="input-field"
    :class="{ valid: isValid && !isDisabled, error: isError && !isDisabled }"
  >
    <label v-if="label">{{ label }}</label>
    <div class="input-container">
      <input
        v-for="i in length"
        :key="i - 1"
        :disabled="isDisabled"
        :type="inputType"
        class="input-box"
        autocorrect="off"
        autocapitalize="off"
        :autocomplete="'random-' + Math.round(Math.random() * 1000)"
        spellcheck="false"
        :data-key="i - 1"
        @change="onChange"
        @input="onInput"
        @paste.prevent="onPaste"
        @blur="onBlur"
        @keydown="onKeyDown"
      >
    </div>
    <div
      v-if="errors && !isDisabled"
      class="error-message"
    >
      <div
        v-for="(error, index) in errors"
        :key="index"
      >
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InputBoxes',
  props: {
    length: {
      type: Number,
      required: true,
      default: 10,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: false,
      default: '',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    regex: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    isLogin: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change', 'update:modelValue'],
  data: () => {
    return {
      isValid: false,
      dirty: false,
      timeoutId: null,
      hasInitialValue: false,
    };
  },
  computed: {
    ...mapGetters(['userCountry']),
    isError() {
      if (this.errors.length > 0) {
        return true;
      }

      if (!this.getValue() && !this.required) {
        return false;
      }

      // we want to check the error only if the form has been dirtied
      if (this.dirty) {
        if (this.regex) {
          // if the regex exists we test both validity of the value and if the value match the regex
          return !this.isValid || !this.getValue().toUpperCase().match(this.regex);
        } else {
          // if not we test only the validity of the value.
          return !this.isValid;
        }
      }

      return false;
    },
  },
  watch: {
    isDisabled: function (newVal) {
      // watch
      if (newVal === true && !this.hasInitialValue) {
        this.$el.querySelectorAll('input').forEach((el) => {
          el.value = '';
        });
        this.resetDefault();
        const val = '';
        this.timeoutId = setTimeout(() => {
          this.$emit('update:modelValue', val);
          this.$emit('change', val);
        }, 50);
      }
    },
    modelValue: {
      handler(newVal) {
        if (newVal && newVal.length === this.length) {
          this.hasInitialValue = true;
          this.printValueInInputs();
        }
      },
      deep: true,
    },
  },
  mounted() {
    // Check if there is an already input value to print in the inputs
    if (this.modelValue && this.modelValue.length === this.length) {
      this.hasInitialValue = true;
      this.printValueInInputs();
    }
  },
  methods: {
    onPaste(e: ClipboardEvent) {
      let pastedData = e.clipboardData.getData('text');

      if (pastedData.toUpperCase().startsWith('BE') && this.isLogin) {
        const n = 2;
        pastedData = pastedData.substring(n);
      }

      // the filter is here to remove white space and all non-alphanum chars for pasted string eg: FR 3993 300939 9030
      const paste: string[] = pastedData.split('').filter((char) => {
        return /[a-zA-Z0-9]/.test(char);
      });

      if (paste.length === 0) {
        return;
      }

      let pasteFromBoxId = 0;
      const targetElement = e.target as HTMLElement;
      if (targetElement.classList?.contains('input-box') && targetElement.dataset['key']) {
        pasteFromBoxId = parseInt(targetElement.dataset['key'], 10);
      }

      // Special case: country code has been already entered and is pasted again on the 3rd box
      // Current: FR______
      // Pasted content: FR123456
      // Without this test, will display : FRFR1234
      // But it's nice to understand that this was intended FR123456
      if (
        pasteFromBoxId === 2 &&
        this.getValue().toUpperCase().startsWith(this.userCountry) &&
        pastedData.toUpperCase().startsWith(this.userCountry)
      ) {
        pasteFromBoxId = 0;
      }

      const inputs: NodeListOf<HTMLInputElement> = this.$el.querySelectorAll('input');

      let key = 0;
      for (const element of inputs) {
        if (key++ < pasteFromBoxId) {
          continue;
        }
        if (paste.length === 0) {
          element.focus();
          break;
        }

        element.value = paste.shift();
      }

      this.onChange();
    },
    onChange() {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.$emit('update:modelValue', this.getValue());
        this.$emit('change', this.getValue());
      }, 50);
    },
    getValue(): string {
      let val = '';

      if (!this.$el) {
        return val;
      }

      this.$el.querySelectorAll('input').forEach((el) => {
        if (el.value) {
          val += el.value;
        }
      });

      return val;
    },
    printValueInInputs() {
      this.$el.querySelectorAll('input').forEach((el, key) => {
        el.value = this.modelValue[key];
      });
    },
    onBlur(e) {
      setTimeout(() => {
        //if the focus element is not in our component we can considere we loose focus on the component
        this.dirty = document.activeElement.parentElement.parentElement !== this.$el;
      }, 10);

      if (!e.nextElementSibling) {
        let valid = true;
        this.$el.querySelectorAll('input').forEach((el) => {
          if (!el.value) {
            valid = false;
          }
        });

        this.isValid = valid;
      }
    },
    onInput(e) {
      // case of a copy taste
      if (!e.data) {
        return;
      }

      if (e.srcElement.value) {
        e.srcElement.value = e.data;

        if (e.srcElement.nextElementSibling) {
          e.srcElement.nextElementSibling.focus();
        } else {
          setTimeout(() => {
            e.srcElement.blur();
          }, 10);
        }
      }

      this.onChange();
    },
    onKeyDown(e) {
      if (e.key === 'Backspace') {
        if (!e.srcElement.value && e.srcElement.previousElementSibling) {
          e.preventDefault();
          e.srcElement.previousElementSibling.focus();
          e.srcElement.previousElementSibling.value = null;
        } else {
          e.srcElement.value = null;
        }

        this.onChange();

        return;
      }

      if (e.key === 'ArrowLeft' && e.srcElement.previousElementSibling) {
        e.preventDefault();
        e.srcElement.previousElementSibling.focus();

        return;
      }

      if (e.key === 'ArrowRight' && e.srcElement.nextElementSibling) {
        e.preventDefault();
        e.srcElement.nextElementSibling.focus();

        return;
      }
    },
    resetDefault() {
      this.isValid = false;
      this.dirty = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@css/vue-import';

.input-field {
  text-align: left;
  margin-bottom: 0;

  label {
    margin-bottom: 5px;
  }

  &.valid {
    input {
      @apply ds-border-solid ds-border-success-700 #{!important};
    }
  }

  &.error {
    input {
      @apply ds-border-solid ds-border-error-700  #{!important};
    }
  }

  .input-container {
    display: block;

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input {
      @apply ds-border ds-border-solid ds-rounded-md ds-w-6 ds-h-6 ds-text-center ds-inline-flex ds-border-neutral-600 ds-mb-1;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      &:disabled {
        @apply ds-bg-neutral-300;
      }

      &:not(:last-child) {
        @apply ds-mr-1;

        @include media-breakpoint-up(md) {
          margin-right: 4px;
        }
      }

      &:focus {
        @apply ds-outline-none ds-border-neutral-500;
      }
    }
  }
}
</style>
