<template>
  <div
    v-if="currentFilter"
    class="list-widget__title"
  >
    <AkParagraph class="ds-text-neutral-900 ds-py-2">
      {{ currentFilter.label }}
      <AkIcon
        v-if="currentFilter.icon"
        :symbol="currentFilter.icon"
        class="list-widget__icon"
      />
    </AkParagraph>
    <AkButton
      v-if="hasSelectedValue"
      link
      symbol="x"
      @click="setValue(undefined)"
    >
      {{ $t('brands.contactManagement.filtersDrawer.clear') }}
    </AkButton>
  </div>
  <div
    v-if="currentFilter && currentFilter.values"
    class="list-widget"
  >
    <AkRadio
      v-for="item in currentFilter.values"
      :key="item.value"
      class="list-widget__item"
      :value="modelValue[item.value.toString()]"
      :is-checked="isChecked(item.value.toString())"
      :name="item.value"
      @change="setValue(item.value.toString())"
    >
      <template #default>
        {{ item.label }}
        <AkIcon
          v-if="item.icon && !(item.icon === 'plus-logo' || item.icon === 'repeat-offer')"
          :symbol="item.icon"
          class="list-widget__icon"
        />
        <img
          v-if="item.icon && (item.icon === 'plus-logo' || item.icon === 'repeat-offer')"
          :src="item.icon === 'plus-logo' ? '/images/logo/ankorstore-plus.svg' : '/images/logo/ankorstore-plus-tiny.svg'"
          :class="{ 'plus-logo': item.icon === 'plus-logo', 'plus-tiny': item.icon === 'repeat-offer' }"
          alt="AKS+ logo"
        >
      </template>
    </AkRadio>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed } from 'vue';
import { isEmpty } from 'lodash-es';
import { AkParagraph, AkRadio } from '@ankorstore/design-system';
import { ListWidgetComponent } from '../../types/filter.types';
import { ContactFilterType, ValueContactFilter } from '../../types/api.types';

export default defineComponent({
  name: 'ListWidget',
  components: {
    AkParagraph,
    AkRadio,
  },
  props: {
    filter: {
      type: Object as PropType<ListWidgetComponent>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<ValueContactFilter>,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const currentFilter = ref(props?.filter);
    const setValue = (item?: string) => {
      emit('update:modelValue', {
        type: ContactFilterType.Value,
        name: props.filter.attributeName,
        value: item ? [item] : [],
      });
    };

    const hasSelectedValue = computed(() => !isEmpty(props?.modelValue?.value));
    const isChecked = (value: string) => props?.modelValue?.value?.includes(value);

    return { setValue, currentFilter, isChecked, hasSelectedValue };
  },
});
</script>
<style scoped lang="scss">
.list-widget {
  @apply ds-px-4;
  &__title {
    @apply ds-flex ds-items-center ds-justify-between;
  }
  &__item {
    @apply ds-mb-2 #{!important};
  }

  &__icon {
    @apply ds-bg-neutral-300 ds-rounded-full ds-ml-1 ds-mr-1 ds-p-1;
    @apply ds-text-xs #{!important};

    &:before {
      @apply ds-text-primary;
    }
  }

  .plus-logo {
    @apply ds-inline-block ds-h-4 ds-p-1 ds-bg-neutral-300 ds-rounded-sm;
  }
  .plus-tiny {
    @apply ds-inline-block ds-bg-neutral-300 ds-rounded-full ds-align-middle ds-leading-4;
    height: 20px;
    padding: 1px;
  }
}
</style>
