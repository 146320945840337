<script lang="ts">
import { defineComponent } from 'vue';
import Analytics from '@monolith/legacy/services/analytics';
import { isEnabled } from '@monolith/legacy/services/features';
import { loginPopinMixin } from '@core/mixins/login-popin';
import { convertPriceMixins } from '@monolith/legacy/mixins/price/convert';
import { formatPriceMixins } from '@monolith/legacy/mixins/price/format';
import { helpCenterLinkAnalyticsMixin } from '@core/mixins/help-center-link-analytics';
import { getUserTraitsFromStore } from '../legacy/services/analytics/get-user-traits';

export default defineComponent({
  name: 'App',
  mixins: [loginPopinMixin, convertPriceMixins, formatPriceMixins, helpCenterLinkAnalyticsMixin],
  data: function () {
    return {
      previousScrollY: 0,
    };
  },
  mounted() {
    // Account pages are defining a router
    if (!this.$router && !window.location.pathname.startsWith('/account/')) {
      Analytics.page();
    }

    Analytics.setUserTraits(getUserTraitsFromStore(this.$store));
  },
  created() {
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'auto';
    }
    if (isEnabled('like_products_and_brands')) {
      if (!this.$store.getters.productLikesAreInitialized) {
        this.$store.dispatch('fetchProductLikes');
      }
      if (!this.$store.getters.brandLikesAreInitialized) {
        this.$store.dispatch('fetchBrandLikes');
      }
    }
    if (this.$store.getters.user && this.$store.getters.userIsRetailer) {
      this.$store.dispatch('headerBadges/getHeaderBadges');
    }

    // Tech Debt. To be handled as part of ticket: https://ankorstore.atlassian.net/browse/TECH-383
    window.ga?.('send', 'pageview');
  },
});
</script>
