import http from '@monolith/legacy/services/api/http';
import { captureBrandsException } from '@core/plugins/sentry/helper';
import type { AxiosResponse } from 'axios';
import type {
  CreateCampaignApiParams,
  CreateCampaignMethodParams,
  CampaignsTemplatesResponse,
  EditCampaignApiParams,
} from '../types/api.types';
import { CampaignResource } from '../types/create-campaigns.types';

const ROOT_API_PATH = '/api/internal/v1/campaigns';

const jsonApiConfigs = {
  headers: {
    'Content-Type': 'application/vnd.api+json',
  },
};

export const getTemplates = async (): Promise<CampaignsTemplatesResponse[]> => {
  try {
    const { data }: AxiosResponse = await http().get(`/api/internal/v1/campaign-templates`, jsonApiConfigs);
    return data?.data;
  } catch (error) {
    captureBrandsException(error, [
      { label: 'component', value: 'campaigns-management/api' },
      { label: 'action', value: 'getTemplates' },
    ]);
    return Promise.reject(error);
  }
};

export const getCampaign = async (campaignUuid: string): Promise<CampaignResource> => {
  try {
    const { data }: AxiosResponse = await http().get(`/api/internal/v1/campaigns/${campaignUuid}`, jsonApiConfigs);
    return data?.data;
  } catch (error) {
    captureBrandsException(error, [
      { label: 'component', value: 'campaigns-management/api' },
      { label: 'action', value: 'getCampaign' },
    ]);
    return Promise.reject(error);
  }
};

export const createCampaign = async ({
  campaignName,
  filters,
  defaultLanguage,
  messages,
}: CreateCampaignMethodParams, startCampaign = true, campaignId?: string): Promise<CampaignResource> => {
  try {
    const apiPayload: CreateCampaignApiParams | EditCampaignApiParams = {
      data: {
        type: 'campaign',
        id: campaignId,
        attributes: {
          name: campaignName,
          audience: {
            filters: Object.values(filters) || [],
          },
          content: {
            defaultLanguage,
            messages,
          },
        },
      },
    };

    const response: AxiosResponse<CampaignResource> = campaignId ?
    await http().patch(`${ROOT_API_PATH}/${campaignId}?start_campaign=${startCampaign}`, apiPayload, jsonApiConfigs)
    : await http().post(`${ROOT_API_PATH}?start_campaign=${startCampaign}`, apiPayload, jsonApiConfigs);

    return response.data;
  } catch (error) {
    captureBrandsException(error, [
      { label: 'component', value: 'campaigns-management/api' },
      { label: 'action', value: 'createCampaign' },
    ]);
    return Promise.reject(error.response);
  }
};
