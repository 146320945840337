<template>
  <div class="ds-relative">
    <img
      :src="imageUrl"
      alt=""
      class="ds-rounded-md ds-w-full"
    >
    <h4 :class="headingClasses">
      <RouterLink
        :to="card.url"
        custom
      >
        <template #default="{ href, navigate }">
          <a
            :href="href"
            class="ak-stretched-link"
            :class="{ 'ds-break-all': isGerman }"
            @click="
              $emit('clicked', card);
              navigate($event);
            "
          >
            {{ card.title }}
          </a>
        </template>
      </RouterLink>
    </h4>
    <p :class="paragraphClasses">
      {{ card.subtitle }}
    </p>
  </div>
</template>

<script lang="ts">
const ratioRegex = /(\d)-(\d)/;
const sizes = ['md', 'lg'] as const;
type Size = (typeof sizes)[number];

export default { name: 'Card' };
</script>
<script setup lang="ts">
import { computed, PropType } from 'vue';
import { CollectionCard } from './types';
import useGlobals from '@core/composables/use-globals';

defineEmits(['clicked']);

const props = defineProps({
  card: {
    type: Object as PropType<CollectionCard>,
    required: true,
  },
  ratio: {
    type: String,
    required: false,
    default: undefined,
    validator: (value: string) => Boolean(ratioRegex.exec(value)),
  },
  textSize: {
    type: String as PropType<Size>,
    required: false,
    default: 'lg',
    validator: (value: Size) => sizes.includes(value),
  },
});

const { lang } = useGlobals();
const isGerman = computed(() => lang === 'de');
const imageUrl = computed(() => {
  const url = new URL(props.card.image);
  if (props.ratio) {
    const [, a, b] = ratioRegex.exec(props.ratio).map(Number);
    url.searchParams.set('fit', 'crop');
    url.searchParams.set('crop', 'edges');
    url.searchParams.set('ar', `${a / b}`);
  }
  return url.href;
});

const headingClasses = computed(() => {
  const classes = ['ds-font-basic ds-font-semibold ds-text-primary', 'ds-mt-4'];
  if (props.textSize === 'md') {
    classes.push('ds-text-base');
  } else {
    classes.push('ds-text-lg');
  }

  return classes;
});
const paragraphClasses = computed(() => {
  const classes = ['ds-font-basic ds-font-normal ds-text-neutral-700'];

  if (props.textSize === 'md') {
    classes.push('ds-text-xs');
  } else {
    classes.push('ds-text-sm');
  }

  if (isGerman.value) {
    classes.push('ds-break-all');
  }

  return classes;
});
</script>

<style scoped lang="scss">
.boutiqueCard {
  &__title {
    font-style: normal;
    font-size: 1.5rem;
    font-weight: 600;
  }
}
</style>
