<template>
  <div
    class="inline-text"
    :class="{ 'link link--bordered': isLink }"
  >
    <span>{{ $t('Ankorstore') }}</span>
    <img
      class="symbol"
      src="/images/logo/ankorstore-plus.svg"
      :alt="$t('ankorstorePlus.program.name')"
    >
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AnkorstorePlusTitle',
  props: {
    isLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
<style lang="scss" scoped>
.inline-text {
  @apply ds-inline;
}

.symbol {
  @apply ds-inline-flex ds-h-3;
}

.link {
  @apply ds-underline ds-inline-flex ds-items-center ds-gap-1 ds-font-bold;
  &--bordered {
    @apply ds-no-underline ds-border-b ds-border-solid;
  }
}
</style>
