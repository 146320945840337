import { ref, computed, ComputedRef, App, Plugin } from 'vue';
import {
  addMatchMediaQueryListener,
  removeMatchMediaQueryListener,
  getMediaQueryLists,
  getNewState,
  getIsMobile,
  getIsXlMobile,
  getIsTablet,
  getIsXlTablet,
  getIsDesktop,
  getIsXlDesktop,
  getClientDevice,
  getIsAboveOrEqualLg,
  getIsAboveOrEqualMd,
  getIsAboveOrEqualSm,
  getIsAboveOrEqualXl,
  getIsAboveOrEqualXs,
  getIsAboveOrEqualXxl,
  getIsBelowLg,
  getIsBelowMd,
  getIsBelowSm,
  getIsBelowXl,
  getIsBelowXs,
  getIsBelowXxl,
} from '@monolith/legacy/modules/design-system-candidates/breakpoints/utils';
import { BreakpointsCoreState } from '@monolith/legacy/modules/design-system-candidates/breakpoints/types';
import { BREAKPOINTS_PROVIDE_ID } from '@monolith/legacy/modules/design-system-candidates/breakpoints/constants';

export const breakpointsPlugin: Plugin = {
  install(app: App) {
    const queries = getMediaQueryLists();

    const events: MediaQueryListEvent[] = [];
    let timerId: number = undefined;

    const isXxs = ref(queries[0].matches);
    const isXs = ref(queries[1].matches);
    const isSm = ref(queries[2].matches);
    const isMd = ref(queries[3].matches);
    const isLg = ref(queries[4].matches);
    const isXl = ref(queries[5].matches);
    const isXxl = ref(queries[6].matches);

    const breakpointsValues: ComputedRef<BreakpointsCoreState> = computed(() => ({
      isXxs: isXxs.value,
      isXs: isXs.value,
      isSm: isSm.value,
      isMd: isMd.value,
      isLg: isLg.value,
      isXl: isXl.value,
      isXxl: isXxl.value,
    }));

    const isBelowXs = ref(getIsBelowXs(breakpointsValues.value));
    const isAboveOrEqualXs = ref(getIsAboveOrEqualXs(breakpointsValues.value));
    const isBelowSm = ref(getIsBelowSm(breakpointsValues.value));
    const isAboveOrEqualSm = ref(getIsAboveOrEqualSm(breakpointsValues.value));
    const isBelowMd = ref(getIsBelowMd(breakpointsValues.value));
    const isAboveOrEqualMd = ref(getIsAboveOrEqualMd(breakpointsValues.value));
    const isBelowLg = ref(getIsBelowLg(breakpointsValues.value));
    const isAboveOrEqualLg = ref(getIsAboveOrEqualLg(breakpointsValues.value));
    const isBelowXl = ref(getIsBelowXl(breakpointsValues.value));
    const isAboveOrEqualXl = ref(getIsAboveOrEqualXl(breakpointsValues.value));
    const isBelowXxl = ref(getIsBelowXxl(breakpointsValues.value));
    const isAboveOrEqualXxl = ref(getIsAboveOrEqualXxl(breakpointsValues.value));

    const isMobile = ref(getIsMobile(breakpointsValues.value));
    const isXlMobile = ref(getIsXlMobile(breakpointsValues.value));
    const isTablet = ref(getIsTablet(breakpointsValues.value));
    const isXlTablet = ref(getIsXlTablet(breakpointsValues.value));
    const isDesktop = ref(getIsDesktop(breakpointsValues.value));
    const isXlDesktop = ref(getIsXlDesktop(breakpointsValues.value));
    const currentDevice = ref(getClientDevice(breakpointsValues.value));

    const onMediaQueryChange = (event: MediaQueryListEvent) => {
      events.push(event);
      if (timerId) {
        window.clearTimeout(timerId);
      }
      timerId = window.setTimeout(processEvents, 0);
    };

    const processEvents = () => {
      const newState = events.reduce((acc, event) => ({ ...acc, ...getNewState(event, queries, acc) }), breakpointsValues.value);

      Object.entries(newState).forEach(([key, value]) => {
        ({
          isXxs,
          isXs,
          isSm,
          isMd,
          isLg,
          isXl,
          isXxl,
        })[key].value = value;
      });

      isBelowXs.value = getIsBelowXs(newState);
      isAboveOrEqualXs.value = getIsAboveOrEqualXs(newState);
      isBelowSm.value = getIsBelowSm(newState);
      isAboveOrEqualSm.value = getIsAboveOrEqualSm(newState);
      isBelowMd.value = getIsBelowMd(newState);
      isAboveOrEqualMd.value = getIsAboveOrEqualMd(newState);
      isBelowLg.value = getIsBelowLg(newState);
      isAboveOrEqualLg.value = getIsAboveOrEqualLg(newState);
      isBelowXl.value = getIsBelowXl(newState);
      isAboveOrEqualXl.value = getIsAboveOrEqualXl(newState);
      isBelowXxl.value = getIsBelowXxl(newState);
      isAboveOrEqualXxl.value = getIsAboveOrEqualXxl(newState);
      isMobile.value = getIsMobile(newState);
      isXlMobile.value = getIsXlMobile(newState);
      isTablet.value = getIsTablet(newState);
      isXlTablet.value = getIsXlTablet(newState);
      isDesktop.value = getIsDesktop(newState);
      isXlDesktop.value = getIsXlDesktop(newState);
      currentDevice.value = getClientDevice(newState);

      events.length = 0;
      timerId = undefined;
    };

    queries.forEach((query) => {
      addMatchMediaQueryListener(query, onMediaQueryChange);
    });

    app.onUnmount(() => {
      if (timerId) {
        clearTimeout(timerId);
      }
      queries.forEach((query) => {
        removeMatchMediaQueryListener(query, onMediaQueryChange);
      });
    });

    app.provide(BREAKPOINTS_PROVIDE_ID, {
      //breakpoints
      isXxs,
      isXs,
      isSm,
      isMd,
      isLg,
      isXl,
      isXxl,

      // ranges
      isBelowXs,
      isAboveOrEqualXs,
      isBelowSm,
      isAboveOrEqualSm,
      isBelowMd,
      isAboveOrEqualMd,
      isBelowLg,
      isAboveOrEqualLg,
      isBelowXl,
      isAboveOrEqualXl,
      isBelowXxl,
      isAboveOrEqualXxl,

      // legacy
      isMobile,
      isXlMobile,
      isTablet,
      isXlTablet,
      isDesktop,
      isXlDesktop,
      currentDevice,
    });
  },
};
