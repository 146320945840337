import { Ref, ref, onBeforeMount, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';

import type { SearchCategory } from '@monolith/legacy/types/api/category';
import type { AkLocale } from '@monolith/core/types/base';

import { getCategoriesByLanguage } from '@monolith/legacy/services/api/categories';

const useCategories = () => {
  const i18n = useI18n();

  const categories: Ref<Record<string, SearchCategory>> = ref(null);

  const getTranslatedCategories = (id: string | number): string => {
    return categories.value?.[id].full_name;
  };

  onBeforeMount(async () => {
    try {
      const _categories = await getCategoriesByLanguage(i18n.locale.value as AkLocale);
      nextTick(() => {
        categories.value = _categories;
      });
    } catch(e) {
      // fail silently
    }
  });

  return { categories, getTranslatedCategories };
};

export default useCategories;
