export const SECTION_ATTRIBUTES = {
  trending: {
    label: undefined,
    testId: 'SearchBoxTrendingSearches',
  },
  brands: {
    label: undefined,
    testId: 'SearchBoxSuggestionBrands',
  },
  history: {
    label: undefined,
    testId: 'SearchBoxHistory',
  },
  categories: {
    label: undefined,
    testId: 'SearchBoxCategories',
  },
  suggestions: {
    label: undefined,
    testId: 'SearchBoxSuggestions',
  },
  boutiques: {
    label: undefined,
    testId: 'SearchBoxBoutiques',
  },
};

export const HISTORICAL_SEARCH_KEY = 'historicalSearch';
export const RECENT_SEARCH_GROUP = 'recent-search';
export const PREFILTERED_PREFIX = 'suggestion-prefiltered-';
export const MAX_RESULTS = 10;
export const MAX_NUM_CATEGORY_RESULTS = 2;
export const MAX_NUM_RECENT_SEARCHES = 10;
export const MAX_NUM_RECENT_SEARCHES_WITH_QUERY = 10;
export const MAX_SUGGESTION = 4;
export const SHOW_ALL_ID = -998;
export const R2B_LINK_ID = -999;
export const R2B_LINK_HREF = '/account/suggestbrand';
export const DEFAULT_ARROW_POSITION = -1;
