<template>
  <div
    ref="badge"
    class="badge"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Badge',
});
</script>

<style scoped>
select {
  width: 100%;
}
button {
  width: 100%;
  margin-top: 10px;
}
</style>

<style scoped lang="scss">
@import '@css/vue-import';

.badge {
  position: absolute;
  font-size: 9px;
  @apply ds-bg-warning-700 ds-font-basic;
  border-radius: 50%;
  @apply ds-border ds-border-solid ds-border-white;
  display: flex;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  @apply ds-text-white;
  left: 11px;
  bottom: 6px;
  font-weight: 700;
  opacity: 0.9;

  @include media-breakpoint-up(xl) {
    @apply ds-top-0;
    left: -15px;
  }
}

$zoom: 1.3;

@keyframes pulse {
  from {
    transform: scale(1, 1);
  }

  50% {
    transform: scale($zoom, $zoom);
  }

  to {
    transform: scale(1, 1);
  }
}

.pulse {
  animation-name: pulse;
  animation-duration: 500ms;
}
</style>
