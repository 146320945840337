<template>
  <div class="ds-flex ds-items-center ds-mb-1">
    <label class="ds-text-base ds-font-bold ds-m-0 ds-mr-1">
      {{ text }}
    </label>
    <AkTooltipInformation
      v-if="hasInfoSlot"
      :placement="tooltipPlacement"
      :type="tooltipType"
    >
      <AkIcon
        class="ds-cursor-pointer"
        symbol="info-circle"
        size="md"
      />
      <template #container>
        <slot name="info" />
      </template>
    </AkTooltipInformation>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AkTooltipInformation } from '@ankorstore/design-system';
type TooltipPlacement = 'bottom' | 'top' | 'left' | 'right';
type TooltipType = 'invert' | 'normal' | 'success';

export default defineComponent({
  name: 'AkFormLabel',
  components: {
    AkTooltipInformation,
  },
  props: {
    text: {
      type: String,
      default: null,
    },
    tooltipPlacement: {
      type: String as PropType<TooltipPlacement>,
      default: 'top',
      validator: function (value: string) {
        return ['bottom', 'top', 'left', 'right'].includes(value);
      },
    },
    tooltipType: {
      type: String as PropType<TooltipType>,
      default: 'invert',
      validator: function (value: string) {
        return ['invert', 'normal', 'success'].includes(value);
      },
    },
  },
  computed: {
    hasInfoSlot() {
      return this.$slots?.info;
    },
  },
});
</script>
