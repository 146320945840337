<template>
  <ItemsFilter
    class="color-filter"
    :items="items"
    :show-as-columns="showAsColumns"
  >
    <template #default="{ item }">
      <div
        class="color-filter__item"
        data-testid="colorFilterCheckbox"
        @click="notifyItemClicked(item)"
      >
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          xmlns="http://www.w3.org/2000/svg"
        >
          <!-- Outermost circle for the border -->
          <circle
            cx="22"
            cy="22"
            r="20"
            :class="{
              'color-filter__circle-outer-border': true,
              'color-filter__circle-outer-border--refined': item.isRefined,
            }"
          />

          <!-- Middle circle for the white space -->
          <circle
            cx="22"
            cy="22"
            r="17"
            class="color-filter__circle-inner-border"
          />

          <!-- Inner circle for the colored area -->
          <template v-if="item.value === 'multicolor'">
            <g transform="rotate(45, 22, 22)">
              <path
                d="M 22 22 L 5 22 A 17 17 0 0 1 22 5 L 22 22"
                fill="yellow"
              />
              <path
                d="M 22 22 L 22 5 A 17 17 0 0 1 39 22 L 22 22"
                fill="blue"
              />
              <path
                d="M 22 22 L 39 22 A 17 17 0 0 1 22 39 L 22 22"
                fill="green"
              />
              <path
                d="M 22 22 L 22 39 A 17 17 0 0 1 5 22 L 22 22"
                fill="red"
              />
            </g>
          </template>
          <circle
            v-else
            cx="22"
            cy="22"
            r="17"
            :fill="item.color"
          />
        </svg>
        <label class="color-filter__label">
          <input
            v-show="false"
            type="checkbox"
            :checked="item.isRefined"
            @change="notifyItemClicked(item)"
            @click.stop.prevent
          >
          <FilterLabel
            :label="item.label"
            :count="item.count"
            :selected="item.isRefined"
          />
        </label>
      </div>
    </template>
  </ItemsFilter>
</template>

<script setup lang="ts">
import { ColorItem, ItemsFilterProps } from './types';
import FilterLabel from './filter-label.vue';
import ItemsFilter from './items-filter.vue';

defineOptions({ name: 'ColorFilter' });
defineProps<ItemsFilterProps<ColorItem>>();
const emit = defineEmits<{ (event: 'itemClicked', item: ColorItem): void }>();

const notifyItemClicked = (item: ColorItem) => emit('itemClicked', item);
</script>

<style scoped lang="scss">
.color-filter {
  .color-filter__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: theme('spacing.2');
  }

  .color-filter__circle-outer-border {
    fill: none;
    stroke: theme('colors.neutral.300');
    stroke-width: 2;

    &--refined {
      stroke: theme('colors.accent.700');
    }
  }

  .color-filter__circle-inner-border {
    fill: none;
    stroke: white;
    stroke-width: 3;
  }

  .color-filter__label {
    cursor: pointer;
  }
}
</style>
