import { ShipmentMethod } from '@monolith/legacy/types/shipping/shipment-method';
import { Amount } from '@core/types/amount';
import { CollectionMethod } from '@monolith/legacy/types/shipping/shipping-service';

export enum ShippingMethodUnavailableReason {
  ShippingCorridorNotBetweenEu8Countries = 'shipping_corridor_not_between_eu8_countries',
  ShipFromOrShipToInFrenchOverseasTerritory = 'shipfrom_or_shipto_in_french_overseas_territory',
  ShipFromOrShipToInSpanishNonPeninsularTerritory = 'shipfrom_or_shipto_in_spanish_non_peninsular_territory',
  InternationalShippingProductsWithoutHSCode = 'international_shipping_products_without_hs_code',
  InternationalShippingProductsWithoutMadeIn = 'international_shipping_products_without_made_in',
  AnkorstoreLabelsMethodDisabledForThisOrder = 'ankorstore_labels_method_disabled_for_this_order',
  UPSShippingCountryDoesNotMatchBillingCountry = 'ups_shipping_country_does_not_match_billing_country',
}

export interface ShippingMethod {
  name: ShipmentMethod;
  canBeUsed: boolean;
  errors:
    | {
        generic?: string[];
        service?: string[];
      }
    | { code: ShippingMethodUnavailableReason; message: string }[];
}

export interface ShippingEstimation {
  rateProvider: string;
  rateAmount: Amount;
  collectionMethods: CollectionMethod[];
  estimatedParcels: ShippingParcel[];
}

export interface ShippingParcel {
  weight: number;
  height: number;
  length: number;
  width: number;
  distanceUnit: 'cm';
}

export enum ShippingQuoteValidationErrorCode {
  UpsShippingCountryDoesNotMatchBillingCountry = 'ups_shipping_country_does_not_match_billing_country',
  ParcelLengthAndGirthExceeded = 'parcel_length_and_girth_exceeded',
  MaxNumberOfParcelsExceeded = 'max_number_of_parcels_exceeded',

  ParcelLengthMustBeInteger = 'parcel_length_must_be_integer',
  ParcelLengthMaxExceeded = 'parcel_length_max_exceeded',
  ParcelLengthBelowMinimum = 'parcel_length_below_minimum',

  ParcelWidthMustBeInteger = 'parcel_width_must_be_integer',
  ParcelWidthMaxExceeded = 'parcel_width_max_exceeded',
  ParcelWidthBelowMinimum = 'parcel_width_below_minimum',

  ParcelHeightMustBeInteger = 'parcel_height_must_be_integer',
  ParcelHeightMaxExceeded = 'parcel_height_max_exceeded',
  ParcelHeightBelowMinimum = 'parcel_height_below_minimum',

  ParcelWeightMustBeNumeric = 'parcel_weight_must_be_numeric',
  ParcelWeightMaxExceeded = 'parcel_weight_exceeded',
  ParcelWeightBelowMinimum = 'parcel_weight_below_minimum',

  DhlShippingAllowedOnlyForInternationalShipment = 'dhl_shipping_allowed_only_for_international_shipment',
  DhlShippingAllowedOnlyForDeDomesticShipment = 'dhl_shipping_allowed_only_for_de_domestic_shipment',
  DhlShippingOfForbiddenProducts = 'dhl_shipping_of_forbidden_products',
}
