<template>
  <a
    v-if="imageUrl"
    data-testid="category-link"
    class="search-autocomplete-category-tile"
    :href="tileData.href"
    @click="handleClick"
  >
    <img
      data-testid="category-image"
      class="search-autocomplete-category-tile__image ds-w-full ds-rounded-md ds-object-contain"
      :alt="tileData.name"
      :src="imageUrl"
    >
    <AkParagraph
      data-testid="category-name"
      size="sm"
      class="ds-mt-0.5 ds-text-center"
    >
      {{ tileData.name }}
    </AkParagraph>
  </a>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import { AkParagraph } from '@ankorstore/design-system';
import { CategoryTileData } from './types';

const imageSize = {
  w: '175',
  h: '120',
};

export default defineComponent({
  name: 'CategoryTile',
  components: { AkParagraph },
  props: {
    tileData: {
      type: Object as PropType<CategoryTileData>,
      required: true,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const imageUrl = computed(() => {
      if (props.tileData.imageUrl) {
        const url = new URL(props.tileData.imageUrl);
        url.searchParams.set('w', imageSize.w);
        url.searchParams.set('h', imageSize.h);
        url.searchParams.set('fit', 'crop');
        url.searchParams.set('crop', 'entropy');
        return url.href;
      }
      return null;
    });
    const handleClick = async () => {
      emit('click', props.tileData.id);
    };
    return { imageUrl, handleClick };
  },
});
</script>

<style scoped lang="scss">
.search-autocomplete-category-tile {
  $base: &;
  &__image {
    transition: opacity 50ms;
  }
  &:hover {
    #{$base}__image {
      @apply ds-opacity-80;
    }
  }
}
</style>
