import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { type ContactManagementState } from '../types/store.types';
import { ENABLED_FILTERS, DEFAULT_SORTING, FACETS } from '../constants';

export const initialState: ContactManagementState = {
  contacts: {
    data: [],
    facets: [],
    page: {
      current: 0,
      size: 0,
      totalPages: 0,
      totalResults: 0,
    },
  },
  searchParameters: {
    searchQuery: '',
    facets: [...ENABLED_FILTERS, ...FACETS],
    filters: {},
    sort: { ...DEFAULT_SORTING },
    page: {
      size: 100,
      current: 1,
    },
  },
  filters: [],
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  mutations,
  getters,
};
