<template>
  <div>
    <div
      v-if="isRevamp"
      v-on-click-outside="close"
      class="ds-relative"
    >
      <InputSearch
        :model="searchTerm"
        :error="error"
        name="address"
        :label="$t('Street')"
        :placeholder="$t('Street')"
        @focused="onInputFocused"
        @input="onInputChanged"
      />
      <AutocompleteDropdown
        v-if="!!isDropdownActivated"
        :options="results"
        :selected-option-id="selectedOptionId"
        @select-option="selectOption"
        @button-tab="onDropdownButtonTab"
      >
        <div
          data-testid="dropdownIsActivated"
          class="ds-flex ds-flex-wrap ds-items-center ds-my-3 ds-px-3"
        >
          <button
            class="ds-underline ds-mb-1 hover:ds-outline-none focus:ds-outline-none hover:ds-opacity-80 focus:ds-opacity-80"
            data-testid="useAnyways"
            @click.prevent="selectAnyway"
            @keydown="onSelectAnywayTab($event)"
          >
            {{ $t('I don’t find my address') }}
          </button>
        </div>
        <template #item="{ option }">
          <div>
            <AkIcon
              symbol="geo-alt"
              size="sm"
            />

            {{ option.description }}
          </div>
        </template>
      </AutocompleteDropdown>
    </div>
    <div
      v-else
      v-on-click-outside="close"
      class="form-label-group required-field"
    >
      <input
        v-model="searchTerm"
        class="form-control places-autocomplete-input hide-placeholder-on-focus"
        data-testid="register-address-input"
        name="address"
        autocomplete="off"
        :class="{ 'is-invalid': !!error }"
        :placeholder="$t('Street')"
        @focus="onInputFocused()"
        @focusout="$emit('focusout')"
        @input="onInputChanged($event.target.value)"
        @keydown.tab="onInputTab($event)"
      >
      <label
        data-testid="register-address-label"
        class="places-autocomplete-input-label ds-pointer-events-none"
      >{{ $t('Address') }}
      </label>
      <div
        v-if="isLoading"
        class="ds-absolute ds-top-2.5 ds-right-0"
      >
        <LoaderIcon :small="true" />
      </div>
      <div
        v-if="isDropdownActivated"
        class="ds-absolute ds-bg-white ds-z-10 ds-w-full"
      >
        <div
          data-testid="dropdownIsActivated"
          class="ds-flex ds-flex-wrap ds-items-center ds-my-3"
        >
          <button
            class="ds-underline ds-mb-1 hover:ds-outline-none focus:ds-outline-none hover:ds-opacity-80 focus:ds-opacity-80"
            data-testid="useAnyways"
            @click.prevent="selectAnyway"
            @keydown="onSelectAnywayTab($event)"
          >
            {{ $t('I don’t find my address') }}
          </button>
        </div>

        <ul class="places-autocomplete--dropdown-menu">
          <li
            v-for="(option, i) in results"
            :key="i"
          >
            <button
              class="ds-px-3 ds-py-2 ds-w-full hover:ds-outline-none hover:ds-bg-neutral-300 focus:ds-outline-none focus:ds-bg-neutral-300"
              :class="{
                'ds-bg-warm-white': isSelected(option),
              }"
              role="option"
              type="button"
              data-testid="placesAutocompleteDropdownItem"
              @click.prevent="selectOption(option)"
              @keydown.tab="onDropdownButtonTab(i)"
            >
              <div
                :class="[
                  'ds-text-neutral-900 ds-mb-1 ds-text-left',
                  {
                    'ds-font-semibold': isSelected(option),
                  },
                ]"
              >
                <AkIcon
                  symbol="geo-alt"
                  size="sm"
                />
                {{ option.description }}
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// @ts-nocheck
import { vOnClickOutside } from '@vueuse/components';
import { defineComponent } from 'vue';
import { CountryIsoCode } from '@core/types/country';
import { debounce } from 'lodash-es';
import { AsyncScript, initScript } from '@monolith/legacy/modules/shared/services/load-async-script';
import GoogleMapsAutocomplete from '@monolith/legacy/services/places/GoogleMapsAutocomplete';
import { FeatureFlag } from '@monolith/legacy/services/features';
import { QuestionType } from '@monolith/legacy/types/question';
import LoaderIcon from '@monolith/legacy/components/loader-icon.vue';
import InputSearch from '@monolith/legacy/components/sign-up/common/autocomplete/input-search.vue';
import AutocompleteDropdown from '@monolith/legacy/components/sign-up/common/autocomplete/autocomplete-dropdown.vue';

export default defineComponent({
  name: 'AkPlacesAutocomplete',
  components: { LoaderIcon, InputSearch, AutocompleteDropdown },
  directives: { vOnClickOutside },
  props: {
    countryCode: {
      type: String as () => CountryIsoCode,
      required: false,
      default: 'FR',
    },
    model: {
      type: String,
      required: false,
      default: '',
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    isRevamp: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['change', 'update-place', 'address-not-found', 'focusout'],
  data() {
    return {
      searchTerm: this.model,
      selectedOption: {} as google.maps.places.QueryAutocompletePrediction,
      selectedOptionId: 0,
      results: [] as google.maps.places.QueryAutocompletePrediction[],
      isComponentFocused: false,
      isLoading: false,
      autocompleteService: {} as GoogleMapsAutocomplete,
      FeatureFlag,
    };
  },
  computed: {
    isDropdownActivated() {
      return this.isComponentFocused && !!this.searchTerm.length;
    },
  },
  watch: {
    model(value) {
      this.searchTerm = value;
    },
    countryCode() {
      this.setCountryCode();
    },
  },
  mounted() {
    initScript(AsyncScript.GOOGLE_MAP, this.initFunction);
    this.setCountryCode();
  },
  methods: {
    async initFunction() {
      this.autocompleteService = new GoogleMapsAutocomplete();
      if (this.searchTerm.length) {
        this.search();
      }
    },
    onInputFocused() {
      this.isComponentFocused = true;
    },
    setCountryCode() {
      this.autocompleteService.setCountryRestriction(this.countryCode);
    },
    onInputChanged(value) {
      this.searchTerm = value;
      this.$emit('change', value, 'address');
      this.$emit('change', value, 'street');
      this.$emit('change', value, QuestionType.GoogleMapAutocomplete);
      if (this.searchTerm.length) {
        this.isLoading = true;
        this.debouncedSearch();
      }
    },
    debouncedSearch: debounce(function (this: Vue) {
      this.search();
    }, 500),
    async search() {
      this.autocompleteService
        .getResults(this.searchTerm)
        .then((results) => {
          this.results = results;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async selectOption(option: google.maps.places.QueryAutocompletePrediction, index: number) {
      try {
        const place = await this.autocompleteService.getPlace(option.place_id);
        this.$emit('update-place', place);
        this.selectedOption = option;
        this.selectedOptionId = index + 1;
        this.searchTerm = option.description;
        this.close();
      } catch {
        this.selectAnyway();
      }
    },
    selectAnyway() {
      this.$emit('address-not-found', this.searchTerm);
      this.close();
    },
    close() {
      this.isComponentFocused = false;
      this.isLoading = false;
    },
    isSelected(option: google.maps.places.QueryAutocompletePrediction) {
      return option.place_id === this.selectedOption.place_id;
    },
    onInputTab(event) {
      if (event.shiftKey || !this.isDropdownActivated) {
        this.close();
      }
    },
    onSelectAnywayTab(event) {
      if (!event.shiftKey && !this.results.length) {
        this.close();
      }
    },
    onDropdownButtonTab(index) {
      if (index === this.results.length - 1) {
        this.close();
      }
    },
  },
});
</script>
<style scoped lang="scss">
.form-control {
  height: 35px;
}

.places-autocomplete-input {
  @apply ds-pr-5 #{!important};

  &:focus ~ .places-autocomplete-input-label {
    @apply ds-top-0;
  }
}

.places-autocomplete--dropdown-menu {
  @apply ds-mt-2 ds--mx-3 ds-mb-0 ds-border ds-border-neutral-300 ds-bg-white ds-border-solid ds-overflow-auto ds-w-auto;
  max-height: 237px;
  scrollbar-color: rgba(200, 196, 191, var(--tw-bg-opacity)) transparent;
  scrollbar-width: 8px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply ds-bg-neutral-500 ds-rounded-full;
  }
}
</style>
