import type { SearchState } from './index';
import type { SearchGetters } from './getters';
import type { SearchActions } from './actions';
import type { SearchMutations } from './mutations';

import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { SEARCH_STORE_ID } from "./constants";

const { useGetters, useActions, useMutations } = createNamespacedHelpers<
  SearchState,
  SearchGetters,
  SearchActions,
  SearchMutations
>(SEARCH_STORE_ID);

export default {
  useGetters,
  useActions,
  useMutations,
};
