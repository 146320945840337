<template>
  <FiltersSidebar
    class="filter-sidebar"
    :mobile-button-label="$t('All filters')"
    :mobile-heading="$t('All filters')"
  >
    <slot name="topSection" />
    <div class="filter-sidebar__section filter-sidebar__section--first">
      <FilterHeading class="filter-sidebar__section-heading">
        <template #title>
          {{ $t('retailer.catalog.filterSidebar.selectedFilters.title') }}
        </template>
      </FilterHeading>
      <SelectedFilters
        :facets="facets"
        :label-overrides="labelOverrides"
      />
      <slot name="savedSearch" />
    </div>

    <div
      v-if="showViewFilter"
      class="filter-sidebar__section"
    >
      <FilterHeading class="filter-sidebar__section-heading">
        <template #title>
          {{ $t('View') }}
        </template>
      </FilterHeading>
      <RadioFilter
        :items="views"
        @changed="(item) => notifyViewChanged(item)"
      />
    </div>

    <div
      v-if="showCategories"
      class="filter-sidebar__section"
      data-testid="categoryFilter"
    >
      <FilterHeading class="filter-sidebar__section-heading">
        <template #title>
          {{ $t('Categories') }}
        </template>
      </FilterHeading>
      <AkExpander
        :keep-alive="true"
        :open="true"
      >
        <CustomAisHierarchicalLinkFilter
          :attributes="[
            'catalog_hierarchical_categories.v3.lvl0',
            'catalog_hierarchical_categories.v3.lvl1',
            'catalog_hierarchical_categories.v3.lvl2',
          ]"
          :label-overrides="labelOverrides"
          @item-clicked="notifyCategoryClicked"
        />
      </AkExpander>
    </div>

    <ais-current-refinements :transform-items="transformRefinementItems">
      <template #default="{ items }">
        <ExpandableFilters
          :filters="items"
          :default-open="false"
        >
          <template #refinement="{ filter }">
            <CustomAisCheckbox
              :attribute="filter.name"
              :sort-by="createSorter(filter)"
              :transform-items-fn="(item, filter) => transformCheckboxItems(item, filter)"
              :filter="filter"
            />
          </template>
          <template #priceRange="{ filter }">
            <CustomAisPriceRangeFilter :filter="filter" />
          </template>
          <template #numeric="{ filter }">
            <CustomAisNumericFilter :filter="filter" />
          </template>
          <template #hierarchicalMultiSelect="{ filter }">
            <CustomAisHierarchicalCheckboxFilter
              :attribute="filter.name"
              :options="locations"
              class="filter-sidebar__filter"
            >
              <template #default="{ options, selectedOptions, facetCounts, refine }">
                <LocationFilter
                  show-counts
                  :show-heading="false"
                  :grid="false"
                  :locations="options"
                  :selected-locations="selectedOptions"
                  :location-facet-counts="facetCounts"
                  :search-bar-enabled="true"
                  class="ds-pr-2"
                  @selected-locations-changed="refine"
                />
              </template>
            </CustomAisHierarchicalCheckboxFilter>
          </template>
          <template #boolean="{ filter }">
            <CustomAisBooleanFilter :filter="filter" />
          </template>
          <template #color="{ filter }">
            <CustomAisColorFilter
              :filter="filter"
              :label-overrides="labelOverrides"
              :sort-by="createSorter(filter)"
            />
          </template>
        </ExpandableFilters>
      </template>
    </ais-current-refinements>
  </FiltersSidebar>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { AisCurrentRefinements } from 'vue-instantsearch/vue3/es';
import { FilterHeading, ExpandableFilters, FiltersSidebar, RadioFilter } from '@bc/discovery/ui/filters';
import CustomAisCheckbox from '../custom-ais-checkbox/custom-ais-checkbox.vue';
import CustomAisPriceRangeFilter from '../custom-ais-price-range-filter.vue';
import CustomAisNumericFilter from '../custom-ais-numeric-filter.vue';
import CustomAisBooleanFilter from '../custom-ais-boolean-filter.vue';
import CustomAisColorFilter from '../custom-ais-color-filter/custom-ais-color-filter.vue';
import { Facet, FacetGroup } from '@bc/discovery/domain/catalog';
import type { Item, Filter } from '@bc/discovery/ui/filters';
import type { Refinement } from '../../types/refinement';
import type { LabelOverrides } from '../../types/label-overrides';
import { createFilters } from '../../utils/create-filters';
import { overrideFilterLabels } from '../../utils/override-labels';
import { createSorter } from '../../utils/sort';
import SelectedFilters from '../selected-filters/selected-filters.vue';
import CustomAisHierarchicalLinkFilter from '../custom-ais-hierarchical-link-filter.vue';
import { AkExpander } from '@ankorstore/design-system';
import { useEventBus } from '@bc/shared';
import { DiscoveryTrackingEvents, NavigationMethodType } from '@bc/discovery/domain/tracking';
import CustomAisHierarchicalCheckboxFilter from '../custom-ais-hierarchical-multis-select/custom-ais-hierarchical-multi-select.vue';
import LocationFilter from '../location/location-filter.vue';
import { getLocations } from '@bc/discovery/domain/catalog';

const props = withDefaults(
  defineProps<{
    facets: (Facet | FacetGroup)[];
    labelOverrides?: LabelOverrides;
    showCategories?: boolean;
    currentView?: 'products' | 'brands';
    showViewFilter?: boolean;
  }>(),
  {
    labelOverrides: () => ({}),
    showViewFilter: false,
    currentView: 'products',
  }
);

const transformCheckboxItems = (items: Item[], filter: Filter) => overrideFilterLabels(filter, props.labelOverrides, items);
const transformRefinementItems = (items: Refinement[]) => createFilters(props.facets, items);

// ### View filter ###
const { t } = useI18n();
const views = computed((): Item[] => [
  { label: t('Products'), value: 'products', isRefined: props.currentView === 'products', count: undefined, data: undefined },
  { label: t('Brands'), value: 'brands', isRefined: props.currentView === 'brands', count: undefined, data: undefined },
]);
const emit = defineEmits(['viewChanged']);
const notifyViewChanged = (item: Item) => emit('viewChanged', item.value);
// ### View filter END ###

const { eventBus } = useEventBus();

const notifyCategoryClicked = (item: Item) => {
  const categoryId = item.value.split(' > ').at(-1);
  eventBus.emit(DiscoveryTrackingEvents.CATEGORY_FILTER_CLICKED, {
    categoryId,
    navigationMethod: NavigationMethodType.Filter,
  });
};

const locations = ref([]);

onMounted(async () => {
  locations.value = await getLocations();
});
</script>

<style lang="scss">
.filter-sidebar {
  &__section {
    border: 1px none theme('colors.neutral.300');
    border-bottom-style: solid;
    padding: 1.5rem 0;
    &--first {
      @apply ds-pt-0;
    }
  }

  &__section:first-child {
    padding-top: 0;
  }

  &__section-heading {
    margin-bottom: 1.5rem;
  }

  &__filter {
    max-height: 291px;
    overflow-y: auto;
  }
}
</style>
