import { NavigationGuardNext } from 'vue-router';
import Store from '@monolith/legacy/store';
import { SaveBarMode } from '@monolith/legacy/store/account/types';
import { getCampaign } from '../services/api';
import { CreateCampaignStatusEnum } from '@ankorstore/api-internal/data-contracts';

export async function campaignCreateGuard(to, _from, next: NavigationGuardNext) {
  to.params.defaultSegment = to.query.defaultSegment;
  to.params.defaultLanguage = to.query.defaultLanguage;
  Store.dispatch('account/contactManagement/fetchFilters');
  Store.dispatch('account/changeSaveBarMode', SaveBarMode.CreateCampaign);
  await Store.dispatch('account/contactManagement/fetchContacts', true);
  next();
}

export async function campaignEditGuard(to, _from, next: NavigationGuardNext) {
  try {
    const campaign = await getCampaign(to.params.campaignId);
    if (campaign?.attributes?.status !== CreateCampaignStatusEnum.Draft) {
      next({ name: 'campaigns' });
    } else {
      to.params.draft = campaign;
      to.params.defaultLanguage = campaign.attributes.content.defaultLanguage;

      if (campaign?.attributes?.audience?.filters?.length) {
        const filtersPayload = {};
        campaign.attributes.audience.filters.forEach((filter) => {
          filtersPayload[filter.name] = filter;
        });
        Store.dispatch('account/contactManagement/resetFilters', filtersPayload);
      }
      Store.dispatch('account/contactManagement/fetchFilters');
      Store.dispatch('account/changeSaveBarMode', SaveBarMode.CreateCampaign);
      await Store.dispatch('account/contactManagement/fetchContacts', true);
      next();
    }
  } catch (err) {
    next({ name: 'campaign-add' });
  }
}
