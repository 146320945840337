<template>
  <div class="reorder-product-card">
    <ReorderNotification ref="reorderNotification">
      {{ reorderNotificationMessage }}
    </ReorderNotification>
    <a
      class="reorder-product-card__wrapper"
      :href="product.link"
      @click.self.prevent
    >
      <AkProductCard
        :image="image"
        :name="product.name"
        :brand-name="brandName"
        :price="price"
        :price-discounted="priceDiscounted"
        :unit-multiplier="i18n.t('reorderProductCard.unitMultiplier', product.unit_multiplier)"
        :show-add-to-favorite="isEnabled('like_products_and_brands')"
        :favorite="isLiked"
        :is-lock="isLock"
        lock-label=""
        :disabled="false"
        :badges="badges"
        @add-to-favorite="addToFavorite"
        @click-product-card="clickProductCard"
        @click-brand="clickBrandName"
      >
        <template #extra>
          <AkParagraph
            size="xs"
            class="reorder-product-card__variant-available"
            :class="isPreorder(product) ? 'ds-mb-2' : 'ds-h-5'"
          >
            <span v-if="hasAvailableVariantLabel">
              {{ availableVariantLabel }}
            </span>
          </AkParagraph>
          <div
            v-if="isPreorder(product) && !isProductOutOfStock(product)"
            data-testid="reorderProductCardPreorderDate"
            class="reorder-product-card__preorder"
          >
            <AkIcon
              ref="iconTruck"
              symbol="truck"
            />
            <span>
              {{ `${$t('reorderProductCard.shippingDate.lbl')} ${formatDate(getPreorderAvailableAt(product), 'dd/MM/yyyy')}` }}
            </span>
          </div>
          <div
            v-if="showReorder"
            class="reorder-product-card__actions"
            @click.stop.prevent
          >
            <RestockAlert
              v-if="isProductOutOfStock(product)"
              :product="product"
              :restock-alerts="restockAlerts"
              @set-restock-alert="showRestockAlertMessage"
            />
            <ProductQuantity
              v-else-if="!hasVariants"
              :product="product"
              :brand="brand"
              :tracking-data="trackingData"
              @cart-action="cartAction"
            />
            <MultivariantSelector
              v-else
              :product="product"
              :brand="brand"
              :product-id="product.id"
              :price-breakdown="priceBreakdown"
              :tracking-data="trackingData"
            />
          </div>
        </template>
      </AkProductCard>
    </a>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from 'vue';
import { AkBadgeColor, AkParagraph, AkProductCard } from '@ankorstore/design-system';
import { OrderedProduct } from '@monolith/legacy/types/re-order';
import { imgCdnUrlFill } from '@bc/shared';
import useI18n from '@core/composables/use-i18n';
import { useRouter } from '@core/composables/use-router';
import { isEnabled } from '@monolith/legacy/services/features';
import StoreHelpers from '@monolith/legacy/store/helpers';
import { PriceBreakdown, ProductDiscount } from '@monolith/legacy/types/product';
import { getDiscount, getPriceBreakdown } from '@monolith/legacy/services/utils/tooling';
import Dinero from 'dinero.js';
import usePrice from '@core/composables/use-price';
import StoreOfferHelpers from '@monolith/legacy/store/offers/helpers';
import {
  getMainVariant,
  getPreorderAvailableAt,
  getVariantValuesByOptionName,
  isPreorder,
  isProductOutOfStock,
} from '@monolith/legacy/services/product-variants';
import { getVariantTypeLabel } from '@monolith/legacy/utilities/variant';
import MultivariantSelector from '@monolith/legacy/modules/reorder/product-card/multivariant-selector.vue';
import ProductQuantity from '@monolith/legacy/modules/reorder/product-card/product-quantity/product-quantity.vue';
import ReorderNotification from '@monolith/legacy/modules/reorder/product-card/reorder-notification.vue';
import RestockAlert from '@monolith/legacy/modules/reorder/product-card/restock-alert.vue';
import { StockAlert } from '@monolith/legacy/services/back-in-stock/back-in-stock';
import {
  ReorderProductCardActions,
  ReorderProductCardTrackingData,
} from '@monolith/legacy/modules/reorder/product-card/tracking';
import Analytics from '@monolith/legacy/services/analytics';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';
import { formatDate } from '@monolith/legacy/services/date-locale';

const emit = defineEmits(['brandClicked', 'productClicked']);

const props = defineProps({
  product: {
    type: Object as PropType<OrderedProduct>,
    required: true,
  },
  brandId: {
    type: Number,
    required: true,
  },
  brandName: {
    type: String,
    required: true,
  },
  restockAlerts: {
    type: Array as PropType<StockAlert[]>,
    required: true,
  },
  trackingData: {
    type: Object as PropType<ReorderProductCardTrackingData>,
    required: true,
  },
});

const i18n = useI18n();
const router = useRouter();
const { formatPrice } = usePrice();

const { canGetWholesalePrice } = StoreHelpers.useGetters(['canGetWholesalePrice']);
const { productIsLiked } = StoreHelpers.useGetters(['productIsLiked']);
const { likeProduct, unLikeProduct } = StoreHelpers.useActions(['likeProduct', 'unLikeProduct']);
const { appliedDiscount } = StoreOfferHelpers.useGetters(['appliedDiscount']);
const { userCurrency } = StoreHelpers.useGetters(['userCurrency']);

const brandDiscount = computed((): ProductDiscount | null => {
  const discount = props.product?.discount_rate;

  return discount ? getDiscount(discount, 'brand') : null;
});

const totalDiscount = computed((): number => {
  const discounts: number[] = [];

  if (brandDiscount.value?.percentage) {
    discounts.push(brandDiscount.value?.percentage);
  }

  if (appliedDiscount.value?.percentage) {
    discounts.push(appliedDiscount.value?.percentage);
  }

  return discounts.reduce((previous, current) => previous + current, 0);
});

const brand = computed(() => {
  return {
    id: props.brandId,
    name: props.brandName,
  };
});

const priceBreakdown = computed((): PriceBreakdown => {
  const { original_wholesale_price, retail_price, wholesale_price } = props.product;

  return getPriceBreakdown(
    Dinero(original_wholesale_price).toObject(),
    Dinero(retail_price).toObject(),
    Dinero(wholesale_price).toObject(),
    brandDiscount.value,
    appliedDiscount.value
  );
});

const hasDiscount = computed((): boolean => {
  return priceBreakdown.value?.originalPrice?.amount !== priceBreakdown.value?.finalPrice?.amount;
});

const price = computed((): string => {
  return formatPrice(priceBreakdown.value?.originalPrice);
});

const priceDiscounted = computed((): string | null => {
  if (hasDiscount.value) {
    return formatPrice(priceBreakdown.value?.finalPrice);
  }

  return null;
});

const isLiked = computed((): boolean => {
  return productIsLiked.value(props.product?.id);
});

function addToFavorite() {
  if (isLiked.value) {
    unLikeProduct({ id: props.product?.id });
  } else {
    likeProduct({ id: props.product?.id });
  }
}

const badges = computed((): object[] => {
  const result: object[] = [];

  if (isProductOutOfStock(props.product)) {
    result.push({
      content: i18n.t('reorderProductCard.badge.outOfStock'),
      color: 'red-full',
    });

    return result;
  }

  if (totalDiscount.value) {
    result.push({
      content: totalDiscount.value + '%',
      color: 'purple-full',
    });
  }

  if (isPreorder(props.product)) {
    result.push({
      content: i18n.t('reorderProductCard.badge.preorder'),
      color: AkBadgeColor.Cold,
      symbol: 'calendar-date',
    });
  }

  return result;
});

const hasAvailableVariantLabel = computed((): boolean => {
  return !isProductOutOfStock(props.product) && props.product.variants && !!getMainVariant(props.product.variants);
});

const hasVariants = computed((): boolean => {
  return props.product?.variants?.length > 1;
});

const availableVariantLabel = computed((): string => {
  const mainOptionName = getMainVariant(props.product?.variants)?.name;
  const values = getVariantValuesByOptionName(props.product?.variants, mainOptionName);
  return getVariantTypeLabel(mainOptionName, values.length);
});

const image: object = {
  src: imgCdnUrlFill(props.product?.images[0], '300'),
  alt: props.product?.name,
  title: props.product?.name,
};

const isLock = computed((): boolean => {
  return !canGetWholesalePrice.value;
});

const showReorder = computed(
  (): boolean =>
    !isLock.value &&
    (!isPreorder(props.product) || (isPreorder(props.product) && isEnabled('oxp-1484-r3') && userCurrency.value === 'EUR'))
);

function clickProductCard() {
  emit('productClicked');
  Analytics.track(
    new UserClick({
      component: props.trackingData.parentComponent,
      action: ReorderProductCardActions.PRODUCT_CLICKED,
      id_product: props.product?.id,
      id_section: props.trackingData.sectionId,
    })
  );
  router.push(props.product?.link);
}

function clickBrandName() {
  emit('brandClicked');
  router.push(props.product?.brand?.link);
}

const reorderNotification = ref();
const reorderNotificationMessage = ref<string>(null);
const productsCount = ref(0);
const cartAction = (amount) => {
  productsCount.value = amount;
  reorderNotificationMessage.value = i18n.t('reorderProductCard.notification.addedToCart', productsCount.value);
  reorderNotification.value.openNotification();
};

function showRestockAlertMessage() {
  reorderNotificationMessage.value = i18n.t('reorderProductCard.notification.restockAlertSet');
  reorderNotification.value.openNotification();
}
</script>

<style lang="scss" scoped>
.reorder-product-card {
  @apply ds-relative ds-flex ds-flex-col;

  &__preorder {
    @apply ds-text-neutral-700 ds-inline-block ds-mb-2;
    font-size: 12px;
    i {
      @apply ds-inline-block;
      margin-top: -1px;
      vertical-align: top;
    }
  }
}

.reorder-product-card__wrapper {
  @apply ds-flex;
  flex-grow: 1;
}

.reorder-product-card__variant-available {
  @apply ds-text-neutral-700;
}

.reorder-product-card__actions:not(:empty) {
  @apply ds-pt-4;
  margin-top: auto;
}

.reorder-product-card .product-card {
  @apply ds-bg-white;
}

.reorder-product-card :deep(.product-card__extra-container) {
  @apply ds-flex ds-flex-col;
  flex-grow: 1;
  border: none;
  background: none;
}
</style>

<style lang="scss">
.reorder-product-card .product-card__thumbmails {
  @apply ds-border ds-border-solid ds-border-neutral-300;
}

.reorder-product-card .product-card__content {
  @apply ds-flex ds-flex-col;
  flex-grow: 1;
}

.reorder-product-card .product-card__title {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.reorder-product-card .product-card__brand-name {
  @apply ds-text-xs;
}
</style>
