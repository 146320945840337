<template>
  <component
    :is="tag"
    v-if="text"
  >
    <template
      v-for="(line, i) in lines"
      :key="i"
    >
      <br v-if="i > 0">
      {{ line }}
    </template>
  </component>
</template>

<script lang="ts">
// this is replacement of functional component from library vue-nl2br (functional components are not compatible with Vue 3)
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'Nl2br',
  props: {
    tag: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const lines = computed((): string[] => {
      return props.text.split('\n');
    });

    return {
      lines,
    };
  },
});
</script>
