<template>
  <div
    class="last-batch-status"
    data-testid="lastBatchStatus"
  >
    <AkAlert
      v-if="isVisible"
      :hide-icon="true"
      :type="alertStatus"
    >
      <div class="last-batch-status__alert">
        <div class="content">
          <div class="content__header">
            <LoaderIcon
              v-if="isInProgress"
              class="content__header__loader"
            />

            <span :class="['title', `title--${alertStatus}`]">
              {{ bannerTitle }}
              <span
                v-if="isCompletedWithPending"
                :class="['title__completed-with-pending', { 'title--link': activeTab === 'products-list' }]"
                @click="goToDrafts()"
              >
                {{ $tc('brand.account.products.alert.completedWithPending.failedCount', batch.product_pending_updates_count) }}
              </span>
            </span>

            <span
              v-if="startedDate"
              class="date"
            >
              <template v-if="isInProgress">{{ $t('Started') }}:</template>{{ startedDate }}
            </span>
          </div>
          <slot
            v-if="isCompleted"
            name="completed"
          />
          <slot
            v-if="isFailed"
            name="failed"
          />
          <slot
            v-if="isInProgress"
            name="inprogress"
          />
          <slot
            v-if="isCompletedWithPending"
            name="completedWithPending"
          />
        </div>
        <AkIcon
          v-if="!isInProgress"
          class="content__close"
          symbol="x"
          size="md"
          @click="close()"
        />
      </div>
    </AkAlert>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  BatchStatusTitle,
  BatchStatusType,
  LastBatchStatus,
} from '@monolith/legacy/types/account/account-products/catalog-integration/product-integration';
import { AkAlert } from '@ankorstore/design-system';
import { AlertType } from '@ankorstore/design-system';
import { formatDate } from '@monolith/legacy/services/date-locale';
import LoaderIcon from '@monolith/legacy/components/loader-icon.vue';
import { TabAvailable } from '@monolith/legacy/components/account/account-products/products-header.vue';
import { UserDisplayEvent, UserDisplayEventAction } from '@monolith/legacy/services/analytics/events/user-display.event';
import Analytics from '@monolith/legacy/services/analytics';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'LastBatchStatus',
  components: {
    AkAlert,
    LoaderIcon,
  },
  props: {
    isDisplayed: {
      type: Boolean,
      required: true,
      default: false,
    },
    title: {
      type: Object as () => BatchStatusTitle,
      required: true,
    },
    status: {
      type: Object as () => LastBatchStatus,
      required: true,
    },
    listenTo: {
      type: [String, Array],
      required: true,
    },
    fetchBatch: {
      type: Function,
      default: null,
    },
    batchType: {
      type: String as () => BatchStatusType,
      default: null,
    },
    initBatch: {
      type: Object,
      required: false,
      default: null,
    },
    activeTab: {
      default: TabAvailable.Products,
      type: String as () => TabAvailable,
      required: false,
    },
  },
  emits: ['toggle', 'goToDrafts', 'onPollStop'],
  data: function () {
    return {
      pollInterval: null,
      batch: null,
    };
  },
  computed: {
    ...mapGetters(['brand']),
    ...mapGetters('account/productsIntegration', {
      draftWithErrorItems: 'getAllNonReadyDraftsCount',
    }),
    isVisible(): boolean {
      return this.isDisplayed && this.batch !== null;
    },
    startedDate(): string {
      return this.batch?.created_at ? formatDate(this.batch?.created_at, 'Pp') : null;
    },
    bannerTitle(): string {
      if (this.isCompleted) {
        return this.title.completed;
      } else if (this.isFailed) {
        return this.title.error;
      } else if (this.isCompletedWithPending) {
        return this.$t('brand.account.products.alert.completedWithPending.title');
      }
      return this.title.in_progress;
    },
    isCompleted(): boolean {
      if (this.batchType === BatchStatusType.IMPORT) {
        return this.status.completed.includes(this.batch?.status) && this.batch?.product_pending_updates_count === 0;
      }
      return this.status.completed.includes(this.batch?.status);
    },
    isFailed(): boolean {
      return this.status.error.includes(this.batch?.status);
    },
    isInProgress(): boolean {
      return !this.status.completed.includes(this.batch?.status) && !this.isFailed;
    },
    /**
     * isCompletedWithPending
     *
     * only for IMPORT type of batches
     * @returns if import is completed and some items need update
     */
    isCompletedWithPending(): boolean {
      if (this.batchType === BatchStatusType.IMPORT) {
        return this.status.completed.includes(this.batch?.status) && this.batch?.product_pending_updates_count > 0;
      }
      return false;
    },
    alertStatus(): AlertType {
      if (this.isCompleted && !this.isCompletedWithPending) {
        return AlertType.Info;
      } else if (this.isFailed) {
        return AlertType.Error;
      } else if (this.isCompletedWithPending) {
        return AlertType.Warning;
      }
      return AlertType.Info;
    },
  },
  watch: {
    isCompleted(newVal) {
      if (newVal && this.isVisible && this.draftWithErrorItems > 0) {
        this.trackDraftsWithErrorsBannerIsVisible();
      }
    },
  },
  created() {
    this.unsubscribe();
    this.batch = this.initBatch;
    this.setPollInterval();
  },
  beforeUnmount() {
    this.unsubscribe();
    clearInterval(this.pollInterval);
    this.pollInterval = null;
  },
  methods: {
    setPollInterval(): void {
      if (this.isInProgress && this.pollInterval === null) {
        this.pollInterval = setInterval(async () => this.getLastBatch(), 10000);
        this.$emit('toggle', this.batchType, true);
      }
    },
    getLastBatch(): void {
      this.fetchBatch(this.workflow).then((response) => {
        this.batch = response;
        if (!this.isInProgress && this.pollInterval !== null) {
          clearInterval(this.pollInterval);
          this.pollInterval = null;
          this.$emit('onPollStop');
        } else {
          this.setPollInterval();
        }
      });
    },
    unsubscribe() {
      this.$store.subscribeAction({
        after: (action) => {
          if (
            (typeof this.listenTo === 'string' && action.type === this.listenTo) ||
            (Array.isArray(this.listenTo) && this.listenTo.includes(action.type))
          ) {
            this.getLastBatch();
          }
        },
      });
    },
    close(): void {
      this.$emit('toggle', this.batchType);
    },
    /**
     * goToDrafts
     *
     * Emits event goToDrafts
     */
    goToDrafts(): void {
      if (this.activeTab === TabAvailable.Products) {
        this.$emit('goToDrafts');
      }
    },
    trackDraftsWithErrorsBannerIsVisible(): void {
      Analytics.track(
        new UserDisplayEvent({
          component: 'brand_bo_catalogue_management',
          action: UserDisplayEventAction.DISPLAY,
          value: this.brand?.id.toString(),
        })
      );
    },
  },
});
</script>

<style lang="scss">
.last-batch-status {
  @apply ds-mb-4;

  .last-batch-status__alert {
    @apply ds-flex ds-items-start;
  }
  .content {
    @apply ds-text-base ds-flex-1 ds-text-primary;

    &__header {
      @apply ds-flex ds-items-center ds-gap-x-2;

      .icon-download {
        @apply ds-text-success-700;
      }

      .date {
        @apply ds-text-neutral-700 ds-text-sm;
      }
      .title {
        @apply ds-font-bold ds-text-base;
        &--error {
          @apply ds-text-error-700;
        }
        &--warning {
          @apply ds-text-primary;
        }
        &--success {
          @apply ds-text-success-700;
        }
        &--info {
          @apply ds-text-primary;
        }
        &--link {
          @apply ds-text-info-700 ds-underline ds-cursor-pointer;
        }
      }
      &__loader {
        @apply ds-text-info-700 ds-w-4 ds-h-4 ds-m-0;

        & > div {
          @apply ds-h-4 ds-w-4 ds-m-0;
        }
      }
    }

    &__text {
      @apply ds-text-neutral-900 ds-font-normal ds-text-sm ds-block;
    }

    &__close {
      @apply ds-flex ds-cursor-pointer ds-leading-5;
      @apply ds-transition-opacity hover:ds-opacity-75 ds-text-primary;
    }

    &__list {
      @apply ds-flex ds-gap-x-4 ds-text-sm ds-items-center ds-mb-0;

      li {
        @apply ds-flex ds-items-center;

        &.content-hide {
          @apply ds-hidden;
        }
      }

      i {
        @apply ds-text-base;
        &.warning {
          @apply ds-text-warning-700;
        }
      }
    }
  }
}
</style>
