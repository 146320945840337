import type { AxiosInstance } from 'axios';
import { memoize } from 'lodash-es';

import type { CategoryResult, PopularSearch, ServiceResponse, SuggestionServiceApiParams } from './types';

export class SearchService {
  constructor(private readonly http: AxiosInstance) {}

  public fetchAll = memoize(this.__fetchAll);
  public fetchPopularSearches = memoize(this.__fetchPopularSearches);
  public fetchCategories = memoize(this.__fetchCategories);

  private async __fetchAll({
    query = '',
    country,
    filters = ['brands', 'popularSearches', 'boutiques', 'categories'],
    locale,
  }: SuggestionServiceApiParams): Promise<ServiceResponse> {
    const response = await this.http.get(this.getSuggestionsUrl({ query, filters, country, locale }), {
      noProgressBar: true,
    });
    return response?.data?.data ?? response?.data;
  }

  private async __fetchPopularSearches({
    query = '',
    country,
    locale,
  }: Omit<SuggestionServiceApiParams, 'filters'>): Promise<Omit<PopularSearch, 'id' | 'link'>[]> {
    const response = await this.http.get(this.getSuggestionsUrl({ query, filters: ['popularSearches'], country, locale }), {
      noProgressBar: true,
    });

    const data = response?.data?.data ?? response?.data;

    return data?.popularSearches;
  }

  private async __fetchCategories({
    query = '',
    country,
    locale,
  }: Omit<SuggestionServiceApiParams, 'filters'>): Promise<Omit<CategoryResult, 'id'>[]> {
    const response = await this.http.get(this.getSuggestionsUrl({ query, filters: ['categories'], country, locale }), {
      noProgressBar: true,
    });
    const data = response?.data?.data ?? response?.data;
    return data?.categories;
  }

  private getSuggestionServiceBaseUrl(): string {
    return window.location.origin;
  }

  private getSuggestionsUrl(params: SuggestionServiceApiParams) {
    const url = new URL(`${this.getSuggestionServiceBaseUrl()}/api/suggestions/v1`);
    url.searchParams.set('locale', params.locale);

    if (params.query) {
      url.searchParams.set('query', params.query);
    }

    if (params.filters) {
      params.filters.forEach((filter) => {
        url.searchParams.append(`filters`, filter);
      });
    }

    if (params.country) {
      url.searchParams.set('country', params.country.toLowerCase());
    }

    return url.href;
  }
}
