import AnalyticsEvent from './analytics-event';
import type { SearchBarEvent, SearchBarItemTracking } from '@bc/discovery/domain/search';

export class SearchBarResultItemClickEvent extends AnalyticsEvent {
  public readonly name = 'User Click';
  public properties: SearchBarItemTracking;

  constructor({
    action,
    query,
    brandId,
    categoryId,
  }: {
    action: SearchBarEvent;
    query: string;
    brandId?: string | number;
    categoryId?: string | number;
  }) {
    super();
    this.properties = {
      component: 'search bar',
      action,
      brand_id: brandId,
      search_term: query,
      category_id: categoryId,
    };
  }
}

export default SearchBarResultItemClickEvent;
