<template>
  <div class="ak-progress-bar">
    <div
      class="ak-progress-bar__progress"
      :style="progressBarStyle"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AkProgressBar',
  props: {
    fillColour: {
      type: String,
      default: '#0B00F4',
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    progressBarStyle(): { 'background-color': string; width: string } {
      return {
        'background-color': this.fillColour,
        width: this.value + '%',
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.ak-progress-bar {
  @apply ds-w-full ds-h-2.5 ds-rounded-full ds-bg-neutral-300;

  &__progress {
    @apply ds-h-2.5 ds-rounded-full;
  }
}
</style>
