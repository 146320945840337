<template>
  <div class="section-proof-article">
    <a
      class="section-proof-article__image"
      :href="item.href"
      target="_blank"
    >
      <img
        :src="item.image.url"
        :alt="item.image.alt"
      >
    </a>
    <AkParagraph
      class="section-proof-article__text"
      size="sm"
    >
      {{ item.description }}
    </AkParagraph>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SocialProofElement',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@css/vue-import';

.section-proof-article {
  @apply ds-flex ds-flex-col ds-items-center ds-mx-6;

  @include media-breakpoint-up(lg) {
    max-width: calc(25% - 4rem);
    height: 11rem;
  }

  &__image {
    @apply ds-h-7 ds-flex ds-items-center;
  }

  &__text {
    @apply ds-text-center ds-pt-4;
  }
}
</style>
