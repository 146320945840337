import { Sort } from '@monolith/legacy/types/sorting';
import { SortColumns } from '../types/contacts.types';

export const ENABLED_FILTERS: string[] = [
  'firstOrderInterval',
  'ordering.lastOrderedAt',
  'totalCompletedOrdersRange',
  'ordering.totalSpent.amount',
  'hasProductsInCart',
  'registered',
  'retailer.contact.country',
  // language facet is a virtual helper facet, on the backend it is calculating the correct language based on `contact.language` facet and `retailer.language` facet
  'language',
  'retailer.shopType',
  'communication.emailing.subscriptionStatus',
  'commercialTerms.serviceFees',
  // freeShippingInterval facet is a virtual helper facet, on the backend it is calculating the correct value based on `commercialTerms.freeShippingForBrandUntil` facet and current date
  'freeShippingInterval',
  'ordering.liftEligible',
  'discount',
  'commercialTerms.hasRepeatOffer',
  'retailer.aksPlus.active',
  'repeatOffer',
  // cards_facets is a virtual field
  'cards_facets',
];

export const DEFAULT_SORTING = { [SortColumns.total_spent]: Sort.Desc };

export const FACETS: string[] = ['segment'];

export const ENABLED_FILTERS_UI = [
  {
    groupName: 'orders-history',
    items: [
      {
        filterNames: ['firstOrderInterval', 'ordering.lastOrderedAt', 'totalCompletedOrdersRange', 'ordering.totalSpent.amount'],
      },
      { groupName: 'items-in-cart', filterNames: ['hasProductsInCart'] },
    ],
  },
  {
    groupName: 'shop-details',
    items: [
      { filterNames: ['registered'] },
      {
        groupName: 'shop-information',
        filterNames: ['retailer.contact.country', 'language', 'retailer.shopType'],
      },
      {
        filterNames: ['communication.emailing.subscriptionStatus', 'commercialTerms.serviceFees', 'freeShippingInterval'],
      },
    ],
  },
  {
    groupName: 'purchasing-benefit',
    items: [
      {
        filterNames: ['ordering.liftEligible'],
      },
      {
        filterNames: ['discount'],
      },
      {
        filterNames: ['retailer.aksPlus.active'],
      },
      {
        filterNames: ['repeatOffer'],
      },
    ],
  },
];
