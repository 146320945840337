import type { BreakpointsCoreState } from './types';
import {
  BREAKPOINT_NAME,
  BREAKPOINT_PX_LIMIT,
  CLIENT_DEVICE
} from './constants';

const getBreakpointIndex = (state: BreakpointsCoreState): number => Object.values(state).findIndex(Boolean);

export function addMatchMediaQueryListener(
  mediaQueryList: MediaQueryList,
  changeCallback: (event: MediaQueryListEvent) => void
): void {
  typeof mediaQueryList.addEventListener === 'function'
    ? mediaQueryList.addEventListener('change', changeCallback)
    : mediaQueryList.addListener(changeCallback);
}

export function removeMatchMediaQueryListener(
  mediaQueryList: MediaQueryList,
  changeCallback: (event: MediaQueryListEvent) => void
): void {
  typeof mediaQueryList.removeEventListener === 'function'
    ? mediaQueryList.removeEventListener('change', changeCallback)
    : mediaQueryList.removeListener(changeCallback);
}

export function getMediaQueryStrings(): string[] {
  return [
    // xxs -> mobile
    `(max-width: ${BREAKPOINT_PX_LIMIT.XS - 1}px)`,
    // xs -> mobile
    `(min-width: ${BREAKPOINT_PX_LIMIT.XS}px) and (max-width: ${BREAKPOINT_PX_LIMIT.SM - 1}px)`,
    // sm -> mobile, xl mobile
    `(min-width: ${BREAKPOINT_PX_LIMIT.SM}px) and (max-width: ${BREAKPOINT_PX_LIMIT.MD - 1}px)`,
    // md -> tablet
    `(min-width: ${BREAKPOINT_PX_LIMIT.MD}px) and (max-width: ${BREAKPOINT_PX_LIMIT.LG - 1}px)`,
    // lg -> tablet, xl tablet
    `(min-width: ${BREAKPOINT_PX_LIMIT.LG}px) and (max-width: ${BREAKPOINT_PX_LIMIT.XL - 1}px)`,
    // xl -> desktop
    `(min-width: ${BREAKPOINT_PX_LIMIT.XL}px) and (max-width: ${BREAKPOINT_PX_LIMIT.XXL - 1}px)`,
    // xxl -> desktop, xl desktop
    `(min-width: ${BREAKPOINT_PX_LIMIT.XXL}px)`,
  ];
}

export function getMediaQueryLists(): MediaQueryList[] {
  return getMediaQueryStrings().map((query) => window.matchMedia(query));
}

export function getNewState(
  event: MediaQueryListEvent,
  queries: MediaQueryList[],
  currentState: BreakpointsCoreState
): BreakpointsCoreState {
  const newState = { ...currentState };
  const mediaQueryIndex = queries.findIndex((query) => query.media === event.media);
  newState[`is${Object.values(BREAKPOINT_NAME)[mediaQueryIndex]}`] = event.matches;
  return newState;
}

const evaluateCondition = <T extends (currentIndex: number) => unknown>(condition: T, state: BreakpointsCoreState) =>
  condition(getBreakpointIndex(state)) as ReturnType<T>;

const isMobile = (breakpointIndex: number): boolean => breakpointIndex >= 0 && breakpointIndex <= 2;
const isXlMobile = (breakpointIndex: number): boolean => breakpointIndex === 2;
const isTablet = (breakpointIndex: number): boolean => breakpointIndex >= 3 && breakpointIndex <= 4;
const isXlTablet = (breakpointIndex: number): boolean => breakpointIndex === 4;
const isDesktop = (breakpointIndex: number): boolean => breakpointIndex >= 5 && breakpointIndex <= 6;
const isXlDesktop = (breakpointIndex: number): boolean => breakpointIndex === 6;
const isClientDevice = (breakpointIndex: number): CLIENT_DEVICE => {
  if (isMobile(breakpointIndex) || isXlMobile(breakpointIndex)) {
    return CLIENT_DEVICE.mobile;
  } else if (isTablet(breakpointIndex) || isXlTablet(breakpointIndex)) {
    return CLIENT_DEVICE.tablet;
  } else if (isDesktop(breakpointIndex) || isXlDesktop(breakpointIndex)) {
    return CLIENT_DEVICE.desktop;
  }
  return undefined;
};

export const getIsMobile = (state: BreakpointsCoreState): boolean => evaluateCondition(isMobile, state);
export const getIsXlMobile = (state: BreakpointsCoreState): boolean => evaluateCondition(isXlMobile, state);
export const getIsTablet = (state: BreakpointsCoreState): boolean => evaluateCondition(isTablet, state);
export const getIsXlTablet = (state: BreakpointsCoreState): boolean => evaluateCondition(isXlTablet, state);
export const getIsDesktop = (state: BreakpointsCoreState): boolean => evaluateCondition(isDesktop, state);
export const getIsXlDesktop = (state: BreakpointsCoreState): boolean => evaluateCondition(isXlDesktop, state);
export const getClientDevice = (state: BreakpointsCoreState): CLIENT_DEVICE => evaluateCondition(isClientDevice, state);

const isBelowXs = (breakpointIndex: number): boolean => breakpointIndex === 0;
const isAboveOrEqualXs = (breakpointIndex: number): boolean => breakpointIndex >= 1;
const isBelowSm = (breakpointIndex: number): boolean => breakpointIndex < 2;
const isAboveOrEqualSm = (breakpointIndex: number): boolean => breakpointIndex >= 2;
const isBelowMd = (breakpointIndex: number): boolean => breakpointIndex < 3;
const isAboveOrEqualMd = (breakpointIndex: number): boolean => breakpointIndex >= 3;
const isBelowLg = (breakpointIndex: number): boolean => breakpointIndex < 4;
const isAboveOrEqualLg = (breakpointIndex: number): boolean => breakpointIndex >= 4;
const isBelowXl = (breakpointIndex: number): boolean => breakpointIndex < 5;
const isAboveOrEqualXl = (breakpointIndex: number): boolean => breakpointIndex >= 5;
const isBelowXxl = (breakpointIndex: number): boolean => breakpointIndex < 6;
const isAboveOrEqualXxl = (breakpointIndex: number): boolean => breakpointIndex >= 6;

export const getIsBelowXs = (state: BreakpointsCoreState): boolean => evaluateCondition(isBelowXs, state);
export const getIsAboveOrEqualXs = (state: BreakpointsCoreState): boolean => evaluateCondition(isAboveOrEqualXs, state);
export const getIsBelowSm = (state: BreakpointsCoreState): boolean => evaluateCondition(isBelowSm, state);
export const getIsAboveOrEqualSm = (state: BreakpointsCoreState): boolean => evaluateCondition(isAboveOrEqualSm, state);
export const getIsBelowMd = (state: BreakpointsCoreState): boolean => evaluateCondition(isBelowMd, state);
export const getIsAboveOrEqualMd = (state: BreakpointsCoreState): boolean => evaluateCondition(isAboveOrEqualMd, state);
export const getIsBelowLg = (state: BreakpointsCoreState): boolean => evaluateCondition(isBelowLg, state);
export const getIsAboveOrEqualLg = (state: BreakpointsCoreState): boolean => evaluateCondition(isAboveOrEqualLg, state);
export const getIsBelowXl = (state: BreakpointsCoreState): boolean => evaluateCondition(isBelowXl, state);
export const getIsAboveOrEqualXl = (state: BreakpointsCoreState): boolean => evaluateCondition(isAboveOrEqualXl, state);
export const getIsBelowXxl = (state: BreakpointsCoreState): boolean => evaluateCondition(isBelowXxl, state);
export const getIsAboveOrEqualXxl = (state: BreakpointsCoreState): boolean => evaluateCondition(isAboveOrEqualXxl, state);
