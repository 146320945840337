import './plugins/configure-injected-content'; // Ensure this is first to allow all other parts of the app to access the injected content
import { configureGa } from './plugins/configure-ga';
import { configureCommitShaVariable } from '@core/utilities/application/appVersion';
import { configureHttp } from './plugins/configure-http';
import http from '@monolith/legacy/services/api/http';
import store from '@monolith/legacy/store';

configureCommitShaVariable();
configureGa();
configureHttp(http(), store);
