<template>
  <span
    v-on-click-outside="hide"
    :class="[{ 'with-label': label }, { 'select-opened': opened }, { 'out-of-stock': outOfStock }, 'select-input']"
    @click.prevent="toggleOpen"
  >
    <span
      v-if="label"
      class="select-label"
    >{{ label }}</span>
    <span
      v-if="!outOfStock"
      class="select-value"
    >
      <span>{{ list[modelValue] }}</span>
      <AkIcon symbol="chevron-down" />
    </span>
    <span
      v-else
      class="select-value"
    >
      {{ $t('Out of stock') }}
    </span>
    <ul
      v-if="!outOfStock"
      ref="select-values"
      class="select-values"
      :class="[{'options-opened': opened }]"
    >
      <li
        v-for="(labelOfOption, itemValue) in list"
        :key="itemValue"
        :class="{ 'selected-value': itemValue == modelValue }"
        @click.prevent.stop="select(itemValue)"
      >
        {{ stockUnavailable && itemValue == 0 ? $t('Stock unavailable') : labelOfOption }}
      </li>
    </ul>
  </span>
</template>

<script lang="ts">
import { vOnClickOutside } from '@vueuse/components';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'SelectInput',
  directives: { vOnClickOutside },
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    list: {
      type: Array as PropType<string[] | number[]>,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    stockUnavailable: {
      type: Boolean,
      required: false,
      default: false,
    },
    outOfStock: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      opened: false,
    };
  },
  mounted() {
    // prevent click outside event
    this.popupItem = this.$el;
  },
  methods: {
    toggleOpen() {
      if (this.disabled) {
        return;
      }

      this.opened = !this.opened;
      if (this.opened && (this.$refs['select-values'] as HTMLUListElement)?.querySelector('.selected-value')) {
        // Always scroll down the list on open
        (this.$refs['select-values'] as HTMLUListElement).scrollTop = ((this.$refs['select-values'] as HTMLUListElement).querySelector('.selected-value') as HTMLLIElement).offsetTop;
      }
    },
    hide() {
      this.opened = false;
    },
    select(newValue) {
      this.$emit('update:modelValue', newValue);
      this.hide();
    },
  },
});
</script>
<style scoped lang="scss">

.select-input {
  .select-values {
    &.options-opened {
      z-index: 1040;
    }
  }
}

</style>
