<template>
  <li
    :class="{
      'search-autocomplete-section-item': true,
      'search-autocomplete-section-item--selected': currentSelectionId === item.id,
    }"
    @click="handleClick"
  >
    <div
      v-if="item.group === recentSearchGroup"
      class="search-autocomplete-section-item__closer"
      @click.prevent.stop="removeRecentSearch"
    >
      <AkIcon
        size="sm"
        symbol="x"
      />
    </div>

    <img
      v-if="item.images"
      class="search-autocomplete-section-item__image search-autocomplete-section-item__image--circle"
      :alt="item.name"
      :src="imgCdnUrl(item.images.rounded, '36')"
    />
    <img
      v-else-if="item.imageUrl"
      class="search-autocomplete-section-item__image search-autocomplete-section-item__image--big"
      :alt="item.name"
      :src="item.imageUrl"
    />
    <!-- eslint-disable vue/no-v-html -->
    <span class="ds-flex ds-items-center ds-flex-grow ds-shrink">
      <div class="ds-flex ds-flex-col">
        <span
          :class="{
            'search-autocomplete-section-item__name': true,
            'search-autocomplete-section-item__name--normal': !highlight,
            'search-autocomplete-section-item__name--big': Boolean(item.info),
          }"
          v-html="highlightedName"
        />
        <span
          v-if="Boolean(item.info)"
          class="search-autocomplete-section-item__info"
        >
          {{ item.info }}
        </span>
      </div>
      <span
        v-if="shouldDisplaySubcategory"
        class="search-autocomplete-section-item__subcategory"
      >
        <span class="ds-truncate">{{ itemSubcategoryName }}</span>
      </span>
    </span>

    <span class="search-autocomplete-section-item__chevron">
      <AkIcon
        size="sm"
        symbol="chevron-right"
        class="search-autocomplete-section-item__icon"
      />
    </span>
  </li>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { imgCdnUrl } from '@bc/shared/util/img-cdn/img-cdn';
import { highlightTextByQuery } from '@core/utilities/string';
import { HANDLE_ITEM_CLICK, HANDLE_ITEM_CLICK_REMOVAL } from '../store/action-types';
import { ItemResult } from '@bc/discovery/domain/search';
import { SET_ARROW_POSITION } from '../store/mutation-types';
import { getFormattedCategoryName, hasLevelCategory } from '@core/utilities/algolia';
import { AkIcon } from '@ankorstore/design-system';
import SearchStoreHelpers from '../store/helpers';

import { RECENT_SEARCH_GROUP } from '@bc/discovery/feature/search/constants';

const props = withDefaults(
  defineProps<{
    item: ItemResult;
    highlight?: boolean;
  }>(),
  {
    highlight: false,
  }
);

const { originalUserInput, currentSelectionId } = SearchStoreHelpers.useGetters(['originalUserInput', 'currentSelectionId']);
const { [HANDLE_ITEM_CLICK]: handleItemClick, [HANDLE_ITEM_CLICK_REMOVAL]: handleItemClickRemoval } =
  SearchStoreHelpers.useActions([HANDLE_ITEM_CLICK, HANDLE_ITEM_CLICK_REMOVAL]);
const { [SET_ARROW_POSITION]: setArrowPosition } = SearchStoreHelpers.useMutations([SET_ARROW_POSITION]);

const highlightedName = computed(() =>
  highlightTextByQuery({
    content: props.item.name,
    query: originalUserInput.value,
  })
);
const recentSearchGroup = RECENT_SEARCH_GROUP;
const shouldDisplaySubcategory = computed(
  () => Boolean(originalUserInput.value) && hasLevelCategory({ item: props.item, level: 1 })
);
const itemSubcategoryName = computed(() =>
  getFormattedCategoryName({
    item: props.item,
    level: 1,
  })
);

const handleClick = (): void => {
  setArrowPosition(props.item.id);
  handleItemClick({ item: props.item });
};

const removeRecentSearch = (): void => {
  handleItemClickRemoval({ item: props.item });
};
</script>

<style scoped lang="scss">
.search-autocomplete-section-item {
  @apply ds-w-full ds-flex ds-items-center ds-py-2 ds-px-4 ds-cursor-pointer ds-text-left ds-list-none ds-truncate;
  transition: background-color ease-in-out 0.1s;

  &__closer {
    @apply ds-mr-1;
  }

  &--selected,
  &:hover {
    @apply ds-bg-neutral-300;
  }

  &__image {
    width: 36px;
    height: 36px;
    @apply ds-rounded-sm ds-border ds-border-solid ds-border-neutral-300 ds-inline ds-flex-initial ds-mr-2 ds-overflow-hidden;
    &--circle {
      @apply ds-rounded-full;
    }
    &--big {
      width: 40px;
      height: 40px;
      @media screen and (min-width: 1024px) {
        width: 48px;
        height: 48px;
      }
    }
  }

  &__name {
    @apply ds-font-bold ds-whitespace-pre;

    &--normal {
      @apply ds-font-normal;
    }
    &--big {
      @apply ds-text-sm;
      @media screen and (min-width: 1024px) {
        @apply ds-text-base;
      }
    }
  }
  &__info {
    @apply ds-text-neutral-700 ds-italic;
  }

  &__icon {
    @apply ds-text-neutral-500;
    flex-basis: 40px;
  }

  &__chevron {
    @apply ds-flex-initial md:ds-hidden;
  }

  &__subcategory {
    @apply ds-text-xs ds-text-accent-700 ds-font-semibold ds-pl-1 ds-inline-grid;
    letter-spacing: 0.008em;
  }
}
</style>
