<template>
  <Slide
    :is-open="opened"
    no-overlay
    slide
    data-testid="slideMenu"
    :close-on-navigation="true"
    @open-menu="onOpenMenu"
    @close-menu="onCloseMenu"
  >
    <div class="block-logo">
      <a :href="links.home">
        <img
          alt="Ankorstore"
          class="logoMenu"
          src="/images/logo/logo-black.svg"
          importance="high"
        >
      </a>
    </div>
    <div v-if="user">
      <div class="dropdown-divider" />
      <h6 class="dropdown-header">
        {{ $t('Hello {Name}', { Name: user.first_name }) }}
      </h6>
      <template v-if="userIsBrand">
        <div class="dropdown-divider" />
        <a
          class="dropdown-item dropdown-brand"
          :href="brand.link"
        >
          <AccountLogo size="60" />
          <span class="brand-name">{{ brand.name }}</span>
        </a>
      </template>
    </div>
    <RetailInformationMenuAccount v-if="userIsRetailer" />
    <div class="dropdown-divider" />
    <div id="navbarSupportedContent">
      <ul v-if="userIsCandidateBrand">
        <li>
          <a href="/brand-onboarding/login">{{ $t('Continue onboarding') }}</a>
        </li>
        <li class="divider" />
      </ul>
      <ul
        v-if="navigationItems"
        class="main-nav"
      >
        <li v-if="!userIsRetailer">
          <a :href="links.home">{{ $t('Home') }}</a>
        </li>
        <li v-if="$isEnabled('discount_in_navbar')">
          <a :href="links.discounts">{{ $t('Discount Title') }}</a>
        </li>
        <AkNavigation
          v-if="opened"
          :use-router-link="isRouterLink"
          is-mobile
          :items="navigationItems"
          @item-clicked="trackNavigation"
        />
      </ul>
      <ul
        v-if="!user"
        class="user-nav"
      >
        <li class="divider" />
        <li>
          <a
            href=""
            @click.prevent="openLoginPopin(RetailerFormType.Login)"
          >{{ $t('Login') }}</a>
        </li>
        <li>
          <a
            href="#"
            @click.prevent="openLoginPopin(RetailerFormType.Register)"
          >{{ registerCopyright }}</a>
        </li>
        <li>
          <a :href="links.apply">{{ $t('Apply as a brand') }}</a>
        </li>
      </ul>
      <MenuMobileRetailer
        v-if="userIsRetailer"
        :links="links"
        data-testid="menuMobileRetailer"
      />
      <MenuMobileCandidateBrand
        v-else-if="userIsCandidateBrand"
        :links="links"
      />
      <MenuMobileBrand
        v-else-if="userIsBrand"
        :links="links"
        :has-ful-fillment="hasFulfillment"
        :brand="brand"
        data-testid="menuMobileBrand"
      />
    </div>
  </Slide>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Slide } from 'vue3-burger-menu';
import { mapGetters } from 'vuex';
import { loginPopinMixin } from '@core/mixins/login-popin';
import MenuMobileBrand from '@monolith/legacy/components/header/menu-mobile-brand.vue';
import MenuMobileCandidateBrand from '@monolith/legacy/components/header/menu-mobile-candidate-brand.vue';
import MenuMobileRetailer from '@monolith/legacy/components/header/menu-mobile-retailer.vue';
import RetailInformationMenuAccount from '@monolith/legacy/components/header/retail-information-menu-account.vue';
import { FeatureFlag } from '@monolith/legacy/services/features';
import { RetailerFormType } from '@monolith/legacy/types/retailer-form-type';
import AccountLogo from '@monolith/legacy/components/account/account-logo.vue';
import { AkNavigation, AkNavMenuExtended } from '@ankorstore/design-system';
import { NavigationMenuService, prepareDataForAkNavBar, TrackingService } from '@bc/discovery';

export default defineComponent({
  name: 'HeaderMobile',
  components: {
    AccountLogo,
    Slide,
    MenuMobileBrand,
    MenuMobileRetailer,
    MenuMobileCandidateBrand,
    RetailInformationMenuAccount,
    AkNavigation,
  },
  mixins: [loginPopinMixin],
  props: {
    links: { type: Object, required: true },
    showAnkorRdv: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      navigationItems: [],
      opened: false,
      link: '',
      RetailerFormType,
    };
  },
  computed: {
    ...mapGetters(['userIsRetailer', 'userIsBrand', 'userIsCandidateBrand', 'user']),
    ...mapGetters(['brand']),
    isRouterLink() {
      if (!this.$router || !this.$route || !this.$route.path) {
        return false;
      }
      const currentPath = this.$route.path;
      const resolvedPath = this.$router?.resolve(currentPath);
      return resolvedPath?.route?.path !== '*';
    },
    registerCopyright(): string {
      return this.$isEnabled(FeatureFlag.RegistrationRename) ? this.$t('Join as a retailer') : this.$t('Register');
    },
    hasFulfillment(): boolean {
      return this.user.business.brand?.fulfillment_enabled;
    },
  },
  watch: {
    $route() {
      this.onCloseMenu();
    },
  },
  async mounted() {
    const data = await NavigationMenuService.getMainNavigationMenu();
    if (data) {
      this.navigationItems = prepareDataForAkNavBar(data);
    }
  },
  methods: {
    onCloseMenu(): void {
      this.$el.classList.remove('menu-mobile--open');
      document.body.classList.remove('noScroll');
      this.opened = false;
    },
    onOpenMenu(): void {
      this.$el.classList.add('menu-mobile--open');
      document.body.classList.add('noScroll');
      this.opened = true;
    },
    trackNavigation(item: AkNavMenuExtended): void {
      TrackingService.trackNavigation(item);
    },
  },
});
</script>

<style lang="scss">
@import '@css/vue-import';

.burgerMenu {
  .block-logo {
    line-height: 48px;
    display: flex !important;
    justify-content: center;
    min-height: 60px;
    align-items: center;

    img.logoMenu {
      width: 190px;
    }
  }

  .bm-burger-button {
    @apply ds-relative ds-top-0 ds-left-0 ds-cursor-pointer;
    z-index: 1;
    width: 20px;
    height: 14px;
  }

  .bm-burger-bars {
    @apply ds-bg-primary;
  }

  .line-style {
    position: absolute;
    height: 8%;
    left: 0;
    right: 0;
  }

  .cross-style {
    position: absolute;
    top: 10px;
    right: 4px;
    cursor: pointer;
  }

  .bm-cross {
    @apply ds-bg-neutral-500;
    top: 14px;
    left: 19px;
  }

  .bm-cross-button {
    height: 40px;
    width: 40px;
  }

  .bm-menu {
    @apply ds-font-basic;
    height: 100vh; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Stay on top */
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    @apply ds-bg-white;
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: scroll;
    padding-top: 0; /* Place content 60px from the top */
    transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
    box-shadow: 8px 0 8px -10px black, -8px 0 8px -10px black;
  }
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

nav.bm-item-list {
  @apply ds-text-primary;
  margin-left: 0%;
  font-size: 14px;

  & > * {
    display: block;
    text-decoration: none;
    @apply ds-w-screen;

    & > span {
      margin-left: 10px;
      font-weight: 700;
      @apply ds-text-primary;
    }
  }
}

// Lift days are enabled via FF and only part of the year
.lift-days-title {
  @apply ds-text-discount-700;
  white-space: pre-wrap;
  font-weight: 700;
}

.lift-days-subtitle {
  @apply ds-text-primary;
  font-weight: 400;
  text-decoration: underline;
}

#headerMobile #navbarSupportedContent {
  position: relative;
  width: 100%;
  padding: 0;
  overflow: auto;
  flex-shrink: 1;
  padding-bottom: 113px;

  ul {
    margin: 0;

    > li {
      display: block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      @apply ds-text-primary;
      align-items: center;

      &#liftBrandPage {
        a {
          display: flex;
          align-items: center;

          img {
            width: 24px;
            height: auto;
            margin-right: 8px;
          }
        }
      }

      &#lift-days-item,
      &#ankorstore-rdv-item {
        display: flex;
      }

      &.header {
        @apply ds-border-b ds-border-solid ds-border-neutral-300 ds-text-neutral-500 ds-font-basic;
        text-align: center;
      }

      &.divider {
        @apply ds-border-b ds-border-solid ds-border-neutral-300;
        height: 0;
      }

      a {
        @apply ds-flex ds-items-center ds-pl-2;
        text-decoration: none;

        &.disabled {
          @apply ds-text-neutral-600 ds-cursor-not-allowed;
        }

        .ak-badge {
          top: 3px;
        }

        .menu-badge--new {
          @apply ds-ml-2;
        }
      }

      img {
        height: 28px;
        display: block;
      }
    }
  }
}

.menu-mobile--open {
  .bm-menu {
    width: 100% !important;
    opacity: 1;
  }
}
</style>
