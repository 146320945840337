import { InjectedContentRepositoryMeta } from '@monolith/legacy/services/injected-content-repository-meta';
import { InjectedContentRepositoryScript } from '@monolith/legacy/services/injected-content-repository-script';
import { provideInjectedContentRepository } from '@monolith/legacy/services/injected-content';

const isV2MetaActivate = () => !!document.querySelector('#meta_v2');

const repository = isV2MetaActivate()
  ? new InjectedContentRepositoryScript(document)
  : new InjectedContentRepositoryMeta(document);

provideInjectedContentRepository(repository);
