import { Router } from 'vue-router';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import type { GroupedResults, ResultId, SearchService } from '@bc/discovery/domain/search';
import { DEFAULT_ARROW_POSITION } from '@bc/discovery/feature/search/constants';

export const state = {
  country: '',
  originalUserInput: '',
  isOpen: false,
  arrowPosition: DEFAULT_ARROW_POSITION,
  shouldDisplayBrandResults: false,
  results: {
    trending: [],
    suggestions: [],
    categories: [],
    brands: [],
    searchAll: null,
    r2bLink: null,
    history: [],
    boutiques: [],
  } as GroupedResults,
  resultsIds: [] as ResultId[],
  userLocalHistory: [],
};
export type SearchState = typeof state;

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export const createSearchStore = (searchService: SearchService, locale: string, router?: Router) => {
  return {
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(searchService, locale, router),
  };
};
