import { defineComponent } from 'vue';
import { BREAKPOINTS_PROVIDE_ID } from '@monolith/legacy/modules/design-system-candidates/breakpoints/constants';
import { BreakpointsRefState } from '@monolith/legacy/modules/design-system-candidates/breakpoints/types';

const withBreakpoints = defineComponent({
  name: 'WithBreakpoints',
  inject: {
    breakpoints: { from: BREAKPOINTS_PROVIDE_ID },
  },
  data() {
    return this.breakpoints as BreakpointsRefState;
  },
});

export default withBreakpoints;
