<template>
  <AkForm
    v-model="form"
    class="report-reason-form ds-flex ds-justify-center ds-flex-col"
    data-testId="reportPopinModalContent"
    @update:model-value="updateForm"
  >
    <div class="ds-text-center">
      <p
        class="ds-text-base ds-font-bold ds-m-0"
        data-testid="reportPopinTitle"
      >
        {{ $t('@@ret_core_discovery.reportPopin.title') }}
      </p>
      <p
        class="ds-mt-2 ds-text-neutral-700 ds-mb-5 ds-pb-1"
        data-testid="reportPopinSubTitle"
      >
        {{ $t('@@ret_core_discovery.reportPopin.subTitle') }}
      </p>
    </div>
    <ul
      class="report-form ds-flex ds-flex-col ds-gap-4 ds-text-left ds-pb-4"
      data-testid="reportReasonsForm"
    >
      <li
        v-for="reason of reasonsToDisplay"
        :key="reason.id"
        :data-testid="`reportReasons${reason.id}`"
      >
        <AkCheckbox
          :value="reason.id.toString()"
          class="ds-text-neutral-700"
          :disabled="isCheckboxDisabled"
          @change="onCheckboxChanges(reason)"
        >
          <p class="ds-text-neutral-700 ds-m-0">
            {{ reason.title }}
          </p>
        </AkCheckbox>
      </li>
    </ul>
    <AkInputField
      v-if="isCheckboxDisabled"
      class="report-reason-form__description"
      name="description"
      input-type="textarea"
      :max-length="500"
      :placeholder="$t('@@ret_core_discovery.reportPopin.description')"
      data-testid="reportReasonDescription"
    />
    <!-- We dont need a submit button in this form -->
    <template #submit>
      <span />
    </template>
  </AkForm>
</template>
<script lang="ts">
import { defineComponent, ref, toRefs, onMounted, computed, PropType, watch } from 'vue';
import { getReportReasons } from '@monolith/legacy/services/report/report';
import { captureRetailerCoreDiscoveryException } from '@core/plugins/sentry/helper';
import { ReportReason, ReportFormData, ReportPopinStep } from '@monolith/legacy/components/brand/report-popin/types';
import AkInputField from '@monolith/legacy/components/review/AkForm/AkInputField/ak-input-field.vue';
import AkForm from '@monolith/legacy/components/review/AkForm/ak-form.vue';

export default defineComponent({
  name: 'ReportReasonsForm',
  components: {
    AkForm,
    AkInputField,
  },
  props: {
    modelValue: {
      type: Object as PropType<ReportFormData>,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue, step } = toRefs(props);
    const form = ref({
      reasons: [],
      description: '',
    });
    const reasons = ref<ReportReason[]>([]);
    const reasonsToDisplay = computed(() => {
      return step.value === ReportPopinStep.ISSUE_SELECTION ? reasons.value : modelValue.value.reasons || [];
    });

    const isCheckboxDisabled = computed(() => {
      return step.value !== ReportPopinStep.ISSUE_SELECTION;
    });

    onMounted(async () => {
      try {
        const { data } = await getReportReasons();
        reasons.value = data;
      } catch (error) {
        captureRetailerCoreDiscoveryException(error, [
          { label: 'component', value: 'ReportReasonsForm' },
          { label: 'action', value: 'Get report reasons' },
        ]);
        throw error;
      }
    });

    watch(modelValue, () => {
      form.value = modelValue.value;
    });

    const onCheckboxChanges = (reason) => {
      const existed = modelValue.value.reasons.some((item) => item.id === reason.id);
      let newReasons: ReportReason[];
      if (existed) {
        // A reason have been removed
        newReasons = modelValue.value.reasons.filter((item) => item.id !== reason.id);
      } else {
        // A new reason have been selected
        newReasons = [...modelValue.value.reasons, reason];
      }

      emit('update:modelValue', {
        ...modelValue.value,
        reasons: newReasons,
      });
    };

    const updateForm = (form) => {
      // The reasons checkboxes is not handled by the ak form
      // We need to update it separately, or we are going to lose this information
      emit('update:modelValue', { ...form, reasons: modelValue.value.reasons });
    };

    return {
      reasons,
      onCheckboxChanges,
      reasonsToDisplay,
      isCheckboxDisabled,
      form,
      updateForm,
    };
  },
});
</script>

<style lang="scss" scoped>
.report-reason-form {
  // Overrides the default style of AkInputField
  & &__description {
    & :deep(textarea) {
      min-height: 6.5rem;
    }
  }
}
</style>
