<template>
  <div
    class="banner-2-container"
    :style="backgroundStyle"
  >
    <div class="banner-2-container-wrap ds-mx-auto">
      <div class="banner-2-foreground-image--left">
        <picture v-if="hasForegroundImageLeft">
          <source
            v-if="foregroundImageLeft.mobile"
            :srcset="foregroundImageLeft.mobile.url"
            :width="foregroundImageLeft.mobile.dimensions.width"
            :height="foregroundImageLeft.mobile.dimensions.height"
            media="(max-width: 1023px)"
          >
          <img
            :src="foregroundImageLeft.url"
            :alt="foregroundImageLeft.alt"
            :width="foregroundImageLeft.dimensions.width"
            :height="foregroundImageLeft.dimensions.height"
            class="ds-object-contain"
          >
        </picture>
      </div>
      <div class="banner-2-foreground-image--center">
        <picture v-if="hasForegroundImageCenter">
          <source
            v-if="foregroundImageCenter.mobile"
            :srcset="foregroundImageCenter.mobile.url"
            :width="foregroundImageCenter.mobile.dimensions.width"
            :height="foregroundImageCenter.mobile.dimensions.height"
            media="(max-width: 1023px)"
          >
          <img
            :src="foregroundImageCenter.url"
            :alt="foregroundImageCenter.alt"
            :width="foregroundImageCenter.dimensions.width"
            :height="foregroundImageCenter.dimensions.height"
            class="ds-object-contain"
          >
        </picture>
      </div>
      <div class="banner-2-foreground-image--right">
        <picture v-if="hasForegroundImageRight">
          <source
            v-if="foregroundImageRight.mobile"
            :srcset="foregroundImageRight.mobile.url"
            :width="foregroundImageRight.mobile.dimensions.width"
            :height="foregroundImageRight.mobile.dimensions.height"
            media="(max-width: 1023px)"
          >
          <img
            :src="foregroundImageRight.url"
            :alt="foregroundImageRight.alt"
            :width="foregroundImageRight.dimensions.width"
            :height="foregroundImageRight.dimensions.height"
            class="ds-object-contain"
          >
        </picture>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Banner2, ImageWithMobile } from './types';

export default defineComponent({
  name: 'Banner2',
  props: {
    content: {
      type: Object as () => Banner2,
      required: true,
    },
  },
  computed: {
    backgroundImage(): ImageWithMobile {
      return this.content.background_image;
    },
    backgroundStyle() {
      return {
        '--background-image': this.backgroundImage?.url ? `url(${this.backgroundImage.url})` : '',
        '--background-image-mobile': this.backgroundImage?.mobile.url ? `url(${this.backgroundImage.mobile.url})` : '',
      };
    },
    foregroundImageRight(): ImageWithMobile {
      return this.content.foreground_image_right;
    },
    foregroundImageLeft(): ImageWithMobile {
      return this.content.foreground_image_left;
    },
    foregroundImageCenter(): ImageWithMobile {
      return this.content.foreground_image_center;
    },
    hasForegroundImageRight(): boolean {
      return Boolean(this.foregroundImageRight?.url);
    },
    hasForegroundImageLeft(): boolean {
      return Boolean(this.foregroundImageLeft?.url);
    },
    hasForegroundImageCenter(): boolean {
      return Boolean(this.foregroundImageCenter?.url);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@css/vue-import';

.banner-2-container {
  @apply ds-box-border ds-w-full ds-relative;
  background-size: cover;
  background-image: var(--background-image-mobile, none);

  @include media-breakpoint-up(lg) {
    background-image: var(--background-image, none);
  }

  .banner-2-container-wrap {
    @apply ds-max-w-screen-2xl;
    display: grid;
    grid-template-areas: 'left center right';
    grid-template-columns: auto 1fr auto;
    align-items: center;
  }
}

.banner-2-foreground-image--left {
  grid-area: left;
}
.banner-2-foreground-image--center {
  grid-area: center;
  margin: 0 auto;
}
.banner-2-foreground-image--right {
  grid-area: right;
}
</style>
