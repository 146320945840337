import { type ContactItem, type PaginationData } from './contacts.types';
import { Sort } from '@monolith/legacy/types/sorting';
import { FilterItem } from './filter.types';
import { ContactFacetType } from './contact.facet.type';
import { StatsContactFacet } from './stats.contact.facet';

export interface ContactsSearchResponse {
  data: ContactItem[];
  meta: {
    facets: Array<ValueContactFacet | RangeContactFacet | StatsContactFacet>;
    page: PaginationData;
  };
}

export interface FiltersResponse {
  data: FilterItem[];
}

export interface PersonalisedDiscountResponse {
  meta: {
    affectedContacts: number;
  };
}

interface ContactSearchData {
  type: 'search-params';
  attributes: {
    query?: {
      term: string;
    };
    facets: string[];
    filters: ContactFilter[];
    sort: Record<string, Sort>;
    page: {
      size: number;
      current: number;
    };
  };
}

export interface ContactsSearchParams {
  data: ContactSearchData;
}

export interface ContactsMultiSearchParams {
  data: ContactSearchData[];
}

export type ContactFilter = ValueContactFilter | RangeContactFilter | BooleanContactFilter;

export enum ContactFilterType {
  Value = 'value',
  Range = 'range',
  Boolean = 'boolean',
}

export interface ValueContactFilter {
  type: ContactFilterType.Value;
  name: string;
  value: string[] | number[];
  not?: boolean;
}

export interface RangeContactFilter {
  type: ContactFilterType.Range;
  name: string;
  min: string | number;
  max?: string | number;
  exclusiveMin?: string | number;
  exclusiveMax: string | number;
}

export interface BooleanContactFilter {
  type: ContactFilterType.Boolean;
  name: string;
  value: boolean;
}

export interface ValueContactFacet {
  type: ContactFacetType.Value;
  name: string;
  data: {
    value: string;
    count: number;
  }[];
}

export interface RangeContactFacet {
  type: ContactFacetType.Range;
  name: string;
  data: {
    value: string;
    count: number;
    from: string | number;
    to: string | number;
  }[];
}

export interface ContactsExportParams {
  data: {
    type: 'contact-export';
    attributes: {
      query?: {
        term: string;
      };
      filters: ContactFilter[];
      sort: Record<string, Sort>;
    };
  };
}

export interface BrandSegment {
  label: string;
  description?: string;
  name: string;
  max_contacts_locale: string;
  contacts_count: number;
}
