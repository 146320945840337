import { DynamicMetaTagName, GlobalMetaTagName, StateMetaTagName } from '@monolith/legacy/services/initial-state';
import { InjectedContentRepository } from '@monolith/legacy/types/injected-content-repository';

let repository: InjectedContentRepository;
export const provideInjectedContentRepository = (_repository: InjectedContentRepository) => {
  repository = _repository;
};

export const getInjectedContent = <T>(name: GlobalMetaTagName | StateMetaTagName | DynamicMetaTagName): null | T => {
  return repository?.get(name) ?? null;
};
