<template>
  <div v-if="!isCheckoutPage">
    <div>
      <AkIcon
        symbol="search"
        size="md"
        @click="onFocus()"
      />
    </div>
    <AkModal
      ref="productSearchMobileModal"
      size="2xl"
      :with-padding="false"
      :with-closer="false"
    >
      <SearchAutocomplete @close="closeModal" />
    </AkModal>
  </div>
</template>

<script setup lang="ts">
import { useTemplateRef } from 'vue';
import SearchAutocomplete from './search-autocomplete.vue';
import { AkModal, AkIcon } from '@ankorstore/design-system';
import useIsCheckout from '@core/composables/use-enclosed-checkout';

const { isCheckoutPage } = useIsCheckout();
const productSearchMobileModal = useTemplateRef<InstanceType<typeof AkModal>>('productSearchMobileModal');

const onFocus = () => {
  productSearchMobileModal.value.openModal();
};

const closeModal =() => {
  productSearchMobileModal.value.close();
};
</script>

<style scoped lang="scss">
:deep(.ak-modal .ak-modal__wrapper) {
  @apply ds-align-top;
}

:deep(.ak-modal .ak-modal__container) {
  @apply md:ds-bg-transparent;
}
</style>
