<template>
  <div
    :class="[
      'search-autocomplete__suggestions',
      'ds-z-20',
      'ds-flex',
      'ds-flex-col',
      'ds-relative',
      'ds-bg-white',
      'ds-w-full',
      'ds-overflow-y-auto',
      'md:ds-shadow-md',
      'md:ds-rounded-md',
      'xl:ds-absolute',
    ]"
  >
    <CategoryTilesContainer v-if="shouldShowCategoryTiles">
      <template #default="{ categoriesTileData }">
        <CategoryTiles
          class="ds-p-4"
          :categories-tile-data="categoriesTileData"
          :columns="isMobile ? 2 : 4"
          @tile-clicked="handleCategoryTileClicked"
        />
      </template>
    </CategoryTilesContainer>
    <template v-else>
      <template
        v-for="(_value, key) in resultsDynamic"
        :key="key"
      >
        <SearchAutocompleteSection
          v-if="results[key]?.length"
          :label="SECTION_ATTRIBUTES[key].label"
          :items="results[key]"
          :data-testid="SECTION_ATTRIBUTES[key].testId"
          :should-highlight-results="shouldHighlightResults"
        />
      </template>
    </template>
    <SearchStaticItem
      v-for="(_, name) in resultsStatic"
      :key="name"
      :class="{
        'search-autocomplete__suggestions-r2bLink': isR2BLink(results[name]),
      }"
      :is-visible="!!results[name]"
      :item="results[name]"
      @click="handleStaticItemClick"
    />
  </div>
</template>

<script setup lang="ts">
import SearchAutocompleteSection from './search-autocomplete-section.vue';
import SearchStaticItem from './search-autocomplete-static-item.vue';
import CategoryTilesContainer from './category-tiles-container.vue';
import { computed } from 'vue';
import { CategoryTiles } from '@bc/discovery/ui/category-tiles';
import { useBreakpoints } from '@monolith/legacy/modules/design-system-candidates';
import { R2B_LINK_ID, SECTION_ATTRIBUTES, SHOW_ALL_ID } from '../constants';

import Analytics from '@monolith/legacy/services/analytics';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';
import SearchBarStoreHelpers from '../store/helpers';
import { StaticItem } from '@bc/discovery/domain/search';

const emit = defineEmits<{
  'click-see-all-results': [];
  'click-r2b-link': [];
}>();

const { isMobile } = useBreakpoints();
const { results, resultsDynamic, resultsStatic, originalUserInput } = SearchBarStoreHelpers.useGetters([
  'results',
  'resultsDynamic',
  'resultsStatic',
  'originalUserInput',
]);

const shouldShowCategoryTiles = computed(
  () =>
    !(
      resultsDynamic.value.history?.length ||
      resultsDynamic.value.suggestions?.length ||
      resultsDynamic.value.categories?.length ||
      resultsDynamic.value.brands?.length ||
      resultsDynamic.value.boutiques?.length
    )
);
const shouldHighlightResults = computed(() => Boolean(originalUserInput.value));

const isR2BLink = (item: StaticItem) => item?.id === R2B_LINK_ID;

const handleStaticItemClick = (item: StaticItem): void => {
  if (item?.id === SHOW_ALL_ID) {
    emit('click-see-all-results');
  } else if (isR2BLink(item)) {
    emit('click-r2b-link');
  }
};

const handleCategoryTileClicked = async (categoryId: number) => {
  await Analytics.track(
    new UserClick({
      component: 'search bar',
      action: 'click_lvl1_category_suggestion',
      id_category: categoryId || null,
    })
  );
};
</script>

<style scoped lang="scss">
.search-autocomplete__suggestions {
  min-height: 1rem;

  &-r2bLink {
    &:before {
      @apply ds-bg-neutral-300 ds-mx-4 ds-absolute;
      content: '';
      height: 1px;
      top: 0;
      left: -16px;
      right: -16px;
    }
  }
}
</style>
