import type { Store } from 'vuex';
import type { Address } from '@monolith/legacy/types/address';
import type { SegmentUserTraits } from '@monolith/legacy/types/segment';
import type { Business, User } from '@monolith/legacy/types/user';
import type { Brand } from '@monolith/legacy/types/api/brand';
import type { Retailer } from '@monolith/legacy/types/order';
import type { OfferProgramEligibility } from '@monolith/legacy/modules/promote-engage/services/offer';
import type { RootState } from '@monolith/legacy/store/index';

type UserSegmentContext = Pick<User, 'id' | 'email' | 'first_name' | 'last_name' | 'business'>;
type BrandSegmentContext = Pick<Brand, 'country' | 'fulfillment_enabled'>;
type RetailerSegmentContext = Pick<Retailer, 'country' | 'shop_type' | 'subscription_status'>;
type BrandTraits = { brand: { is_fulfillment_enabled: boolean; business_country: string } };
type RetailerTraits = { retailer: { shop_type: string; business_country: string; subscription_status?: boolean } };

const getRetailerTraits = (retailer: RetailerSegmentContext, ankorstorePlus: OfferProgramEligibility): RetailerTraits => {
  return {
    retailer: {
      business_country: retailer?.country?.iso_code,
      shop_type: retailer?.shop_type,
      subscription_status: ankorstorePlus?.optIn,
    },
  };
};
const getBrandTraits = (brand: BrandSegmentContext): BrandTraits => {
  return {
    brand: {
      is_fulfillment_enabled: brand?.fulfillment_enabled,
      business_country: brand?.country?.iso_code,
    },
  };
};

export const getUserTraitsFromUserObject = (
  user: UserSegmentContext,
  userTraits?: RetailerTraits | BrandTraits
): SegmentUserTraits & (RetailerTraits | BrandTraits) => {
  const address: Address = (user?.business as Business)?.addresses?.[0];
  const getPhone = (business: Business): string => {
    if (business?.phone_country?.calling_code && business?.phone_number) {
      return `+${business.phone_country.calling_code}${business.phone_number}`;
    }
    return undefined;
  };
  const traits = {
    id: user?.id,
    email: user?.email,
    firstName: user?.first_name,
    lastName: user?.last_name,
    phone: getPhone(user?.business),
    address: {
      street: address?.street,
      postalCode: address?.postal_code,
      city: address?.city,
      country: address?.country?.iso_code,
    },
  };

  return user?.id ? Object.assign(traits, userTraits) : null;
};

export const getUserTraitsFromStore = (store: Store<RootState>): SegmentUserTraits => {
  const user: UserSegmentContext = store.state?.user;
  const userTraits = () => {
    if (user?.business?.brand) {
      return getBrandTraits(store.state?.brand);
    } else if (user?.business?.retailer) {
      return getRetailerTraits(store.state?.retailer, store.getters['offers/ankorstorePlus']);
    } else {
      return null;
    }
  };

  return getUserTraitsFromUserObject(user, userTraits());
};
