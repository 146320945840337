<!-- eslint-disable @intlify/vue-i18n/no-dynamic-keys -->
<template>
  <div>
    <template v-if="hasNotAnswers">
      <div v-if="!isRevamp">
        <div class="form-label-group ds-first:ds-mb-1">
          <input
            v-model="currentModel"
            :data-testid="`qualification-question-input-${question.alias}`"
            :name="question.alias"
            class="form-control hide-placeholder-on-focus"
            :class="vuelidateFieldClassName(state[question.alias])"
            :placeholder="currentLabel"
            @change="(event) => onExtraContentChange(event, question.id)"
          >

          <label
            class="ds-pointer-events-none"
            data-testid="question-item-free-label"
          >{{ currentLabel }}</label>
        </div>
        <span
          v-if="state[question.alias] && state[question.alias].$dirty && state[question.alias].required.$invalid"
          data-testid="question-free-error"
          class="error-message"
        >{{ $t('This field is required') }}</span>
      </div>
      <div v-else>
        <AkInput
          v-model="currentModel"
          :label="currentLabel"
          :data-testid="`qualification-question-input-${question.alias}`"
          :name="question.alias"
          :has-error="state[question.alias] && state[question.alias].$error"
          :errors="getErrors"
          :placeholder="question.placeholder"
          :required="question.required"
          :disabled="question.disabled"
          @change="(event) => onExtraContentChange(event, question.id)"
        />
      </div>
    </template>
    <template
      v-for="answer in question.answers"
      :key="answer.alias"
    >
      <div v-if="answerRequiresContent(answer.answer_type)">
        <div v-if="!isRevamp">
          <div v-if="answer.answer_type === AnswerType.Longtext">
            <label data-testid="question-item-free-label">{{ currentLabel }}</label>
            <textarea
              v-model="currentModel"
              data-testid="question-item-textarea"
              :name="question.alias"
              class="form-control"
              :class="vuelidateFieldClassName(state[question.alias])"
              :placeholder="$t(answer.label)"
              :required="question.required"
              @change="(event) => onExtraContentChange(event, answer.id)"
            />
          </div>
          <input
            v-else-if="answer.answer_type === AnswerType.Text"
            v-model="currentModel"
            :data-testid="`qualification-question-answer-${question.alias}`"
            :name="question.alias"
            class="form-control"
            :class="vuelidateFieldClassName(state[question.alias])"
            :placeholder="$t(answer.label)"
            :required="question.required"
            @change="(event) => onExtraContentChange(event, answer.id)"
          >
        </div>
        <div v-else>
          <div v-if="answer.answer_type === AnswerType.Longtext">
            <AkTextarea
              v-model="currentModel"
              :label="currentLabel"
              data-testid="question-item-textarea"
              :name="question.alias"
              :has-error="state[question.alias] && state[question.alias].$error"
              :placeholder="$t(answer.label)"
              :required="question.required"
              @change="(event) => onExtraContentChange(event, answer.id)"
            />
          </div>
          <AkInput
            v-else-if="answer.answer_type === AnswerType.Text"
            v-model="currentModel"
            :data-testid="`qualification-question-answer-${question.alias}`"
            :name="question.alias"
            :has-error="state[question.alias].$error"
            :placeholder="$t(answer.label)"
            :required="question.required"
            @change="(event) => onExtraContentChange(event, answer.id)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AnswerType, Question } from '@monolith/legacy/types/question';
import { vuelidateFieldClassName } from '@core/utilities/fieldClassName';
import { State } from '@monolith/legacy/types/state';
import { extraContentAnswers } from '@monolith/legacy/components/sign-up/questions/constants';
import { AkInput, AkTextarea } from '@ankorstore/design-system';

export default defineComponent({
  name: 'QuestionFree',
  components: {
    AkInput,
    AkTextarea,
  },
  props: {
    question: {
      type: Object as () => Question,
      required: true,
    },
    state: {
      type: Object as () => State,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    isRevamp: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      AnswerType: AnswerType,
      currentModel: '',
    };
  },
  computed: {
    getErrors() {
      return this.state[this.question.alias]?.$error ? this.$t('This field is required') : '';
    },
    currentLabel() {
      // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
      return this.label ? this.$t(this.label) : this.$t(this.question.label);
    },
    hasNotAnswers() {
      return !this.question.answers?.length;
    },
  },
  watch: {
    model({ content }) {
      this.currentModel = content;
    },
  },
  mounted() {
    if (this.isRevamp) {
      this.currentModel = this.model.content;
    }
  },
  methods: {
    answerRequiresContent(type): boolean {
      return extraContentAnswers.includes(type);
    },
    onExtraContentChange(event, id): void {
      const { target } = event;
      this.$emit('change', this.question.alias, {
        answer_id: id,
        content: target.value,
      });
    },
    vuelidateFieldClassName,
  },
});
</script>

<style scoped lang="scss">
.textarea-form {
  textarea {
    min-height: 70px;
    font-size: 14px;
  }
}

textarea {
  @apply ds-mt-2 ds-text-sm;
}
</style>
