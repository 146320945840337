<template>
  <div>
    <AkHeading4> {{ $t('brand.account.products.csvUploadPreview.heading') }} </AkHeading4>
    <div class="ds-text-base ds-py-2 ds-mb-5">
      {{
        $t('brand.account.products.csvUploadPreview.subHeading', {
          products: productsCount,
          productAttributes: productAttributesCount,
        })
      }}
    </div>
    <AkAlert
      class="ds-text-left"
      type="info"
      :title="title"
      icon="info-circle"
      :hide-icon="false"
      :border="false"
    >
      <AkParagraph>
        {{ $t('brand.account.products.csvUploadPreview.banner') }}
      </AkParagraph>
    </AkAlert>
    <div class="ds-text-left ds-mt-7">
      <AkHeading4 class="ds-mb-4">
        {{ fileName }}
      </AkHeading4>
      <div class="ds-text-base ds-text-neutral-600">
        <div class="ds-flex ds-relative">
          <div>
            <div class="google-sheets-header">
              <img src="/images/account/account-products/google_sheets_head.png">
            </div>

            <table class="csv-upload-preview-table">
              <thead class="ds-text-center ds-capitalize ds-bg-neutral-300">
                <tr class="ds-text-neutral-700">
                  <th class="ds-w-6" />
                  <th
                    v-for="letter in letters"
                    :key="letter"
                  >
                    {{ letter }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="ds-font-bold">
                  <td>1</td>
                  <td
                    v-for="(header, i) in fileHeadersLimited"
                    :key="i"
                  >
                    {{ header }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="displayGradient"
            class="table-gradient"
          />
        </div>
        <div class="header-highlight" />
        <div class="ds-mt-3">
          {{ $t('brand.account.products.csvUploadPreview.tableBottomNote') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'CsvUploadPreview',
  props: {
    fileHeaders: {
      type: Array as PropType<(string | number)[]>,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    productAttributesCount: {
      type: String,
      required: true,
    },
    productsCount: {
      type: String,
      required: true,
    },
  },
  computed: {
    letters(): string[] {
      return this.fileHeadersLimited.map((_, i) => String.fromCharCode(97 + i));
    },
    fileHeadersLimited(): (string | number)[] {
      return this.fileHeaders.slice(0, 8);
    },
    displayGradient(): boolean {
      return this.fileHeaders.length > this.fileHeadersLimited.length;
    },
  },
});
</script>
<style scoped>
th,
td {
  @apply ds-border ds-border-solid ds-border-neutral-500 ds-px-1 ds-whitespace-nowrap ds-overflow-hidden ds-text-ellipsis;
  max-width: 12.5%;
}

thead {
  font-size: 10px;
}

td:first-child {
  @apply ds-bg-neutral-100 ds-font-normal ds-text-center;
  font-size: 10px;
}

.header-highlight {
  @apply ds-relative ds-border-2 ds-border-warning-700 ds-border-solid ds-rounded-md ds-h-6 ds--mx-3;
  top: -24px;
}

.filename {
  @apply ds-relative ds-font-bold ds-text-neutral-900;
  left: 40px;
  top: 24px;
  font-size: 10px;
}

.table-gradient {
  @apply ds-bg-gradient-to-r ds-from-transparent ds-to-white ds-absolute ds-w-4 ds-right-0 ds-h-full;
}

.google-sheets-header {
  @apply ds-border-t ds-border-l ds-border-r ds-border-solid ds-border-neutral-500;
}

.csv-upload-preview-table {
  @apply ds-w-full ds-text-xs ds-text-neutral-900 ds-table-fixed;
}
</style>
