<template>
  <div
    v-if="brandWidget"
    class="widget-container"
  >
    <div class="widget-container__content">
      <AkParagraph
        v-if="brandWidget.title"
        size="base"
        weight="bold"
        class="ds-text-neutral-600 ds-uppercase ds-mb-1 ds-text-center"
        data-testid="main-heading"
      >
        {{ brandWidget.title }}
      </AkParagraph>

      <img
        src="/images/logo/logo-black.svg"
        alt="Ankorstore"
        class="content__logo"
      >

      <AkHeading2
        v-if="brandWidget.subtitle"
        class="widget-container__title"
        data-testid="subtitle"
      >
        {{ brandWidget.subtitle }}
      </AkHeading2>

      <ul class="widget-container__features">
        <li
          v-if="brandWidget.payment"
          data-testid="payment"
          class="features__item"
        >
          <AkParagraph
            size="lg"
            class="ds-mb-0"
          >
            <AkIcon
              symbol="calendar-all-fill"
              class="ds-text-neutral-600 ds-mr-3"
            />
            {{ brandWidget.payment }}
          </AkParagraph>
        </li>
        <li
          v-if="brandWidget.minimum_order"
          data-testid="minimum-order"
          class="features__item"
        >
          <AkParagraph
            size="lg"
            class="ds-mb-0"
          >
            <AkIcon
              symbol="cash"
              class="ds-text-neutral-600 ds-mr-3"
            />
            {{ brandWidget.minimum_order }}
          </AkParagraph>
        </li>
        <li
          v-if="brandWidget.shipping"
          data-testid="shipping"
          class="features__item"
        >
          <AkParagraph
            size="lg"
            class="ds-mb-0"
          >
            <AkIcon
              symbol="truck"
              class="ds-text-neutral-600 ds-mr-3"
            />
            {{ brandWidget.shipping }}
          </AkParagraph>
        </li>
      </ul>
      <!-- eslint-disable vue/no-v-html -->
      <AkParagraph
        v-if="brandWidget.description"
        class="widget-container__description"
        data-testid="description"
        v-html="brandWidget.description"
      />

      <a
        v-if="brandUrl"
        :href="brandUrl"
        target="_parent"
        class="ds-max-w-lg ds-my-5 ds-flex ds-flex-col ds-items-center"
      >
        <AkButton
          size="xl"
          multiline
        >
          {{ brandWidget.brand_page_link }}
        </AkButton>
      </a>
    </div>
  </div>
  <div
    v-else
    class="widget-container"
  >
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    />
  </div>
</template>
<script lang="ts">
import { prismic } from '@core/mixins/prismic';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BrandWidgetV2',
  components: {},
  mixins: [prismic],
  props: {
    brandUrl: {
      type: String,
      required: true,
    },
    brandId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      brandWidget: null,
    };
  },
  async created() {
    const brand = await this.$store.dispatch('shop/fetchBrand', {
      brand_id: this.brandId,
    });
    this.$store.commit('REPLACE_BRAND', brand);
    const document = await this.getSingle('brand_widget_v2');
    if (document) {
      this.brandWidget = document.data;
      await this.sendResizeMessage();
    }
  },
  methods: {
    async sendResizeMessage() {
      await this.$nextTick();
      parent.postMessage('resize::' + document.body.offsetHeight, document.referrer);
    },
  },
});
</script>
<style lang="scss">
@import '@css/vue-import';
.widget-container {
  @apply ds-flex ds-items-center ds-justify-center ds-min-h-screen;

  &__content {
    @apply ds-w-full ds-rounded-md ds-border ds-border-solid ds-border-neutral-300 ds-p-6 ds-m-4 ds-max-w-lg;

    .content__logo {
      @apply ds-flex ds-mx-auto;
      width: 9rem;
    }
  }

  &__title {
    @apply 2xl:ds-font-bold ds-mt-5 ds-flex ds-flex-col ds-items-center ds-text-center ds-mx-auto ds-max-w-xs ds-my-5 ds-text-2xl ds-text-center;
  }

  &__features {
    @apply ds-bg-neutral-100 ds-rounded-md ds-border ds-border-solid ds-border-neutral-300 ds-border-2 ds-my-5;

    .features__item {
      @apply ds-border-solid ds-border-neutral-300 ds-border-b-2 ds-p-3;

      &:last-child {
        @apply ds-border-b-0;
      }
    }
  }

  &__description {
    @apply ds-text-center ds-text-lg;

    .lift-code {
      @apply ds-rounded-lg ds-border ds-border-solid ds-border-neutral-300 ds-border-2 ds-bg-neutral-100 ds-py-1 ds-px-4 ds-inline-block ds-mt-2;
    }
  }
}
</style>
