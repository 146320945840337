import {
  SET_COUNTRY,
  REMOVE_RECENT_SEARCH,
  RESET_ARROW_POSITION,
  SET_ARROW_POSITION,
  SET_ARROW_POSITION_DOWN,
  SET_ARROW_POSITION_UP,
  SET_HISTORICAL_FROM_LOCALSTORAGE,
  SET_INITIAL_RESULTS,
  SET_ORIGINAL_USER_INPUT,
  SET_RECENT_SEARCH,
  SET_RECENT_SEARCH_HISTORY,
  SET_RESULTS_ELASTIC,
  SET_RESULTS_IDS,
  SET_RESULTS_VISIBILITY,
  SET_SUGGESTED_CATEGORIES,
  SET_POPULAR_SEARCHES,
} from './mutation-types';
import {
  CategoryResult,
  GroupedResults,
  PopularSearch,
  RecentSearchItem,
  RESULT_TYPE,
  ServiceResponse,
  StaticItem,
  TrendingSuggestion,
} from '@bc/discovery/domain/search';
import { SearchState } from './index';
import i18n from '@monolith/legacy/services/i18n';
import {
  DEFAULT_ARROW_POSITION,
  HISTORICAL_SEARCH_KEY,
  MAX_NUM_CATEGORY_RESULTS,
  MAX_NUM_RECENT_SEARCHES,
  MAX_RESULTS,
  MAX_SUGGESTION,
  SHOW_ALL_ID,
} from '../constants';

const searchMutations = {
  [SET_COUNTRY]: (state: SearchState, country: string) => {
    state.country = country;
  },
  [SET_INITIAL_RESULTS]: (
    state: SearchState,
    {
      r2bLink,
    }: {
      r2bLink?: StaticItem;
    }
  ) => {
    state.results = {
      history: state.results.history ?? [],
      suggestions: [],
      categories: [],
      brands: [],
      searchAll: null,
      boutiques: [],
      ...(r2bLink && { r2bLink }),
    };
  },
  [SET_SUGGESTED_CATEGORIES]: (state: SearchState, { categories }: { categories: CategoryResult[] }) => {
    state.results = {
      ...state.results,
      categories: categories
        ?.map((x) => ({
          ...x,
          id: `category-${x.name}`,
          type: RESULT_TYPE.CATEGORIES,
        }))
        .slice(0, MAX_NUM_CATEGORY_RESULTS),
    };
  },
  [SET_POPULAR_SEARCHES]: (
    state: SearchState,
    { popularSearches, query }: { popularSearches: Omit<PopularSearch, 'id' | 'link'>[]; query: string }
  ) => {
    const maxResultsWithoutSearchQuery =
      MAX_RESULTS -
      (state.results.history?.length > MAX_NUM_RECENT_SEARCHES ? MAX_NUM_RECENT_SEARCHES + 1 : state.results.history?.length);
    state.results = {
      ...state.results,
      trending: popularSearches
        ?.map(
          (x) =>
            ({
              ...x,
              id: `suggestion-${x.name}`,
              link: `/search?q=${encodeURIComponent(x.name)}`,
              type: RESULT_TYPE.TRENDING,
            }) as TrendingSuggestion
        )
        .slice(0, query ? MAX_SUGGESTION : maxResultsWithoutSearchQuery),
    };
  },
  [SET_RESULTS_ELASTIC]: (state: SearchState, results: ServiceResponse) => {
    state.results = {
      ...state.results,
      suggestions: results.popularSearches
        ?.filter((x) => state.results.history.every((y) => y.name !== x.name))
        ?.map(
          (x) =>
            ({
              ...x,
              id: `suggestion-${x.name}`,
              link: `/search?q=${encodeURIComponent(x.name)}`,
              type: RESULT_TYPE.POPULAR,
            }) as TrendingSuggestion
        )
        .slice(0, 10),
      categories: results.categories
        ?.map((x) => ({
          ...x,
          id: `category-${x.name}`,
          type: RESULT_TYPE.CATEGORIES,
        }))
        .slice(0, MAX_NUM_CATEGORY_RESULTS),
      brands: results.brands
        ?.map((x) => ({
          ...x,
          id: `brand-${x.reference}`,
          type: RESULT_TYPE.BRANDS,
        }))
        .slice(0, MAX_SUGGESTION),
      trending: [],
      searchAll: {
        id: SHOW_ALL_ID,
        link: `/search?q=${encodeURIComponent(state.originalUserInput)}`,
      },
      boutiques: results.boutiques?.map((item) => {
        if (item.link === '/boutique/holiday') {
          // Yes, this is 100% hardcoded for the time being.
          // The current response only contains 'name' and 'link' properties.
          return {
            id: item.name,
            name: i18n.global.t('@@core_discovery.searchBar.temp.boutiqueSuggestion.name'),
            info: i18n.global.t('@@core_discovery.searchBar.temp.boutiqueSuggestion.info'),
            link: item.link,
            imageUrl: '/images/search/holidayRecoSearch.png',
            type: RESULT_TYPE.BOUTIQUE,
          };
        }
        return item;
      }),
    };
  },
  [SET_RESULTS_IDS]: (
    state: SearchState,
    { brands, trending, suggestions, categories, history, boutiques, searchAll, r2bLink }: GroupedResults
  ) => {
    const maxHistoryResults =
      state.results.history?.length > MAX_NUM_RECENT_SEARCHES ? MAX_NUM_RECENT_SEARCHES + 1 : state.results.history?.length;
    state.resultsIds = [
      ...(history ? history.map(({ id }) => id) : []).slice(0, maxHistoryResults),
      ...(trending ? trending.map(({ id }) => id) : []),
      ...(suggestions ? suggestions.map(({ id }) => id) : []),
      ...(categories ? categories.map(({ id }) => id) : []),
      ...(brands ? brands.map(({ id }) => id) : []),
      ...(boutiques ? boutiques.map(({ id }) => id) : []),
      ...(searchAll ? [searchAll.id] : []),
      ...(r2bLink ? [r2bLink.id] : []),
    ];
  },
  [SET_RESULTS_VISIBILITY]: (state: SearchState, value: boolean) => {
    state.isOpen = value;
  },
  [SET_ORIGINAL_USER_INPUT]: (state: SearchState, value: string) => {
    state.originalUserInput = value;
  },
  [RESET_ARROW_POSITION]: (state: SearchState) => {
    state.arrowPosition = DEFAULT_ARROW_POSITION;
  },
  [SET_ARROW_POSITION]: (state: SearchState, itemId: number | string) => {
    state.arrowPosition = state.resultsIds.indexOf(itemId);
  },
  [SET_ARROW_POSITION_UP]: (state: SearchState) => {
    if (state.arrowPosition > 0) {
      state.arrowPosition -= 1;
    } else if (state.arrowPosition === 0) {
      state.arrowPosition = DEFAULT_ARROW_POSITION;
    } else {
      state.arrowPosition = state.resultsIds.length - 1;
    }
  },
  [SET_ARROW_POSITION_DOWN]: (state: SearchState) => {
    if (state.arrowPosition === state.resultsIds.length - 1) {
      state.arrowPosition = DEFAULT_ARROW_POSITION;
    } else if (state.arrowPosition < state.resultsIds.length) {
      state.arrowPosition += 1;
    }
  },
  [SET_RECENT_SEARCH_HISTORY]: (state: SearchState, items: RecentSearchItem[]) => {
    state.results.history = items;
  },
  [SET_RECENT_SEARCH]: (state: SearchState, item: RecentSearchItem) => {
    if (!state.userLocalHistory?.find((element) => element.name === item.name)) {
      state.userLocalHistory.unshift(item);
    }
    localStorage.setItem(HISTORICAL_SEARCH_KEY, JSON.stringify(state.userLocalHistory ?? []));
  },
  [SET_HISTORICAL_FROM_LOCALSTORAGE]: (state: SearchState, historical: Array<RecentSearchItem>) => {
    state.userLocalHistory = historical || [];
  },
  [REMOVE_RECENT_SEARCH]: (state: SearchState, item: RecentSearchItem) => {
    state.results.history.splice(state.results.history.indexOf(item), 1);
    const indexToRemove = state.userLocalHistory.findIndex((i) => i.name === item.name);
    state.userLocalHistory.splice(indexToRemove, 1);
    localStorage.setItem(HISTORICAL_SEARCH_KEY, JSON.stringify(state.userLocalHistory ?? []));
  },
};

export default searchMutations;
export type SearchMutations = typeof searchMutations;
