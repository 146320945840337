/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Failure {
  /** @uniqueItems true */
  errors: Error[];
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

/** Non-standard meta-information that can not be represented as an attribute or relationship. */
export type Meta = Record<string, any>;

/** References to other resource objects in a to-one (`relationship`). Relationships can be specified by including a member in a resource's links object. */
export type RelationshipToOne = Linkage;

/**
 * An array of objects each containing `type` and `id` members for to-many relationships.
 * @uniqueItems true
 */
export type RelationshipToMany = Linkage[];

/** Resource identification present in Resource Objects and Resource Identifier Objects. */
export interface Linkage {
  /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
  id: Id;
  /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
  type: Type;
  /** Non-standard meta-information that can not be represented as an attribute or relationship. */
  meta?: Meta;
}

/** An object describing the server's implementation */
export interface Jsonapi {
  /** @example "1.0" */
  version?: string;
  /** Non-standard meta-information that can not be represented as an attribute or relationship. */
  meta?: Meta;
}

export interface Error {
  /** An application-specific error code, expressed as a string value. */
  code?: string;
  /** A human-readable explanation specific to this occurrence of the problem. */
  detail?: string;
  /** The HTTP status code applicable to this problem, expressed as a string value. */
  status?: string;
  /** The HTTP status code description applicable to this problem */
  title?: string;
  /** Optional object pointing towards the problematic field */
  source?: {
    /** The field key */
    pointer?: string;
  };
}

/**
 * [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification)
 * @format uuid
 */
export type Id = string;

/** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
export type Type = string;

export enum CarouselTypeEnum {
  DiscoveryBrand = 'discovery-brand',
  ReorderBrand = 'reorder-brand',
}

/**
 * An object used to detail pricing information for various Resources.
 * @example {"currency":"EUR","amount":142}
 */
export interface Amount {
  /** ISO 3-Letter Currency Code */
  currency: Currency;
  /** Lowest denomination of the currency this amount belongs to. */
  amount: Money;
}

/**
 * ISO 3-Letter Currency Code
 * @example "EUR"
 */
export type Currency = string;

/** Lowest denomination of the currency this amount belongs to. */
export type Money = number;

/**
 * [Short and unique alphanumeric codes to represent the relevant country](https://en.wikipedia.org/wiki/ISO_3166-2)
 * @pattern ^[A-Z]{2}$
 * @example "FR"
 */
export type CountryCode = string;

export interface MasterOrder {
  /** Type of wrapped order */
  type: MasterOrderTypeEnum;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  preorderCreatedAt?: null | string;
  /** @pattern ^[EI][A-NP-Z]{3}[1-9]{7}$ */
  reference: string;
  customReference?: null | string;
  masterStatus: MasterOrderMasterStatusEnum;
  status: {
    name: string;
    shortTitle?: string;
    title: null | string;
    details: null | string;
    subStatus?: {
      shortTitle?: string;
    };
  };
  /** The `amounts` property of the `ExternalOrder`, if set. */
  amounts?: {
    /** @example "EUR" */
    brandCurrency: string;
    /** @example 1 */
    brandCurrencyRate: number;
    /** @example "€546.20" */
    brandTotalAmount: string;
    /** @example "€126.33" */
    brandTotalAmountVat: string;
    /** @example "€672.53" */
    brandTotalAmountWithVat: string;
    /** @example "EUR" */
    customerCurrency: string;
    /** @example 1 */
    customerCurrencyRate: number;
    /** @example "€546.20" */
    customerTotalAmount: string;
    /** @example "€126.33" */
    customerTotalAmountVat: string;
    /** @example "€672.53" */
    customerTotalAmountWithVat: string;
    /** @example "€2.20" */
    shippingFeesAmount: string;
    /** @example "€0.44" */
    shippingFeesAmountVat: string;
    /** @example "€2.64" */
    shippingFeesAmountWithVat: string;
    /** @example "€548.40" */
    customerGrandTotalAmount: string;
    /** @example "€126.77" */
    customerGrandTotalAmountVat: string;
    /** @example "€675.17" */
    customerGrandTotalAmountWithVat: string;
  } | null;
  /** The `metadata` property of the `ExternalOrder`, if set. */
  metadata?: {
    /** @example "fulfillment" */
    shippingMethod: string;
    address: {
      /** @example "FR" */
      countryCode: string;
      /** @example "75116" */
      postalCode: string;
      /** @example "Paris" */
      city: string;
      /** @example "av Kleber" */
      addressLine: string;
      /** @example null */
      company: string | null;
    };
    contactPerson: {
      /** @example "John" */
      firstName: string;
      /** @example "Smith" */
      lastName: string;
      /** @example "foo@bar.com" */
      email: string;
      /** @example null */
      phone: string | null;
    };
  };
  internalId: null | number;
  /** @format uuid */
  internalUuid?: string | null;
  /** @format uuid */
  externalOrderUuid?: string | null;
  isDiscovery?: null | boolean;
  isDiscoveryForRetailer?: null | boolean;
  isBelongingToReferral?: null | boolean;
  totals: null | {
    totalAmount: any;
    totalVatAmount: any;
    totalAmountWithVat: any;
    netAmount: any;
  };
  brand: {
    /** @format uuid */
    id: string;
    name: string;
    /** @format url */
    link: string;
    display_address: {
      city: string;
    };
  };
  user: {
    /** @format uuid */
    uuid: string;
    /** @example "John" */
    firstName: string;
    /** @example "Smith" */
    lastName: string;
    /**
     * @format email
     * @example "john@smith.com"
     */
    email: string;
  } | null;
  updates: null | {
    oldTotalAmount: any;
    oldTotalAmountVat: any;
    oldTotalAmountWithVat: any;
    oldRecargoAmount: any;
    itemUpdates: {
      itemId: string;
      oldQuantity: number;
      oldMultipliedQuantity: number;
      oldAmount: any;
    }[];
  };
  taxes:
    | null
    | {
        name: string;
        amount: {
          amount: number;
          currency: string;
        };
      }[];
  priorityTag: null | {
    name: string;
  };
  vat: null | {
    isShifted: boolean;
    applicationDetails: null | {
      message: string;
      link: null | {
        href: string;
        text: string;
      };
    };
  };
  deadlines: null | {
    /** @format date-time */
    toShip: null | string;
    /** @format date-time */
    toAccept: null | string;
    /** @format date-time */
    toActOnIssue: null | string;
  };
  synchronizations:
    | null
    | {
        type?: MasterOrderTypeEnum1;
        platformName?: MasterOrderPlatformNameEnum;
        error?: null | string;
      }[];
  invoices:
    | null
    | {
        isGenerated: boolean;
        title: string;
        number: null | string;
        path: null | string;
      }[];
  shipping: {
    shippingProvider: null | string;
    isInternational: boolean;
    hasShippingLabelGenerationError: boolean;
    hasRevertedShippingLabels: boolean;
    fees: null | {
      amount: any;
      refundAmount: any;
    };
    shippingAddress: {
      address: {
        countryCode: null | string;
        postalCode: null | string;
        city: null | string;
        addressLine: null | string;
      };
      company: null | string;
      contactPerson: {
        firstName: null | string;
        lastName: null | string;
        fullName: null | string;
        phoneNumber: null | string;
        /** @format email */
        email: null | string;
      };
    };
    availableShippingMethods:
      | null
      | {
          name: MasterOrderNameEnum;
          canBeUsed: boolean;
          errors: string[] | object;
        }[];
    shipmentEstimation: null | {
      collectionMethods: MasterOrderCollectionMethodsEnum[];
      rateProvider: null | string;
      rateAmount: any;
      estimatedParcels: {
        weight: null | number;
        height: null | number;
        length: null | number;
        width: null | number;
        distanceUnit?: MasterOrderDistanceUnitEnum;
      }[];
    };
    shipment: null | {
      /** @format date-time */
      shippedAt: null | string;
      labelPrintingFormat: MasterOrderLabelPrintingFormatEnum;
      trackedPackages: {
        /** @format url */
        labelUrl?: null | string;
        parcel?: any;
      }[];
      shipFromAddress: any;
      pickup: null | {
        /** @format date-time */
        date: string;
        readyTime: string;
        closeTime: string;
      };
      labelRevertReasons: {
        actionType: string;
        actionReason: string;
      }[];
    };
  };
  tracking: null | {
    trackingNumber: null | string;
    trackingLink: null | string;
    status: null | string;
  };
  billing: null | {
    billingItems: {
      id: number;
      type: string;
      label: string;
      displayVat: boolean;
      isVatShifted: boolean;
      amount: object;
      amountVat: object;
      amountWithVat: object;
      /** Amount of the billing item in percents */
      percentage: null | number;
      /** Whether the billing item was created as part of a special deal */
      isSpecialDeal: boolean;
      francoSetting?: null | {
        francoAmount?: object;
        francoFlatFeeAmount?: object;
      };
    }[];
  };
  issue: null | {
    casesVisibleToBrand: string[];
    casesAllowedToBeSentToBrand: string[];
    current: null | {
      id: number;
      status: string;
      escalatedToCsByBrand: boolean;
      escalatedToCsToAdjustQuantityByBrand: boolean;
      escalatedToCsOnReship: boolean;
      autoEscalatedToCs: boolean;
      autoEscalatedToCsOnReship: boolean;
      send_to_brand_enabled: boolean;
      escalatedNewClaimToCs: null | boolean;
      reason: string;
      shippedDate: string;
      autoEscalateDate: string;
      trackingLink: string;
      trackingNumber: string;
      shippingProvider: string;
      reshippedDate: null | string;
      products: {
        id: number;
        name: string;
        sku: string;
        description: string;
        quantity: number;
        files: {
          url: string;
        }[];
      }[];
      packagingFiles: {
        url: string;
      }[];
      parcelReservationFiles: {
        url: string;
      }[];
      parcelContentFiles: {
        url: string;
      }[];
      options: {
        label: string;
        text: string;
      }[];
    };
  };
  retailer: {
    id: null | number;
    /** @format uuid */
    uuid?: string | null;
    name: string;
    /** @format email */
    email: string;
    phone_number: string;
    city: string;
    business: {
      user_first_name: string;
      user_last_name: string;
      vat_number: null | string;
      tax_number: null | string;
      country: {
        iso_code: string;
      };
    };
  };
  rejection: null | {
    brandRejectType: null | string;
    availableBrandRejectReasons: {
      rejectType: string;
      rejectTypeLabel: string;
    }[];
    isRetailerCancellationRequestOpened: boolean;
  };
  meta: null | {
    showAlcoholWarning?: boolean;
    showManualInvoiceDownloadWarning?: boolean;
    casesVisibleToBrand?: string[];
    casesAllowedToBeSentToBrand?: string[];
  };
}

export interface BrandCardResource {
  type: string;
  id: string;
  attributes: BrandCardAttributes;
}

export interface ProductCardResource {
  type: string;
  id: string;
  attributes: ProductCardAttributes;
}

export interface BrandCardAttributes {
  brand_id: number;
  brand_link: string;
  brand_name: string;
  brand_logo: string;
  brand_is_new: boolean;
  brand_discount: number;
  brand_images: {
    src: string;
  }[];
  brand_total_products_count?: number;
  brand_has_new_products?: boolean;
  is_first_order?: boolean;
  last_order_on?: string;
}

export interface ProductCardAttributes {
  uuid: string;
  id: number;
  retail_price: {
    currency?: string;
    amount?: number;
  };
  retail_prices: {
    EUR?: number;
    GBP?: number;
    SEK?: number;
  };
  wholesale_price: {
    currency?: string;
    amount?: number;
  };
  original_wholesale_price: {
    currency?: string;
    amount?: number;
  };
  discount_rate: number;
  discount_type: string;
  is_discounted: boolean;
  unit_multiplier: number;
  link: string;
  name: string;
  out_of_stock: boolean;
  is_new: boolean;
  brand: {
    id?: number;
    name?: string;
    brandLocation?: {
      lon?: number;
      lat?: number;
    };
    link?: string;
    minimum?: number;
    country_code?: string;
    segment?: string;
    country?: object;
    opened_countries?: string[];
    images?: {
      squared?: string;
      rounded?: string;
      large?: string;
    };
    business_events?: object;
    business_events_eligible?: number[];
    business_events_rankings?: object;
    location?: string[];
    shipping_lead_time?: string[];
    brand_tags?: string[];
    synonyms?: string[];
    aks_plus?: string[];
  };
  options: {
    id?: number;
    variation?: string | null;
    ian?: string;
    sku?: string;
    out_of_stock?: boolean;
    stock?: {
      id?: number;
      is_always_in_stock?: boolean;
      available_quantity?: number | null;
      status?: string;
    };
  }[];
  variants: {
    id?: number;
    uuid?: string;
    sku?: string;
    ian?: string;
    out_of_stock?: boolean;
    retail_prices?: {
      EUR?: number;
      GBP?: number;
      SEK?: number;
    };
    retail_price?: {
      currency?: string;
      amount?: number;
    };
    wholesale_price?: {
      currency?: string;
      amount?: number;
    };
    stock?: {
      id?: number;
      is_always_in_stock?: boolean;
      available_quantity?: number | null;
      status?: string;
    };
    options?: object[];
  }[];
  images: string[];
  discount_event_price?: {
    currency?: string | null;
    amount?: number | null;
  };
}

export interface CollectionCardResource {
  type: string;
  id: string;
  attributes: {
    collection_uuid: string;
    collection_link: string;
    collection_name: string;
    collection_image: string;
    collection_count: number;
    collection_type: CollectionCardResourceCollectionTypeEnum;
  };
}

/** Type of wrapped order */
export enum MasterOrderTypeEnum {
  Internal = 'internal',
  External = 'external',
}

export enum MasterOrderMasterStatusEnum {
  Created = 'created',
  Prepared = 'prepared',
  Shipped = 'shipped',
  Delivered = 'delivered',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export enum MasterOrderTypeEnum1 {
  PublicApp = 'publicApp',
}

export enum MasterOrderPlatformNameEnum {
  WOO_COMMERCE = 'WOO_COMMERCE',
  SHOPIFY = 'SHOPIFY',
}

export enum MasterOrderNameEnum {
  Custom = 'custom',
  Ankorstore = 'ankorstore',
  Fulfillment = 'fulfillment',
}

export enum MasterOrderCollectionMethodsEnum {
  Pickup = 'pickup',
  DropOff = 'drop-off',
}

export enum MasterOrderDistanceUnitEnum {
  Cm = 'cm',
}

export enum MasterOrderLabelPrintingFormatEnum {
  Default = 'default',
  FullPage = 'full_page',
}

export enum CollectionCardResourceCollectionTypeEnum {
  Product = 'product',
  Brand = 'brand',
}

export interface ListApplicationsInternalData {
  /**
   * ApplicationResource
   * @example {"id":"43efbfbd-bfbd-1eef-1e6a-6200efbfbdef","type":"applications","attributes":{"name":"My sandbox","developerEmail":"dev@my-app.com","clientId":"k322k7frs87e8w7hri3jkke7ry7","clientSecret":"v8s98g9s895h8929834"}}
   */
  data: {
    type: 'applications';
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      /** Custom application name (should be unique within user applications) */
      name: string;
      /**
       * Email of the developer to contact in context of any issues with this application
       * @format email
       * @example "developer@application.com"
       */
      developerEmail: string;
      /** Client ID to be used for access token issuance */
      clientId: string;
      /** Secret to be used for access token issuance. Can be invoked only once, when application is created or credentials re-generated. */
      clientSecret: string | null;
    };
    /** Only presented if asked explicitly in the request */
    relationships?: {
      webhookSubscriptions?: {
        /** An array of objects each containing `type` and `id` members for to-many relationships. */
        data?: RelationshipToMany;
      };
    };
  };
  /** To reduce the number of HTTP requests, servers **MAY** allow responses that include related resources along with the requested primary resources. Such responses are called `compound documents`. */
  included?: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      /**
       * URL of the service where webhook request will be sent when one of the events from the list occurs
       * @format uri
       * @example "https://my.app/webhook/listener"
       */
      webhookUrl: string;
      /** @uniqueItems true */
      events: string[];
      signingSecret: string;
    };
  }[];
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

/** @example {"jsonapi":{"version":"1.0"},"data":{"type":"applications","id":"43efbfbd-bfbd-1eef-1e6a-6200efbfbdef","attributes":{"name":"My sandbox","developerEmail":"dev@my-app.com","clientId":"k322k7frs87e8w7hri3jkke7ry7","clientSecret":"v8s98g9s895h8929834"},"relationships":{"webhookSubscriptions":{"data":[{"id":"efbfbdef-bfbd-1eef-bfbd-efbfbdefbfbd","type":"webhook-subscription"}]}}},"included":[{"id":"efbfbdef-bfbd-1eef-bfbd-efbfbdefbfbd","type":"webhook-subscription","attributes":{"webhookUrl":"https://my-app.com/webhook-listener","signingSecret":"some-secret","events":["orders.brand_created","orders.brand_accepted","orders.brand_rejected","orders.shipped"]}}]} */
export interface CreateApplicationData {
  data: any;
  /** To reduce the number of HTTP requests, servers **MAY** allow responses that include related resources along with the requested primary resources. Such responses are called `compound documents`. */
  included?: any[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export type DeleteApplicationData = any;

export enum GetApiInternalV1BrandsBrandIdTypeEnum {
  Onboarding = 'onboarding',
}

export enum GetApiInternalV1BrandsBrandIdTypeEnum1 {
  FrancoSettingsSummary = 'franco-settings-summary',
}

export enum GetApiInternalV1BrandsBrandIdTypeEnum2 {
  Onboarding = 'onboarding',
}

export enum GetApiInternalV1BrandsBrandIdStatusEnum {
  Completed = 'completed',
  InProgress = 'in_progress',
}

export enum GetApiInternalV1BrandsBrandIdTypeEnum3 {
  FrancoSettingsSummary = 'franco-settings-summary',
}

export interface GetApiInternalV1BrandsBrandIdParams {
  /** Brand's attributes separated by comma */
  'fields[brands]'?: string;
  /**
   * A comma-separated list of resources to include (e.g: onboarding)
   * @pattern ^(users|onboarding|franco-settings-summary)(,(users|onboarding|franco-settings-summary))*$
   */
  include?: string;
  /** @format uuid */
  brandId: string;
}

export interface GetApiInternalV1BrandsBrandIdData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: {
    /** @format uuid */
    id: string;
    type: string;
    attributes: {
      name?: string;
      status?: string;
      isOnHoliday?: boolean;
      /** @format date */
      holidayStartDate?: string;
      /** @format date */
      holidayEndDate?: string;
    };
    relationships?: {
      onboarding?: {
        data: {
          /** @format uuid */
          id: string;
          type: GetApiInternalV1BrandsBrandIdTypeEnum;
        };
      };
      'franco-settings-summary'?: {
        data: {
          /** @format uuid */
          id: string;
          type: GetApiInternalV1BrandsBrandIdTypeEnum1;
        };
      };
    };
  };
  included?: (
    | {
        type: GetApiInternalV1BrandsBrandIdTypeEnum2;
        /** @format uuid */
        id: string;
        attributes: {
          status: GetApiInternalV1BrandsBrandIdStatusEnum;
          steps: {
            name?: string;
            progress?: number;
            isComplete?: boolean;
          }[];
          /** @format date */
          createdAt: string;
          /** @format date */
          completedAt: string | null;
        };
      }
    | {
        type: GetApiInternalV1BrandsBrandIdTypeEnum3;
        /** @format uuid */
        id: string;
        attributes: {
          hasFrancoSettings: boolean;
        };
      }
  )[];
}

export enum PatchApiInternalV1BrandsBrandIdStatusEnum {
  Online = 'online',
  Offline = 'offline',
  ComingSoon = 'coming_soon',
}

export interface GetBrandsCompanyData {
  /** CompanyResource */
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      founded_in_year: number;
      company_name: string;
      company_invoice_description: string;
      tax_number: string | null;
      vat_exemption: boolean;
      vat_number: string | null;
      sole_trader_number: string | null;
      above_intracomm_threshold: boolean;
    };
  };
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

export interface ListBrandsFrancoParams {
  /** @minItems 1 */
  'filter[brandId]': string[];
}

export interface ListBrandsFrancoData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: {
    type?: 'franco';
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      /** @format uuid */
      brandId: string;
      amount: number;
      fee: number;
      maxFee: number;
      currency: string;
    };
  }[];
}

export interface ListBrandsFrancoSettingsData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: any[];
}

export enum CreateBrandsFrancoSettingsTypeEnum {
  FrancoSettings = 'franco-settings',
}

export enum CreateBrandsFrancoSettingsCorridorEnum {
  Domestic = 'domestic',
  Eu8 = 'eu8',
  International = 'international',
}

export interface CreateBrandsFrancoSettingsData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  /** FrancoSettingsResource */
  data: {
    type: 'franco-settings';
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      corridor: string;
      amount: number;
      currency: string;
      fee: number;
    };
    meta: {
      amountOptions: any[];
      feeOptions: {
        amount: number;
        currency: string;
        isSpecialDeal: boolean;
        isDefault: boolean;
      }[];
      [key: string]: any;
    };
  };
}

export interface GetBrandsShippingSettingsData {
  /** ShippingSettingsResource */
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      publicAddress: {
        street: string;
        postalCode: string;
        city: string;
        countryCode: string;
      };
      shippingAddress: {
        street: string;
        postalCode: string;
        city: string;
        countryCode: string;
      };
      billingAddress: {
        street: string;
        postalCode: string;
        city: string;
        countryCode: string;
      };
      eoriNumber: string;
      lucidNumber: string;
      averageLeadTime: string;
      /** List of country iso codes */
      whitelistCountries: string[];
    };
  };
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

export enum GetBrandRetailerTypeEnum {
  Custom = 'custom',
  BrandCustom = 'brand_custom',
  LiftOrder = 'lift_order',
  Network = 'network',
  SpecialOperation = 'special_operation',
  BrandCustomNetwork = 'brand_custom_network',
  Widget = 'widget',
}

export interface GetBrandRetailerData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      automaticOrderValidationMode?: boolean | null;
      repeatFeesPercentage?: number | null;
      firstFeesPercentage?: number | null;
      type?: GetBrandRetailerTypeEnum;
      /** @format date-time */
      expiresAt?: string | null;
    };
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export interface GetCartItemCounterData {
  /**
   * ItemCounterResource
   * Item Counter resource
   */
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      main: number;
      savedForLater: number;
    };
  };
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

export interface GetCheckoutValidationsData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  /**
   * CheckoutValidationsResource
   * Checkout validations resource
   */
  data?: {
    /**
     * [resource object type](https://jsonapi.org/format/#document-resource-object-identification)
     * @example "checkout-validations"
     */
    type?: string;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      /** @example true */
      shouldShowTaxNumberQuestion?: boolean;
      /** @example "HRA10526" */
      taxNumber?: string | null;
      /** @example false */
      shouldShowVatNumberQuestion?: boolean;
      /** @example "DE279778283" */
      vatNumber?: string | null;
      /** @example true */
      shouldShowVatExemptionCheckbox?: boolean;
      /** @example false */
      vatExemption?: boolean;
      /** @example true */
      shouldDisableNoNumberOption?: boolean;
      /** @example false */
      isSoleTrader?: boolean;
      /** @example true */
      shouldShowCompanySelect?: boolean;
      /** @example false */
      shouldShowCompanyRegistrationForm?: boolean;
      /** @example false */
      shouldShowCompanyRegistrationBusinessNameInput?: boolean;
      /** @example null */
      eoriNumber?: string | null;
      /** @example false */
      shouldShowEoriNumberQuestion?: boolean;
      /** @example null */
      isPayingRecargo?: boolean | null;
      /** @example false */
      shouldShowRecargoQuestion?: boolean;
    };
  };
}

export enum GetCartSummaryTypeEnum {
  RetailerShippingFees = 'retailer_shipping_fees',
}

export enum GetCartSummaryTypeEnum1 {
  FeesVat = 'fees_vat',
  GoodsVat = 'goods_vat',
  RecargoDeEquivalencia = 'recargo_de_equivalencia',
}

export enum GetCartSummaryTypeEnum2 {
  Promocode = 'promocode',
  OfferDiscount = 'offer_discount',
  EventDiscount = 'event_discount',
  PaymentMethodDiscount = 'payment_method_discount',
  Wallet = 'wallet',
}

export interface GetCartSummaryData {
  /**
   * CartSummaryResource
   * Cart Summary resource
   */
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      summary: {
        /** sum of product taxes (vat) + sum of individual fees (and their respective vat) + sum of global taxes (recargo, ...) */
        feesAndTaxesTotal?: Amount;
        /** sum of discounts (promocode, offers, ...) */
        discountsTotal?: Amount;
        /** everything considered, taxes included */
        grandTotal?: Amount;
      };
      fees: ({
        type: string;
        label?: string;
        /** An object used to detail pricing information for various Resources. */
        amount: Amount;
      } & {
        type?: GetCartSummaryTypeEnum;
        /** An object used to detail pricing information for various Resources. */
        amountLeftForFreeShipping?: Amount;
        metadata?: {
          offerName?: string;
          isShippingFree?: boolean;
          isEligibleForDiscoveryFreeShipping?: boolean;
          brandNonEligibleForAkPlusFreeShipping?: boolean;
          francoAmount?: number;
          francoFeeAmount?: number;
          francoMaxFeeAmount?: number;
        };
      })[];
      taxes: {
        type: GetCartSummaryTypeEnum1;
        /** An object used to detail pricing information for various Resources. */
        amount: Amount;
      }[];
      discounts: {
        type: GetCartSummaryTypeEnum2;
        /** An object used to detail pricing information for various Resources. */
        amount: Amount;
        metadata?: {
          label: string;
        };
      }[];
    };
    meta: {
      isFirstPurchase: boolean;
      isShippable: boolean;
      [key: string]: any;
    };
  };
  included?: (
    | {
        /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
        type?: Type;
        /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
        id?: Id;
        attributes?: {
          code: string;
          message?: string | null;
          status?: string | null;
          /** An object used to detail pricing information for various Resources. */
          amount: Amount;
          /** @format datetime */
          expiresAt: string;
          /** An object used to detail pricing information for various Resources. */
          minimumOrder: Amount;
          /** An object used to detail pricing information for various Resources. */
          minimumCheckout?: Amount;
        };
      }
    | {
        /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
        type: Type;
        /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
        id: Id;
        attributes: {
          name: string;
          organisationName: string;
          city: string;
          postalCode: string;
          street: string;
          country: any;
        };
      }
  )[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

/** Availability status of Purchasable Item (whenever the item can be bought or not) */
export enum GetAllCartsAvailabilityEnum {
  Available = 'available',
  AvailableForPreorder = 'available_for_preorder',
  VariantDisabled = 'variant_disabled',
  ProductDisabled = 'product_disabled',
  BrandDisabled = 'brand_disabled',
  CountryBlacklisted = 'country_blacklisted',
  BrandInHoliday = 'brand_in_holiday',
  OutOfStock = 'out_of_stock',
}

/** Type of the discount */
export enum GetAllCartsTypeEnum {
  ProductDiscount = 'product_discount',
  BrandDiscount = 'brand_discount',
  EventSpecialDiscount = 'event_special_discount',
}

/** General availability (stock) status of the item */
export enum GetAllCartsStatusEnum {
  InStock = 'in_stock',
  LowInStock = 'low_in_stock',
  OutOfStock = 'out_of_stock',
}

/** Type of the fee */
export enum GetAllCartsTypeEnum1 {
  LongDistanceFees = 'long_distance_fees',
}

/** Type of the fee */
export enum GetAllCartsTypeEnum2 {
  HeavyBulkFees = 'heavy_bulk_fees',
}

/** Type of the fee */
export enum GetAllCartsTypeEnum3 {
  RetailerShippingFees = 'retailer_shipping_fees',
}

export interface GetAllCartsData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: object;
    relationships?: {
      list?: {
        /** References to other resource objects in a to-one (`relationship`). Relationships can be specified by including a member in a resource's links object. */
        data?: RelationshipToOne;
      };
    };
  }[];
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  included?: (
    | {
        /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
        type: Type;
        /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
        id: Id;
        attributes: {
          /**
           * ID of the linked Purchasable Item (product variant)
           * @format uuid
           */
          purchasableItemID?: string;
          /** @format uuid */
          productID?: string;
          /** Quantity of units of the item (ex: 2 packs of 6 bottles of wine: quantity = 2) */
          quantity?: number;
          /** Total quantity of individual units of the item (ex: 2 packs of 6 bottles of wine: quantity = 12) */
          multipliedQuantity?: number;
          /** Amount (excl. taxes) of the item, respecting the chosen quantity, before any discount */
          amountBeforeDiscount?: Amount;
          /** Amount (excl. taxes) of the item, respecting the chosen quantity, discount being eventually applied */
          amountAfterDiscount?: Amount;
        };
        relationships?: {
          purchasableItem?: {
            /** References to other resource objects in a to-one (`relationship`). Relationships can be specified by including a member in a resource's links object. */
            data?: RelationshipToOne;
          };
        };
      }
    | {
        /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
        type: Type;
        /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
        id: Id;
        attributes: {
          /** Name of the item */
          name?: string;
          /** Path of the item */
          path?: string;
          /** Options of the item */
          options?: {
            /** Type of the option assigned to this item (ex: size) */
            type?: string;
            /** Name of the option assigned to this item, translated in user's language (ex: size, taille, ...) */
            name?: string;
            /** Value of the option assigned to this item (ex: 42 FR) */
            value?: string;
          }[];
          /** Path to the main image of the item */
          imagePath?: string | null;
          /** Availability status of Purchasable Item (whenever the item can be bought or not) */
          availability?: GetAllCartsAvailabilityEnum;
          /** Number of individual units contained in a single item unit (ex: 6 bottles of wine in a pack) */
          unitMultiplier?: number;
          /**
           * Shipping date(in future) of a preorder product e.g. 2024-12-01
           * @format date
           */
          availableAt?: string | null;
          /**
           * PurchasableItemPricing
           * Pricing details of the Purchasable Item
           */
          pricing?: {
            /** Optional discount applied on the Purchasable Item */
            discount?: null | {
              /** Type of the discount */
              type?: GetAllCartsTypeEnum;
              /**
               * Value (percentage) of the discount
               * @format float
               */
              percentage?: number;
            };
            /** Unit price (excl. taxes) of the Purchasable Item, before any discount */
            unitPriceBeforeDiscount?: Amount;
            /** Unit price (excl. taxes) of the Purchasable Item, after (eventual) discount */
            unitPriceAfterDiscount?: Amount;
            /** If product discount is higher than brand global discount */
            isUpperThanGlobalBrandDiscount?: boolean;
          };
          /**
           * PurchasableItemStock
           * Stock details of the Purchasable Item
           */
          stock?: {
            /** General availability (stock) status of the item */
            status: GetAllCartsStatusEnum;
            /** Flag indicating if the item is always in stock */
            isAlwaysInStock: boolean;
            /** Available quantity of the item in the stock (non-present if the product is always in stock) */
            availableQuantity?: number;
          };
          /** Optional extra fees applied on the Purchasable Item */
          applicableFees?: (
            | {
                /** Type of the fee */
                type?: GetAllCartsTypeEnum1;
                /** Amount of the fee */
                amount?: Amount;
                /** Country where the fee is applied */
                shippingCountryIsoCode?: CountryCode;
              }
            | {
                /** Type of the fee */
                type?: GetAllCartsTypeEnum2;
                /** Amount of the fee */
                amount?: Amount;
              }
            | {
                /** Type of the fee */
                type?: GetAllCartsTypeEnum3;
                /** Amount of the fee */
                amount?: Amount;
                /** Amount left to reach the free shipping */
                amountLeftForFreeShipping?: Amount;
                metadata?: {
                  brandNonEligibleAkPlusFreeShipping?: boolean;
                  isDiscovery?: boolean;
                  isEligibleForDiscoveryFreeShipping?: boolean;
                  /** An object used to detail pricing information for various Resources. */
                  francoAmount?: Amount;
                  /** An object used to detail pricing information for various Resources. */
                  francoFeeAmount?: Amount;
                  /** An object used to detail pricing information for various Resources. */
                  francoMaxFeeAmount?: Amount;
                };
              }
          )[];
        };
      }
    | {
        /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
        type: Type;
        /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
        id: Id;
        attributes: {
          /** Total amount (excl. taxes) of the aggregate (sum of the amounts of all the items contained in it, respecting the chosen quantity), before any discount */
          totalAmountBeforeDiscount?: Amount;
          /** Total amount (excl. taxes) of the aggregate (sum of the amounts of all the items contained in it, respecting the chosen quantity), after discount applied */
          totalAmountAfterDiscount?: Amount;
          /** Whether the aggregate is part of the selection or not */
          selected?: boolean;
          /** Whether the aggregate contains items with discounts */
          hasDiscounts?: boolean;
          /** Whether the aggregate contains items with differents discounts */
          hasMultiDiscounts?: boolean;
          /**
           * Resulting global discount percentage applied on the total amount of the aggregate
           * @format float
           */
          globalDiscountPercentage?: number;
          /** Repeat offers information */
          repeatOffers?: {
            /** Total amount to spend in order to unlock AKS+ repeat offer */
            amountLeftToReachThreshold?: Amount;
            /**
             * Discount the brand will give to retailer for the AKS+ repeat offer
             * @format float
             */
            percentage?: number;
            activeDiscount?: {
              /**
               * Percentage of the current active discount
               * @format float
               */
              percentage?: number;
              /** EndDate of the current active discount */
              endDate?: string;
            };
          };
          /** Total number of items contained in the aggregate */
          itemCount?: number;
          /** Whether the global total amount of the aggregate has reached the minimum order value (for the brand) */
          hasReachedBrandMinimumOrderValue?: boolean;
          /** Aggregate of the optional extra fees applied on some of the Purchasable Items contained in the list */
          applicableFees?: any[];
          /** Whether the brand can ship to the retailer country */
          canShipToRetailerCountry?: boolean;
          /** Whether it's the first order between the retailer and the brand */
          isDiscovery?: boolean;
        };
        relationships?: {
          brand?: {
            /** References to other resource objects in a to-one (`relationship`). Relationships can be specified by including a member in a resource's links object. */
            data?: RelationshipToOne;
          };
          items?: {
            /** An array of objects each containing `type` and `id` members for to-many relationships. */
            data?: RelationshipToMany;
          };
        };
      }
    | {
        /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
        type: Type;
        /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
        id: Id;
        attributes: {
          /** Name of the brand */
          name?: string;
          /** Path to the logo image of the brand */
          logoImagePath?: string;
          /** Minimum amount (excl. taxes) threshold to reach in order to be able to place an order for this brand */
          minimumOrderValue?: Amount;
          /**
           * PurchasableAggregateBrandShippingLeadTime
           * Brand's shipping lead time information
           */
          shippingLeadTime?: {
            /** Minimum number of days before the order is shipped */
            min?: number;
            /** Maximum number of days before the order is shipped */
            max?: number;
          };
          /**
           * CountryObject
           * Country object
           */
          country?: {
            /** [Short and unique alphanumeric codes to represent the relevant country](https://en.wikipedia.org/wiki/ISO_3166-2) */
            isoCode: CountryCode;
            name: string;
          };
          /** Whether the brand is enabled (i.e. has been accepted) or not */
          isEnabled?: boolean;
          /**
           * PurchasableAggregateBrandHolidayMode
           * Brand's holiday mode information
           */
          holidayMode?: {
            /** Whether the brand is currently on holiday mode */
            isEnabled?: boolean;
            /**
             * Start date of the holiday mode
             * @format date
             */
            startDate?: string | null;
            /**
             * End date of the holiday mode
             * @format date
             */
            endDate?: string | null;
          };
        };
      }
  )[];
}

export interface GetOneCartData {
  data: object;
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  included?: any[];
}

export interface GetAllPurchasingListsParams {
  /** Filter by purchasing list type */
  'filter[type]'?: FilterTypeEnum;
}

/** Filter by purchasing list type */
export enum FilterTypeEnum {
  Cart = 'cart',
  SavedForLater = 'saved_for_later',
}

export interface GetAllPurchasingListsData {
  data: any[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  included?: any[];
}

/** Filter by purchasing list type */
export enum GetAllPurchasingListsParams1FilterTypeEnum {
  Cart = 'cart',
  SavedForLater = 'saved_for_later',
}

/** Type of the Purchasing List */
export enum GetPurchasingListTypeEnum {
  SavedForLater = 'saved_for_later',
  Cart = 'cart',
}

export interface GetPurchasingListData {
  /**
   * PurchasingListResource
   * Purchasing List resource
   */
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    id: string;
    attributes: {
      /** Type of the Purchasing List */
      type?: GetPurchasingListTypeEnum;
      /** Custom name of Purchasing List */
      name?: string | null;
      /** Total amount (excl. taxes) of the Purchasing List (sum of the amounts of all the items contained in the list, respecting the chosen quantity). Inactive (or non-buyable) items are excluded, same for product aggregates (brands) which doesn't reach the minimum order value threshold. */
      totalAmount?: Amount;
      /** Total amount (excl. taxes) of the selected set if items in the Purchasing List (sum of the amounts of all the selected items contained in the list, respecting the chosen quantity). Inactive (or non-buyable) items are excluded, same for product aggregates (brands) which doesn't reach the minimum order value threshold. */
      selectionTotalAmount?: Amount;
      /** Total number of items units contained in the Purchasing List (respecting the chosen quantity) */
      totalItemCount?: number;
    };
    relationships?: {
      items?: {
        /** An array of objects each containing `type` and `id` members for to-many relationships. */
        data?: RelationshipToMany;
      };
      itemAggregates?: {
        /** An array of objects each containing `type` and `id` members for to-many relationships. */
        data?: RelationshipToMany;
      };
    };
    links?: {
      self: string;
    };
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  included?: any[];
}

export interface PurchasingListActionCrossListOperationData {
  /**
   * PurchasingListCrossListOperationResultResource
   * Cross list operation resource
   */
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes?: object;
    relationships?: {
      sourceList?: {
        /**
         * Linkage
         * Resource identification present in Resource Objects and Resource Identifier Objects.
         */
        data: {
          id: string;
          /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
          type: Type;
          /** Non-standard meta-information that can not be represented as an attribute or relationship. */
          meta?: Meta;
        };
      };
      destinationList?: {
        data: any;
      };
    };
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  included?: any[];
}

export enum CreateCampaignTypeEnum {
  Campaign = 'campaign',
}

/** The type of campaign. */
export enum CreateCampaignTypeEnum1 {
  Regular = 'regular',
  Referral = 'referral',
}

/** The current status of the campaign. */
export enum CreateCampaignStatusEnum {
  Draft = 'draft',
  Processing = 'processing',
  Completed = 'completed',
}

/** The type of message. */
export enum CreateCampaignTypeEnum2 {
  Email = 'email',
}

export interface CreateCampaignParams {
  /**
   * Start the campaign after saving it.
   * @default false
   */
  start_campaign?: boolean;
}

export interface GetCampaignsData {
  /** A collection of campaign resources. */
  data: any[];
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

export interface GetCampaignData {
  data: any;
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

export enum GetCampaignDeliveriesTypeEnum {
  CampaignDelivery = 'campaign-delivery',
}

/** The current status of the delivery. */
export enum GetCampaignDeliveriesStatusEnum {
  Queued = 'queued',
  Undeliverable = 'undeliverable',
  Attempted = 'attempted',
  Failed = 'failed',
  Sent = 'sent',
  Delivered = 'delivered',
  Bounced = 'bounced',
  Suppressed = 'suppressed',
}

export interface GetCampaignDeliveriesData {
  /** A collection of campaign delivery resources. */
  data: {
    /**
     * A globally-unique identifier for the delivery.
     * @format uuid
     * @example "1ef077c4-f8a1-6bc8-9ac6-4e29ff3d1748"
     */
    id?: string;
    type: GetCampaignDeliveriesTypeEnum;
    attributes: {
      /** The current status of the delivery. */
      status?: GetCampaignDeliveriesStatusEnum;
      /**
       * The recipient's email address.
       * @format email
       */
      email?: string;
      /**
       * The recipient's language represented as a 2-letter code conforming to [IETF language tag](https://en.wikipedia.org/wiki/IETF_language_tag).
       * @example "en"
       */
      language?: string;
    };
  }[];
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

export interface GetCampaignTemplatesData {
  /** A collection of campaign resources. */
  data: any[];
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

export interface GetCampaignTemplateData {
  /** A campaign template resource */
  data: {
    /** The unique identifier for the template */
    id?: string;
    /** The localized name of the template */
    name?: string;
    /** A localized description of the template */
    description?: string;
    /** A collection of template contents */
    contents?: {
      /** The iso-2 language code of the content */
      language: string;
      /** The type of the content */
      type: string;
    }[];
    /** A collection of allowed segment types */
    allowedSegments?: string[];
    /** A collection of segments for which this template is the default one */
    defaultForSegments?: string[];
  };
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

export interface ListRutterIntegrationsData {
  data: any[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export interface CreateRutterIntegrationData {
  data: {
    /**
     * @default "rutter-integrations"
     * @example "rutter-integrations"
     */
    type: string;
    /** In the current implementation this ID matches the ID of the target brand. In the future most probably will be replaced with Rutter ingeration entity identifier. */
    id: string;
    attributes: {
      /**
       * Access token received from Rutter API
       * @example "937c5af4-d0a4-46b5-820d-300e75d982a5"
       */
      accessToken: string;
      /**
       * Target platform name
       * @example "SHOPIFY"
       */
      platformName: string;
      orderTags?: any;
      configuration?: any;
    };
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export type DeleteRutterIntegrationData = any;

/** Product integration workflow to use for catalog synchronization from Shopify to Ankorstore. */
export enum UpdateRutterIntegrationCatalogSynchronizationWorkflowEnum {
  Import = 'import',
  Update = 'update',
}

/** Strategy to use for price synchronization from Shopify to Ankorstore. */
export enum UpdateRutterIntegrationShopifyCatalogPriceStrategyEnum {
  Retail = 'retail',
  Wholesale = 'wholesale',
}

export enum PlatformVerificationsCodeEnum {
  INVALID_URL_FORMAT = 'INVALID_URL_FORMAT',
  SITE_DOES_NOT_EXIST = 'SITE_DOES_NOT_EXIST',
  PLATFORM_MISMATCH = 'PLATFORM_MISMATCH',
  TARGET_RESPONDED_WITH_ERROR = 'TARGET_RESPONDED_WITH_ERROR',
  UNABLE_TO_CONNECT = 'UNABLE_TO_CONNECT',
  VERIFICATION_ERROR = 'VERIFICATION_ERROR',
}

export interface PlatformVerificationsData {
  data?: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      platformUrl: string;
      authenticationUrl: string | null;
    };
  };
}

export type GetApiMobileV1SearchBrandsIdProductsData = any;

export interface GetApiMobileV1SearchProductsParams {
  /** The search query. */
  query: string;
  /**
   * The total number of pages in the search response.
   * @min 1
   * @max 100
   * @default 20
   */
  hitsPerPage?: number;
  /**
   * The current page of results (zero-indexed).
   * @min 0
   * @max 9223372036854776000
   */
  page?: number;
}

/** @example {"paging":{"page":0,"pages":500,"items_per_page":2,"total":1405},"data":[{"id":112233,"name":"PlateTastics","images":["/products/images/112233-asdfa2ac92fa6.jpg","/products/images/112233-4123asdbaxcvxeb.jpg"],"retail_price":{"currency":"EUR","amount":160},"wholesale_price":{"currency":"EUR","amount":78},"location":"France","brand":{"id":123,"name":"We Sell Everything","images":["/brands/rounded/123-47d64faujbbla9.jpg"]}},{"id":332211,"name":"Super plates","images":["/products/images/332211-3fsfsdfasfc9ddb5.jpg","/products/images/332211-42241basdfsadfa.jpg"],"retail_price":{"currency":"EUR","amount":300},"wholesale_price":{"currency":"EUR","amount":142},"location":"United Kingdom","brand":{"id":321,"name":"Plate Kings","images":["/brands/rounded/321-4balsdfjbla12.jpg"]}}]} */
export interface GetApiMobileV1SearchProductsData {
  paging: {
    page: number;
    pages: number;
    items_per_page: number;
    total: number;
  };
  data: {
    id: number;
    name: string;
    images: string[];
    /** An object used to detail pricing information for various Resources. */
    retail_price: Amount;
    /** An object used to detail pricing information for various Resources. */
    wholesale_price: Amount;
    /** @example "France" */
    location: string;
    brand: {
      id: number;
      name: string;
      images: string[];
    };
    /**
     * The base URL for product images
     * @example "https://img.ankorstore.com"
     */
    image_base_url: string;
  }[];
}

export type ProductVariantsDeclareFulfillmentItemData = any;

export enum ProductsListFulfillableFulfillableTypeEnum {
  Item = 'item',
  Bundle = 'bundle',
}

export enum ProductsListFulfillableClassificationCodeEnum {
  AerosolsInflammables = 'Aérosols inflammables',
  BoissonAlcoolisees = 'Boisson alcoolisées',
  CerealesGrainsProduitsAlimentaires = 'Céréales, grains, produits alimentaires',
  ContenantPilesOuBatteriesLithium = 'Contenant Piles ou batteries lithium',
  Corrosifs = 'Corrosifs',
  GazInflammables = 'Gaz inflammables',
  LiquidesInflammables = 'Liquides inflammables',
  MateriauxComburants = 'Matériaux comburants',
  Plastique = 'Plastique',
  ProduitsBois = 'Produits bois',
  ProduitsExplosifs = 'Produits explosifs',
  ProduitsGazeuxSousPression = 'Produits gazeux sous pression',
  ProduitsToxiquesToxicite = 'Produits toxiques / Toxicité',
  SolidesInflammables = 'Solides inflammables',
  AutresCombustibles = 'Autres combustibles',
  AutresDangereux = 'Autres dangereux',
}

export enum ProductsListFulfillableStatusEnum {
  Created = 'created',
  Pending = 'pending',
  Synced = 'synced',
}

export enum ProductsListFulfillableStockStatusEnum {
  InStock = 'in_stock',
  SoonOutOfStock = 'soon_out_of_stock',
  OutOfStock = 'out_of_stock',
  NeverReplenished = 'never_replenished',
}

export interface ProductsListFulfillableParams {
  'page[limit]'?: number;
  'page[offset]'?: number;
  /** Request the subset with a given fulfillmentItem status */
  'filter[fulfillmentItemStatus]'?: FilterFulfillmentItemStatusEnum;
  /** Exclude product variant which are mapped to a bundle */
  'filter[bundles]'?: boolean;
  /** Search for matches within the SKU, referenceId, barcode and the product name, including translations */
  searchTerm?: string;
  /** Specify what attribute(s) to sort by */
  sort?: SortEnum;
}

/** Request the subset with a given fulfillmentItem status */
export enum FilterFulfillmentItemStatusEnum {
  Synced = 'synced',
  Pending = 'pending',
}

/** Specify what attribute(s) to sort by */
export enum SortEnum {
  ExpectZeroStockAt = 'expectZeroStockAt',
  ValueExpectZeroStockAt = '-expectZeroStockAt',
  StockStatus = 'stockStatus',
  ValueStockStatus = '-stockStatus',
  AvailableQuantityUnits = 'availableQuantityUnits',
  ValueAvailableQuantityUnits = '-availableQuantityUnits',
  ProductArchived = 'productArchived',
  ValueProductArchived = '-productArchived',
}

export interface ProductsListFulfillableData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes:
      | ({
          /** @format uuid */
          fulfillableId?: string;
          fulfillableType?: ProductsListFulfillableFulfillableTypeEnum;
          /** Available batch quantity in the warehouse */
          availableQuantity?: number;
          /** Images from the catalog */
          productImages?: string[];
          /** Name of the product in the catalog */
          productName?: string;
          /** Stock Keeping Unit */
          sku?: string;
        } & {
          /**
           * Unique barcode (EAN13 or ITF14)
           * @pattern ^\d{13,14}$
           */
          barcode?: string;
          /** Number of units in a batch. This indicates the lowest denominator of items that can be fulfilled */
          unitsPerBatch?: number;
          /**
           * Cost price of a single unit, used for insurance purposes
           * @format float
           */
          unitCost?: number;
          /** Name of the product shared with warehouse systems */
          warehouseProductName?: string;
          /** Name of the category shared with warehouse systems */
          warehouseCategoryName?: string;
          classificationCode?: ProductsListFulfillableClassificationCodeEnum;
          /**
           * Whether the item in the warehouse is tracked for expiry
           * @default false
           */
          expiryTracked?: boolean;
          /** Shelf life of the item in days */
          shelfLife?: number | null;
          /**
           * Whether the item in the warehouse is tracked by lot
           * @default false
           */
          lotTracked?: boolean;
          /**
           * Whether the item is considered fragile
           * @default false
           */
          isFragile?: boolean | null;
          /**
           * Whether the item is considered organic
           * @default false
           */
          isOrganic?: boolean | null;
          /**
           * Whether the item needs to be repacked before shipping
           * @default false
           */
          needRepack?: boolean | null;
          /**
           * Whether the item needs to be relabeled before shipping
           * @default false
           */
          needRelabel?: boolean | null;
          /** Code for alcohol classification, as used in the warehouse */
          alcoholCode?: number | null;
          /**
           * Percentage of alcohol in the item
           * @format float
           */
          alcoholPercentage?: number | null;
          /**
           * Volume of liquid per pack in liter
           * @format float
           */
          volumeOfLiquidPerPack?: number | null;
          /** Installation Classified for the Protection of the Environment, as used in the warehouse */
          icpeCode?: number;
          status?: ProductsListFulfillableStatusEnum;
          /** How many days stock is expected to last, based on historical data.  Null if insufficient data available to create an estimate */
          expectedStockForDays?: number | null;
          stockStatus?: ProductsListFulfillableStockStatusEnum;
          /** Reference for the fulfillment item, used in the warehouse */
          referenceId?: string;
        })
      | {
          items?: {
            /** @format uuid */
            fulfillmentItemId?: string;
            /** number of batches of this item included in the bundle */
            quantity?: number;
          }[];
        };
  }[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

/** Request the subset with a given fulfillmentItem status */
export enum ProductsListFulfillableParams1FilterFulfillmentItemStatusEnum {
  Synced = 'synced',
  Pending = 'pending',
}

/** Specify what attribute(s) to sort by */
export enum ProductsListFulfillableParams1SortEnum {
  ExpectZeroStockAt = 'expectZeroStockAt',
  ValueExpectZeroStockAt = '-expectZeroStockAt',
  StockStatus = 'stockStatus',
  ValueStockStatus = '-stockStatus',
  AvailableQuantityUnits = 'availableQuantityUnits',
  ValueAvailableQuantityUnits = '-availableQuantityUnits',
  ProductArchived = 'productArchived',
  ValueProductArchived = '-productArchived',
}

export interface FulfillmentListBundlesParams {
  'page[limit]'?: number;
  'page[offset]'?: number;
}

export interface FulfillmentListBundlesData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type?: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      /** @format uuid */
      productVariantId?: string;
      /** The number of complete bundles available for fulfillment */
      availableQuantity?: number;
      items?: {
        /** @format uuid */
        fulfillmentItemId?: string;
        /** The number of items in the bundle */
        quantity?: number;
      }[];
    };
  }[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export interface FulfillmentListOrdersParams {
  'page[limit]'?: number;
  'page[offset]'?: number;
  /**
   * [Deprecated] The direct fulfillment feature has been replaced by Non-Ankorstore Fulfilled Orders
   * @deprecated
   */
  'filter[isDirect]'?: boolean;
  /** Request a specific status only */
  'filter[status]'?: FilterStatusEnum;
  /** List of fulfillment orders uuids */
  'filter[uuid]'?: string[];
  /**
   * Specify what attribute(s) to sort by
   * @default "-createdAt"
   */
  sort?: SortEnum1;
}

/** Request a specific status only */
export enum FilterStatusEnum {
  Internal = 'internal',
  Requested = 'requested',
  Created = 'created',
  Scheduled = 'scheduled',
  Released = 'released',
  Shipped = 'shipped',
  Cancelled = 'cancelled',
}

/**
 * Specify what attribute(s) to sort by
 * @default "-createdAt"
 */
export enum SortEnum1 {
  CreatedAt = 'createdAt',
  ValueCreatedAt = '-createdAt',
}

export interface FulfillmentListOrdersData {
  data: any[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  included?: (
    | {
        /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
        type?: Type;
        /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
        id?: Id;
        /** Shipment tracking info. Will be null until this information is available */
        attributes?: {
          trackingNumber?: string;
          trackingLink?: string | null;
          /** Shipping provider identifier */
          provider?: string;
          /** Shipping provider display name */
          providerName?: string;
          /** Parcel or pallet width (in MM) */
          width?: number;
          /** Parcel or pallet length (in MM) */
          length?: number;
          /** Parcel or pallet height (in MM) */
          height?: number;
          /** Parcel or pallet weight (in GR) */
          weight?: number;
          /** @format date-time */
          createdAt?: string;
        };
      }
    | {
        /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
        type?: Type;
        /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
        id?: Id;
        /** Fulfillment order status update */
        attributes?: {
          status?: any;
          /** @format date-time */
          receivedAt?: string;
        };
      }
  )[];
}

/** Request a specific status only */
export enum FulfillmentListOrdersParams1FilterStatusEnum {
  Internal = 'internal',
  Requested = 'requested',
  Created = 'created',
  Scheduled = 'scheduled',
  Released = 'released',
  Shipped = 'shipped',
  Cancelled = 'cancelled',
}

/**
 * Specify what attribute(s) to sort by
 * @default "-createdAt"
 */
export enum FulfillmentListOrdersParams1SortEnum {
  CreatedAt = 'createdAt',
  ValueCreatedAt = '-createdAt',
}

export enum FulfillmentCreateOrderDetailEnum {
  NotAFulfillmentBrand = 'not_a_fulfillment_brand',
  UnavailableItems = 'unavailable_items',
  AlreadyBeingFulfilled = 'already_being_fulfilled',
  NotAvailableForInternationalOrders = 'not_available_for_international_orders',
}

/**
 * The type of recipient for a fulfillment order
 * @default "business"
 */
export enum FulfillmentCreateOrderRecipientTypeEnum {
  Consumer = 'consumer',
  Business = 'business',
}

export interface FulfillmentCreateOrderParams {
  /** Validate the request without creating the order */
  validateOrder?: boolean;
}

export interface FulfillmentCreateOrderData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      /**
       * Unique human readable reference for the fulfillment order, used for communication with the warehouse
       * @pattern ^O_[0-9]{1,18}|[A-HJ-NP-TV-Z2-9]+$
       */
      reference?: string;
      /**
       * [Deprecated] Internal Ankorstore order ID. Please use orderUuid instead
       * @deprecated
       */
      orderId?: number | null;
      /** @format uuid */
      masterOrderUuid: string;
      /** @format uuid */
      fulfillmentBrandId?: string;
      /**
       * [Deprecated] This concept no longer exists. Please refer to the related master order if you need to distinguish between internal and external orders
       * @deprecated
       */
      isDirect?: boolean;
      /** Is shipping fee charged for order, default to true */
      shippingCharged?: boolean;
      status?: any;
      /** @format date-time */
      createdAt?: string;
      /** Shipping address for the recipient. Required for order creation */
      shippingAddress: any;
      /** Optional billing address for the recipient */
      billingAddress?: null;
      items: {
        /** @format uuid */
        fulfillmentItemId: string;
        /** quantity in batches */
        quantity: number;
      }[];
      recipientType?: any;
    };
    links?: {
      order?: string;
    };
    relationships?: {
      statusUpdates?: {
        /** An array of objects each containing `type` and `id` members for to-many relationships. */
        data?: RelationshipToMany;
      };
      shipments?: {
        /** An array of objects each containing `type` and `id` members for to-many relationships. */
        data?: RelationshipToMany;
      };
    };
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  included?: any[];
}

export interface FulfillmentListReplenishmentsParams {
  limit?: number;
  offset?: number;
  /**
   * Specify what attribute(s) to sort by
   * @default "-submittedAt"
   */
  sort?: string;
}

export interface FulfillmentListReplenishmentsData {
  data: any[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export enum FulfillmentCreateReplenishmentStatusEnum {
  Created = 'created',
  Sent = 'sent',
  Received = 'received',
  Confirmed = 'confirmed',
  Delivered = 'delivered',
}

export enum FulfillmentCreateReplenishmentShipmentTypeEnum {
  Value = '',
  LTL = 'LTL',
  FTL = 'FTL',
  PARCEL = 'PARCEL',
}

export interface FulfillmentCreateReplenishmentData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      reference?: string;
      status?: FulfillmentCreateReplenishmentStatusEnum;
      /** @format date-time */
      receivedAt?: string | null;
      /** @format date-time */
      deliveredAt?: string | null;
      /** @format date-time */
      confirmedAt?: string | null;
      /** @format date-time */
      submittedAt?: string | null;
      /** @format date-time */
      createdAt?: string;
      receipts?: {
        receiptId?: string;
        /** @format date-time */
        receivedAt?: string;
        items?: any[];
      }[];
    };
    relationships?: {
      fulfillmentBrand?: {
        /** References to other resource objects in a to-one (`relationship`). Relationships can be specified by including a member in a resource's links object. */
        data?: RelationshipToOne;
      };
      warehouse?: {
        /** References to other resource objects in a to-one (`relationship`). Relationships can be specified by including a member in a resource's links object. */
        data?: RelationshipToOne;
      };
    };
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  included?: any[];
}

export enum FulfillmentPatchReplenishmentShipmentTypeEnum {
  LTL = 'LTL',
  FTL = 'FTL',
  PARCEL = 'PARCEL',
}

export enum FulfillmentPatchReplenishmentStatusEnum {
  Sent = 'sent',
}

export enum FulfillmentPatchReplenishmentStatusEnum1 {
  Available = 'available',
  Blocked = 'blocked',
  Incoming = 'incoming',
  QualityControl = 'qualityControl',
  Reserved = 'reserved',
  Damaged = 'damaged',
}

export interface FulfillmentListWarehousesData {
  data: any[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export interface FulfillmentGetWarehouseData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    /** FulfillmentItem */
    attributes: {
      code: string;
      name: string;
      addresseLine1?: string;
      addresseLine2?: string;
      postalCode: string;
      city: string;
      /** @pattern ^[A-Z]{2}$ */
      countryCode: string;
      email: string;
      phone: string;
      /** @format uuid */
      astralLocationId: string | null;
    };
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export interface FulfillmentListItemsParams {
  'page[limit]'?: number;
  'page[offset]'?: number;
  /**
   * Request a subset of IDs only
   * @minItems 1
   */
  'filter[id]'?: string[];
  /** Request a subset of status only */
  'filter[status]'?: FilterStatusEnum1;
  /** Specify what attribute(s) to sort by */
  sort?: SortEnum2;
}

/** Request a subset of status only */
export enum FilterStatusEnum1 {
  Synced = 'synced',
  Pending = 'pending',
}

/** Specify what attribute(s) to sort by */
export enum SortEnum2 {
  ExpectZeroStockAt = 'expectZeroStockAt',
  ValueExpectZeroStockAt = '-expectZeroStockAt',
  StockStatus = 'stockStatus',
  ValueStockStatus = '-stockStatus',
  AvailableQuantityUnits = 'availableQuantityUnits',
  ValueAvailableQuantityUnits = '-availableQuantityUnits',
}

export interface FulfillmentListItemsData {
  data: any[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  included?: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type?: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      reasonText?: string | null;
      /** @format date-time */
      createdAt?: string;
      /** Set of information related to the quantity adjustment action */
      metadata?: (
        | {
            order_id?: number | null;
            order_reference_id?: string;
            /** @format uuid */
            master_order_uuid?: string;
          }
        | {
            webhookcall_id?: number;
          }
      )[];
    };
  }[];
}

/** Request a subset of status only */
export enum FulfillmentListItemsParams1FilterStatusEnum {
  Synced = 'synced',
  Pending = 'pending',
}

/** Specify what attribute(s) to sort by */
export enum FulfillmentListItemsParams1SortEnum {
  ExpectZeroStockAt = 'expectZeroStockAt',
  ValueExpectZeroStockAt = '-expectZeroStockAt',
  StockStatus = 'stockStatus',
  ValueStockStatus = '-stockStatus',
  AvailableQuantityUnits = 'availableQuantityUnits',
  ValueAvailableQuantityUnits = '-availableQuantityUnits',
}

export enum FulfillmentCreateItemStatusEnum {
  Created = 'created',
  Pending = 'pending',
  Synced = 'synced',
}

export enum FulfillmentCreateItemStockStatusEnum {
  InStock = 'in_stock',
  SoonOutOfStock = 'soon_out_of_stock',
  OutOfStock = 'out_of_stock',
  NeverReplenished = 'never_replenished',
}

export interface FulfillmentCreateItemData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      status?: FulfillmentCreateItemStatusEnum;
      /** How many days stock is expected to last, based on historical data.  Null if insufficient data available to create an estimate */
      expectedStockForDays?: number | null;
      stockStatus?: FulfillmentCreateItemStockStatusEnum;
      /** Reference for the fulfillment item, used in the warehouse */
      referenceId?: string;
    };
    relationships?: {
      fulfillmentBrand?: {
        /** References to other resource objects in a to-one (`relationship`). Relationships can be specified by including a member in a resource's links object. */
        data?: RelationshipToOne;
      };
    };
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  included?: any[];
}

export interface FulfillmentListItemLotsParams {
  'page[limit]'?: number;
  'page[offset]'?: number;
  /** Specify what attribute(s) to sort by */
  sort?: SortEnum3;
  /**
   * Fulfillment item ID
   * @format uuid
   */
  id: string;
}

/** Specify what attribute(s) to sort by */
export enum SortEnum3 {
  CreatedAt = 'createdAt',
}

export interface FulfillmentListItemLotsData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      /** @format uuid */
      fulfillmentItemId: string;
      availableQuantity: number;
      /**
       * Optional expiry date of the lot
       * @format date-time
       */
      expiryDate?: string | null;
      /**
       * If the lot can expire, last point in time when this lot can be used to fulfill an order
       * @format date-time
       */
      sellByDate?: string | null;
      lotNumber: string;
    };
    relationships?: {
      fulfillmentItem?: {
        /** References to other resource objects in a to-one (`relationship`). Relationships can be specified by including a member in a resource's links object. */
        data?: RelationshipToOne;
      };
    };
  }[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

/** Specify what attribute(s) to sort by */
export enum FulfillmentListItemLotsParams1SortEnum {
  CreatedAt = 'createdAt',
}

export interface FulfillmentListLotsRelationshipsParams {
  'page[limit]'?: number;
  'page[offset]'?: number;
  /** Specify what attribute(s) to sort by */
  sort?: SortEnum4;
  /**
   * Fulfillment item ID
   * @format uuid
   */
  id: string;
}

/** Specify what attribute(s) to sort by */
export enum SortEnum4 {
  CreatedAt = 'createdAt',
}

export interface FulfillmentListLotsRelationshipsData {
  /** An array of objects each containing `type` and `id` members for to-many relationships. */
  data: RelationshipToMany;
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

/** Specify what attribute(s) to sort by */
export enum FulfillmentListLotsRelationshipsParams1SortEnum {
  CreatedAt = 'createdAt',
}

export interface FulfillmentItemGroupedQuantityAdjustmentsParams {
  'page[limit]'?: number;
  'page[offset]'?: number;
  /** Specify what attribute(s) to sort by */
  sort?: SortEnum5;
  /**
   * Fulfillment item ID
   * @format uuid
   */
  id: string;
}

/** Specify what attribute(s) to sort by */
export enum SortEnum5 {
  CreatedAt = 'createdAt',
  ValueCreatedAt = '-createdAt',
}

export interface FulfillmentItemGroupedQuantityAdjustmentsData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type?: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      /** @format uuid */
      fulfillmentItemId?: string;
      /** @format uuid */
      fulfillmentOrderUuid?: string | null;
      /** @format uuid */
      replenishmentUuid?: string | null;
      /** @format uuid */
      lotUuid?: string | null;
      previousQuantity?: number;
      newQuantity?: number;
      reasonCode?: any;
    };
    relationships?: {
      quantityAdjustments?: {
        /** An array of objects each containing `type` and `id` members for to-many relationships. */
        data?: RelationshipToMany;
      };
    };
  }[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  included?: any[];
}

/** Specify what attribute(s) to sort by */
export enum FulfillmentItemGroupedQuantityAdjustmentsParams1SortEnum {
  CreatedAt = 'createdAt',
  ValueCreatedAt = '-createdAt',
}

export interface FulfillmentListGroupedQuantityAdjustmentsRelationshipsParams {
  'page[limit]'?: number;
  'page[offset]'?: number;
  /** Specify what attribute(s) to sort by */
  sort?: SortEnum6;
  /** Exclude quantity adjustments with a specific reason */
  'filter[adjustmentReason]'?: FilterAdjustmentReasonEnum[];
  /**
   * Fulfillment item ID
   * @format uuid
   */
  id: string;
}

/** Specify what attribute(s) to sort by */
export enum SortEnum6 {
  CreatedAt = 'createdAt',
}

export enum FilterAdjustmentReasonEnum {
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_RESERVED = 'ORDER_RESERVED',
  ORDER_SHIPPED = 'ORDER_SHIPPED',
  REPLENISHMENT = 'REPLENISHMENT',
  SNAPSHOT = 'SNAPSHOT',
  LOT_SNAPSHOT = 'LOT_SNAPSHOT',
  LOT_SNAPSHOT_CORRECTION = 'LOT_SNAPSHOT_CORRECTION',
  MANUAL = 'MANUAL',
  BROKEN = 'BROKEN',
  LOST = 'LOST',
  FOUND = 'FOUND',
  DESTROYED = 'DESTROYED',
  REPLENISHMENT_MISMATCH = 'REPLENISHMENT_MISMATCH',
  DELIVERY_REFUSED = 'DELIVERY_REFUSED',
  RETURNED_TO_BRAND = 'RETURNED_TO_BRAND',
  ADJUSTMENT_REVERSED = 'ADJUSTMENT_REVERSED',
  QUALITY_CONTROL = 'QUALITY_CONTROL',
  EXPIRED = 'EXPIRED',
  BRAND_REQUEST = 'BRAND_REQUEST',
  BLOCKED_BY_PROVIDER = 'BLOCKED_BY_PROVIDER',
  UNBLOCKED = 'UNBLOCKED',
  BATCHED = 'BATCHED',
  UNBATCHED = 'UNBATCHED',
  RETURNED = 'RETURNED',
  BATCH_MODIFIED = 'BATCH_MODIFIED',
  EXPIRY_DATE_MODIFIED = 'EXPIRY_DATE_MODIFIED',
}

export interface FulfillmentListGroupedQuantityAdjustmentsRelationshipsData {
  /** An array of objects each containing `type` and `id` members for to-many relationships. */
  data: RelationshipToMany;
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

/** Specify what attribute(s) to sort by */
export enum FulfillmentListGroupedQuantityAdjustmentsRelationshipsParams1SortEnum {
  CreatedAt = 'createdAt',
}

export enum FulfillmentListGroupedQuantityAdjustmentsRelationshipsParams1FilterAdjustmentReasonEnum {
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_RESERVED = 'ORDER_RESERVED',
  ORDER_SHIPPED = 'ORDER_SHIPPED',
  REPLENISHMENT = 'REPLENISHMENT',
  SNAPSHOT = 'SNAPSHOT',
  LOT_SNAPSHOT = 'LOT_SNAPSHOT',
  LOT_SNAPSHOT_CORRECTION = 'LOT_SNAPSHOT_CORRECTION',
  MANUAL = 'MANUAL',
  BROKEN = 'BROKEN',
  LOST = 'LOST',
  FOUND = 'FOUND',
  DESTROYED = 'DESTROYED',
  REPLENISHMENT_MISMATCH = 'REPLENISHMENT_MISMATCH',
  DELIVERY_REFUSED = 'DELIVERY_REFUSED',
  RETURNED_TO_BRAND = 'RETURNED_TO_BRAND',
  ADJUSTMENT_REVERSED = 'ADJUSTMENT_REVERSED',
  QUALITY_CONTROL = 'QUALITY_CONTROL',
  EXPIRED = 'EXPIRED',
  BRAND_REQUEST = 'BRAND_REQUEST',
  BLOCKED_BY_PROVIDER = 'BLOCKED_BY_PROVIDER',
  UNBLOCKED = 'UNBLOCKED',
  BATCHED = 'BATCHED',
  UNBATCHED = 'UNBATCHED',
  RETURNED = 'RETURNED',
  BATCH_MODIFIED = 'BATCH_MODIFIED',
  EXPIRY_DATE_MODIFIED = 'EXPIRY_DATE_MODIFIED',
}

export enum FulfillmentCreateBrandBillingCategoryEnum {
  S = 'S',
  M = 'M',
  L = 'L',
}

export enum FulfillmentCreateBrandParcelCarrierEnum {
  DPD_CLA = 'DPD_CLA',
  DPD_PRE = 'DPD_PRE',
  UPSB2B = 'UPS_B2B',
  UPSB2C = 'UPS_B2C',
}

export enum FulfillmentCreateBrandPalletCarrierEnum {
  KNC01C = 'KNC01_C',
  KNC02C = 'KNC02_C',
  DE_MES = 'DE_MES',
  DE_MEI = 'DE_MEI',
}

export interface FulfillmentCreateBrandData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      /** @format uuid */
      warehouseId: string;
      billingCategory: any;
      reference: string;
      parcelCarrier?: any;
      palletCarrier?: any;
      maxParcelShipmentWeight?: number | null;
    };
    relationships?: {
      warehouse?: {
        /** References to other resource objects in a to-one (`relationship`). Relationships can be specified by including a member in a resource's links object. */
        data?: RelationshipToOne;
      };
    };
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  included?: any[];
}

export enum IntegrationListExternalIntegrationsPlatformEnum {
  Shopify = 'shopify',
  Woocommerce = 'woocommerce',
  Prestashop = 'prestashop',
  Odoo = 'odoo',
  Sellsy = 'sellsy',
}

export enum IntegrationListExternalIntegrationsStatusEnum {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export interface IntegrationListExternalIntegrationsData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type?: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      platform: IntegrationListExternalIntegrationsPlatformEnum;
      status: IntegrationListExternalIntegrationsStatusEnum;
    };
  }[];
}

export interface ListOrderPayCustomerParams {
  'filter[storeName]'?: string;
  'filter[email]'?: string;
  /**
   * Page number, starting at 1
   * @default 1
   */
  'page[number]'?: number;
  /**
   * Maximum number of elements per page
   * @default 20
   */
  'page[size]'?: number;
  /** Comma-separated list of attributes to sort by (storeName by default) */
  sort?: SortEnum7;
}

/** Comma-separated list of attributes to sort by (storeName by default) */
export enum SortEnum7 {
  Id = 'id',
  StoreName = 'storeName',
}

export interface ListOrderPayCustomerData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: any[];
  meta: {
    page: {
      currentPage?: number;
      from?: number;
      lastPage?: number;
      perPage?: number;
      to?: number;
      total?: number;
    };
    [key: string]: any;
  };
}

/** Comma-separated list of attributes to sort by (storeName by default) */
export enum ListOrderPayCustomerParams1SortEnum {
  Id = 'id',
  StoreName = 'storeName',
}

export interface CreateOrderPayCustomerData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  /** CustomerResource */
  data: {
    type: 'order-pay-customer';
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      storeName: string;
      email: string;
      firstName: string | null;
      lastName: string | null;
      paymentMethods?: {
        type: string;
        email?: string | null;
        name?: string | null;
        /** @example "1234" */
        last4?: string | null;
      }[];
      company: {
        phoneNumber: string;
        phoneCountry: {
          isoCode: string;
          name: string;
          callingCode: number;
        };
        taxNumber: string | null;
        vatNumber: string | null;
        vatExemption: boolean;
      };
      billingAddress?: {
        name: string;
        street: string;
        postalCode: string;
        city: string;
        country: any;
      };
      shippingAddress?: any;
    };
    relationships?: {
      paymentMethods?: {
        /**
         * An array of objects each containing `type` and `id` members for to-many relationships.
         * @uniqueItems true
         */
        data: {
          id: string;
          /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
          type: Type;
          /** Non-standard meta-information that can not be represented as an attribute or relationship. */
          meta?: Meta;
        }[];
      };
    };
  };
  included?: {
    type: 'order-pay-customer-payment-method';
    id: string;
    attributes: {
      type: string;
      email: string | null;
      name: string | null;
      /** @example "1234" */
      last4: string | null;
    };
  }[];
}

export interface ShowOrderPayCustomerParams {
  /** A comma-separated list of resources to include */
  include?: IncludeEnum;
  /**
   * Customer ID
   * @format uuid
   */
  orderPayCustomer: string;
}

/** A comma-separated list of resources to include */
export enum IncludeEnum {
  PaymentMethods = 'paymentMethods',
}

/** A comma-separated list of resources to include */
export enum ShowOrderPayCustomerParams1IncludeEnum {
  PaymentMethods = 'paymentMethods',
}

export enum CreateOrderPayOrderStatusEnum {
  Created = 'created',
  BrandConfirmed = 'brand_confirmed',
  PaymentConfirmed = 'payment_confirmed',
  WaitingShipping = 'waiting_shipping',
  Shipped = 'shipped',
  Received = 'received',
  Invoiced = 'invoiced',
  BrandPaid = 'brand_paid',
  Cancelled = 'cancelled',
}

/** OrderSchema */
export interface CreateOrderPayOrderData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  /** OrderResource */
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes?: {
      brand: {
        /**
         * @format uuid
         * @example "1ef2e44a-b396-6b56-80d5-c66dfcccaea9"
         */
        brandUuid: string;
        /**
         * Brand name.
         * @example "Chevalier"
         */
        name: string;
        /**
         * Brand rounded logo image path.
         * @example "/brands/rounded/rounded.jpg"
         */
        imageRoundedPath: string;
      };
      /**
       * @format date-time
       * @example "2024-07-01T13:00:50.000000Z"
       */
      createdAt: string;
      /**
       * @format uuid
       * @example "2cef2271-11fd-4cfc-925f-d5ea761229c6"
       */
      customerUuid: string;
      /** @example 20 */
      discountRate: number;
      /** List of items with their corresponding quantities and unit amounts. */
      items: {
        /**
         * Product variant UUID.
         * @format uuid
         * @example "1ef2e4f1-5a37-66c6-9399-c66dfcccaea9"
         */
        productVariantUuid: string;
        /**
         * Product variant name.
         * @example "Voluptas ut consequatur (1789457692, Jaune de Mars)"
         */
        productVariantName: string;
        /**
         * Quantity of bundles of the item (ex: 2 boxes of 6 bottles of wine each: `quantity = 2`)
         * @example 7
         */
        quantity: number;
        /**
         * Total quantity of individual units of the item (ex: 2 boxes of 6 bottles of wine each: `multipliedQuantity = 12`)
         * @example 7
         */
        multipliedQuantity: number;
        /** Total amounts of the order item. */
        amounts: {
          /** @example "EUR" */
          brandCurrency: string;
          /** @example 1 */
          brandCurrencyRate: number;
          /** @example 1700 */
          brandUnitPriceBeforeDiscount: number;
          /** @example "€17.00" */
          brandUnitPriceBeforeDiscountFormatted: string;
          /** @example 1360 */
          brandUnitPrice: number;
          /** @example "€13.60" */
          brandUnitPriceFormatted: string;
          /** @example 11900 */
          brandAmountBeforeDiscount: number;
          /** @example "€119.00" */
          brandAmountBeforeDiscountFormatted: string;
          /** @example 2380 */
          brandDiscountAmount: number;
          /** @example "€23.80" */
          brandDiscountAmountFormatted: string;
          /** @example 9520 */
          brandAmount: number;
          /** @example "€95.20" */
          brandAmountFormatted: string;
          /** @example 1809 */
          brandAmountVat: number;
          /** @example "€18.09" */
          brandAmountVatFormatted: string;
          /** @example 11329 */
          brandAmountWithVat: number;
          /** @example "€113.29" */
          brandAmountWithVatFormatted: string;
          /** @example "EUR" */
          customerCurrency: string;
          /** @example 1 */
          customerCurrencyRate: number;
          /** @example 1700 */
          customerUnitPriceBeforeDiscount: number;
          /** @example "€17.00" */
          customerUnitPriceBeforeDiscountFormatted: string;
          /** @example 1360 */
          customerUnitPrice: number;
          /** @example "€13.60" */
          customerUnitPriceFormatted: string;
          /** @example 11900 */
          customerAmountBeforeDiscount: number;
          /** @example "€119.00" */
          customerAmountBeforeDiscountFormatted: string;
          /** @example 2380 */
          customerDiscountAmount: number;
          /** @example "€23.80" */
          customerDiscountAmountFormatted: string;
          /** @example 9520 */
          customerAmount: number;
          /** @example "€95.20" */
          customerAmountFormatted: string;
          /** @example 1809 */
          customerAmountVat: number;
          /** @example "€18.09" */
          customerAmountVatFormatted: string;
          /** @example 11329 */
          customerAmountWithVat: number;
          /** @example "€113.29" */
          customerAmountWithVatFormatted: string;
          /** @example 19 */
          vatRate: number;
          /** @example 20 */
          discountRate: number;
          /** @example 100 */
          discountAmount: number;
          /** @example "€1.00" */
          discountAmountFormatted: string;
        };
      }[];
      status?: CreateOrderPayOrderStatusEnum;
      /** @example "EACN9214515" */
      masterOrderReference?: string | null;
      /** Total amounts of the order. */
      amounts: {
        /** @example "EUR" */
        brandCurrency: string;
        /** @example 1 */
        brandCurrencyRate: number;
        /** @example 54620 */
        brandTotalAmountBeforeDiscount: number;
        /** @example "€546.20" */
        brandTotalAmountBeforeDiscountFormatted: string;
        /** @example 0 */
        brandTotalDiscountAmount: number;
        /** @example "€0.00" */
        brandTotalDiscountAmountFormatted: string;
        /** @example 54620 */
        brandTotalAmount: number;
        /** @example "€546.20" */
        brandTotalAmountFormatted: string;
        /** @example 12633 */
        brandTotalAmountVat: number;
        /** @example "€126.33" */
        brandTotalAmountVatFormatted: string;
        /** @example 67253 */
        brandTotalAmountWithVat: number;
        /** @example "€672.53" */
        brandTotalAmountWithVatFormatted: string;
        /** @example "EUR" */
        customerCurrency: string;
        /** @example 1 */
        customerCurrencyRate: number;
        /** @example 54620 */
        customerTotalAmountBeforeDiscount: number;
        /** @example "€546.20" */
        customerTotalAmountBeforeDiscountFormatted: string;
        /** @example 54620 */
        customerTotalDiscountAmount: number;
        /** @example "€0.00" */
        customerTotalDiscountAmountFormatted: string;
        /** @example 54620 */
        customerTotalAmount: number;
        /** @example "€546.20" */
        customerTotalAmountFormatted: string;
        /** @example 12633 */
        customerTotalAmountVat: number;
        /** @example "€126.33" */
        customerTotalAmountVatFormatted: string;
        /** @example 67253 */
        customerTotalAmountWithVat: number;
        /** @example "€672.53" */
        customerTotalAmountWithVatFormatted: string;
        /** @example 220 */
        shippingFeesAmount: number;
        /** @example "€2.20" */
        shippingFeesAmountFormatted: string;
        /** @example 44 */
        shippingFeesAmountVat: number;
        /** @example "€0.44" */
        shippingFeesAmountVatFormatted: string;
        /** @example 264 */
        shippingFeesAmountWithVat: number;
        /** @example "€2.64" */
        shippingFeesAmountWithVatFormatted: string;
        /** @example 54840 */
        customerGrandTotalAmount: number;
        /** @example "€548.40" */
        customerGrandTotalAmountFormatted: string;
        /** @example 12677 */
        customerGrandTotalAmountVat: number;
        /** @example "€126.77" */
        customerGrandTotalAmountVatFormatted: string;
        /** @example 67517 */
        customerGrandTotalAmountWithVat: number;
        /** @example "€675.17" */
        customerGrandTotalAmountWithVatFormatted: string;
      };
      shipping: {
        /** @example "fulfillment" */
        shippingMethod: string;
        shippingAddress: {
          /** @example "FR" */
          countryCode: string;
          /** @example "92100" */
          postalCode: string;
          /** @example "Boulogne-Billancourt" */
          city: string;
          /** @example "105, rue de Sevres" */
          street: string;
          addressLine?: string | null;
        };
        contactPerson: {
          /** @example "etienne.guillou@roy.com" */
          email: string;
          /** @example "Etienne" */
          firstName: string;
          /** @example "Guillou" */
          lastName: string;
          company?: string | null;
          phoneNumber?: string | null;
        };
      };
    };
  };
}

/** The payment service provider selected for the payment */
export enum CreateOrderPayPaymentPspEnum {
  Stripe = 'stripe',
}

/** Status of the payment */
export enum CreateOrderPayPaymentStatusEnum {
  Pending = 'pending',
  Succeeded = 'succeeded',
  Cancelled = 'cancelled',
}

/** The payment method selected for the payment */
export enum CreateOrderPayPaymentPaymentMethodEnum {
  SepaCreditTransfer = 'sepa_credit_transfer',
  SepaDirectDebit = 'sepa_direct_debit',
}

export enum CreateOrderPayPaymentPspEnum1 {
  Stripe = 'stripe',
}

export enum CreateOrderPayPaymentPaymentMethodEnum1 {
  SepaCreditTransfer = 'sepa_credit_transfer',
  SepaDirectDebit = 'sepa_direct_debit',
}

export interface CreateOrderPayPaymentData {
  /**
   * PaymentResource
   * The resource of a payment object
   */
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      /**
       * External order uuid
       * @format uuid
       */
      orderUuid: string;
      /**
       * Order pay customer uuid (external)
       * @format uuid
       */
      customerUuid: string;
      /**
       * Brand uuid
       * @format uuid
       */
      brandUuid: string;
      /**
       * Creation date of the payment
       * @format date-time
       */
      createdAt: string;
      /** The payment service provider selected for the payment */
      psp: CreateOrderPayPaymentPspEnum;
      /** Status of the payment */
      status: CreateOrderPayPaymentStatusEnum;
      /** An object used to detail pricing information for various Resources. */
      amount: Amount;
      /** ISO 3-Letter Currency Code */
      currency: Currency;
      /** @format float */
      currencyRate: number;
      /** The payment method selected for the payment */
      paymentMethod: CreateOrderPayPaymentPaymentMethodEnum;
      fundedBy?: string | null;
      paymentTerms?: string | null;
    };
    relationships?: {
      transactions?: {
        /** An array of objects each containing `type` and `id` members for to-many relationships. */
        data?: RelationshipToMany;
      };
    };
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  included?: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      /**
       * Creation date of the transaction
       * @format date-time
       */
      createdAt: string;
      /** The payment service provider transaction id */
      pspId: string;
      amount: number;
      currency: string;
      type: string;
      status: string;
      pspData?: object | null;
    };
  }[];
}

export interface OrderingListExternalCustomersParams {
  /**
   * Search string to filter the list of external customers. The search string
   * is matched against the external customer's first and last name, email address and business name.
   */
  'search-term'?: string;
}

export interface OrderingListExternalCustomersData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: any[];
}

export enum OrderingCreateExternalCustomerTypeEnum {
  Consumer = 'consumer',
  Business = 'business',
}

export enum OrderingCreateExternalCustomerTypeEnum1 {
  Consumer = 'consumer',
  Business = 'business',
}

export interface OrderingCreateExternalCustomerData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      firstName: string;
      lastName: string;
      street: string;
      additionalAddress: null | string;
      postalCode: string;
      city: string;
      countryCode: string;
      businessName: null | string;
      email: string;
      phoneNumber: string;
      type?: OrderingCreateExternalCustomerTypeEnum;
    };
  };
}

export enum OrderingUpdateExternalCustomerTypeEnum {
  Consumer = 'consumer',
  Business = 'business',
}

export enum OrderingListOrdersShippingMethodEnum {
  Custom = 'custom',
  Ankorstore = 'ankorstore',
  Fulfillment = 'fulfillment',
}

export enum OrderingListOrdersStockOriginEnum {
  DirectShipping = 'directShipping',
  FulfillmentCenter = 'fulfillmentCenter',
}

export interface OrderingListOrdersParams {
  /** Choose only specific attributes from order payload */
  'fields[orders]'?: string;
  /** Choose only specific attributes from order payload */
  'fields[order-shipping]'?: string;
  /** Include related resources in the response */
  include?: IncludeEnum1;
  'page[limit]'?: number;
  'page[offset]'?: number;
  /** Request masters orders for a specific priority category */
  'filter[priority_key]'?: FilterPriorityKeyEnum;
  /** Request master orders for a specific status category */
  'filter[status_key]'?: FilterStatusKeyEnum;
  /** Request master orders based on free text search e.g. product, brand, retailer etc. */
  'filter[search_term]'?: string;
  /** Request master orders for a specific brand cancellation reason */
  'filter[brand_cancellation_key]'?: FilterBrandCancellationKeyEnum;
  /** Request master orders of a specific type */
  'filter[order_type]'?: FilterOrderTypeEnum;
  /** Request external orders of a specific type. Can be used only if the `filter[order_type]` is set to `external`. */
  'filter[external_order_type]'?: FilterExternalOrderTypeEnum;
  /** Request master orders for a specific wrapped order statuses */
  'filter[wrapped_order_status]'?: FilterWrappedOrderStatusEnum[];
  /**
   * Specify attribute(s) to sort by
   * @default "-created_at"
   */
  sort?: SortEnum8;
}

/** Include related resources in the response */
export enum IncludeEnum1 {
  OrderItems = 'orderItems',
  OrderShipping = 'orderShipping',
}

/** Request masters orders for a specific priority category */
export enum FilterPriorityKeyEnum {
  OnTime = 'on_time',
  Overdue = 'overdue',
  CloseDeadline = 'close_deadline',
}

/** Request master orders for a specific status category */
export enum FilterStatusKeyEnum {
  Created = 'created',
  Prepared = 'prepared',
  Shipped = 'shipped',
  Delivered = 'delivered',
  Completed = 'completed',
  Cancelled = 'cancelled',
  PendingValidation = 'pending_validation',
  ReceptionRefused = 'reception_refused',
}

/** Request master orders for a specific brand cancellation reason */
export enum FilterBrandCancellationKeyEnum {
  PRODUCT_OUT_OF_STOCK = 'PRODUCT_OUT_OF_STOCK',
  BRAND_HAS_EXCLUSIVE_DISTRIBUTOR_IN_THE_REGION = 'BRAND_HAS_EXCLUSIVE_DISTRIBUTOR_IN_THE_REGION',
  BRAND_CANNOT_DELIVER_TO_THE_AREA = 'BRAND_CANNOT_DELIVER_TO_THE_AREA',
  RETAILER_AGREED_TO_DO_CHANGES_TO_ORDER = 'RETAILER_AGREED_TO_DO_CHANGES_TO_ORDER',
  BRAND_SHIPPING_FEES_VIA_CUSTOM = 'BRAND_SHIPPING_FEES_VIA_CUSTOM',
  OTHER = 'OTHER',
  REJECTED_VIA_EXTERNAL_INTEGRATION = 'REJECTED_VIA_EXTERNAL_INTEGRATION',
  BRAND_SHIPPING_FEES_VIA_ANKORSTORE = 'BRAND_SHIPPING_FEES_VIA_ANKORSTORE',
  PAYMENT_ISSUES_WITH_RETAILER = 'PAYMENT_ISSUES_WITH_RETAILER',
  PREPARATION_TIME_TOO_HIGH = 'PREPARATION_TIME_TOO_HIGH',
  BUYER_NOT_A_RETAILER = 'BUYER_NOT_A_RETAILER',
  PURCHASE_NOT_FOR_RESALE = 'PURCHASE_NOT_FOR_RESALE',
  RETAILER_NOT_GOOD_FIT_FOR_BRAND = 'RETAILER_NOT_GOOD_FIT_FOR_BRAND',
  RETAILER_VAT_NUMBER_MISSING = 'RETAILER_VAT_NUMBER_MISSING',
  ORDER_ITEMS_PRICES_INCORRECT = 'ORDER_ITEMS_PRICES_INCORRECT',
  BRAND_ALREADY_HAS_CUSTOMER_IN_THE_AREA = 'BRAND_ALREADY_HAS_CUSTOMER_IN_THE_AREA',
}

/** Request master orders of a specific type */
export enum FilterOrderTypeEnum {
  Internal = 'internal',
  External = 'external',
}

/** Request external orders of a specific type. Can be used only if the `filter[order_type]` is set to `external`. */
export enum FilterExternalOrderTypeEnum {
  Nafo = 'nafo',
  OrderPay = 'order_pay',
}

export enum FilterWrappedOrderStatusEnum {
  Created = 'created',
  AwaitingFulfillment = 'awaiting_fulfillment',
  Shipped = 'shipped',
  Arrived = 'arrived',
  Cancelled = 'cancelled',
  BrandConfirmed = 'brand_confirmed',
  PaymentConfirmed = 'payment_confirmed',
  WaitingShipping = 'waiting_shipping',
  Received = 'received',
  Invoiced = 'invoiced',
  BrandPaid = 'brand_paid',
}

/**
 * Specify attribute(s) to sort by
 * @default "-created_at"
 */
export enum SortEnum8 {
  CreatedAt = 'created_at',
  ValueCreatedAt = '-created_at',
  Status = 'status',
  ValueStatus = '-status',
}

export interface OrderingListOrdersData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: any[];
  included?: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type?: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      shippingMethod: null | OrderingListOrdersShippingMethodEnum;
      isAddingTrackingInformationPostponed: null | boolean;
      stockOrigin: null | OrderingListOrdersStockOriginEnum;
    };
  }[];
  meta: {
    count_by_status_key?: {
      /** @min 1 */
      created?: number | null;
      /** @min 1 */
      prepared?: number | null;
      /** @min 1 */
      shipped?: number | null;
      /** @min 1 */
      delivered?: number | null;
      /** @min 1 */
      completed?: number | null;
      /** @min 1 */
      cancelled?: number | null;
    };
    count_by_type_of_order?: {
      /** @min 1 */
      internal?: number | null;
      /** @min 1 */
      external?: number | null;
    };
    [key: string]: any;
  };
}

/** Include related resources in the response */
export enum OrderingListOrdersParams1IncludeEnum {
  OrderItems = 'orderItems',
  OrderShipping = 'orderShipping',
}

/** Request masters orders for a specific priority category */
export enum OrderingListOrdersParams1FilterPriorityKeyEnum {
  OnTime = 'on_time',
  Overdue = 'overdue',
  CloseDeadline = 'close_deadline',
}

/** Request master orders for a specific status category */
export enum OrderingListOrdersParams1FilterStatusKeyEnum {
  Created = 'created',
  Prepared = 'prepared',
  Shipped = 'shipped',
  Delivered = 'delivered',
  Completed = 'completed',
  Cancelled = 'cancelled',
  PendingValidation = 'pending_validation',
  ReceptionRefused = 'reception_refused',
}

/** Request master orders for a specific brand cancellation reason */
export enum OrderingListOrdersParams1FilterBrandCancellationKeyEnum {
  PRODUCT_OUT_OF_STOCK = 'PRODUCT_OUT_OF_STOCK',
  BRAND_HAS_EXCLUSIVE_DISTRIBUTOR_IN_THE_REGION = 'BRAND_HAS_EXCLUSIVE_DISTRIBUTOR_IN_THE_REGION',
  BRAND_CANNOT_DELIVER_TO_THE_AREA = 'BRAND_CANNOT_DELIVER_TO_THE_AREA',
  RETAILER_AGREED_TO_DO_CHANGES_TO_ORDER = 'RETAILER_AGREED_TO_DO_CHANGES_TO_ORDER',
  BRAND_SHIPPING_FEES_VIA_CUSTOM = 'BRAND_SHIPPING_FEES_VIA_CUSTOM',
  OTHER = 'OTHER',
  REJECTED_VIA_EXTERNAL_INTEGRATION = 'REJECTED_VIA_EXTERNAL_INTEGRATION',
  BRAND_SHIPPING_FEES_VIA_ANKORSTORE = 'BRAND_SHIPPING_FEES_VIA_ANKORSTORE',
  PAYMENT_ISSUES_WITH_RETAILER = 'PAYMENT_ISSUES_WITH_RETAILER',
  PREPARATION_TIME_TOO_HIGH = 'PREPARATION_TIME_TOO_HIGH',
  BUYER_NOT_A_RETAILER = 'BUYER_NOT_A_RETAILER',
  PURCHASE_NOT_FOR_RESALE = 'PURCHASE_NOT_FOR_RESALE',
  RETAILER_NOT_GOOD_FIT_FOR_BRAND = 'RETAILER_NOT_GOOD_FIT_FOR_BRAND',
  RETAILER_VAT_NUMBER_MISSING = 'RETAILER_VAT_NUMBER_MISSING',
  ORDER_ITEMS_PRICES_INCORRECT = 'ORDER_ITEMS_PRICES_INCORRECT',
  BRAND_ALREADY_HAS_CUSTOMER_IN_THE_AREA = 'BRAND_ALREADY_HAS_CUSTOMER_IN_THE_AREA',
}

/** Request master orders of a specific type */
export enum OrderingListOrdersParams1FilterOrderTypeEnum {
  Internal = 'internal',
  External = 'external',
}

/** Request external orders of a specific type. Can be used only if the `filter[order_type]` is set to `external`. */
export enum OrderingListOrdersParams1FilterExternalOrderTypeEnum {
  Nafo = 'nafo',
  OrderPay = 'order_pay',
}

export enum OrderingListOrdersParams1FilterWrappedOrderStatusEnum {
  Created = 'created',
  AwaitingFulfillment = 'awaiting_fulfillment',
  Shipped = 'shipped',
  Arrived = 'arrived',
  Cancelled = 'cancelled',
  BrandConfirmed = 'brand_confirmed',
  PaymentConfirmed = 'payment_confirmed',
  WaitingShipping = 'waiting_shipping',
  Received = 'received',
  Invoiced = 'invoiced',
  BrandPaid = 'brand_paid',
}

/**
 * Specify attribute(s) to sort by
 * @default "-created_at"
 */
export enum OrderingListOrdersParams1SortEnum {
  CreatedAt = 'created_at',
  ValueCreatedAt = '-created_at',
  Status = 'status',
  ValueStatus = '-status',
}

/** Type of wrapped order */
export enum OrderingShowOrderTypeEnum {
  Internal = 'internal',
}

export interface OrderingShowOrderParams {
  /** Include related resources in the response */
  include?: IncludeEnum2;
  /** Choose only specific attributes from order payload */
  'fields[orders]'?: string;
  /** Choose only specific attributes from order payload */
  'fields[order-shipping]'?: string;
  /**
   * UUID of the requested resource
   * @format uuid
   */
  id: string;
}

/** Include related resources in the response */
export enum IncludeEnum2 {
  OrderItems = 'orderItems',
  OrderShipping = 'orderShipping',
}

export interface OrderingShowOrderData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes:
      | MasterOrder
      | {
          /** Type of wrapped order */
          type: OrderingShowOrderTypeEnum;
          /** @format date-time */
          createdAt: string;
          orderItemCount: number;
          internalId: null | number;
          checkoutId?: null | number;
          status: {
            name: string;
            shortTitle?: string;
            title: null | string;
            details: null | string;
            subStatus?: {
              shortTitle?: string;
            };
          };
          totals: null | {
            totalAmount: any;
            totalVatAmount: any;
            totalAmountWithVat: any;
            netAmount: any;
            retailerTotalAmountBeforeDiscount?: any;
          };
          brand: {
            id: number;
            name: string;
            /** @format url */
            link: string;
            display_address: {
              city: string;
            };
            images?: {
              rounded: null | string;
            };
          };
          updates: null | object;
          taxes:
            | null
            | {
                name: string;
                amount: any;
              }[];
          shipping: {
            shippingProvider: null | string;
            /** @format date-time */
            shippedAt: null | string;
            shippingMethod: null | string;
            shippingAddress: any;
            shipment: null | {
              trackedPackages: {
                /** @format url */
                labelUrl?: null | string;
                parcel?: any;
              }[];
            };
          };
          tracking: null | {
            trackingNumber: null | string;
            trackingLink: null | string;
            status: null | string;
          };
          billing: null | {
            billingItems: any[];
          };
          issue: null | object;
          retailer: any;
          rejection: null | {
            brandRejectType: null | string;
          };
          cancellation: null | {
            retailerCanOpenCancellationRequest: boolean;
          };
          meta: null | {
            showManualInvoiceDownloadWarning?: boolean;
            casesVisibleToBrand?: string[];
            casesAllowedToBeSentToBrand?: string[];
          };
        };
    relationships?: {
      orderItems?: {
        /** An array of objects each containing `type` and `id` members for to-many relationships. */
        data?: RelationshipToMany;
      };
      orderShipping?: {
        /** An array of objects each containing `type` and `id` members for to-many relationships. */
        data?: RelationshipToMany;
      };
    };
  };
  included?: any[];
}

/** Include related resources in the response */
export enum OrderingShowOrderParams1IncludeEnum {
  OrderItems = 'orderItems',
  OrderShipping = 'orderShipping',
}

export interface GetMasterOrderUuidByInternalIdData {
  data: {
    masterOrderUuid?: string;
  };
}

export interface OrderingExportOrdersParams {
  /** Year for which orders to be exported */
  export_year?: number;
  /** Month for which orders to be exported */
  export_month?: number;
  /** Export format i.e. csv, xlsx */
  export_format?: ExportFormatEnum;
  status_key?: StatusKeyEnum;
}

/** Export format i.e. csv, xlsx */
export enum ExportFormatEnum {
  Csv = 'csv',
  Xlsx = 'xlsx',
}

export enum StatusKeyEnum {
  Created = 'created',
  Prepared = 'prepared',
  Shipped = 'shipped',
  Delivered = 'delivered',
  Completed = 'completed',
  Cancelled = 'cancelled',
  PendingValidation = 'pending_validation',
  ReceptionRefused = 'reception_refused',
}

export type OrderingExportOrdersData = any;

/** Export format i.e. csv, xlsx */
export enum OrderingExportOrdersParams1ExportFormatEnum {
  Csv = 'csv',
  Xlsx = 'xlsx',
}

export enum OrderingExportOrdersParams1StatusKeyEnum {
  Created = 'created',
  Prepared = 'prepared',
  Shipped = 'shipped',
  Delivered = 'delivered',
  Completed = 'completed',
  Cancelled = 'cancelled',
  PendingValidation = 'pending_validation',
  ReceptionRefused = 'reception_refused',
}

export interface OrderingListOrderItemsParams {
  /** Include related resources in the response */
  include?: IncludeEnum3;
  /**
   * UUID of the requested resource
   * @format uuid
   */
  id: string;
}

/** Include related resources in the response */
export enum IncludeEnum3 {
  OrderedProduct = 'orderedProduct',
}

export interface OrderingListOrderItemsData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type?: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      internalId: null | number;
      unitQuantity: number;
      batchQuantity: number;
      unitPrice: null | object;
      totalPrice: any;
    };
    relationships?: {
      /** References to other resource objects in a to-one (`relationship`). Relationships can be specified by including a member in a resource's links object. */
      orderedProduct?: RelationshipToOne;
    };
  }[];
  included?: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type?: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      /** @format uuid */
      fulfillableId: null | string;
      sku: string;
      name: string;
      pageUrl: string;
      /** Harmonized System Code - product classification for customs purposes. */
      hsCode?: null | string;
      images: string[];
      options: {
        name: string;
        value: null | string;
      }[];
    };
  }[];
}

/** Include related resources in the response */
export enum OrderingListOrderItemsParams1IncludeEnum {
  OrderedProduct = 'orderedProduct',
}

export interface OrderingShowPromiseDeliveryDateData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type?: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      /** @format uuid */
      brandId: string;
      /** @format uuid */
      retailerId: string;
      /** @format date */
      deliveryDate: null | string;
    };
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export interface OrderingPromiseDeliveryDatesListParams {
  /**
   * Filter by one or several brandId.
   * @format uuid
   */
  'filter[brandId][]'?: string;
}

export interface OrderingPromiseDeliveryDatesListData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: any[];
}

export interface OrderingGetPreorderRetailerData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type?: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      ibanLast4: null | string;
      /** Whether the feature is using the new SEPA flow (Stripe implementation has been migrated from Source to Setup/Payment Intent and PaymentMethod APIs) */
      isNewSepaFlow: boolean;
    };
  }[];
}

export interface OrderingGetPreordersCartParams {
  /** Include related resources in the response */
  include?: IncludeEnum4;
}

/** Include related resources in the response */
export enum IncludeEnum4 {
  Preorders = 'preorders',
  PreordersPreorderItems = 'preorders.preorderItems',
  PreordersPreorderItemsProductVariant = 'preorders.preorderItems.productVariant',
  PreordersBrand = 'preorders.brand',
}

export interface OrderingGetPreordersCartData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type?: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      itemCount: number;
      totalBaseAmount: any;
      totalAmountVat: any;
      totalAmountWithVat: any;
      grandTotalAmountWithVat: any;
    };
  };
  included?: any[];
}

/** Include related resources in the response */
export enum OrderingGetPreordersCartParams1IncludeEnum {
  Preorders = 'preorders',
  PreordersPreorderItems = 'preorders.preorderItems',
  PreordersPreorderItemsProductVariant = 'preorders.preorderItems.productVariant',
  PreordersBrand = 'preorders.brand',
}

export enum OrderingGetPreordersPaymentTermsNameEnum {
  UponAvailability = 'upon-availability',
  UponAvailabilityNet30 = 'upon-availability-net-30',
  UponAvailabilityNet60 = 'upon-availability-net-60',
}

export interface OrderingGetPreordersPaymentTermsData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type?: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      name: OrderingGetPreordersPaymentTermsNameEnum;
    };
  }[];
}

export enum OrderingListPreordersPaymentStatusEnum {
  Pending = 'pending',
  Captured = 'captured',
  Failed = 'failed',
}

export interface OrderingListPreordersParams {
  /** Include related resources in the response */
  include?: string;
  'page[limit]'?: number;
  'page[offset]'?: number;
  /**
   * Filter preorders by included product variant UUIDs, separated by comma
   * @example "123e4567-e89b-12d3-a456-426614174000,123e4567-e89b-12d3-a456-426614174001"
   */
  'filter[productVariantUuid]'?: string;
  /**
   * Filter preorders by statuses, separated by comma
   * @example "requested,waiting_availability"
   */
  'filter[status]'?: string;
}

export interface OrderingListPreordersData {
  meta: {
    page: {
      currentPage?: number;
      from?: number;
      lastPage?: number;
      perPage?: number;
      to?: number;
      total?: number;
    };
    [key: string]: any;
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  links?: {
    first?: string;
    last?: string;
    [key: string]: any;
  };
  data: any[];
  included?: (
    | {
        /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
        type?: Type;
        /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
        id?: Id;
        attributes?: {
          internalId: number;
          name: string;
          countryIsoCode: string;
          link: string;
          logoImagePath: string;
          isEnabled: boolean;
          canShipToRetailerCountry: boolean;
        };
      }
    | {
        /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
        type?: Type;
        /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
        id?: Id;
        attributes?: {
          id: null | number;
          name: string;
          store_url?: null | string;
          /** @format email */
          email: string;
          phone_number: string;
          city: string;
          postal_code?: string;
          address_line?: string;
          company_name?: string;
          business: {
            vat_number: null | string;
            tax_number: null | string;
            user_first_name: null | string;
            user_last_name: null | string;
            country: {
              iso_code: string;
            };
          };
        };
      }
    | {
        /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
        type?: Type;
        /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
        id?: Id;
        attributes?: {
          /** @format uuid */
          productVariantId: string;
          quantity: number;
          multipliedQuantity: number;
          /** @format date-time */
          availableAt: string;
          canBeBought?: boolean;
          unitPrice: any;
          baseAmount: any;
          amountBeforeDiscount?: any;
        };
        relationships?: {
          productVariant?: {
            /** References to other resource objects in a to-one (`relationship`). Relationships can be specified by including a member in a resource's links object. */
            data?: RelationshipToOne;
          };
          order?: {
            /** References to other resource objects in a to-one (`relationship`). Relationships can be specified by including a member in a resource's links object. */
            data?: RelationshipToOne;
          };
        };
      }
    | {
        /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
        type?: Type;
        /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
        id?: Id;
        attributes?: {
          internalId: number;
          /** @format date-time */
          availableAt: string | null;
          name: string;
          sku: string;
          path: string;
          imagePath: string;
          unitMultiplier: number;
          options: {
            name: string;
            value: string;
          }[];
          stock: {
            status: string;
            isAlwaysInStock: boolean;
            availableQuantity?: number;
          };
        };
      }
    | {
        /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
        type?: Type;
        /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
        id?: Id;
        attributes?: {
          master_order_uuid?: string;
          payment_status?: OrderingListPreordersPaymentStatusEnum;
          /** @format date-time */
          payment_capture_end_date?: string;
        };
      }
  )[];
}

export enum OrderingShowPreorderStatusEnum {
  Created = 'created',
  Requested = 'requested',
  WaitingAvailability = 'waiting_availability',
  PartiallyAvailable = 'partially_available',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export enum OrderingShowPreorderPaymentTermsEnum {
  UponAvailability = 'upon-availability',
  UponAvailabilityNet30 = 'upon-availability-net-30',
  UponAvailabilityNet60 = 'upon-availability-net-60',
}

export interface OrderingShowPreorderParams {
  /** Include related resources in the response */
  include?: IncludeEnum5;
  /**
   * UUID of the requested resource
   * @format uuid
   */
  id: string;
}

/** Include related resources in the response */
export enum IncludeEnum5 {
  PreorderItems = 'preorderItems',
  PreorderItemsProductVariant = 'preorderItems.productVariant',
  PreorderItemsOrder = 'preorderItems.order',
  Brand = 'brand',
  Retailer = 'retailer',
}

export interface OrderingShowPreorderData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  data?: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type?: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      isSelected: boolean;
      isShippingRestrictedByEori: boolean;
      itemCount: number;
      status: OrderingShowPreorderStatusEnum;
      /** @format date-time */
      submittedAt: string | null;
      totalBaseAmount: any;
      totalAmountVat?: any;
      totalAmountWithVat?: any;
      totalAmountBeforeDiscount?: any;
      grandTotalAmountWithVat: any;
      offerDiscounts?: {
        name: string;
        type: string;
        amount: any;
      }[];
      preorderMinimumAmount?: any;
      shippingContributionAmount?: any;
      hasDiscounts?: boolean;
      hasMultiDiscounts?: boolean;
      globalDiscountPercentage?: number;
      paymentTerms?: OrderingShowPreorderPaymentTermsEnum;
      metadata?: {
        isDiscovery: boolean;
        actualShippingFeeAmount?: any;
        shippingFeeAmountVat?: any;
        francoAmount?: null | number;
        discountedShippingAmount?: any;
        amountLeftForFreeShipping?: any;
        isEligibleForDiscoveryFreeShipping?: boolean;
        isBrandEligibleForAkPlusFreeShipping?: boolean;
        offerName?: null | string;
      };
    };
    relationships?: {
      preorderItems?: {
        /** An array of objects each containing `type` and `id` members for to-many relationships. */
        data?: RelationshipToMany;
      };
      brand?: {
        /** References to other resource objects in a to-one (`relationship`). Relationships can be specified by including a member in a resource's links object. */
        data?: RelationshipToOne;
      };
      retailer?: {
        /** References to other resource objects in a to-one (`relationship`). Relationships can be specified by including a member in a resource's links object. */
        data?: RelationshipToOne;
      };
    };
  };
  included?: any[];
}

/** Include related resources in the response */
export enum OrderingShowPreorderParams1IncludeEnum {
  PreorderItems = 'preorderItems',
  PreorderItemsProductVariant = 'preorderItems.productVariant',
  PreorderItemsOrder = 'preorderItems.order',
  Brand = 'brand',
  Retailer = 'retailer',
}

export interface OrderingShipExternalOrderWithCustomMethodData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      /** @format uuid */
      masterOrderUuid: string;
    };
  };
}

export interface PaymentViewRetailerAvailableCreditData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  /**
   * RetailerAvailableCreditResource
   * Retailer available credit resource
   */
  data?: {
    /**
     * [resource object type](https://jsonapi.org/format/#document-resource-object-identification)
     * @example "retailer-available-credit"
     */
    type?: string;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      /** @example 15000 */
      amount?: number;
      /** @example "EUR" */
      currency?: string;
    };
  };
}

export enum CreatePurchasePaymentPspEnum {
  Stripe = 'stripe',
}

export enum CreatePurchasePaymentPaymentMethodEnum {
  SepaCreditTransfer = 'sepa_credit_transfer',
  SepaDebit = 'sepa_debit',
}

export enum CreatePurchasePaymentPurchasableEntityTypeEnum {
  Checkout = 'checkout',
  Preorder = 'preorder',
}

export enum CreatePurchasePaymentPspEnum1 {
  Stripe = 'stripe',
}

/** Using `sepa_credit_transfer` will always create a new PaymentIntent in Stripe, while `sepa_direct_debit` will create a new SetupIntent in Stripe only if `withNewPaymentMethod` is set to `true`. */
export enum CreatePurchasePaymentPaymentMethodEnum1 {
  SepaCreditTransfer = 'sepa_credit_transfer',
  SepaDebit = 'sepa_debit',
}

export interface CreatePurchasePaymentData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  /** PaymentResource */
  data: {
    type: 'purchase-payments';
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      psp: CreatePurchasePaymentPspEnum;
      paymentMethod: CreatePurchasePaymentPaymentMethodEnum;
      pspData: {
        /** Stripe PaymentIntent ID for `sepa_credit_transfer` payment method, or Stripe SetupIntent ID for of `sepa_direct_debit` payment method. */
        pspId?: string;
        clientSecret?: string;
      };
    };
  };
}

/** Service type of the invoice */
export enum InvoicingListServiceInvoicesServiceTypeEnum {
  Fulfillment = 'fulfillment',
  Ads = 'ads',
}

export interface InvoicingListServiceInvoicesParams {
  limit?: number;
  offset?: number;
  /**
   * Request specific statuses only
   * @minItems 1
   */
  'filter[status]'?: FilterStatusEnum2[];
}

export enum FilterStatusEnum2 {
  ProForma = 'pro_forma',
  Paid = 'paid',
  PaymentFailed = 'payment failed',
  PaymentPending = 'payment pending',
  PaymentProcessing = 'payment_processing',
}

export interface InvoicingListServiceInvoicesData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      /**
       * The invoice number
       * @pattern ^AKS-\d{6}-\d{9}$
       */
      invoiceNumber?: string | null;
      /** Total amount for the item, excluding VAT (in EUR & in minor units) */
      totalAmount?: number;
      /** Total amount of VAT for the item (in EUR & in minor units) */
      totalVat?: number;
      /** Total amount for the item, including VAT (in EUR & in minor units) */
      totalAmountWithVat?: number;
      /**
       * The date that invoiced this service invoice
       * @format datetime
       */
      invoicedAt?: string;
      status?: any;
      /**
       * The period for which the invoice is issued
       * @pattern ^(\d{2}-)?(\d{2}-)?\d{4}$
       */
      invoicePeriod?: string;
      /** Service type of the invoice */
      serviceType?: InvoicingListServiceInvoicesServiceTypeEnum;
      /**
       * PDF contents, base64 encoded
       * @format byte
       */
      content?: string;
    };
  }[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export enum InvoicingListServiceInvoicesParams1FilterStatusEnum {
  ProForma = 'pro_forma',
  Paid = 'paid',
  PaymentFailed = 'payment failed',
  PaymentPending = 'payment pending',
  PaymentProcessing = 'payment_processing',
}

export interface ListInvoiceExportsData {
  /**
   * InvoiceExportResource
   * @example {"id":"43efbfbd-bfbd-1eef-1e6a-6200efbfbdef","type":"invoice-export","attributes":{"invoiced_at_from":"2023-01-01T00:00:00.000Z","invoiced_at_to":"2023-12-31T23:59:59.000Z","finished_at":"2024-01-05T10:37:27.000Z","expires_at":"2024-01-06T10:37:27.000Z","download_links":["https://ankorlocal.com/api/internal/v1/invoice-exports/1eeabb66-b239-6f70-b2d5-0242ac12000b/zip-file/25387/download"]}}
   */
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      /**
       * Datetime from which invoices are taken
       * @format datetime
       */
      invoiced_at_from: string;
      /**
       * Datetime to which invoices are taken
       * @format datetime
       */
      invoiced_at_to: string;
      /**
       * Datetime when export is finished
       * @format datetime
       */
      finished_at: string;
      /**
       * Datetime when export expires
       * @format datetime
       */
      expires_at: string;
      /** Download urls of parts of invoice export */
      download_links: string[];
    };
  };
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

export interface TriggerInvoiceExportData {
  data: any;
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

export interface DownloadInvoiceExportZipFileData {
  data: {
    /** @format binary */
    file?: File;
  };
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

/** Ankstore customer type e.g. Brand, Retailer etc. */
export enum CreateInvoicingPaymentServiceProviderCustomerAksCustomerTypeEnum {
  Brand = 'brand',
  Retailer = 'retailer',
}

export enum CreateInvoicingPaymentServiceProviderCustomerMandateTypeEnum {
  MultiUse = 'multi_use',
  SingleUse = 'single_use',
}

export enum CreateInvoicingPaymentServiceProviderCustomerMandateStatusEnum {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending',
  Processing = 'processing',
}

export interface CreateInvoicingPaymentServiceProviderCustomerData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      /** Ankstore customer ID e.g. brand_id, retailer_id etc. */
      aksCustomerId: number;
      /** Ankstore customer type e.g. Brand, Retailer etc. */
      aksCustomerType: CreateInvoicingPaymentServiceProviderCustomerAksCustomerTypeEnum;
      /** Customer ID from payment service provider */
      customerId: string;
      /** Intent Setup ID for future payments from payment service provider */
      setupIntentId?: string;
      paymentServiceProvider: string;
      email?: string;
      description?: string;
      name?: string;
      mandateType: CreateInvoicingPaymentServiceProviderCustomerMandateTypeEnum;
      mandateStatus?: CreateInvoicingPaymentServiceProviderCustomerMandateStatusEnum;
    };
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export interface InvoicingPaymentServiceProviderCustomerParams {
  /** Ankorstore customer e.g. brand, retailer, by default it's brand */
  aksCustomerType?: string;
  /** Ankorstore customer ID e.g. brand_id, retailer_id */
  aksCustomerId: number;
}

export interface CreateInvoicingPaymentServiceSetupIntentData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** @pattern [A-Z_0-9a-z]+ */
    id: string;
    attributes: {
      /** Setup Intent client secret */
      clientSecret?: string;
      /** Last 4 characters of Setup Intent Sepa Debit iban */
      ibanLast4?: string;
    };
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export type PostApiInternalV1RetailerEmailSignupData = any;

export interface GetApiInternalV1CandidateBrandsIdData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  data?: {
    type?: string;
    id?: string;
    attributes?: {
      email?: string;
      firstName?: string;
    };
  };
}

export interface RutterIntegrationSendTestOrderData {
  /**
   * URL of the created test order
   * @format uri
   * @example "https://my-store.com/admin/orders/12345"
   */
  orderUrl: string;
}

export interface PostApiInternalV1CatalogIntegrationProductSpreadsheetMappingData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  data?: {
    type?: string;
    id?: string;
    attributes?: {
      name?: string;
      configuration?: {
        identifier?: string;
        field?: string | number | null;
        missingValue?: string | null;
        emptyValue?: string | null;
        behavior?: string | null;
      }[];
    };
  };
}

export interface GetApiInternalV1CatalogIntegrationProductSpreadsheetMappingsListData {
  data: any[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export interface GetCatalogIntegrationProductSpreadsheetMappingData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  links?: {
    self?: string;
  };
  data?: {
    type?: string;
    id?: string;
    attributes?: {
      uuid?: string;
      name?: number;
      user_id?: number;
      is_custom?: boolean;
      configuration?: {
        identifier?: string;
        field?: string | number | null;
        missingValue?: string | null;
        emptyValue?: string | null;
        behavior?: string | null;
      }[];
    };
    links?: {
      self?: string;
    };
  };
}

export enum PostApiInternalV1CatalogIntegrationGoogleBatchesWorkflowEnum {
  Import = 'import',
  Replace = 'replace',
  Update = 'update',
}

export enum PostApiInternalV1CatalogIntegrationGoogleBatchesWorkflowEnum1 {
  Import = 'import',
  Replace = 'replace',
  Update = 'update',
}

export interface PostApiInternalV1CatalogIntegrationGoogleBatchesData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  data?: {
    type?: 'product-integration-batches';
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      payload?: {
        spreadsheet_url?: string;
        drive_url?: string;
      };
      workflow?: PostApiInternalV1CatalogIntegrationGoogleBatchesWorkflowEnum;
    };
  };
}

export enum PostApiInternalV1CatalogIntegrationCsvBatchesWorkflowEnum {
  Import = 'import',
  Replace = 'replace',
  Update = 'update',
}

export enum PostApiInternalV1CatalogIntegrationCsvBatchesWorkflowEnum1 {
  Import = 'import',
  Replace = 'replace',
  Update = 'update',
}

/** @example {"jsonapi":{"version":"1.0"},"data":{"type":"product-integration-batches","id":"27","attributes":{"payload":{"spreadsheet_upload_file_id":"file-upload:some-hash.csv"},"workflow":"import"}}} */
export interface PostApiInternalV1CatalogIntegrationCsvBatchesData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  data?: {
    type?: string;
    id?: string;
    attributes?: {
      payload?: {
        spreadsheet_upload_file_id?: string;
      };
      workflow?: PostApiInternalV1CatalogIntegrationCsvBatchesWorkflowEnum;
    };
  };
}

export interface GetCatalogIntegrationIntegrationsIntegrationsParams {
  /**
   * Results page number.
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Number of items returned per page.
   * @min 1
   * @max 50
   * @default 50
   */
  perPage?: number;
  /**
   * Property to sort results by.
   * @default "id"
   */
  sort?: SortEnum9;
  /** Keep only results with issues (true) or without issues (false). */
  'filter[hasIssues]'?: boolean;
}

/**
 * Property to sort results by.
 * @default "id"
 */
export enum SortEnum9 {
  Id = 'id',
  Status = 'status',
  Type = 'type',
  PlatformProductId = 'platform_product_id',
  ExternalProductId = 'external_product_id',
  InternalProductId = 'internal_product_id',
  ProductUpdatedAt = 'product_updated_at',
  IntegratedAt = 'integrated_at',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at',
}

export interface GetCatalogIntegrationIntegrationsIntegrationsData {
  data: any[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

/**
 * Property to sort results by.
 * @default "id"
 */
export enum GetCatalogIntegrationIntegrationsIntegrationsParams1SortEnum {
  Id = 'id',
  Status = 'status',
  Type = 'type',
  PlatformProductId = 'platform_product_id',
  ExternalProductId = 'external_product_id',
  InternalProductId = 'internal_product_id',
  ProductUpdatedAt = 'product_updated_at',
  IntegratedAt = 'integrated_at',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at',
}

/** @example {"jsonapi":{"version":"1.0"},"links":{"self":"http://www.ankorlocal.com:8000/api/internal/v1/integrations/1ed3fd06-050a-6a00-8009-0242ac120003"},"data":{"type":"integrations","id":"1ed3fd06-050a-6a00-8009-0242ac120003","attributes":{"id":9,"brand_id":22408,"type":"rutter","platform":null,"status":"integrated","platform_product_id":"7636912570603","external_product_id":"198d8bbc-cdb7-469c-a4f8-472e0d1841ca","internal_product_id":null,"internal_state":{"images":[],"name":"Juicy Orange","description":"Such a wonderful orange, you should buy it 1","unit_multiplier":2,"currency":"EUR","discount_rate":0,"retail_price":{"amount":20,"currency":"EUR"},"wholesale_price":{"amount":10,"currency":"EUR"},"original_wholesale_price":{"amount":10,"currency":"EUR"},"vat_rate":21,"categories":[{"id":1269,"name":"Gourmet","parent_id":1262}],"properties":[],"tags":[{"name":"tags_fresh_product"}],"made_in":null,"hs_code":null,"variants":[{"id":null,"sku":"orng-1","ian":"1234567891234","price":{"currency":"EUR","retail_price":{"amount":20,"currency":"EUR"},"wholesale_price":{"amount":10,"currency":"EUR"},"original_wholesale_price":{"amount":10,"currency":"EUR"},"discount_rate":0},"stock":{"id":null,"stock_quantity":16,"is_always_in_stock":false,"reserved_quantity":0,"available_quantity":16},"shape_properties":[{"id":null,"weight":0.1,"weight_unit":"kg","length":0.1,"width":0.1,"height":0.1,"length_unit":"cm","capacity":0.1,"capacity_unit":"l"}],"options":[{"id":3,"name":"other","value":"Default Title"}],"name":null,"created_at":null,"images":[{"filename":"https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?v=1530129081","order":1}]}]},"issues":[],"internal_state_updated_at":"2022-09-30T14:27:32.793045Z","integrated_at":null,"created_at":"2022-08-05T13:32:31.713000Z","updated_at":"2022-09-30T14:27:32.811189Z"},"links":{"self":"http://www.ankorlocal.com:8000/api/internal/v1/integrations/1ed3fd06-050a-6a00-8009-0242ac120003"}}} */
export interface GetCatalogIntegrationIntegrationsIntegrationsIdData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  links?: {
    self?: string;
  };
  data?: {
    type?: string;
    id?: string;
    attributes?: {
      id?: number;
      brand_id?: number;
      type?: string;
      platform?: null;
      status?: string;
      platform_product_id?: string;
      external_product_id?: string;
      internal_product_id?: null;
      internal_state?: {
        images?: object[];
        name?: string;
        description?: string;
        unit_multiplier?: number;
        currency?: string;
        discount_rate?: number;
        retail_price?: {
          amount?: number;
          currency?: string;
        };
        wholesale_price?: {
          amount?: number;
          currency?: string;
        };
        original_wholesale_price?: {
          amount?: number;
          currency?: string;
        };
        vat_rate?: number;
        categories?: {
          id?: number;
          name?: string;
          parent_id?: number;
        }[];
        properties?: object[];
        tags?: {
          name?: string;
        }[];
        made_in?: null;
        hs_code?: null;
        variants?: {
          id?: null;
          sku?: string;
          ian?: string;
          price?: {
            currency?: string;
            retail_price?: {
              amount?: number;
              currency?: string;
            };
            wholesale_price?: {
              amount?: number;
              currency?: string;
            };
            original_wholesale_price?: {
              amount?: number;
              currency?: string;
            };
            discount_rate?: number;
          };
          stock?: {
            id?: null;
            stock_quantity?: number;
            is_always_in_stock?: boolean;
            reserved_quantity?: number;
            available_quantity?: number;
          };
          shape_properties?: {
            id?: number | null;
            /** @format float */
            weight?: number;
            weight_unit?: string;
            /** @format float */
            length?: number;
            /** @format float */
            width?: number;
            /** @format float */
            height?: number;
            length_unit?: string;
            /** @format float */
            capacity?: number;
            capacity_unit?: string;
          }[];
          options?: {
            id?: number;
            name?: string;
            value?: string;
          }[];
          name?: null;
          created_at?: null;
          images?: {
            filename?: string;
            order?: number;
          }[];
        }[];
      };
      issues?: object[];
      internal_state_updated_at?: string;
      integrated_at?: null;
      created_at?: string;
      updated_at?: string;
    };
    links?: {
      self?: string;
    };
  };
}

export interface GetApiInternalV1CatalogIntegrationBrandConfigData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type?: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    /** CatalogIntegrationBrandConfig */
    attributes?: {
      images_google_folder_url?: string;
      empty_xlsx_download_link?: string;
      generated_google_spreadsheet?: {
        instructions_sheet_url?: string;
        data_sheet_url?: string;
      };
      /** @format date-time */
      created_at?: string;
      /** @format date-time */
      updated_at?: string;
    };
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export interface GetApiInternalV1CatalogExportsData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  data?: {
    type?: string;
    id?: string;
    attributes?: {
      status?: string;
      spreadsheet_url?: string;
    };
  };
}

export type PostApiInternalV1CatalogExportsData = any;

export type PostApiInternalV1CatalogIntegrationBrandConfigsSpreadsheetData = any;

export type DeleteApiInternalV1CatalogIntegrationBrandConfigsSpreadsheetData = any;

export interface PatchApiInternalV1CatalogIntegrationsActionsUpdateAllParams {
  /** Product integration ID */
  'filter[id]'?: string[];
  /** Product integration status */
  'filter[status]'?: string[];
  /** Apply only to results with issues (true) or without issues (false). */
  'filter[hasIssues]'?: boolean;
}

export type PatchApiInternalV1CatalogIntegrationsActionsUpdateAllData = any;

export type DeleteApiInternalV1CatalogIntegrationsActionsDeleteManyData = any;

export interface GetApiInternalV1CatalogIntegrationsIntegrationsActionsStatisticsData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  meta: {
    products_total_count: number;
    products_without_issues_total_count: number;
    [key: string]: any;
  };
}

export interface PostApiInternalV1CatalogIntegrationsIntegrationsActionsApproveAllParams {
  /** Product integration statuses */
  'filter[status]'?: string[];
  /** Apply only to results with issues (true) or without issues (false). */
  'filter[hasIssues]'?: boolean;
}

export type PostApiInternalV1CatalogIntegrationsIntegrationsActionsApproveAllData = any;

export type PostApiInternalV1CatalogIntegrationsIntegrationsActionsApproveManyData = any;

export interface DeleteApiInternalV1CatalogIntegrationsActionsPurgeParams {
  /** Product integration status */
  'filter[status]'?: string[];
  /** Apply only to results with issues (true) or without issues (false). */
  'filter[hasIssues]'?: boolean;
}

export type DeleteApiInternalV1CatalogIntegrationsActionsPurgeData = any;

export enum GetApiInternalV1CatalogIntegrationBatchesImportTypeEnum {
  Add = 'add',
  Replace = 'replace',
}

export enum GetApiInternalV1CatalogIntegrationBatchesWorkflowEnum {
  Import = 'import',
  Replace = 'replace',
  Update = 'update',
  Activate = 'activate',
}

export interface GetApiInternalV1CatalogIntegrationBatchesParams {
  /**
   * Results page number.
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Number of items returned per page.
   * @min 1
   * @max 50
   * @default 50
   */
  perPage?: number;
  /**
   * Property to sort results by.
   * @default "created_at"
   */
  sort?: string;
}

export interface GetApiInternalV1CatalogIntegrationBatchesData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type?: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    /** ProductIntegrationBatchItem */
    attributes?: {
      fetched_count?: number;
      imported_count?: number;
      integrated_count?: number;
      failed_count?: number;
      created_count?: number;
      updated_count?: number;
      type?: string;
      import_type?: GetApiInternalV1CatalogIntegrationBatchesImportTypeEnum;
      workflow?: GetApiInternalV1CatalogIntegrationBatchesWorkflowEnum;
      status?: string;
    };
  }[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

/** @example {"jsonapi":{"version":"1.0"},"data":[{"type":"offer-programmes","id":"1ed505e9-0137-6440-888f-0242ac12000c","attributes":{"name":"Diane Reynaud","type":"offer-programmes","start_date":"2022-10-20T00:00:00.000000Z","end_date":"2022-11-03T00:00:00.000000Z","optin":true,"free_trial_eligible":false,"content_management":{"prismic_id":"test","link":{"terms_conditions":"test_link"}}},"links":{"self":"https://ankorstore.com/api/internal/v1/offer-programmes/1ed505e9-0137-6440-888f-0242ac12000c"}},{"type":"offer-programmes","id":"1ed505eb-fd41-69d2-ad3d-0242ac12000c","attributes":{"name":"Henriette-Élise Giraud","type":"offer-programmes","start_date":"2022-10-20T00:00:00.000000Z","end_date":"2022-11-03T00:00:00.000000Z","optin":false,"free_trial_eligible":true,"content_management":{"prismic_id":"test","link":{"terms_conditions":"test_link"}}},"links":{"self":"https://ankorstore.com/api/internal/v1/offer-programmes/1ed505eb-fd41-69d2-ad3d-0242ac12000c"}},{"type":"offer-programmes","id":"1ed5063b-2ada-68cc-a370-0242ac12000c","attributes":{"name":"William Ollivier","type":"offer-programmes","start_date":"2022-10-20T00:00:00.000000Z","end_date":"2022-11-03T00:00:00.000000Z","optin":false,"free_trial_eligible":true,"content_management":{"prismic_id":"test","link":{"terms_conditions":"test_link"}}},"links":{"self":"https://ankorstore.com/api/internal/v1/offer-programmes/1ed5063b-2ada-68cc-a370-0242ac12000c"}}]} */
export interface GetApiInternvalV1OfferProgrammesEligibleData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  data?: {
    type?: string;
    id?: string;
    attributes?: {
      name?: string;
      type?: string;
      start_date?: string;
      end_date?: string;
      optin?: boolean;
      free_trial_eligible?: boolean;
      content_management?: {
        prismic_id?: string;
      };
      subscription?: {
        status?: string;
        price?: {
          amount?: number;
          currency?: string;
        };
        currentPeriodStart?: string;
        currentPeriodEnd?: string;
        billingDate?: string;
        subscribedAt?: string;
        canceledAt?: string;
      };
      savings?: {
        nb_net_90_checkouts?: number;
        total_amount?: {
          amount?: number;
          currency?: string;
        };
        shipping_fees?: {
          amount?: number;
          currency?: string;
        };
        discount?: {
          amount?: number;
          currency?: string;
        };
      };
    };
    links?: {
      self?: string;
    };
  }[];
}

/** @example {"jsonapi":{"version":"1.0"},"data":[{"type":"offer-programmes","id":"1ed505e9-0137-6440-888f-0242ac12000c","attributes":{"name":"Diane Reynaud","type":"offer-programmes","start_date":"2022-10-20T00:00:00.000000Z","end_date":"2022-11-03T00:00:00.000000Z","optin":true,"content_management":{"prismic_id":"test","link":{"terms_conditions":"test_link"}}},"links":{"self":"https://ankorstore.com/api/internal/v1/offer-programmes/1ed505e9-0137-6440-888f-0242ac12000c"}}]} */
export interface GetApiInternvalV1OfferProgrammesData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  data?: {
    type?: string;
    id?: string;
    attributes?: {
      name?: string;
      type?: string;
      start_date?: string;
      end_date?: string;
      optin?: boolean;
      free_trial_eligible?: boolean;
      content_management?: {
        prismic_id?: string;
      };
      subscription?: {
        status?: string;
        price?: {
          amount?: number;
          currency?: string;
        };
        currentPeriodStart?: string;
        currentPeriodEnd?: string;
        billingDate?: string;
        subscribedAt?: string;
        canceledAt?: string;
      };
      savings?: {
        nb_net_90_checkouts?: number;
        total_amount?: {
          amount?: number;
          currency?: string;
        };
        shipping_fees?: {
          amount?: number;
          currency?: string;
        };
        discount?: {
          amount?: number;
          currency?: string;
        };
      };
      potential_savings?: {
        net90?: boolean;
        total_amount?: {
          amount?: number;
          currency?: string;
        };
        shipping_fees?: {
          amount?: number;
          currency?: string;
        };
        nb_brands_repeat_offer?: number;
        nb_brands_48H?: number;
      };
    };
    links?: {
      self?: string;
    };
  }[];
}

/** @example {"jsonapi":{"version":"1.0"},"data":{"type":"offer-programmes","id":"1ed505e9-0137-6440-888f-0242ac12000c","attributes":{"name":"Diane Reynaud","type":"offer-programmes","start_date":"2022-10-20T00:00:00.000000Z","end_date":"2022-11-03T00:00:00.000000Z","optin":true},"links":{"self":"https://ankorstore.com/api/internal/v1/offer-programmes/1ed505e9-0137-6440-888f-0242ac12000c"}}} */
export interface PostApiInternalV1OfferProgrammesIdOptinsData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  data?: {
    type?: string;
    id?: string;
    attributes?: {
      name?: string;
      type?: string;
      start_date?: string;
      end_date?: string;
      optin?: boolean;
    };
    links?: {
      self?: string;
    };
  };
}

/** @example {"jsonapi":{"version":"1.0"},"data":{"type":"offer-programmes","id":"1ed505e9-0137-6440-888f-0242ac12000c","attributes":{"name":"Diane Reynaud","type":"offer-programmes","start_date":"2022-10-20T00:00:00.000000Z","end_date":"2022-11-03T00:00:00.000000Z","optin":true},"links":{"self":"https://ankorstore.com/api/internal/v1/offer-programmes/1ed505e9-0137-6440-888f-0242ac12000c"}}} */
export interface PostApiInternalV1OfferProgrammesIdOptoutsData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  data?: {
    type?: string;
    id?: string;
    attributes?: {
      name?: string;
      type?: string;
      start_date?: string;
      end_date?: string;
      optin?: boolean;
    };
    links?: {
      self?: string;
    };
  };
}

/** @example {"data":[{"ankorstore_plus_opt_in":true}]} */
export interface GetApiInternalV1OfferProgrammeHeaderPillsData {
  data?: {
    ankorstore_plus_opt_in?: boolean;
    pill?: {
      title?: string;
      description?: string;
      action_type?: string;
      action_label?: string;
      feedback?: string;
    };
  }[];
}

export interface GetPlatformConfigData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      cdn?: string;
      stripePublicKey?: string;
    };
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export interface GetApiInternalV1TestimonialsParams {
  /** Filter testimonials by multiple country ids */
  'filter[countryId]'?: number[];
  /** Filter testimonials by multiple country iso codes */
  'filter[country][isoCode]'?: string[];
  /** A limit on the number of resources to be returned, i.e. the per-page amount. */
  'page[size]'?: string;
  /** A cursor for use in pagination. after is a resource ID that defines your place in the list. For instance, if you make a paged request and receive 100 resources, ending with resource with id foo, your subsequent call can include page[after]=foo in order to fetch the next page of the list. */
  'page[after]'?: string;
  sort?: SortEnum10;
}

export enum SortEnum10 {
  CreatedAt = 'createdAt',
  ValueCreatedAt = '-createdAt',
}

export interface GetApiInternalV1TestimonialsData {
  meta?: {
    page?: {
      /** Uuid of the first element */
      from?: string;
      hasMore?: boolean;
      perPage?: number;
      /** Uuid of the last element */
      to?: string;
    };
    [key: string]: any;
  };
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  links?: {
    first?: string;
    prev?: string;
  };
  data?: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type?: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id?: Id;
    attributes?: {
      body?: string;
      authorName?: string;
      authorShopName?: string;
      authorImage?: string;
      country?: string;
    };
  }[];
}

export enum GetApiInternalV1TestimonialsParams1SortEnum {
  CreatedAt = 'createdAt',
  ValueCreatedAt = '-createdAt',
}

export interface GetApiInternalV1CategoriesData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  data?: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      name: string;
    };
    relationships?: {
      parent: {
        data: {
          type: 'categories';
          /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
          id: Id;
        };
      };
    };
  }[];
}

export enum PostApiInternalV1OperationsOpEnum {
  Add = 'add',
  Update = 'update',
}

/** Service Type used to distinguish what this item is for. */
export enum PostApiInternalV1OperationsServiceTypeEnum {
  Fulfillment = 'fulfillment',
  Ads = 'ads',
}

export interface PostApiInternalV1OperationsData {
  'atomic:results'?: {
    data?: {
      /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
      type: Type;
      /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
      id: Id;
      attributes?: object;
    };
    /** Non-standard meta-information that can not be represented as an attribute or relationship. */
    meta?: Meta;
  }[];
}

/** @example {"jsonapi":{"version":"1.0"},"data":[{"type":"segments","id":"1ed5ed69-3b3b-675a-9c3d-0242ac12000b","attributes":{"name":"country_FR"}},{"type":"segments","id":"1ed5ed69-4694-6070-a1c3-0242ac12000b","attributes":{"name":"core_country"}},{"type":"segments","id":"1ed5ed69-4bd6-688a-85f8-0242ac12000b","attributes":{"name":"retailer"}}]} */
export interface GetApiInternalV1SegmentsData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  data?: {
    type?: string;
    id?: string;
    attributes?: {
      name?: string;
    };
  }[];
}

export interface GetBrandPerformanceMetricsCurrentMonthParams {
  /**
   * One of the predefined period types
   * @default "currentMonth"
   */
  periodType?: PeriodTypeEnum;
}

/**
 * One of the predefined period types
 * @default "currentMonth"
 */
export enum PeriodTypeEnum {
  CurrentMonth = 'currentMonth',
  PreviousMonth = 'previousMonth',
  PreviousThreeMonths = 'previousThreeMonths',
}

export interface GetBrandPerformanceMetricsCurrentMonthData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
  };
}

/**
 * One of the predefined period types
 * @default "currentMonth"
 */
export enum GetBrandPerformanceMetricsCurrentMonthParams1PeriodTypeEnum {
  CurrentMonth = 'currentMonth',
  PreviousMonth = 'previousMonth',
  PreviousThreeMonths = 'previousThreeMonths',
}

export interface GetApiInternalV1BusinessEventBrandDiscountsParams {
  /** array of json encoded products ids */
  'filter[id]'?: string;
}

/** @example {"jsonapi":{"version":"1.0"},"data":[{"type":"business-event-brand-discounts","id":"31951","attributes":{"discount":10},"links":{"self":"https://ankorstore.localhost/api/internal/v1/business-event-brand-discounts/31951"}}]} */
export interface GetApiInternalV1BusinessEventBrandDiscountsData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  data?: {
    type?: string;
    id?: string;
    attributes?: {
      discount?: number;
    };
    links?: {
      self?: string;
    };
  }[];
}

export interface GetApiInternalV1ProductPricesParams {
  /** lists of product ids separated with a coma */
  'filter[id]'?: string;
}

/** @example {"jsonapi":{"version":"1.0"},"data":[{"type":"product-prices","id":"3231389","attributes":{"originalWholesalePrice":{"currency":"EUR","amount":1010},"wholesalePrice":{"currency":"EUR","amount":1010},"retailPrice":{"currency":"EUR","amount":2000},"discountRate":0}},{"type":"product-prices","id":"3231391","attributes":{"originalWholesalePrice":{"currency":"GBP","amount":674},"wholesalePrice":{"currency":"GBP","amount":674},"retailPrice":{"currency":"GBP","amount":899},"discountRate":0}},{"type":"product-prices","id":"3231397","attributes":{"originalWholesalePrice":{"currency":"GBP","amount":419},"wholesalePrice":{"currency":"GBP","amount":419},"retailPrice":{"currency":"GBP","amount":599},"discountRate":0}}]} */
export interface GetApiInternalV1ProductPricesData {
  jsonapi?: {
    version?: string;
  };
  data?: {
    type?: string;
    id?: string;
    attributes?: {
      originalWholesalePrice?: {
        currency?: string;
        amount?: number;
      };
      wholesalePrice?: {
        currency?: string;
        amount?: number;
      };
      retailPrice?: {
        currency?: string;
        amount?: number;
      };
      discountRate?: number;
    };
  }[];
}

/** @example {"jsonapi":{"version":"1.0"},"links":{"self":"https://www.ankorstore.com/api/internal/v1/product-types/6343"},"data":{"type":"product-type","id":"6343","attributes":{"label":"Dresses","assignable":false,"prohibited":false},"relationships":{"schema":{"links":{"related":"https://www.ankorstore.com/api/internal/v1/product-types/6343/schema"}}}}} */
export interface GetProductTypeData {
  /**
   * ProductTypeResource
   * A resource that describes a product type
   */
  data?: {
    /**
     * [The type of resource](https://jsonapi.org/format/#document-resource-object-identification)
     * @default "product-type"
     */
    type: string;
    /**
     * A unique identifier for the product type.
     * @format int64
     */
    id: string;
    attributes: {
      /** A human-readable label for the product type */
      label?: string;
      /** Whether a product can be assigned to this product type or not */
      assignable?: boolean;
      /** Whether this type of product can be sold on the website or not */
      prohibited?: boolean;
    };
    relationships?: {
      /** The product attribute schema */
      schema?: {
        /** Links to the endpoints that describe the attribute schema. */
        links?: {
          /** @format uri-reference */
          related?: string;
        };
      };
    };
  };
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

/** Describes the type of the attribute */
export enum GetProductTypeSchemaAttributeTypeEnum {
  SingleSelect = 'single-select',
  MultiSelect = 'multi-select',
}

/** Describes the requirement level for the attribute */
export enum GetProductTypeSchemaRequirementEnum {
  Mandatory = 'mandatory',
  Recommended = 'recommended',
  Optional = 'optional',
}

/** @example {"jsonapi":{"version":"1.0"},"links":{"related":"https://www.ankorstore.com/api/internal/v1/product-types/6431/schema"},"data":[{"type":"product-attribute","id":"age_group","attributes":{"attributeType":"single-select","label":"Age Group","inputTip":"Indicate the target consumer age group for which the product has been designed.","inputHint":"Select an age group","requirement":"mandatory","options":[{"value":"adult","label":"Adult"},{"value":"baby","label":"Baby"},{"value":"kid","label":"Kid"}]}},{"type":"product-attribute","id":"target_gender","attributes":{"attributeType":"single-select","label":"Target Gender","inputTip":"Indicate the target consumer gender for which the product has been designed.","inputHint":"Select a gender","requirement":"mandatory","options":[{"value":"female","label":"Female"},{"value":"male","label":"Male"},{"value":"unisex","label":"Unisex"}]}}]} */
export interface GetProductTypeSchemaData {
  data?: {
    /**
     * [The type of resource](https://jsonapi.org/format/#document-resource-object-identification)
     * @default "product-attribute"
     */
    type: string;
    /** A globally-unique identifier for the attribute. */
    id: string;
    attributes: {
      /** Describes the type of the attribute */
      attributeType?: GetProductTypeSchemaAttributeTypeEnum;
      /** A human-readable label for the product attribute */
      label?: string;
      inputTip?: string | null;
      inputHint?: string | null;
      /** Describes the requirement level for the attribute */
      requirement?: GetProductTypeSchemaRequirementEnum;
      options?: {
        value?: string;
        label?: string;
      }[];
    };
  }[];
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

/** @example {"jsonapi":{"version":"1.0"},"data":[{"type":"product-type-suggestion","id":"12345","attributes":{"name":"Pet Supplies","context":"","searchString":"Pet Supplies"}},{"type":"product-type-suggestion","id":"67890","attributes":{"name":"Bird Supplies","context":"Pet Supplies","searchString":"Bird Supplies Pet Supplies"}}]} */
export interface GetApiInternalV1ProductTypesAutocompleteData {
  jsonapi?: {
    version?: string;
  };
  data?: {
    type?: string;
    id?: string;
    attributes?: {
      name?: string;
      context?: string;
      searchString?: string;
    };
  }[];
}

/** @example {"jsonapi":{"version":"1.0"},"data":[{"type":"category","id":"12345","attributes":{"fullPath":"Pet Supplies > Bird Supplies > Bird Cages & Stands"}},{"type":"category","id":"54321","attributes":{"fullPath":"Stationary & Hobbies > Accessories > Bird Cages & Stands"}}]} */
export interface GetApiInternalV1ProductTypesCategoriesData {
  jsonapi?: {
    version?: string;
  };
  data?: {
    type?: string;
    id?: string;
    attributes?: {
      fullPath?: string;
    };
  }[];
}

/** @example {"jsonapi":{"version":"1.0"},"data":[{"type":"product-type","id":1,"attributes":{"name":"Pet Supplies","isSelectable":true,"children":[{"type":"product-type","id":2,"attributes":{"name":"Bird Supplies","isSelectable":true}},{"type":"product-type","id":3,"attributes":{"name":"Cat Supplies","isSelectable":true}}]}}]} */
export interface GetApiInternalV1ProductTypesTreeData {
  jsonapi?: {
    version?: string;
  };
  data?: {
    type?: string;
    id?: number;
    attributes?: {
      name?: string;
      isSelectable?: boolean;
      children?: any[];
    };
  }[];
}

export enum CreateLabelFormatEnum {
  ZPL = 'ZPL',
}

export enum CreateLabelEncodingEnum {
  Base64 = 'base64',
}

/** Format: AKS_{GEOGRAPHICAL-CLUSTER}_{LAST-MILE-CARRIER} */
export enum CreateLabelServiceEnum {
  AKS_PAR_COG = 'AKS_PAR_COG',
  AKS_PAR_AKS = 'AKS_PAR_AKS',
}

export interface CreateLabelData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    /** Label */
    attributes: {
      format: CreateLabelFormatEnum;
      encoding: CreateLabelEncodingEnum;
      data: string;
      containerReference: string;
    };
  };
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export interface ListHistoricalParcelDimensionsData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    /** HistoricalParcelDimension */
    attributes: {
      width: number;
      height: number;
      length: number;
    };
  }[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
}

export enum ListOrderShippingQuotesCollectionMethodEnum {
  Pickup = 'pickup',
  DropOff = 'drop-off',
}

export interface ListOrderShippingQuotesParams {
  /** Include shipping service in the payload */
  include?: IncludeEnum6;
  /**
   * Id of master order for which the quotes are requested
   * @format uuid
   */
  masterOrderUuid: string;
}

/** Include shipping service in the payload */
export enum IncludeEnum6 {
  ShippingService = 'shippingService',
}

export interface ListOrderShippingQuotesData {
  data: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    /** Quote */
    attributes: {
      /** Order master uuid linked to the quote */
      masterOrderUuid?: string;
      /** The provider of the quote, generally a carrier (ups, dhl_express ...) */
      provider?: string;
      /** Estimation of the cost for the brand */
      shippingCost?: {
        /** The amount of the shipping cost */
        amount?: number;
        /** The currency of the shipping cost */
        currency?: string;
      };
    };
    meta?: {
      /** The validation/business rule errors for the carrier */
      validationError?: {
        /** An application-specific error code, expressed as a string value. */
        code?: string;
        /** A human-readable explanation specific to this occurrence of the problem. */
        detail?: string;
        /** The HTTP status code applicable to this problem, expressed as a string value. */
        status?: string;
        /** Optional object pointing towards the problematic field */
        source?: {
          /** The field key */
          pointer?: string;
        };
        /** The HTTP status code description applicable to this problem */
        title?: string;
        /** Meta-information about the rules that failed and the limit */
        meta?: {
          rule?: string;
          options?: string[];
          [key: string]: any;
        };
      }[];
      [key: string]: any;
    };
    relationships?: {
      shippingService?: {
        /** References to other resource objects in a to-one (`relationship`). Relationships can be specified by including a member in a resource's links object. */
        data?: RelationshipToOne;
      };
    };
  }[];
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  included?: {
    /** [resource object type](https://jsonapi.org/format/#document-resource-object-identification) */
    type: Type;
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    /** ShippingService */
    attributes: {
      /** The carrier code of the service */
      carrierCode?: string;
      /** The carrier service code */
      serviceCode?: string;
      /** The commercial name of the service */
      serviceCommercialName?: string;
      /** The collection method for the carrier service */
      collectionMethod?: ListOrderShippingQuotesCollectionMethodEnum[];
      /** Price of the service */
      servicePrice?: {
        /** The amount of the service price */
        amount?: number;
        /** The currency of the service price */
        currency?: string;
      };
      /** Delivery estimation information for the service if provided by carrier */
      timeInTransit?: {
        /**
         * Estimation of delivery date by the carrier
         * @format date
         */
        estimatedDeliveryDate?: null | string;
        /**
         * Pickup date considered for the estimation
         * @format date
         */
        pickupDate?: null | string;
        /** Number of business days the shipment is in transit */
        businessDaysInTransit?: null | number;
      };
    };
  }[];
}

/** Include shipping service in the payload */
export enum ListOrderShippingQuotesParams1IncludeEnum {
  ShippingService = 'shippingService',
}

/** @example {"loyalty":{"available_discounts":[5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],"current_discount":10,"network":null}} */
export interface GetApiInternalV1LoyaltyData {
  data?: {
    current_discount?: number;
    available_discounts?: any[];
    network?: any[];
  }[];
}

export type UpdateApiInternalV1LoyaltyData = object;

export type DeleteApiInternalV1LoyaltyData = object;

/** Where to open the linked URL */
export enum GetNavMenuItemsTargetEnum {
  Self = 'self',
  Blank = 'blank',
}

export interface GetNavMenuItemsData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  data?: (
    | ({
        /**
         * A globally-unique identifier for the navigation element
         * @format uuid
         */
        id: string;
        type: string;
        attributes?: object;
        meta?: {
          /**
           * Depth of the navigation element in the hierarchy (starting at 0)
           * @min 0
           */
          depth: number;
          /**
           * A decimal value used to order elements that are on the same level in the navigation tree (starting at 0)
           * @min 0
           */
          position: number;
          [key: string]: any;
        };
      } & {
        type?: 'nav-item';
        attributes?: {
          /** The label of the link */
          label: string;
          /**
           * The URL to which the link points to
           * @format uri-reference
           */
          url?: string;
          /** Where to open the linked URL */
          target?: GetNavMenuItemsTargetEnum;
          /** Whether or not the URL is external to the website */
          external?: boolean;
          /**
           * Exclude the link from the navigation menu
           * @default false
           */
          exclude?: boolean;
          /** The tracking event to be sent when the link is clicked */
          'tracking-event'?: string;
        } & {
          /** Secondary navigation links */
          related?: {
            self?: any;
            'newest-brands'?: any;
            'newest-products'?: any;
            [key: string]: any;
          };
        };
      })
    | {
        type?: 'nav-item-group';
        attributes?: {
          /** Title of the menu item group */
          title?: string;
          /** The list of menu items in the group */
          items?: any[];
        };
      }
  )[];
}

/** @example {"loyalty":{"network":null}} */
export interface GetApiInternalV1LoyaltyRetailerData {
  data?: {
    network?: any[];
  }[];
}

export interface GetApiInternalV1AnkorstorePlusPerksBrandData {
  data?: {
    title?: string;
    description?: string;
    perks?: any[];
  }[];
}

export interface GetApiInternalV1AnkorstorePlusPerksProductData {
  data?: {
    title?: string;
    description?: string;
    perks?: any[];
  }[];
}

export type BrandNetworkContactDeleteData = any;

export interface BrandNetworkContactDeleteListData {
  data?: {
    deleted?: {
      /**
       * The unique identifier for the deleted contact
       * @format uuid
       */
      id?: string;
    }[];
    notFound?: {
      /**
       * Unique identifier of the resource not found when processing
       * @format uuid
       */
      id?: string;
    }[];
    error?: {
      /**
       * Unique identifier of the resource failed to process
       * @format uuid
       */
      id?: string;
    }[];
  };
}

export enum ExportContactsTypeEnum {
  ContactExport = 'contact-export',
}

/**
 * The type of filter.
 *
 * This filter returns results that contain a specific attribute value.<br/>
 * Available on **text**, **number** or **date** attributes and supports arrays.
 */
export enum ExportContactsTypeEnum1 {
  Value = 'value',
}

/**
 * The type of filter.
 *
 * This filter returns results over a range of dates or numbers.<br/>
 * Available on **number** or **date** attributes.
 */
export enum ExportContactsTypeEnum2 {
  Range = 'range',
}

/** The type of filter. */
export enum ExportContactsTypeEnum3 {
  Boolean = 'boolean',
}

/**
 * The content of the CSV file to download.
 * @format binary
 */
export type ExportContactsData = File;

/**
 * Type of facet.
 *
 * A value facet provides the counts of each value for an attribute.
 * This type of facet is only available on **text**, **number** and **date** attributes.
 */
export enum SearchContactsTypeEnum {
  Value = 'value',
}

/**
 * Type of facet.
 *
 * A range facet returns counts of documents within the provided ranges.
 * This type of facet is only available on **number** and **date** attributes.
 */
export enum SearchContactsTypeEnum1 {
  Range = 'range',
}

/**
 * Type of facet.
 *
 * Stats (also called *aggregation* or *analytic*) facets compute stats over
 * numeric values extracted from the aggregated results. This type of facet
 * is only available on **number** attributes.
 */
export enum SearchContactsTypeEnum2 {
  Stats = 'stats',
}

export enum SearchContactsTypeEnum3 {
  ContactDocument = 'contact-document',
}

/** Status of the relationship with this contact. */
export enum SearchContactsStatusEnum {
  Prospect = 'prospect',
  Customer = 'customer',
}

/** Origin of the contact. */
export enum SearchContactsOriginEnum {
  Ankorstore = 'ankorstore',
  Imported = 'imported',
}

/** Who's the sender of the last sent message. */
export enum SearchContactsLastMessageByEnum {
  Retailer = 'retailer',
  Brand = 'brand',
}

/**
 * Indicates the level of consent this contact has given
 * to receive email marketing campaigns.
 *
 * - **Subscribed** — A subscribed contact, or subscriber,
 * is someone who has opted in to receive marketing messages.
 *
 * - **Unsubscribed** — An unsubscribed contact is someone who
 * previously received marketing messages but has opted out.
 *
 * - **Suppressed** — A suppressed contact is someone who is
 * on the suppression list and thus is excluded from receiving
 * marketing messages. Contacts may be added to the suppression
 * list for various reasons, including if they have complained
 * about receiving emails (they marked emails as spam)
 * or if their email address bounced or is invalid.
 */
export enum SearchContactsSubscriptionStatusEnum {
  Subscribed = 'subscribed',
  Unsubscribed = 'unsubscribed',
  Suppressed = 'suppressed',
}

export enum SearchContactsTypeEnum4 {
  SearchParams = 'search-params',
}

export enum SearchContactsSortEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export type SearchContactsData = {
  /** Array of results matching the search. */
  data: any[];
  meta: {
    page?: {
      /**
       * Number of results per page.
       * @min 1
       */
      size?: number;
      /**
       * Index of the current search result page.
       * @min 1
       */
      current?: number;
      /**
       * Total number of search result pages.
       * @min 0
       */
      totalPages?: number;
      /**
       * Total number of results.
       * @min 0
       */
      totalResults?: number;
    };
    /**
     * Contains the number of matching contacts distributed among
     * the values of a given facet. Each facet is represented as
     * an object.
     */
    facets?: Record<
      string,
      | {
          /**
           * Type of facet.
           *
           * A value facet provides the counts of each value for an attribute.
           * This type of facet is only available on **text**, **number** and **date** attributes.
           */
          type: SearchContactsTypeEnum;
          data: {
            /** A human-readable name for the value. */
            name?: string;
            /** The value of the attribute. */
            value: string | number;
            /**
             * The number of results for this value of the attribute.
             * @min 1
             */
            count: number;
          }[];
        }
      | {
          /**
           * Type of facet.
           *
           * A range facet returns counts of documents within the provided ranges.
           * This type of facet is only available on **number** and **date** attributes.
           */
          type: SearchContactsTypeEnum1;
          data: {
            /** Name given to the range. */
            name?: string;
            /**
             * Number of results within the range.
             * @min 1
             */
            count: number;
            /**
             * Inclusive lower bound of the range.
             * @format date-time
             */
            from?: number | string;
            /**
             * Exclusive upper bound of the range.
             * @format date-time
             */
            to?: number | string;
          }[];
        }
      | {
          /**
           * Type of facet.
           *
           * Stats (also called *aggregation* or *analytic*) facets compute stats over
           * numeric values extracted from the aggregated results. This type of facet
           * is only available on **number** attributes.
           */
          type: SearchContactsTypeEnum2;
          /** The lowest numerical value across all results. */
          min: number;
          /** The highest numerical value across all results. */
          max: number;
        }
    >;
  };
} & {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  data?: {
    /**
     * A globally-unique identifier for the contact.
     * @format uuid
     * @example "1ef077c4-f8a1-6bc8-9ac6-4e29ff3d1748"
     */
    id: string;
    type: SearchContactsTypeEnum3;
    attributes: {
      /** Relationship details */
      relation: {
        /**
         * The date and time when the contact was added to the brand's network.
         * @format date-time
         */
        addedAt: string;
        /**
         * The date and time when the contact was invited to the brand's network (i.e.
         * when a referral invitation was sent to the contact's email address).
         * @format date-time
         */
        invitedAt?: null | string;
        /**
         * The date and time when the contact became affiliated with the brand.
         * Affiliation is confirmed when a retailer places a first order with the brand.
         * @format date-time
         */
        affiliatedAt?: null | string;
        /** Status of the relationship with this contact. */
        status: SearchContactsStatusEnum;
        /** Origin of the contact. */
        origin: SearchContactsOriginEnum;
      };
      /** Contact details */
      contact: {
        /** The contact's first name. */
        firstname?: string;
        /** The contact's last name. */
        lastname?: string;
        /**
         * The contact's primary email address.
         * @format email
         */
        email?: string;
        /** The contact's primary phone number. */
        phone?: string;
        /**
         * The contact's preferred language for communications
         * (represented as a 2-letter language code conforming to
         * [IETF language tag](https://en.wikipedia.org/wiki/IETF_language_tag)).
         */
        language?: string;
        /** The contact's city. */
        city?: string;
        /** The contact's zip code. */
        zip?: string;
        /** The contact's country code. */
        country?: string;
      };
      /** Retailer details */
      retailer?: {
        /**
         * The globally-unique identifier of the retailer.
         * @format uuid
         */
        uuid?: string;
        /** The shop name. */
        name?: string;
        /**
         * The type of shop.
         * @example "pet_store"
         */
        shopType?: null | string;
        /**
         * The date and time when the retailer signed up for an account.
         * @format date-time
         */
        createdAt?: string;
        /** Contact details */
        contact?: {
          /** The retailer's first name. */
          firstname?: string;
          /** The retailer's last name. */
          lastname?: string;
          /**
           * The retailer's primary email address.
           * @format email
           */
          email?: string;
          /** The retailer's primary phone number. */
          phone?: string;
          /** The retailer's city. */
          city?: string;
          /** The retailer's zip code. */
          zip?: string;
          /** The retailer's country code. */
          country?: string;
        };
        /** Status of the Ankorstore+ subscription. */
        aksStatus?: {
          /** Whether this retailer has an active subscription to Ankorstore+ */
          active?: boolean;
          /** Whether this retailer is eligible for the Ankorstore+ offer. */
          eligible?: boolean;
        };
      };
      /** Commercial terms for this contact */
      commercialTerms?: {
        /**
         * The service fee invoiced for this contact's purchases.
         * @format float
         * @min 0
         */
        serviceFees?: number;
        /**
         * The personalised discount for this contact.
         * @format float
         * @min 0
         */
        brandDiscount?: number;
        /**
         * The discount the contact benefits from as part of the repeat offer.
         * @format float
         * @min 0
         */
        repeatOffer?: number;
      };
      /** Order activity */
      ordering?: {
        /**
         * The date and time when last order was placed.
         * @format date-time
         */
        lastOrderedAt?: null | string;
        /** The total amount of the last placed order. */
        lastOrderedAmount?: Amount;
        /**
         * The total number of completed orders for this contact.
         * @min 0
         */
        totalCompletedOrders?: number;
        /** The total amount of completed orders for this contact. */
        totalSpent?: Amount;
        /** Whether this contact is eligible for the referral offer. */
        liftEligible?: boolean;
      };
      /** Prepared order activity */
      preparedOrders?: {
        /**
         * Total number of prepared orders for this contact.
         * @min 0
         */
        number: number;
      };
      /** Shopping activity */
      cart?: {
        /** Order total for the shopping cart of this retailer. */
        amountInCart: Amount;
        /**
         * The date and time when the shopping cart was last updated.
         * @format date-time
         */
        cartUpdatedAt: string;
      };
      /** Communications with this contact */
      communication?: {
        /** Direct in-app messaging */
        messaging?: {
          /**
           * The date and time when the last message was sent.
           * @format date-time
           */
          lastContactedAt?: string;
          /** Who's the sender of the last sent message. */
          lastMessageBy?: SearchContactsLastMessageByEnum;
        };
        /** Emailing activity */
        emailing?: {
          /**
           * Indicates the level of consent this contact has given
           * to receive email marketing campaigns.
           *
           * - **Subscribed** — A subscribed contact, or subscriber,
           * is someone who has opted in to receive marketing messages.
           *
           * - **Unsubscribed** — An unsubscribed contact is someone who
           * previously received marketing messages but has opted out.
           *
           * - **Suppressed** — A suppressed contact is someone who is
           * on the suppression list and thus is excluded from receiving
           * marketing messages. Contacts may be added to the suppression
           * list for various reasons, including if they have complained
           * about receiving emails (they marked emails as spam)
           * or if their email address bounced or is invalid.
           */
          subscriptionStatus?: SearchContactsSubscriptionStatusEnum;
          /**
           * The date and time when the last email was sent.
           * @format date-time
           */
          lastContactedAt?: string;
        };
      };
    };
  }[];
};

export type MultipleSearchContactsData = {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  /** An array of search results, in the same order as the search queries. */
  data?: {
    data?: any[];
  }[];
};

export enum GetSearchFiltersTypeEnum {
  Widget = 'widget',
}

/**
 * The `Range` widget allows a user to select a numeric range
 * using a minimum and maximum input.
 */
export enum GetSearchFiltersTypeEnum1 {
  RangeWidget = 'range-widget',
}

export enum GetSearchFiltersTypeEnum2 {
  Widget = 'widget',
}

/**
 * The `Toggle` widget provides an on/off filtering feature
 * based on an attribute value.
 */
export enum GetSearchFiltersTypeEnum3 {
  ToggleWidget = 'toggle-widget',
}

export enum GetSearchFiltersTypeEnum4 {
  Widget = 'widget',
}

/**
 * The `Dropdown` widget allows a user to select values upon which
 * to filter inside a dropdown element.
 */
export enum GetSearchFiltersTypeEnum5 {
  DropdownWidget = 'dropdown-widget',
}

export enum GetSearchFiltersTypeEnum6 {
  Widget = 'widget',
}

/**
 * The `List` widget displays a menu that lets users choose one
 * or multiple values for a specific attribute.
 */
export enum GetSearchFiltersTypeEnum7 {
  ListWidget = 'list-widget',
}

export interface GetSearchFiltersData {
  /** List of available filters. */
  data: (
    | {
        type: GetSearchFiltersTypeEnum;
        attributes: {
          /**
           * The `Range` widget allows a user to select a numeric range
           * using a minimum and maximum input.
           */
          type?: GetSearchFiltersTypeEnum1;
          /** The name of the attribute upon which to apply the filter. */
          attribute: string;
          minLabel?: string;
          maxLabel?: string;
          separatorLabel?: string;
          icon?: string;
          /** WidgetValue */
          values?: {
            value: string;
            label: string;
            icon?: string;
          }[];
        };
      }
    | {
        type: GetSearchFiltersTypeEnum2;
        attributes: {
          /**
           * The `Toggle` widget provides an on/off filtering feature
           * based on an attribute value.
           */
          type?: GetSearchFiltersTypeEnum3;
          /** The name of the attribute upon which to apply the filter. */
          attribute: string;
          label?: string;
          icon?: string;
          values?: any;
        };
      }
    | {
        type: GetSearchFiltersTypeEnum4;
        attributes: {
          /**
           * The `Dropdown` widget allows a user to select values upon which
           * to filter inside a dropdown element.
           */
          type?: GetSearchFiltersTypeEnum5;
          /** The name of the attribute upon which to apply the filter. */
          attribute: string;
          /**
           * Allow the multiple selection.
           * @default true
           */
          multiple?: boolean;
          label?: string;
          icon?: string;
          placeholder?: string;
          values?: any;
        };
      }
    | {
        type: GetSearchFiltersTypeEnum6;
        attributes: {
          /**
           * The `List` widget displays a menu that lets users choose one
           * or multiple values for a specific attribute.
           */
          type?: GetSearchFiltersTypeEnum7;
          /** The name of the attribute upon which to apply the filter. */
          attribute: string;
          /**
           * Allow the multiple selection.
           * @default true
           */
          multiple?: boolean;
          label?: string;
          icon?: string;
          values?: any;
        };
      }
  )[];
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

export enum ApplyPersonalisedDiscountTypeEnum {
  PersonalisedDiscountBulkRequest = 'personalised-discount-bulk-request',
}

export interface ApplyPersonalisedDiscountData {
  meta?: {
    /**
     * Number of contacts who were applied the personalised discount.
     * @min 0
     */
    affectedContacts?: number;
    [key: string]: any;
  };
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
}

export interface ListPromocodeDiscoveryRelationshipsData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  meta?: {
    promocode?: {
      code?: string;
      /** @format datetime */
      expired_at?: string;
      percentage?: string;
    };
    [key: string]: any;
  };
  data: {
    type?: 'promocode-discovery-relationships';
    id?: string;
    attributes?: {
      brand_id: number;
      brand_link: string;
      brand_name: string;
      brand_logo: string;
      brand_image: string;
      brand_product_count: string;
      already_used: boolean;
    };
  }[];
}

export interface DisplayDiscoveryBrandAndPromocodeForFirstCheckoutData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  meta?: {
    promocode?: {
      title: string;
      description: string;
      action_type: string;
      action_label: string;
      feedback: string;
    };
    [key: string]: any;
  };
  data: {
    type?: 'promocode-first-checkout';
    id?: string;
    attributes?: {
      brand_id: number;
      brand_link: string;
      brand_name: string;
      brand_logo: string;
      brand_image: string;
      brand_product_count: string;
    };
  }[];
}

export enum GetConnectedHomepageConfigurationSegmentSlugEnum {
  AksplusEligibleNonMembers = 'aksplus-eligible-non-members',
  AksplusEligibleMembers = 'aksplus-eligible-members',
  NewRetailers = 'new-retailers',
  Default = 'default',
}

export interface GetConnectedHomepageConfigurationData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: {
    type: 'connected-homepage-configuration';
    id: string;
    attributes: {
      segment_slug: GetConnectedHomepageConfigurationSegmentSlugEnum;
      components: {
        name: string;
        parameters?: object[];
      }[];
    };
  } | null;
}

export interface GetConnectedHomepageRecommendationsData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  meta: {
    type?: string;
    [key: string]: any;
  };
  data: {
    type?: 'connected-homepage-recommendations';
    id?: string;
    attributes?: {
      brand_id: number;
      brand_link: string;
      brand_name: string;
      brand_logo: string;
      brand_images: {
        src?: string;
      }[];
      brand_is_new: boolean;
      brand_discount: number;
    };
  }[];
}

export interface GetHighlightedBrandParams {
  brandId: number;
  carouselSelectionType: CarouselTypeEnum;
}

export interface GetHighlightedBrandData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: {
    id: string;
    type: string;
    attributes: BrandCardAttributes;
  } | null;
  included?: ProductCardResource[];
}

export interface GetBrandCardListParams {
  carouselSelectionType: CarouselTypeEnum;
}

export interface GetBrandCardListData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: BrandCardResource[];
}

export interface GetProductCardListParams {
  brandId: number;
  carouselSelectionType: CarouselTypeEnum;
}

export interface GetProductCardListData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: ProductCardResource[];
}

export enum GetCarouselCarouselTypeEnum {
  Brands = 'brands',
  Products = 'products',
  Collections = 'collections',
}

export interface GetCarouselParams {
  /** Carousel uuid */
  carouselUuid: string;
}

export interface GetCarouselData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: {
    id: string;
    type: string;
    attributes: {
      title: string | null;
      subtitle: string | null;
      cta_label: string | null;
      cta_link: string | null;
      carousel_type: GetCarouselCarouselTypeEnum;
    };
  };
  included?: (BrandCardResource | ProductCardResource | CollectionCardResource)[];
}

export interface GetUnconnectedFooterData {
  /** An object describing the server's implementation */
  jsonapi?: Jsonapi;
  data?: {
    type: string;
    id: string;
    attributes: {
      branding?: {
        title?: string;
        elements?: {
          url?: string;
          link?: string;
          alt?: string;
          title?: string;
        }[];
      };
      links?: {
        title?: string;
        elements?: {
          title?: string;
          url?: string;
        }[];
      }[];
      payments?: {
        svg?: string;
      }[];
      legals?: {
        title?: string;
        url?: string;
        javascript?: string;
      }[];
      socials?: {
        link?: string;
        icon?: string;
        svg?: string;
      }[];
      copyright?: string;
    };
  };
}

export interface GetBrandListData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  meta: {
    threshold_amount: string;
    total_unlocked_brands: number;
    total_locked_brands: number;
    total_brands: number;
    locked_brands_cta_link: string | null;
    unlocked_brands_cta_link: string | null;
    [key: string]: any;
  };
  data: {
    type: 'brand-list';
    id: string;
    attributes: {
      brand_id: number;
      brand_link: string;
      brand_name: string;
      brand_logo: string;
      brand_images: {
        src: string;
      }[];
      repeat_offer: {
        current: {
          /** @format datetime */
          start_date: string | null;
          /** @format datetime */
          end_date: string;
          percentage: number;
          unlocked: boolean;
        };
        next?: {
          /** @format datetime */
          start_date: string;
          /** @format datetime */
          end_date: string;
          percentage: number;
          unlocked: boolean;
        };
        progress_bar: {
          title: string | null;
          amount_spent: string;
          progress: number;
          subtitle: string;
          eligible_for_next: boolean;
        };
      };
    };
  }[];
}

export interface GetBrandDiscountData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  /** LoyaltyOfferBrandDiscountResource */
  data: {
    type?: 'brand-discount';
    id?: string;
    attributes?: {
      brand_id: number;
      discount: number;
      /** @format date-time */
      start_date: string;
      /** @format date-time */
      created_at: string;
    };
  };
}

export enum RenderCatalogAttributesAndFacetsContextEnum {
  Default = 'default',
  Brand = 'brand',
  CategoryBrands = 'category_brands',
  CategoryProducts = 'category_products',
  CollectionBrands = 'collection_brands',
  CollectionProducts = 'collection_products',
  NewBrands = 'new_brands',
  NewProducts = 'new_products',
  PreorderBrands = 'preorder_brands',
  PreorderProducts = 'preorder_products',
  Search = 'search',
  Wholesale = 'wholesale',
}

export interface RenderCatalogAttributesAndFacetsData {
  data: {
    slug?: string;
    name?: string;
    type?: 'catalog_facet';
    catalog_facet?: {
      message?: string;
      tracking_name?: string;
      is_quick_filter?: boolean;
      searchable?: boolean;
      placeholder?: string;
      facet_type?: string;
      badge_type?: string | null;
      is_open?: boolean;
    };
    /** Custom value-label mapping */
    choices?: Record<string, any>;
  }[];
}

export interface GetCategoryAttributesParams {
  lang?: string;
  /** Category ID */
  categoryId: number;
}

export type GetCategoryAttributesData = any[];

export interface GetProductAttributesParams {
  lang?: string;
  /** Product ID */
  productId: number;
}

export type GetProductAttributesData = {
  slug?: string;
  name?: string;
  description?: string;
  type?: string;
  unit?: string | null;
  isMultiValued?: boolean;
  isFilterable?: boolean;
  hasChoices?: boolean;
  /** Custom value-label mapping */
  choices?: Record<string, any>;
  /** Children attributes are not documented to prevent circular reference */
  attributes?: object[];
}[];

export enum GetBannerAksPlusStatusEnum {
  Member = 'member',
  Eligible = 'eligible',
  NotEligible = 'not_eligible',
}

export enum GetBannerCarouselTypeEnum {
  Brands = 'brands',
  Products = 'products',
  Collections = 'collections',
}

export interface GetBannerParams {
  /** Banner slug */
  'banner-slug': string;
}

export interface GetBannerData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  meta?: {
    aks_plus_status: GetBannerAksPlusStatusEnum;
    [key: string]: any;
  };
  data: {
    id: string;
    type: string;
    attributes: {
      offer?: {
        /** @format date-time */
        end_date?: string;
      };
      banner?: {
        title?: string | null;
        subtitle?: string | null;
        cta_label?: string | null;
        cta_link?: string | null;
      };
      carousel_type?: GetBannerCarouselTypeEnum;
    };
    relationships?: {
      brands?: {
        data: {
          id: string;
          type: string;
        }[];
      };
    };
  };
  included?: (BrandCardResource | ProductCardResource | CollectionCardResource)[];
}

/**
 * The contents of a CSV file.
 * @format binary
 */
export type ExportDraftsData = File;

export interface ListBrandPayoutsParams {
  /**
   * Page number, starting at 1
   * @default 1
   */
  'page[offset]'?: number;
  /**
   * Maximum number of elements per page
   * @default 20
   */
  'page[limit]'?: number;
}

export interface ListBrandPayoutsData {
  /** An object describing the server's implementation */
  jsonapi: Jsonapi;
  data: {
    type: 'payouts';
    /** [resource object identifier (uuid)](https://jsonapi.org/format/#document-resource-object-identification) */
    id: Id;
    attributes: {
      internalId?: number | null;
      /** Amount */
      amount: {
        amount: number;
        currency: string;
      };
      status: string;
      pspId?: string | null;
      /** @format datetime */
      createdAt?: string | null;
      /** @format datetime */
      arrivedAt?: string | null;
      transfers: {
        /** @format uuid */
        uuid: string;
        amount: {
          amount: number;
          currency: string;
        };
        pspId?: string | null;
        type: string;
        /** Source */
        source: {
          /** @format uuid */
          uuid: string;
          /** @format uuid */
          masterOrderUuid?: string;
          internalId: number | null;
          reference?: string;
          customerStoreName?: string;
          type?: string;
        };
      }[];
    };
  }[];
  meta: {
    page: {
      currentPage?: number;
      from?: number;
      lastPage?: number;
      perPage?: number;
      to?: number;
      total?: number;
    };
    [key: string]: any;
  };
}
