<template>
  <div>
    <div class="field-icon">
      <LoaderIcon
        v-if="loading"
        small
      />
    </div>
    <AkInput
      v-if="isRevamp"
      v-model="promoCode"
      :placeholder="$t('Promocode')"
      :has-error="!!error"
      :errors="error"
      @focusout="searchPromoCode()"
      @input="onInput()"
    />
    <div
      v-else
      class="form-label-group required-field ds-relative"
    >
      <input
        v-model="promoCode"
        type="text"
        class="form-control hide-placeholder-on-focus"
        :placeholder="$t('Promocode')"
        @focusout="searchPromoCode()"
        @input="onInput()"
      >
      <span
        v-if="error"
        class="error-message"
      >
        {{ error }}
      </span>
      <label class="ds-pointer-events-none">{{ $t('Promocode') }} {{ $t('(optional)') }}</label>
    </div>
    <div
      v-if="success"
      class="ds-mt-2"
    >
      <AkParagraph size="xs">
        <i18n-t keypath="{amount} off {brandName} successfully applied to your next order">
          <template #amount>
            {{ $root.formatPrice(amount) }}
          </template>
          <template #brandName>
            {{ brandName }}
          </template>
        </i18n-t>
      </AkParagraph>
    </div>
  </div>
</template>
<script lang="ts">
import { validatePromoCode } from '@monolith/legacy/services/api/promocode';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import LoaderIcon from '@monolith/legacy/components/loader-icon.vue';
import { Amount } from '@core/types/amount';

export default defineComponent({
  name: 'PromocodeInput',
  components: { LoaderIcon },
  props: {
    initialValue: {
      type: String,
      default: '',
    },
    isRevamp: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['input'],
  data(): {
    promoCode: string;
    error: string;
    loading: boolean;
    success: boolean;
    brandName: string;
    amount: Amount;
  } {
    return {
      promoCode: null,
      error: null,
      loading: false,
      success: null,
      brandName: null,
      amount: null,
    };
  },
  computed: {
    ...mapGetters('signUp', ['liftPromoCode']),
  },
  mounted() {
    this.promoCode = this.liftPromoCode;
    if (this.initialValue?.length) {
      this.searchPromoCode();
    }
  },
  methods: {
    onInput() {
      this.$emit('input', {
        code: this.promoCode,
        brand: this.brandName,
        amount: this.amount,
      });
      if (!this.promoCode?.length) {
        this.error = null;
      }
    },
    async searchPromoCode() {
      if (this.promoCode?.length) {
        this.loading = true;
        const response = await validatePromoCode(this.promoCode);
        if (response?.valid) {
          this.$emit('input', {
            code: this.promoCode,
            ...response,
          });
          this.error = null;
          this.success = true;
          this.brandName = response.brand.name;
          this.amount = response.promocode.amount;
        } else {
          this.error = this.$t('Invalid code');
          this.success = false;
        }
        this.loading = false;
        return response;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';
.field-icon {
  @apply ds-absolute ds-top-2 ds-right-2 ds-m-0;
}
</style>
