<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Card',
});
</script>

<template>
  <article
    :class="[
      'ds-flex ds-flex-col ds-h-full',
      'ds-border-neutral-300 ds-border ds-border-solid ds-rounded-md ds-overflow-hidden ds-shadow-sm',
    ]"
  >
    <section class="ds-relative">
      <slot name="topContent" />
      <menu class="ds-contents">
        <slot name="middleActions" />
      </menu>
    </section>
    <section class="ds-flex-1">
      <slot name="bottomContent" />
    </section>
    <menu :class="['ds-absolute ds-top-0 ds-left-0 ds-right-0', 'ds-flex ds-justify-between ds-items-center', 'ds-p-4']">
      <div class="card__topActions">
        <slot name="topLeftActions" />
      </div>
      <div class="card__topActions">
        <slot name="topRightActions" />
      </div>
    </menu>
    <menu>
      <slot name="bottomActions" />
    </menu>
  </article>
</template>

<style lang="scss" scoped>
@import '@css/vue-import';

.card {
  &__topActions {
    @apply ds-flex ds-gap-x-2;
    z-index: $z-index-catalog-card-items;
  }
}
</style>
