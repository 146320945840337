export enum OrderStatus {
  AnkorConfirmed = 'ankor_confirmed',
  BrandConfirmed = 'brand_confirmed',
  BrandPaid = 'brand_paid',
  FulfillmentRequested = 'fulfillment_requested',
  ShippingLabeldGenerated = 'shipping_labels_generated',
  Shipped = 'shipped',
  Received = 'received',
  ReceptionRefused = 'reception_refused',
  Invoiced = 'invoiced',
  Cancelled = 'cancelled',
  Created = 'created',
  WaitingShipping = 'waiting_shipping',
  WaitingShippingReconciliation = 'waiting_shipping_reconciliation',
  Delivered = 'DELIVERED',
}
