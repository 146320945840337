import type { AxiosInstance, Method, AxiosError } from 'axios';
import type { Store } from 'vuex';
import { isAxiosError } from 'axios';
import { getGlobalConfig } from '@monolith/legacy/services/global-config';
import { getInjectedContent } from '@monolith/legacy/services/injected-content';
import { GlobalMetaTagName } from '@monolith/legacy/services/initial-state';

type ConfigureHttpOptions = {
  reloadOn401?: boolean;
};

export const configureHttp = (http: AxiosInstance, store: Store<unknown>, { reloadOn401 = true }: ConfigureHttpOptions = {}) => {
  http.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
  configureProgressBar(http, store, { reloadOn401 });
  configureCsrf(http);
};

const configureProgressBar = (http: AxiosInstance, store: Store<unknown>, options: ConfigureHttpOptions) => {
  // Handling progress bar and the fact that for some api call we don't want to progress bar to pop
  // if you don't want the progress bar add noProgressBar: true to your http call in your get/post/patch/delete...
  // example:
  //       return window.http
  //         .post(`/api/checkout/promocodes`, {code}, {'noProgressBar': true});
  globalThis.httpApiCount = 0;
  http.interceptors.request.use((config) => {
    if (!config.noProgressBar) {
      if (!globalThis.httpApiCount && store) {
        store.commit('SET_PROGRESS_BAR_STATE', 'loading');
      }

      globalThis.httpApiCount++;
    }

    if (config.apiCartCall) {
      if (store) {
        store.commit('cart/CART_API_CART_CALL_INCREMENT');
      }
    }

    return config;
  });
  http.interceptors.response.use(
    (response) => {
      if (!response.config.noProgressBar) {
        globalThis.httpApiCount--;
        if (!globalThis.httpApiCount && store) {
          store.commit('SET_PROGRESS_BAR_STATE', 'ending');
        }
      }

      if (response?.config?.apiCartCall) {
        if (store) {
          store.commit('cart/CART_API_CART_CALL_DECREMENT');
        }
      }

      return response;
    },
    (error) => {
      if (options.reloadOn401 && error.response?.status === 401) {
        return location.reload();
      }

      if (error?.response?.config?.noProgressBar !== true) {
        globalThis.httpApiCount--;
        if (!globalThis.httpApiCount && store) {
          store.commit('SET_PROGRESS_BAR_STATE', 'ending');
        }
      }

      if (error?.response?.config?.apiCartCall) {
        if (store) {
          store.commit('cart/CART_API_CART_CALL_DECREMENT');
        }
      }

      return Promise.reject(error);
    }
  );
  // END OF Handling progress bar
};

const configureCsrf = (http: AxiosInstance) => {
  const AKS_CSRF_HEADER = 'x-aks-csrf';
  const CSRF_HEADER = 'X-CSRF-TOKEN';
  const aksCsrfLocalStorageKey = 'aks-csrf';

  const refreshToken = async () => {
    const {
      data: { csrf },
    } = await http.get('/auth/csrf');

    localStorage.setItem(aksCsrfLocalStorageKey, csrf);
    http.defaults.headers[CSRF_HEADER] = csrf;

    return csrf;
  };

  // CSRF interceptor
  if (getGlobalConfig().aegis_enabled) {
    const methodsToExcludeCSRFCheck: Method[] = ['get', 'head', 'options'];

    const initialCsrf = getInjectedContent<string>(GlobalMetaTagName.CSRFToken);

    localStorage.setItem(aksCsrfLocalStorageKey, initialCsrf);

    http.interceptors.request.use(async function (config) {
      if (!methodsToExcludeCSRFCheck.includes(config.method as Method)) {
        let csrfToken = localStorage.getItem(aksCsrfLocalStorageKey);
        if (!csrfToken) {
          csrfToken = await refreshToken();
        }

        config.headers[AKS_CSRF_HEADER] = csrfToken;
        config.headers[CSRF_HEADER] = csrfToken;
      }

      return config;
    });

    const MAX_RETRY_COUNT = 1;

    http.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if (!error.config) {
          return Promise.reject(error);
        }

        error.config.retryCount = error.config.retryCount ?? 0;

        if (
          error.response?.status === 401 &&
          !methodsToExcludeCSRFCheck.includes(error.config.method as Method) &&
          error.config.retryCount < MAX_RETRY_COUNT
        ) {
          // Access token has expired, refresh it
          const newAccessToken = await refreshToken();

          // Update the request headers with the new access token
          error.config.headers[AKS_CSRF_HEADER] = newAccessToken;
          error.config.headers[CSRF_HEADER] = newAccessToken;

          error.config.retryCount++;

          try {
            // Retry the original request
            return await http(error.config);
          } catch (retryError: unknown) {
            if (isAxiosError(retryError) && retryError.response.status === 401) {
              // If the retry request also fails with 401, redirect to login
              globalThis.location.href = `/login?redirect_uri=${globalThis.location.pathname}${globalThis.location.search}${globalThis.location.hash}`;
            }

            return Promise.reject(retryError);
          }
        }

        return Promise.reject(error);
      }
    );

    http.interceptors.response.use(function (response) {
      if (response.headers?.[AKS_CSRF_HEADER]) {
        localStorage.setItem(aksCsrfLocalStorageKey, response.headers[AKS_CSRF_HEADER]);
      }
      return response;
    });
  }
  /**
   * Next we will register the CSRF Token as a common header with Axios so that
   * all outgoing HTTP requests automatically have it attached. This is just
   * a simple convenience so we don't have to attach every token manually.
   */
  const token = getInjectedContent<string>(GlobalMetaTagName.CSRFToken);

  if (token) {
    http.defaults.headers[CSRF_HEADER] = token;
  }
  // END OF CSRF interceptor
};
