import Campaign from '@monolith/legacy/types/campaign';

import { Segment } from '@monolith/legacy/types/segment';
import { captureException } from '@core/plugins/sentry';
import http from '@monolith/legacy/services/api/http';

export enum targetSegment {
  ProductActivation = 'product-activation',
  Order = 'order',
}

interface Segments {
  [index: string]: Segment[];
}

export interface CampaignState {
  campaigns: {
    data: object;
    meta: object;
    links: object;
    segments: Segments;
    topLocale: string;
  };
  currentCampaignsPage: number;
  createCampaignEvent: string;
  saveCampaignDraftEvent: string;
  selectedLanguage: string;
}

export const initialState = (): CampaignState => ({
  campaigns: {
    data: {},
    meta: {},
    links: {},
    segments: {},
    topLocale: null,
  },
  currentCampaignsPage: 0,
  createCampaignEvent: '',
  saveCampaignDraftEvent: '',
  selectedLanguage: null,
});

export default {
  namespaced: true,
  state: initialState,
  actions: {
    changeCurrentCampaignsPage({ commit, dispatch }, page) {
      commit('SET_CAMPAIGN_CURRENT_PAGE', page);
      dispatch('fetchCampaigns');
    },
    createCampaignEvent({ commit }) {
      commit('CREATE_CAMPAIGN');
    },
    saveCampaignDraftEvent({ commit }) {
      commit('SAVE_CAMPAIGN_DRAFT');
    },
    fetchCampaigns({ dispatch, state }): Promise<void> {
      const $vm = this['_vm'];
      const params: {
        page?: number;
      } = {};
      if (state.currentCampaignsPage > 1) {
        params.page = state.currentCampaignsPage;
      }

      $vm.$emit('loading', true);
      return http()
        .get(`/api/me/brand/campaigns`, {
          params,
          noProgressBar: true,
        })
        .then(({ data: { data, links, meta } }) => {
          $vm.$emit('loading', false);
          return dispatch('setCampaigns', { data, links, meta });
        });
    },

    setCampaigns({ commit }, { data, links, meta }): Promise<void> {
      commit('SET_CAMPAIGNS_DATA', data);
      commit('SET_CAMPAIGNS_LINKS', links);
      commit('SET_CAMPAIGNS_META', meta);
      return Promise.resolve();
    },

    setSegments({ commit }, { segments, topLocale }): Promise<void> {
      return commit('SET_CAMPAIGNS_SEGMENTS', {
        segments,
        topLocale,
      });
    },
    async createCampaigns({ commit }, form: Campaign): Promise<void> {
      try {
        const { data } = await http().post(`/api/me/brand/campaigns`, form);
        commit('SET_CAMPAIGNS_DATA', data.data);
        commit('SET_CAMPAIGNS_LINKS', data.links);
        commit('SET_CAMPAIGNS_META', data.meta);
        return Promise.resolve();
      } catch ({ response }) {
        captureException(response);
        throw response.data.errors;
      }
    },
    setLanguage({ commit }, lang: string): Promise<void> {
      return commit('SET_SELECTED_LANGUAGE', lang);
    },
  },
  mutations: {
    SET_CAMPAIGNS_DATA(state, data) {
      state.campaigns.data = data;
    },
    SET_CAMPAIGNS_LINKS(state, data) {
      state.campaigns.links = data;
    },
    SET_CAMPAIGNS_META(state, data) {
      state.campaigns.meta = data;
    },
    SET_CAMPAIGN_CURRENT_PAGE(state, page) {
      state.currentCampaignsPage = page;
    },
    SET_CAMPAIGNS_SEGMENTS(state, { segments, topLocale }) {
      state.campaigns = { ...state.campaigns, segments, topLocale };
    },
    SET_TOP_LOCALE(state, topLocale) {
      state.campaigns.topLocale = topLocale;
    },
    SET_SELECTED_LANGUAGE(state, lang: string): void {
      state.selectedLanguage = lang;
    },
    CREATE_CAMPAIGN(state): void {
      state.createCampaignEvent = Date.now();
    },
    SAVE_CAMPAIGN_DRAFT(state): void {
      state.saveCampaignDraftEvent = Date.now();
    },
  },
  getters: {
    getCampaigns: (state: CampaignState) => state.campaigns,
    getSegments: (state: CampaignState) => state.campaigns.segments,
    getTopLocale: (state: CampaignState) => state.campaigns.topLocale,
    getCurrentLang: (state: CampaignState) => state.selectedLanguage,
    getCurrentLangSegments: (state: CampaignState): Segment[] | null => state.campaigns.segments[state.selectedLanguage],
    getDefaultSegment: (_state: CampaignState, getters): Segment | undefined =>
      getters.getCurrentLangSegments?.find((segment: Segment) => segment.is_default_selected) ??
      getters.getCurrentLangSegments?.find((segment: Segment) => segment.count > 0),
    isCurrentLangSegmentsHavingPeople: (_state: CampaignState, getters): boolean =>
      getters.getCurrentLangSegments?.reduce((accumulator, segment) => accumulator + segment.count, 0) > 0,
  },
};
