import http from '@monolith/legacy/services/api/http';
import { deserialize } from '@monolith/legacy/services/utils/jsonapi-parser';
import { Preorder, Pagination } from '@monolith/legacy/components/account/preorders/preorder/types';

type ListPreorderFilters = {
  uuid: string[];
  status: string[];
};

export const getPreorder = async (id: string): Promise<Preorder> => {
  const response = await http().get(
    `/api/internal/v1/ordering/preorders/${id}?include=preorderItems,preorderItems.productVariant,brand,retailer,preorderItems.order`
  );
  return deserialize(response.data).data;
};

export const acceptPreorder = async (id: string): Promise<Preorder> => {
  const response = await http().post(
    `/api/internal/v1/ordering/preorders/${id}/-actions/accept?include=preorderItems,preorderItems.productVariant,brand,retailer,preorderItems.order`
  );
  return deserialize(response.data).data;
};

export const rejectPreorder = async (id: string): Promise<Preorder> => {
  const response = await http().post(
    `/api/internal/v1/ordering/preorders/${id}/-actions/reject?include=preorderItems,preorderItems.productVariant,brand,retailer,preorderItems.order`
  );
  return deserialize(response.data).data;
};

export const listPreorders = async (page: number = null, preordersPagination: Pagination = null, filters: ListPreorderFilters = null): Promise<{data: Preorder[], meta: {page: Pagination}}> => {
  const defaultPreordersPerPage = 15;
  const params = {
    include: 'preorderItems,preorderItems.productVariant,brand,retailer,preorderItems.order',
  };

  params['page[limit]'] = defaultPreordersPerPage;

  if (page && preordersPagination?.perPage) {
    params['page[offset]'] = (page - 1) * preordersPagination.perPage;
  }

  if (filters?.uuid?.length) {
    params['filter[productVariantUuids]'] = filters.uuid.join(',');
  }
  if (filters?.status?.length) {
    params['filter[statuses]'] = filters.status.join(',');
  }
  const response = await http().get(`/api/internal/v1/ordering/preorders`, { params } );
  return deserialize(response.data);
};
