import { createApp as createVueApp } from 'vue';
import { Store } from 'vuex';
import { initSentry, captureException } from '@core/plugins/sentry';
import { setSentryMetadata } from '@core/plugins/sentry/helper';

import i18n from '@monolith/legacy/services/i18n';
import { configureVue } from '@monolith/legacy/services/features';
import * as router from '@core/routes/main-router';
import { keepParameters } from '@core/utilities/parameters-persistance';
import Plugins from './plugins/plugins';
import GlobalComponents from './plugins/global-components-plugin';
import GlobalProperties from './plugins/global-properties-plugin';
import { createEventBus } from './plugins/global-event-bus-plugin';
import App from './app.vue';

export const createApp = (store: Store<unknown>) => {
  try {
    const app = createVueApp(App);

    configureVue(app);

    // Track UTM parameters from Ankorstore.com to my Ankorstore
    keepParameters();

    app.use(store);
    app.use(i18n);

    app.use(Plugins);
    app.use(GlobalProperties);
    app.use(GlobalComponents);

    initSentry(app, router.router);
    setSentryMetadata(store.state);
    app.use(createEventBus({ store }));

    if (router.router) {
      router.router.app = app;
      app.use(router.router);
      router.router.isReady().then(() => {
        app.mount('#app');
      });
    } else {
      app.mount('#app');
    }

    return app;
  } catch (e) {
    captureException(e);

    throw e;
  }
};
