import { Ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { Router } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { SearchService } from '@bc/discovery/domain/search';
import { createSearchStore } from '../store/index';
import { SEARCH_STORE_ID } from '../store/constants';
import SearchStoreHelpers from '../store/helpers';
import { UPDATE_COUNTRY } from '../store/action-types';

export function useSearchStore(params: ConstructorParameters<typeof SearchService>, country: Ref<string>, router?: Router) {
  const store = useStore();
  const i18n = useI18n();

  if (!store.hasModule(SEARCH_STORE_ID)) {
    store.registerModule(SEARCH_STORE_ID, createSearchStore(new SearchService(...params), i18n.locale.value, router));
  }

  const { updateCountryAction } = SearchStoreHelpers.useActions([UPDATE_COUNTRY]);

  watchEffect(() => {
    updateCountryAction(country.value);
  });
}
