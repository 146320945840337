<template>
  <div
    v-if="isVisible"
    :class="{
      'search-autocomplete-static-item': true,
      'search-autocomplete-static-item--purple': isR2BLink,
      'search-autocomplete-static-item--selected': item?.id === currentSelectionId,
    }"
    :data-testid="testId"
    @click="handleItemClick"
  >
    <AkIcon
      v-if="iconSymbol"
      size="md"
      :symbol="iconSymbol"
      class="search-autocomplete-static-item__icon"
    />
    <span
      class="search-autocomplete-static-item__label"
      data-testid="searchShowAllResults"
    >
      {{ formattedLabel }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { StaticItem } from '@bc/discovery/domain/search';
import { AkIcon } from '@ankorstore/design-system';
import SearchBarStoreHelpers from '../store/helpers';
import OffersStoreHelpers from '@monolith/legacy/store/offers/helpers';
import { formatPrice } from '@core/utilities/price';
import { useI18n } from 'vue-i18n';
import { R2B_LINK_ID, SHOW_ALL_ID } from '@bc/discovery/feature/search/constants';

const props = withDefaults(
  defineProps<{
    item?: StaticItem;
    isVisible?: boolean;
  }>(),
  {
    item: undefined,
    isVisible: false,
  }
);

const emit = defineEmits<{
  click: [StaticItem];
}>();

const { t } = useI18n();
const { originalUserInput, currentSelectionId } = SearchBarStoreHelpers.useGetters(['originalUserInput', 'currentSelectionId']);
const { r2bWalletCreditOffer } = OffersStoreHelpers.useGetters(['r2bWalletCreditOffer']);

const isR2BLink = computed(() => props.item?.id === R2B_LINK_ID);

const iconSymbol = computed(() => {
  if (props.item?.id === SHOW_ALL_ID) {
    return 'search';
  } else if (isR2BLink.value) {
    return 'megaphone';
  } else {
    return '';
  }
});

const testId = computed(() => (props.item?.id === SHOW_ALL_ID ? 'SearchBoxViewAllResults' : ''));

const formattedLabel = computed(() => {
  let name = props.item?.name;
  if (isR2BLink.value) {
    name = t('Refer brands and get {REFERRAL_GIFT_AMOUNT}', {
      REFERRAL_GIFT_AMOUNT: formatPrice(r2bWalletCreditOffer.value?.amount, '$0'),
    });
  } else if (props.item?.id === SHOW_ALL_ID) {
    name = originalUserInput.value
      ? t('Show all results for : {search}', {
          search: originalUserInput.value,
        })
      : t('Show all results for this search');
  }
  return name;
});

const handleItemClick = () => {
  emit('click', props.item);
};
</script>

<style scoped lang="scss">
.search-autocomplete-static-item {
  @apply ds-w-full ds-flex ds-items-center ds-py-3 ds-px-4 ds-relative ds-text-left ds-cursor-pointer;
  transition: background-color ease-in-out 0.2s;

  &:last-child {
    @apply md:ds-rounded-b-md;
  }

  &__icon {
    @apply ds-pr-2;
  }

  &__label {
    flex: 1 2 auto;
  }

  &--selected,
  &:hover {
    @apply ds-bg-neutral-300;
  }

  &--purple {
    @apply ds-text-discount-700 ds-font-bold ds-text-xs ds-uppercase;

    &__icon {
      @apply ds-text-discount-700;
    }
  }
}
</style>
