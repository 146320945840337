import { TailwindConfig } from '@ankorstore/design-system';

export const BREAKPOINTS_PROVIDE_ID = Symbol('breakpoints');

export enum CLIENT_DEVICE {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
}

export enum BREAKPOINT_NAME {
  xxs = 'Xxs',
  xs = 'Xs',
  sm = 'Sm',
  md = 'Md',
  lg = 'Lg',
  xl = 'Xl',
  xxl = 'Xxl',
}

export const BREAKPOINT_PX_LIMIT = {
  XS: Number.parseInt(TailwindConfig.theme.screens.xs, 10),
  SM: Number.parseInt(TailwindConfig.theme.screens.sm, 10),
  MD: Number.parseInt(TailwindConfig.theme.screens.md, 10),
  LG: Number.parseInt(TailwindConfig.theme.screens.lg, 10),
  XL: Number.parseInt(TailwindConfig.theme.screens.xl, 10),
  XXL: Number.parseInt(TailwindConfig.theme.screens['2xl'], 10),
}
