<template>
  <div
    v-if="cart"
    ref="cart"
    class="cart"
  >
    <div
      v-if="brandIsDisabledInThisCountry"
      class="cart__restricted-warning"
    >
      {{ $t('Sorry this brand is not available in your country.') }}
    </div>
    <div
      class="cart-top"
      :class="{ 'cart--disabled': brandIsDisabledInThisCountry }"
    >
      <div
        v-if="$isEnabled('promised_delivery_date')"
        class="cart-top__pdd"
      >
        <PromisedDeliveryDate
          :brand-uuid="cart.brand.uuid"
          :right="!isMobile"
        />
      </div>
      <div class="cart-top__brand-amount">
        <div class="infos">
          <a
            class="infos__brand-link"
            :href="cart.brand.link"
            data-testid="brand-logo"
          >
            <img :src="'https://' + $cdn + cart.brand.images.rounded + '?auto=format,compress&fm=pjpg&h=48&dpr=2'">
          </a>
          <div class="brand-link-wrapper">
            <a
              class="brand-link"
              :href="cart.brand.link"
            ><AkParagraph weight="bold">{{ cart.brand.name }}</AkParagraph></a>

            <template v-if="!isCheckout && !brandIsDisabledInThisCountry">
              <span
                v-if="!cart.minimum.above"
                class="minimum-indication"
              >
                <i18n-t
                  keypath="Add {0} to reach the minimum"
                  tag="span"
                >
                  {{ $root.formatPrice(amountLeftForValidCart(cart)) }}
                </i18n-t>
              </span>

              <span
                v-else
                class="minimum-indication success"
              >
                <AkIcon
                  symbol="check"
                  size="md"
                />
                {{ $t('Minimum reached') }}</span>
            </template>

            <div class="cart-badges">
              <AkBadge
                v-if="cart.brand.event_special_discount"
                data-testid="cartSpecialDiscount"
                :content="
                  $t('global.percentage.minus', {
                    percentage: cart.brand.event_special_discount,
                  })
                "
                size="xs"
                color="purple-full"
              />
              <div
                v-if="ankorstorePlusExtraDiscount"
                class="badge-plus"
              >
                <img
                  class="logo"
                  src="/images/logo/ankorstore_plus_symbol.svg"
                  :alt="$t('ankorstorePlus.program.name')"
                >
                <AkParagraph
                  size="xs"
                  weight="bold"
                >
                  {{ $t('ankorstorePlus.discount.checkout', { discount: ankorstorePlusDiscount.discount }) }}
                </AkParagraph>
              </div>
            </div>

            <div
              v-if="offerDiscountText"
              class="cart-row ds-text-discount-700 ds-flex-1 ds-pl-4 ds-mb-2"
            >
              {{ offerDiscountText }}
            </div>
            <VatMessage
              v-bind="vatMessage"
              class="ds-pr-4"
            />
            <div v-if="recargoMessage && recargoMessage === 'thanks'">
              <AkParagraph
                class="ds-text-info-700"
                size="sm"
              >
                {{ $t('VAT is applied as it is a domestic order.') }}
              </AkParagraph>
              <AkParagraph
                class="ds-text-info-700"
                size="sm"
              >
                {{ $t('Recargo de equivalencia is applied because you registered for this tax.') }}
              </AkParagraph>
            </div>
            <AkParagraph
              v-else-if="recargoMessage"
              class="ds-text-info-700"
              size="sm"
            >
              {{
                $t(
                  'VAT is applied as it is a domestic order. Also Recargo de equivalencia is automatically applied because you registered for this tax'
                )
              }}
            </AkParagraph>
          </div>
        </div>
        <BadgeEoriRequired
          v-if="showEoriRequiredBadge"
          class="ds-mr-3 ds-whitespace-nowrap cart__mobile-detail-row-item"
        />
        <div
          v-if="cart.total_amount_before_discount"
          class="card-price"
        >
          <AkParagraph
            v-if="cart.minimum.above"
            size="base"
            class="cart-price-before-discount ds-mb-1"
          >
            <strike>{{ $root.formatPrice(cart.total_amount_before_discount) }}</strike>
          </AkParagraph>
          <AkParagraph
            v-if="cart.minimum.above"
            size="base"
            weight="bold"
            class="cart-price ds-mb-0"
          >
            {{ $root.formatPrice(cart.total_amount) }}
          </AkParagraph>
        </div>
        <AkParagraph
          v-else
          size="base"
          class="cart-price ds-mb-0"
        >
          {{ $root.formatPrice(cart.total_amount) }}
        </AkParagraph>
      </div>
      <div
        v-if="showEoriRequiredBadge"
        class="cart__mobile-detail-row"
      >
        <BadgeEoriRequired class="ds-mr-3" />
      </div>
      <PersonalisedDiscountBanner
        v-if="shouldDisplayPersonalDiscountBanner"
        class="personalised-discount"
        :discount="discount"
      />
    </div>
    <BannerSitewideOffer
      v-if="sitewideOfferBannerContent"
      v-bind="sitewideOfferBannerContent"
      class="cart__offer-banner"
    />
    <AlertInternationalShippingReimbursement v-if="showInternationalShippingReimbursementAlert" />

    <ShippingFeesBanner
      :is-checkout="true"
      :display-aks-plus-cta="false"
      :type="retailerShippingFeeMessageType"
      :shipping-fee-amount="retailerShippingFeesAmount"
      :franco-amount="francoAmount"
      data-testid="shippingFeesBanner"
    />
    <div
      v-if="$isEnabled('leadtime') && showLead && !$isEnabled('promised_delivery_date')"
      class="cart-delivery"
    >
      <span class="ds-inline-flex ds-items-center">
        <AkIcon
          symbol="truck"
          size="md"
          class="ds-mr-1"
        />
        {{ $t('Ships') }}
        {{
          $t('Between {minimum} and {maximum} business days', {
            minimum: cart.shipping_lead_time?.minimum_estimated_shipping_day,
            maximum: cart.shipping_lead_time?.maximum_estimated_shipping_day,
          }).toLowerCase()
        }}
      </span>
      <AkParagraph
        v-if="hasAdditionalShippingFees"
        class="ds-mb-0"
        label
      >
        {{ $t('Additional shipping fee applied') }}
      </AkParagraph>
    </div>
    <ul class="cart-items cart-items--checkout">
      <CartItem
        v-for="item in cart.items"
        :key="item.id"
        data-testid="cartItem"
        :is-checkout="isCheckout"
        :cart="cart"
        :item="item"
        :expanded="true"
        :special-discount="brandHasDiscount"
      />
    </ul>
    <div
      v-if="!isCheckout && cart.minimum.above"
      class="cart-footer"
    >
      <div
        v-if="!cart.saved_for_later"
        class="ak-link"
        @click="onSaveForLater(cart)"
      >
        {{ $t('Save this order for later') }}
      </div>
      <div
        v-else
        class="ak-link"
        @click="onSaveForLater(cart)"
      >
        {{ $t('Move to my cart') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters, mapActions } from 'vuex';
import CartItem from '@monolith/legacy/components/cart/cart-item.vue';
import BannerSitewideOffer from '@monolith/legacy/components/banners/banner-sitewide-offer.vue';
import AlertInternationalShippingReimbursement from '@monolith/legacy/components/alerts/AlertInternationalShippingReimbursement.vue';
import { sitewideOfferMixin } from '@monolith/legacy/mixins/sitewide-offer';
import { SitewideOfferSliceLabel } from '@core/types/prismic';
import { oneOf } from '@core/utilities/propValidators';
import { AkParagraph, AkIcon } from '@ankorstore/design-system';
import VatMessage from '@monolith/legacy/components/cart/vat-message.vue';
import BadgeEoriRequired from '@monolith/legacy/components/cart/badge-eori-required.vue';
import PersonalisedDiscountBanner from '@monolith/legacy/modules/carts/brand-banners/personalised-discount-banner.vue';
import { BrandDiscount, BrandDiscountContext } from '@monolith/legacy/types/api/brand-discount';
import { defineComponent, PropType } from 'vue';
import { OfferConditionResolutionContext } from '@monolith/legacy/types/offer';
import { FeatureFlag } from '@monolith/legacy/services/features';
import { BillingItemType } from '@monolith/legacy/types/billing-item';
import { Cart, CartBrand } from '@monolith/legacy/types/cart';
import PromisedDeliveryDate from '@monolith/legacy/components/promised-delivery-date/promised-delivery-date.vue';
import usePurchasableAggregate from '@monolith/legacy/modules/cart-2/composables/use-purchasable-aggregate';
import { PurchasableAggregate, RetailerShippingFeesMessageTypeName } from '@monolith/legacy/modules/cart-2/types';
import { Ref } from 'vue';
import ShippingFeesBanner from '@monolith/legacy/modules/cart-2/banners/shipping-fees-banner.vue';
import { Amount } from '@core/types/amount';
import { useBreakpoints } from '@monolith/legacy/modules/design-system-candidates';

export default defineComponent({
  name: 'Cart',
  components: {
    CartItem,
    BannerSitewideOffer,
    AlertInternationalShippingReimbursement,
    AkParagraph,
    AkIcon,
    VatMessage,
    PersonalisedDiscountBanner,
    BadgeEoriRequired,
    PromisedDeliveryDate,
    ShippingFeesBanner,
  },
  mixins: [sitewideOfferMixin],
  props: {
    brand: {
      type: Object as PropType<CartBrand>,
      required: false,
      default: null,
    },
    context: {
      type: String as PropType<BrandDiscountContext>,
      default: null,
      validator: function (value: string) {
        return ['cart_page', 'checkout_page', 'checkout_confirmation_page'].indexOf(value) !== -1;
      },
    },
    vatMessage: {
      type: Object,
      required: false,
      default: null,
    },
    recargoMessage: {
      type: String,
      required: false,
      default: null,
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
    cartFromRecap: {
      type: Object as PropType<Cart>,
      required: false,
      default: null,
    },
    showLead: {
      type: Boolean,
      default: true,
    },
    offerBannerContentTag: {
      type: String as PropType<SitewideOfferSliceLabel>,
      validator: oneOf<SitewideOfferSliceLabel>([
        'cart-page-offer-banner',
        'checkout-page-offer-banner',
        'checkout-confirmation-page-offer-banner',
      ]),
      default: 'cart-page-offer-banner',
    },
    discount: {
      type: Object as PropType<BrandDiscount>,
      required: false,
      default: () => {},
    },
  },
  setup() {
    const { isMobile } = useBreakpoints();

    return {
      isMobile,
    };
  },
  computed: {
    ...mapGetters('cart', ['cartByBrandId', 'checkout', 'isBrandOpenedInUserCountry']),
    ...mapGetters(['retailer']),
    ...mapGetters('account/business', ['getEoriNumber']),
    ...mapGetters('offers', ['ankorstorePlus', 'haveBrandDiscountEventLive', 'ankorstorePlusDiscount']),
    cart(): Cart {
      if (this.cartFromRecap || !this.brand) {
        return this.cartFromRecap;
      }
      return this.cartByBrandId(this.brand.id);
    },
    retailerShippingFeeMessageType(): RetailerShippingFeesMessageTypeName {
      const { retailerShippingFeeMessageType } = usePurchasableAggregate(this.cart as unknown as Ref<PurchasableAggregate>);
      return retailerShippingFeeMessageType.value;
    },
    retailerShippingFeesAmount(): Amount {
      const { retailerShippingFeesAmount } = usePurchasableAggregate(this.cart as unknown as Ref<PurchasableAggregate>);
      return retailerShippingFeesAmount.value;
    },
    francoAmount(): Amount {
      const { francoAmount } = usePurchasableAggregate(this.cart as unknown as Ref<PurchasableAggregate>);
      return francoAmount.value;
    },
    brandIsDisabledInThisCountry(): boolean {
      if (this.cartFromRecap || !this.brand) {
        return false;
      }
      return !this.isBrandOpenedInUserCountry(this.brand);
    },
    brandHasDiscount(): boolean {
      return this.brand?.event_special_discount > 0;
    },
    ankorstorePlusExtraDiscount(): boolean {
      return (
        this.ankorstorePlus?.optIn &&
        this.cart.is_first_order_on_this_brand &&
        this.ankorstorePlusDiscount?.discount > 0 &&
        this.haveBrandDiscountEventLive
      );
    },
    sitewideOfferContext(): OfferConditionResolutionContext {
      if (this.retailer && this.brand) {
        return {
          retailer: this.retailer,
          relativeEntities: {
            brand: this.brand,
          },
        };
      }

      return null;
    },
    sitewideOfferBannerContent() {
      return this.sitewideOfferContent[this.offerBannerContentTag];
    },
    showInternationalShippingReimbursementAlert() {
      return (
        this.$isEnabled('international_shipping_messages') &&
        ((this.brand?.country?.iso_code === 'GB' && this.retailer?.country?.iso_code !== 'GB') ||
          (this.brand?.country?.iso_code !== 'GB' && this.retailer?.country?.iso_code === 'GB')) &&
        this.cart.minimum.above
      );
    },
    showEoriRequiredBadge() {
      return (
        !(this.getEoriNumber?.length > 0) &&
        this.$isEnabled(FeatureFlag.MandatoryEori) &&
        this.cart.shipping_is_restricted_by_eori
      );
    },
    hasAdditionalShippingFees() {
      return this.cart.billing_items.find((item) => item.billing_item_type === BillingItemType.shippingFees);
    },
    ...mapGetters('offers', ['appliedDiscount']),
    discountTranslations() {
      const placeholders = {
        discount: this.appliedDiscount.percentage,
      };
      return {
        'black friday': this.$t('@@promote_and_engage.cart.discount_aks_black_friday', placeholders),
        B2R: this.$t('@@promote_and_engage.cart.discount_B2R', placeholders),
        welcome: this.$t('@@promote_and_engage.cart.discount_welcome', placeholders),
        sumup: this.$t('@@promote_and_engage.cart.discount_sumup', placeholders),
      };
    },
    offerDiscountText(): string | null {
      if (!this.appliedDiscount) {
        return null;
      }
      return this.discountTranslations[this.appliedDiscount.origin];
    },
    shouldDisplayPersonalDiscountBanner(): boolean {
      return !!this.discount || !!(this.cart?.brand?.id && this.context);
    },
  },
  methods: {
    ...mapActions('cart', ['saveCartForLater']),
    onSaveForLater(cart) {
      (this.$refs.cart as HTMLElement).classList.add('removing');

      //Waiting the animation ends then toggle cart.save_for_later value
      setTimeout(() => this.$store.dispatch('cart/toggleSaveCartForLater', cart), 500);

      this.$store.dispatch('cart/saveForLater', {
        cart: cart,
        checkoutId: this.checkout.id,
      });
    },
    amountLeftForValidCart(cart) {
      return this.$root.substractPrices([cart.minimum.amount, cart.total_amount]);
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';
.removing {
  animation: cart-delete;
  animation-duration: 500ms;
  animation-timing-function: ease-out;
  position: relative;
  animation-fill-mode: forwards;
}

.brand-link-wrapper {
  @apply ds-ml-2;
  display: flex;
  flex-direction: column;
}

.cart__offer-banner {
  margin: -1px 0 0 -1px;
  width: calc(100% + 2px);
}

.cart__mobile-detail-row {
  @apply ds-flex
    lg:ds-hidden
    ds-justify-center
    ds-py-4
    ds-border-t
    ds-border-solid
    ds-border-neutral-300;
}

.cart__mobile-detail-row-item {
  @apply ds-hidden lg:ds-flex;
}

@keyframes cart-delete {
  0% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 30px;
    opacity: 0;
    display: none;
  }
}

.personalised-discount {
  @apply ds-mt-0 ds-mr-4 ds-mb-4;
  margin-left: 72px;
}

.cart-badges {
  @apply ds-flex ds-gap-3 ds-flex-wrap;
}

.badge-plus {
  @apply ds-bg-accent-alt ds-rounded-sm ds-py-1 ds-px-2 ds-flex ds-gap-1;
  .logo {
    @apply ds-h-auto ds-w-3;
  }
}

.card-price {
  @apply ds-flex ds-gap-1 ds-flex-col-reverse md:ds-flex-row;
}

.cart-top__pdd {
  @apply ds-mt-2;
}

.shipping-fees-banner-container {
  @apply ds-flex ds-flex-col ds-bg-warm-white ds-rounded-md ds-p-2 ds-m-2;
}
</style>
<style lang="scss">
.cart-badges {
  .ak-badge--xs {
    @apply ds-h-auto;
  }
}
</style>
