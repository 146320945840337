import Product from '@monolith/legacy/types/product';
import { AkBadgeColor } from '@ankorstore/design-system';
import { AnkorstoreLabelFormat } from '@monolith/legacy/types/shipping';

export type ReplenishmentItemStatus = 'available' | 'blocked';

interface Receipt {
  receiptId: string;
  receivedAt: string;
  items: ReplenishmentItem[];
}

export interface Lot {
  id: string;
  fulfillmentItemId: string;
  lotNumber: string;
  availableQuantity: number;
  expiryDate?: string;
  sellByDate?: string;
}

export enum ReplenishmentShipmentType {
  FULL_TRUCK_LOAD = 'FTL',
  LESS_FULL_TRUCK_LOAD = 'LTL',
  PARCEL = 'PARCEL',
}

export interface Replenishment {
  type: 'replenishments';
  id: string;
  brandId: number;
  warehouseId: string;
  shipmentType: ReplenishmentShipmentType | string;
  shippingCarrierName: string;
  reference: string;
  receivedAt: string | null;
  submittedAt: string | null;
  confirmedAt: string | null;
  createdAt: string | null;
  status: ReplenishmentStatus | string;
  items: ReplenishmentItem[];
  receipts?: Receipt[];
}

/**
 * This is the raw API response for a replenishment.
 */
export interface RawApiReplenishment extends Replenishment {
  warehouse?: {
    data: Warehouse;
  };
}

export enum FulfillmentTabs {
  inventory = 'inventory',
  replenishments = 'replenishments',
  lots = 'lots',
}

export enum ReplenishmentStatus {
  created = 'created',
  sent = 'sent',
  received = 'received',
  confirmed = 'confirmed',
  delivered = 'delivered',

  /** Statuses available on client only. */
  receivedWithDifferences = 'receivedWithDifferences',
  draft = 'draft',
  unknown = 'unknown',
}

export enum WarehouseCode {
  evry = 'EVRY',
}

export interface FulfillableProductVariant {
  availableQuantity: number;
  barcode: string;
  classificationCode: string;
  expectedStockForDays: number;
  expiryTracked: boolean;
  fulfillableId: string;
  id: string;
  lotTracked: boolean;
  productImages: string[];
  productName: string;
  referenceId: string;
  shelfLife: number;
  sku: string;
  status: string;
  stockStatus: FulfillableItemStatus;
  type: string;
  unitCost: number;
  unitCostMargin: number;
  unitsPerBatch: number;
  warehouseCategoryName: string;
  warehouseProductName: string;
}

export interface FulfillmentBrand {
  billingCategory: string;
  id: string;
  maxParcelShipmentWeight: number;
  palletCarrier: string;
  parcelCarrier: string;
  reference: string;
  type: 'fulfillment-brands';
  warehouseId: string;
}

export interface FulfillableItem {
  type: string;
  id: string;
  status: string;
  productVariantId: string;
  barcode: string;
  unitCost: number;
  unitsPerBatch: number;
  unitCostMargin: number;
  classificationCode: string;
  expiryTracked: boolean;
  shelfLife: number;
  lotTracked: boolean;
  availableQuantity: number;
  expectedStockForDays: number;
  stockStatus: FulfillableItemStatus;
  sku: string;
  productName: string;
  categoryName: string;
  /* GEODIS Id */
  referenceId: string;
}

export interface HistoricalParcelDimension {
  width: number;
  height: number;
  length: number;
}

export interface Fulfillable {
  fulfillableId: string;
  batchQuantity: number;
  unitQuantity: number;
  lotNumber?: string;
  expiryDate?: string;
  item?: {
    data: FulfillableItem;
  };
}

export enum QuantityAdjustmentReasonCode {
  OrderCreated = 'ORDER_CREATED',
  OrderReserved = 'ORDER_RESERVED',
  OrderShipped = 'ORDER_SHIPPED',
  Replenishment = 'REPLENISHMENT',
  Snapshot = 'SNAPSHOT',
  LotSnapshot = 'LOT_SNAPSHOT',
  LotSnapshotCorrection = 'LOT_SNAPSHOT_CORRECTION',
  ReplenishmentMismatch = 'REPLENISHMENT_MISMATCH',
  DeliveryRefused = 'DELIVERY_REFUSED',
  OrderCancelled = 'ORDER_CANCELLED',
  BrandRequest = 'BRAND_REQUEST',
  Unblocked = 'UNBLOCKED',
  ReturnedToBrand = 'RETURNED_TO_BRAND',
  Found = 'FOUND',
  Lost = 'LOST',
  BlockedByProvider = 'BLOCKED_BY_PROVIDER',
  Batched = 'BATCHED',
  Unbatched = 'UNBATCHED',
  BatchModified = 'BATCH_MODIFIED',
  Expired = 'EXPIRED',
  ExpiryDateModified = 'EXPIRY_DATE_MODIFIED',
  Broken = 'BROKEN',
  Manual = 'MANUAL',
  Destroyed = 'DESTROYED',
  AdjustmentReversed = 'ADJUSTMENT_REVERSED',
  QualityControl = 'QUALITY_CONTROL',
}

export interface QuantityAdjustmentMetadata {
  order_id: number;
  order_reference_id: string;
  master_order_uuid?: string;
}

export interface QuantityAdjustment {
  createdAt?: Date;
  id: string;
  newQuantity: number;
  previousQuantity: number;
  reasonCode: QuantityAdjustmentReasonCode;
  reasonText: string | null;
  type: 'fulfillment-quantity-adjustments';
  metadata?: QuantityAdjustmentMetadata;
  fulfillmentOrderUuid: string | null;
  fulfillmentItemId?: string;
  replenishmentUuid: string | null;
  lotUuid?: string | null;
  lots?: { data: Lot[] };
}

export interface GroupedQuantityAdjustment extends QuantityAdjustment {
  quantityAdjustments?: { data: QuantityAdjustment[] };
}

export interface JsonApiResponse<T> {
  data: T;
  jsonapi: {
    version: string;
  };
}

export interface JSONApiErrorItem {
  detail: string;
  status: string;
  title: string;
  meta?: unknown;
}

export interface JSONApiError {
  jsonapi: {
    version: string;
  };
  errors: Array<JSONApiErrorItem>;
}

export interface PaginatedResponse<T> extends JsonApiResponse<T[]> {
  meta: {
    page: {
      currentPage: number;
      perPage: number;
      total: number;
      from: number;
      to: number;
      lastPage: number;
    };
  };
  data: T[];
}

export interface PaginatedParams {
  offset: number;
  query?: string;
  limit: number;
}

export type IncludedRelationshipParams<T extends string> = {
  include?: T[];
};

export interface CursorPaginationParams {
  after?: string;
  before?: string;
  limit: number;
}

export type GetFulfillableProductVariantsResponse = PaginatedResponse<FulfillableProductVariant>;
export type GetFulfillmentBrandResponse = JsonApiResponse<FulfillmentBrand>;
export type GetFulfillmentItemsResponse = PaginatedResponse<FulfillableItem>;
export type GetFulfillmentOrdersResponse = PaginatedResponse<FulfillmentOrder>;
export type GetFulfillmentOrderResponse = JsonApiResponse<FulfillmentOrder>;
export type GetFulfillmentItemResponse = JsonApiResponse<FulfillableItem>;
export type GetReplenishmentsResponse = PaginatedResponse<Replenishment>;
export type GetQuantityAdjustmentResponse = PaginatedResponse<GroupedQuantityAdjustment>;
export type GetReplenishmentResponse = JsonApiResponse<RawApiReplenishment>;
export type GetLotsResponse = PaginatedResponse<Lot & { fulfillmentItem: { data: FulfillableItem } }>;
export type GetHistoricalParcelDimensionsResponse = JsonApiResponse<HistoricalParcelDimension[]>;

export interface QuantityAdjustmentEntity {
  previousQuantity: number;
  newQuantity: number;
  reasonCode: string;
  createdAt: string;
}

export interface FulfillableItemWithProductData {
  itemImg: string;
  itemName: string;
  barcode: string;
  sku: string;
  availableQuantity: number;
  unitsPerBatch: number;
  fulfillmentItemId: string;
  expectedStockForDays?: number;
  // Synchronization status
  status: string;
  // Stock adjustment status
  stockStatus: FulfillableItemStatus;
  expiryTracked?: boolean;
  productVariantId: string;
}

export enum FulfillmentOrderStatus {
  INTERNAL = 'internal',
  REQUESTED = 'requested',
  CREATED = 'created',
  SCHEDULED = 'scheduled',
  RELEASED = 'released',
  SHIPPED = 'shipped',
  CANCELLED = 'cancelled',
}

export interface FulfillmentAddress {
  firstName: string;
  lastName: string;
  company: string;
  phone: string;
  email: string;
  street: string;
  city: string;
  postalCode: string;
  country: string;
}

export interface FulfillmentShipment {
  createdAt: string;
  id: string;
  // Internal name of shipping provider
  // Example: dpd, kuehne_nagel
  provider: string;
  // User-friendly name of shipping provider
  // Example DPD, Kuehne + Nagel
  providerName: string;
  trackingLink: string;
  trackingNumber: string;
}

export interface FulfillmentOrder {
  id: string;
  reference: string;
  /** Ankorstore order ID, or null for direct fulfillment order */
  orderId?: number;
  isDirect: boolean;
  status: FulfillmentOrderStatus;
  masterOrderUuid: string;
  createdAt: Date;
  /** Shipping address for the recipient. Required for order creation */
  shippingAddress: FulfillmentAddress;
  /** Optional billing address for the recipient */
  billingAddress?: FulfillmentAddress;
  items?: FulfillmentOrderItem[];
  shipments?: FulfillmentShipment[];
  recipientType?: 'consumer' | 'business';
  shippedItems: FulfillmentOrderShippedItem[];
}

export interface FulfillmentOrderItem {
  fulfillmentItemId: string;
  quantity?: number;
}

export type FulfillmentOrderShippedItem = Omit<Fulfillable, 'item'>;

export interface FulfillableBatchesSpecification {
  fulfillableId: string;
  unitQuantity: number;
}

export interface PostFulfillmentOrderPayload {
  reference?: string;
  orderId?: number | null;
  shippingAddress: Partial<FulfillmentAddress>;
  billingAddress?: Partial<FulfillmentAddress>;
  items: FulfillableBatchesSpecification[];
  masterOrderUuid: string;
}

export interface PostNonAnkorstoreFulfilledOrderPayload {
  shippingAddress: Partial<FulfillmentAddress>;
  billingAddress?: Partial<FulfillmentAddress>;
  items: { fulfillableId: string; quantity: number }[];
  masterOrderUuid?: string;
  customReference?: string;
  recipientType?: string;
}

export type ReplenishmentItem = {
  fulfillmentItemId: string;
  /** Indicates the quantity of batches, not quantity of units. */
  quantity: number;
  status?: ReplenishmentItemStatus;
};

export type ReplenishmentRelationship = 'fulfillmentBrand' | 'warehouse';
export type GetReplenishmentsParams = PaginatedParams;
export type GetReplenishmentParams = { id: string } & IncludedRelationshipParams<ReplenishmentRelationship>;
export type PostReplenishmentPayload = {
  brandId: string;
  warehouseId?: string;
  shippingCarrierName: string;
  shipmentType: string;
  items: ReplenishmentItem[];
};
export type UpdateReplenishmentPayload = {
  shippingCarrierName?: string;
  shipmentType?: string;
  items?: ReplenishmentItem[];
  status?: 'sent';
};

export type LaravelValidationError = {
  message: string;
  errors: Array<LaravelValidationErrorItem>;
};

type LaravelValidationErrorItem = {
  [field: string]: string[];
};

export enum FulfillableItemStatus {
  NEVER_REPLENISH = 'never_replenished',
  OUT_OF_STOCK = 'out_of_stock',
  SOON_OUT_OF_STOCK = 'soon_out_of_stock',
  IN_STOCK = 'in_stock',
}

export enum FulfillmentIneligibilityReason {
  Generic = 'generic',
  UnavailableItems = 'unavailableItems',
  UndeclaredItems = 'undeclaredItems',
  UnsupportedDestination = 'unsupportedDestination',
}

export interface Warehouse {
  id: string;
  city: string;
  code: WarehouseCode | string;
  countryCode: string;
  email: string;
  name: string;
  phone: string;
  postalCode: string;
  addressLine1: string;
  addressLine2: string;
  type: 'warehouses';
}

export interface ReplenishmentStatusDetail {
  status: ReplenishmentStatus;
  formattedStatus: string;
  color: AkBadgeColor;
}

export type FulfillableItemWithProductDataAndQuantity = FulfillableItemWithProductData & {
  declaredQuantity?: number;
};

export enum LotSellByStatus {
  Imminent = 'imminent',
  Approaching = 'approaching',
  Distant = 'distant',
  None = 'none',
}

export interface FulfillmentEntityWithRelations {
  item?: FulfillableItem;
  product?: Product;
  lot?: Lot;
  lots?: Lot[];
}

export enum AutomaticOrderValidationMode {
  AUTOMATIC_CONFIRM_AND_FULFILL = 'automatic-confirm-and-request-fulfillment',
  AUTOMATIC_CONFIRM_ON_REORDER = 'automatic-confirm-and-request-fulfillment-on-reorder',
  AUTOMATIC_FULFILL = 'automatic-request_fulfillment',
  AUTOMATIC_BRAND_CONTROL = 'automatic-brand_control',
  MANUAL = 'manual',
}

export interface BrandRetailerRelation {
  automaticOrderValidationMode?: boolean;
  repeatFeesPercentage?: number;
  firstFeesPercentage?: number;
  type: 'custom' | 'brand_custom' | 'lift_order' | 'network' | 'special_operation' | 'brand_custom_network' | 'widget';
  expiresAt: string;
}

export interface UpdateBrandRetailerRelationPayload {
  firstFeesPercentage?: number;
  repeatFeesPercentage?: number;
  automaticOrderValidationMode?: boolean;
}

export interface ConfirmShippingQuotePayload {
  labelFormat?: AnkorstoreLabelFormat;
  tracking?:
    | {
        trackingProvider: string;
        trackingNumber: string;
      }
    | {
        trackingLink: string;
      };
}

export interface ConfirmShippingQuoteResponsePayload {
  /** Quote db id (deprecated) */
  id?: string;
  /** Uuid of the quote */
  uuid?: string;
  /** Order master uuid linked to the quote */
  orderUuid?: string;
  /** Provider of the quote (ups, ankorstore...) */
  provider?: string;
  /** Currency of the quote */
  currency?: string;
  /** Refund done to the brand for custom quote - Available only for custom quote */
  refund?: string;
  /** Shipping cost subtracted from the total amount for Ankorstore quotes - Available only for Ankorstore quotes */
  shippingCost?: string;
}
