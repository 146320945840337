<template>
  <div
    ref="quickFilterRef"
    class="ds-relative"
  >
    <AkButton
      ref="quickFilterButton"
      :symbol="buttonSymbol"
      :color="buttonColor"
      :outlined="!selected"
      symbol-position="right"
      class="ds-flex ds-gap-2"
      @click="toggleOpen"
    >
      <slot name="title">
        <slot name="badgeBefore" />
        <div :class="{ 'ds-font-semibold': selected }">
          {{ title }}
        </div>
        <slot name="badgeAfter" />
      </slot>
      <AkPill
        v-if="subTitle && selected && hasDropdownContent"
        :content="subTitle"
        :active="true"
        color="success-100"
        text-color="accent-700"
      />
    </AkButton>

    <AkExpander
      ref="quickFilterDropdown"
      :open="isOpen"
      :keep-alive="isDropdownEnabled"
      :class="['ds-absolute ds-top-full ds-mt-2', 'ds-z-10']"
      data-testid="quick-filter-dropdown"
    >
      <div
        :class="[
          'ds-whitespace-nowrap',
          'ds-bg-white',
          'ds-shadow-lg ds-rounded-md',
          'ds-border ds-border-solid ds-border-neutral-500',
          'ds-p-4',
        ]"
      >
        <slot />
      </div>
    </AkExpander>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, useSlots } from 'vue';
import { AkButton, AkExpander, AkPill } from '@ankorstore/design-system';
import { onClickOutside } from '@vueuse/core';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subTitle: {
    type: String,
    default: '',
  },
  selected: {
    type: Boolean,
    default: false,
  },
  enableDropdown: {
    type: Boolean,
    default: true,
  },
});

const slots = useSlots();

const quickFilterRef = ref<HTMLDivElement>();

const buttonColor = computed(() => (props.selected ? 'success' : 'white'));

const hasDropdownContent = computed(() => 'default' in slots);
const isDropdownEnabled = computed(() => props.enableDropdown && hasDropdownContent.value);

const buttonSymbol = computed(() => {
  if (!isDropdownEnabled.value) {
    return '';
  }

  return isOpen.value ? 'chevron-up' : 'chevron-down';
});

const isOpen = ref(false);
const toggleOpen = () => {
  if (!isDropdownEnabled.value) {
    return;
  }

  isOpen.value = !isOpen.value;
};

onClickOutside(quickFilterRef, () => isOpen.value = false);
</script>
