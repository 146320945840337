<template>
  <li>
    <button
      class="ak-dropdown__item ak-input--md ds-px-3"
      :class="{ 'ds-bg-warm-white': isSelectedOption }"
      role="option"
      type="button"
      data-testid="placesAutocompleteDropdownItem"
      @click.prevent="$emit('select-option', option)"
      @keydown.tab="$emit('button-tab', index)"
    >
      <div
        :class="[
          'ds-text-neutral-900 ds-mb-1 ds-text-left',
          {
            'ds-font-semibold': isSelectedOption,
          },
        ]"
      >
        <slot
          v-bind="{ option }"
          name="item"
        />
      </div>
    </button>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AutocompleteDropdownItem',
  props: {
    option: {
      type: Object,
      required: false,
      default: () => {},
    },
    isSelected: {
      type: Boolean,
      required: false,
    },
    index: {
      type: Number,
      required: true,
    },
    selectedOptionId: {
      type: Number,
      required: true,
    },
  },
  emits: ['select-option', 'button-tab'],
  computed: {
    isSelectedOption() {
      return this.selectedOptionId === this.index + 1;
    },
  },
});
</script>
