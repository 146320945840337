<template>
  <ul>
    <li class="divider" />
    <li>
      <a :href="links.offers_and_events">
        <span class="badge-holder">
          {{ $t('account.brand.offersAndEvent') }}
          <BadgePendingActions :categories="['offers_and_events']" />
        </span>
      </a>
    </li>
    <li v-if="isCampaignAvailable">
      <a :href="links.campaigns">
        <span class="badge-holder">
          {{ $t('Campaigns') }}
          <BadgePendingActions :categories="['campaigns']" />
        </span>
      </a>
    </li>
    <li>
      <a :href="links.network">
        <span class="badge-holder">
          {{ $t('My network') }}
          <BadgePendingActions :categories="['network']" />
        </span>
      </a>
    </li>
    <li>
      <a :href="links.orders">
        <span class="badge-holder">
          {{ $t('Orders') }}
          <BadgePendingActions :categories="['orders']" />
        </span>
      </a>
    </li>
    <li v-if="isEnabled('OXP-1484')">
      <a :href="links.preorders">
        <span class="badge-holder">
          {{ $t('accountMenu.preorders') }}
        </span>
        <AkBadge
          :content="$t('Beta')"
          size="xs"
          color="blue"
          class="menu-badge--new"
        />
      </a>
    </li>
    <li v-if="isEnabled('prepared_orders')">
      <a :href="links.prepared_orders">
        <span class="badge-holder">
          {{ $t('Prepared orders') }}
        </span>
        <AkBadge
          v-if="isEnabled('prepared_orders_new')"
          :content="$t('New')"
          size="xs"
          color="green"
          class="menu-badge--new"
        />
      </a>
    </li>
    <li>
      <a :href="links.reviews">
        {{ $t('Reviews') }}
      </a>
    </li>
    <li v-if="isEnabled('messages')">
      <a :href="links.messages">
        <span class="badge-holder">
          {{ $t('Messages') }}
          <BadgePendingActions :categories="['messages']" />
        </span>
      </a>
    </li>
    <li v-if="isBapAvailable">
      <a :href="links.brand_accelerator_program">
        <span class="badge-holder">
          {{ $t('Accelerator') }}
          <BadgePendingActions :categories="['brand_accelerator_program']" />
        </span>
      </a>
    </li>
    <li v-if="isEnabled('MNY_115')">
      <a :href="links.brand_dashboard_ads">
        <span class="badge-holder">
          {{ $t('brandMenu.ads.text') }}
        </span>
        <AkBadge
          v-if="isEnabled('MNY_182')"
          :content="$t('Beta')"
          class="menu-badge--new"
          color="blue"
          size="xs"
        />
      </a>
    </li>
    <li v-if="isEnabled('account_product')">
      <a :href="links.products">
        <span class="badge-holder">
          {{ $t('Products') }}
          <BadgePendingActions :categories="['products']" />
        </span>
        <AkBadge
          v-if="isEnabled('account_menu.new_badges.products')"
          :content="$t('New')"
          size="xs"
          color="green"
          class="menu-badge--new"
        />
      </a>
    </li>
    <li v-if="isEnabled('account_product_collections')">
      <a :href="links.productCollections">
        <span class="badge-holder">
          {{ $t('Collections') }}
        </span>
      </a>
    </li>
    <li v-if="hasFulFillment">
      <a :href="links.fulfillment">
        {{ $t('Fulfilment') }}
      </a>
    </li>
    <li v-if="isEnabled('account_store_page')">
      <a :href="links.store">
        <span class="badge-holder">
          {{ $t('brand.account.menu.mobile.storePage.lbl') }}
        </span>
      </a>
    </li>

    <li v-if="!isEnabled('account_store_page')">
      <a
        :href="brand.link"
        target="_blank"
      >
        {{ $t('Online Store') }}
      </a>
    </li>
    <li v-if="isEnabled('account_brand_private_offer_page')">
      <a :href="links.privateOffers">
        {{ $t('Exclusivities') }}
      </a>
    </li>
    <li
      v-if="isEnabled('account_brand_analytics')"
      data-testid="analytics-dashboard-mobile"
    >
      <a :href="links.analyticsDashboard">
        {{ $t('Analytics') }}
      </a>
    </li>
    <li>
      <a :href="links.payments">
        <span class="badge-holder">
          {{ $t('Payments') }}
          <BadgePendingActions :categories="['payments']" />
        </span>
      </a>
    </li>
    <li v-if="showBillingItem">
      <a :href="links.billing">
        <span class="badge-holder">
          {{ $t('header.menu-mobile.billing') }}
        </span>
      </a>
    </li>
    <li v-if="isEnabled('account_integrations')">
      <a :href="links.integrations">
        <span class="badge-holder">
          {{ $t('Integrations') }}
        </span>
        <AkBadge
          v-if="isEnabled('account_menu.new_badges.integrations')"
          :content="$t('New')"
          size="xs"
          color="green"
          class="menu-badge--new"
        />
      </a>
    </li>
    <li>
      <a :href="links.settings">
        <span class="badge-holder">
          {{ $t('Settings') }}
          <BadgePendingActions :categories="['settings']" />
        </span>
      </a>
    </li>
    <MenuMobileItemHelpCenter />
    <li class="divider" />
    <li>
      <a
        :href="links.logout"
        onclick="document.getElementById('logout-form').submit(); return false;"
      >{{ $t('Logout') }}</a>
    </li>
    <li class="divider" />
  </ul>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { hasEventPage } from '@monolith/legacy/services/api/business-events';
import BadgePendingActions from '@monolith/legacy/components/account/badge-pending-actions.vue';
import MenuMobileItemHelpCenter from '@monolith/legacy/components/header/menu-mobile-item-help-center.vue';
import { AkBadge } from '@ankorstore/design-system';
import { isEnabled } from '@monolith/legacy/services/features';

export default defineComponent({
  name: 'MenuMobileBrand',
  components: {
    MenuMobileItemHelpCenter,
    BadgePendingActions,
    AkBadge,
  },
  props: {
    links: { type: Object, required: true },
    hasFulFillment: { type: Boolean, required: true },
    brand: { type: Object, required: true },
  },
  data() {
    return {
      hasEvents: false,
      isEnabled,
    };
  },
  computed: {
    isCampaignAvailable(): boolean {
      return isEnabled('campaigns') && this.$store.state.brand.lift_promocode !== null;
    },
    isBapAvailable(): boolean {
      return !isEnabled('mxb-703') || isEnabled('has-bap-credits');
    },
    showBillingItem(): boolean {
      return this.hasFulFillment || (isEnabled('MNY_321') && isEnabled('MNY_115'));
    },
  },
  async created() {
    const hasEvents = await hasEventPage();
    this.hasEvents = hasEvents;
  },
});
</script>
