import { getInjectedContent } from '@monolith/legacy/services/injected-content';
import { GlobalMetaTagName } from '@monolith/legacy/services/initial-state';
import { ImgCdnParameters, ImgCdnImageTransformFn, ImgCdnImageFormat } from './types';
import { getVariant, isEnabled } from '@monolith/legacy/services/features';

export const IMG_CDN_BASE_URL = getInjectedContent(GlobalMetaTagName.CDN) ?? 'img.ankorstore.com';

export const imgCdnUrl = (filename: string, size: string = null, extraParameters: ImgCdnParameters = {}): string => {
  const parameters: ImgCdnParameters = {
    dpr: `${window.devicePixelRatio}` || '1',
    ...extraParameters,
  };

  if (size && !parameters.w) {
    parameters.w = size;
  }

  if (isEnabled('img_cdn_webp')) {
    parameters.force_format = ImgCdnImageFormat.WEBP;
  }

  const urlParams = new URLSearchParams(parameters);

  return `https://${IMG_CDN_BASE_URL}${filename}?${urlParams}`;
};

export const imgCdnUrlFill = (filename: string, size: string, extraParameters: ImgCdnParameters = {}): string => {
  return imgCdnUrl(filename, size, {
    h: `${size}`,
    w: `${size}`,
    func: ImgCdnImageTransformFn.FIT,
    ...extraParameters,
  });
};

export const imgCdnUrlCrop = (filename: string, size: string, extraParameters: ImgCdnParameters = {}): string => {
  const cropGravity: ImgCdnParameters['gravity'] = getVariant('crop_image_algo');
  const parameters: ImgCdnParameters = {
    h: `${size}`,
    w: `${size}`,
    func: cropGravity === 'off' ? ImgCdnImageTransformFn.FACE : ImgCdnImageTransformFn.CROP,
  };
  if (cropGravity !== 'off') {
    parameters.gravity = cropGravity;
  }
  return imgCdnUrl(filename, size, {
    ...parameters,
    ...extraParameters,
  });
};
