<script setup lang="ts">
import { useRouter } from 'vue-router';
import { SearchAutocomplete, useSearchStore } from '@client/modules/discovery/feature/search';
import { http } from '@monolith/legacy/services/api/http';
import RootStateHelpers from '@client/monolith/legacy/store/helpers';

const { userCountry } = RootStateHelpers.useGetters(['userCountry']);

useSearchStore([http()], userCountry, useRouter());
</script>

<template>
  <SearchAutocomplete />
</template>
