import 'core-js/features/array/at';

import './core/configure'; // this must be imported before the app to ensure the configuration is done before any app code executes
import { createApp } from './core/app';
import store from '@monolith/legacy/store';
import 'autotrack';
// @vite import requirements
import '@css/vue-import.scss';
import '@css/app.scss';

createApp(store);

