<template>
  <div :class="listClasses">
    <SearchAutocompleteCategoryTile
      v-for="item in categoriesTileData"
      :key="`category-tile-${item.id || 'new'}`"
      :tile-data="item"
      @click="handleTileClick"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import SearchAutocompleteCategoryTile from './category-tile.vue';
import { CategoriesTileData } from './types';

export default defineComponent({
  name: 'CategoryTiles',
  components: { SearchAutocompleteCategoryTile },
  props: {
    columns: {
      type: Number as PropType<2 | 3 | 4>,
      default: 2,
    },
    categoriesTileData: {
      type: Array as PropType<CategoriesTileData>,
      required: true,
    },
  },
  emits: ['tileClicked'],
  setup(props, { emit }) {
    const listClasses = computed(() => {
      const classes = ['ds-grid', 'ds-gap-x-4', 'ds-gap-y-6'];

      switch (props.columns) {
        case 2:
          classes.push('ds-grid-cols-2');
          break;
        case 3:
          classes.push('ds-grid-cols-3');
          break;
        case 4:
          classes.push('ds-grid-cols-4');
          break;
      }

      return classes.join(' ');
    });
    const handleTileClick = (tileCategoryId: number) => {
      emit('tileClicked', tileCategoryId);
    };
    return { listClasses, handleTileClick };
  },
});
</script>
