<template>
  <IntersectionObserver @change="handleObserverChange">
    <figure
      class="image-lazy-load"
      :style="`--image-lazy-load__image-height: ${imageHeight}px;`"
    >
      <loader-icon
        v-if="!isLoaded"
        class="image-lazy-load__spinner"
      />
      <img
        v-if="shouldLoad"
        v-bind="$attrs"
        :class="{
          'image-lazy-load__image': true,
          'image-lazy-load__image--loaded': isLoaded,
          'image-lazy-load__image--fixed-height': isLoaded && props.fixedHeight,
        }"
        @load="handleImageLoaded"
      >
    </figure>
  </IntersectionObserver>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ImageLazyload',
});
</script>
<script setup lang="ts">
import { ref } from 'vue';
import LoaderIcon from '@monolith/legacy/components/loader-icon.vue';
import IntersectionObserver from '@monolith/legacy/components/global/interception-observer.vue';

const props = defineProps<{ fixedHeight?: boolean }>();

const emit = defineEmits<{
  (e: 'loaded', value: HTMLImageElement): void;
}>();

const shouldLoad = ref<boolean>(false);
const isLoaded = ref<boolean>(false);
const imageHeight = ref<number>(undefined);

const handleObserverChange = (entry: IntersectionObserverEntry, unobserve: () => void) => {
  if (!entry.isIntersecting) {
    return;
  }
  shouldLoad.value = true;
  unobserve();
};

const handleImageLoaded = (event: Event) => {
  const image = event.target as HTMLImageElement;
  emit('loaded', image);
  imageHeight.value = image.height;
  isLoaded.value = true;
};
</script>

<style scoped lang="scss">
.image-lazy-load {
  position: relative;
  aspect-ratio: 1 / 1;

  &__spinner {
    left: calc(50% - 16px);
    top: calc(50% - 16px);
    position: absolute;
  }

  & &__image {
    transition: opacity 1s;
    aspect-ratio: 1 / 1;
    opacity: 0;
    height: auto;
    &--loaded {
      opacity: 1;
    }
    &--fixed-height {
      width: var(--image-lazy-load__image-height);
      height: var(--image-lazy-load__image-height);
    }
  }
}
</style>
