<!-- eslint-disable @intlify/vue-i18n/no-dynamic-keys -->
<template>
  <div class="checkbox-form">
    <div
      v-for="answer in question.answers"
      :key="answer.id"
    >
      <AkCheckbox
        v-if="isRevamp"
        :key="`${answer.id}-input`"
        v-model="currentModel[question['alias']]"
        data-testid="questionCheckboxItem"
        :value="currentModel[question['alias']]"
        :checked="currentModel[question['alias']] === answer.alias"
        @change="handleChange(question['alias'], answer, $event)"
      >
        <label data-testid="questionCheckboxLabel">
          {{ $t(answer.label) }}
        </label>
      </AkCheckbox>
      <template v-else>
        <input
          :id="answer.alias"
          :key="`${answer.id}-input`"
          v-model="currentModel[question['alias']]"
          :class="vuelidateFieldClassName(state[question.alias])"
          data-testid="questionCheckboxItem"
          type="checkbox"
          :name="question.alias"
          :required="question.required"
          @change="onCheckboxChange(question.alias, answer)"
        >
        <label
          :key="answer.id + '-label'"
          :for="answer.alias"
          class="side-label ds-mt-0"
          data-testid="questionCheckboxLabel"
        >
          {{ $t(answer.label) }}
        </label>
      </template>
      <span
        v-if="state[question.alias] && state[question.alias].$dirty && state[question.alias].required.$invalid"
        class="error-message"
        data-testid="questionCheckboxesError"
      >{{ $t('This field is required') }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Answer, Question } from '@monolith/legacy/types/question';
import { vuelidateFieldClassName } from '@core/utilities/fieldClassName';
import { State } from '@monolith/legacy/types/state';

export default defineComponent({
  name: 'QuestionCheckboxes',
  props: {
    question: {
      type: Object as () => Question,
      required: true,
    },
    state: {
      type: Object as () => State,
      required: true,
    },
    isRevamp: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      currentModel: {
        [this.question.alias]: '',
      },
    };
  },
  methods: {
    handleChange(alias, answer, value) {
      this.currentModel[alias] = answer.alias;

      this.$emit('change', alias, {
        content: answer.alias,
        answer_id: value ? answer.id : '',
      });
    },
    onCheckboxChange(alias: string, answer: Answer): void {
      const isChecked = this.currentModel[alias];
      this.$emit('change', alias, {
        answer_id: isChecked ? answer.id : null,
      });
    },
    vuelidateFieldClassName,
  },
});
</script>
