import { Amount } from '@core/types/amount';
import { CountryIsoCode } from '@core/types/country';

export interface PaginationData {
  current: number;
  size: number;
  totalPages: number;
  totalResults: number;
}

export enum ContactFilterName {
  Segment = 'segment',
}

export enum ContactOrigin {
  Ankorstore = 'ankorstore',
  Imported = 'imported',
}

export enum LiftEligibility {
  Eligible = 'eligible',
  Claimed = 'claimed',
  Ineligible = 'ineligible',
}

export enum SortColumns {
  shop_name = 'retailer',
  total_spent = 'ordering.totalSpent.amount',
  last_purchase = 'ordering.lastOrderedAt.range',
  eligible_lift = 'ordering.liftEligible',
  commission_fees = 'commercialTerms.serviceFees',
  personalised_discount = 'commercialTerms.brandDiscount',
}

export enum SubscriptionStatus {
  subscribed = 'subscribed',
  unsubscribed = 'unsubscribed',
  suppressed = 'suppressed',
}

export interface RetailerContact {
  id: number;
  uuid: string;
  name: string;
  shopType: string;
  createdAt: string;
  contact: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    country: CountryIsoCode;
    language: string;
    city: string;
    address: string;
  };
  aksPlus: {
    active: boolean;
    eligible: boolean;
  };
}

export interface ContactItem {
  type: 'contact-document';
  attributes: {
    id: number;
    uuid: string;
    contact: {
      email: string;
      language: string;
    };
    relation: {
      addedAt: string;
      invitedAt: string;
      affiliatedAt: string;
      status: string;
      origin: ContactOrigin;
    };
    retailer: RetailerContact;
    commercialTerms: {
      serviceFees: number;
      brandDiscount: number;
      repeatOffer: number;
      hasRepeatOffer: boolean;
      hasBrandDiscount: boolean;
      amountToUnlockRepeatOffer: number;
      freeShippingForBrandUntil: string;
      repeatOfferUnlockedUntil: string;
    };
    ordering: {
      lastOrderedAt: string;
      lastOrderedAmount: Amount;
      totalCompletedOrders: number;
      totalSpent: Amount;
      liftEligible: boolean;
      liftRedeemed: boolean;
    };
    preparedOrders: {
      number: number;
    };
    cart: {
      amountInCart: Amount;
      cartUpdatedAt: string;
    };
    communication: {
      messaging: {
        lastContactedAt: string;
        lastMessageBy: string;
      };
      emailing: {
        lastContactedAt: string;
        subscriptionStatus: SubscriptionStatus;
      };
    };
  };
}
