<template>
  <div>
    <div
      class="drawer-mask"
      :class="isOpen ? 'ds-w-full ds-opacity-30' : 'ds-w-0 ds-opacity-0'"
      :style="{
        '--top': topOffset,
        '--zIndex': isClipped ? 3 : 5,
      }"
      @click="onBackgroundClick"
    />
    <div
      class="drawer"
      :class="isOpen ? ['ds-pl-2', 'ds-w-full', widthClass] : ['ds-w-0', 'ds-pl-0']"
      :style="{
        '--top': topOffset,
        '--zIndex': isClipped ? 4 : 6,
      }"
    >
      <div
        v-if="isOpen"
        class="ds-w-full ds-h-full ds-flex ds-flex-col ds-flex-1"
      >
        <div class="ds-relative ds-p-4">
          <div v-if="title">
            <div class="ds-flex ds-flex-col">
              <span class="ds-font-basic ds-text-xl">{{ title }}</span>
              <span v-if="subtitle">
                {{ subtitle }}
                <AkIcon
                  v-if="linkIcon"
                  :symbol="linkIcon"
                />
                <a
                  v-if="link"
                  :href="link.url"
                  target="_blank"
                  class="ak-link"
                >
                  {{ link.text }}
                </a>
              </span>
            </div>
            <hr>
          </div>
          <button
            class="ds-p-4 ds-absolute ds-right-1 ds-top-0 close-icon"
            @click.prevent="triggerCloseDrawer"
          >
            <AkIcon symbol="x" />
          </button>
        </div>
        <component :is="drawerComponent" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import DrawerComponents from './drawers';
import { debounce } from 'lodash-es';
import { DrawerSize } from '@monolith/legacy/store/drawer';

const DRAWER_SIZES: {
  [key in DrawerSize]: string;
} = {
  small: 'ds-w-1/4',
  medium: 'ds-w-1/2',
  large: 'ds-w-3/4',
  full: 'ds-w-full',
};

export default defineComponent({
  name: 'Drawer',
  components: { ...DrawerComponents },
  data() {
    return {
      topOffset: '0px',
    };
  },
  computed: {
    ...mapGetters('drawer', ['isOpen', 'drawerComponent', 'drawerOptions', 'isClipped', 'isPersistent', 'preventClose']),
    widthClass() {
      return `sm:${DRAWER_SIZES[this.drawerOptions.size]}`;
    },
    title() {
      return this.drawerOptions.title;
    },
    subtitle() {
      return this.drawerOptions.subtitle;
    },
    linkIcon() {
      return this.drawerOptions.link?.icon;
    },
    link() {
      return this.drawerOptions.link;
    },
  },
  mounted() {
    this.calculateTopOffset();
    window.addEventListener('resize', debounce(this.calculateTopOffset, 100));
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateTopOffset);
  },
  methods: {
    ...mapActions('drawer', ['closeDrawer', 'openDrawer', 'setRequestClose']),
    calculateTopOffset() {
      if (this.isClipped) {
        const content = document.getElementById('content');
        this.topOffset = `${content?.getBoundingClientRect()?.top}px`;
      }
    },
    onBackgroundClick() {
      if (this.isPersistent) {
        return;
      }
      this.triggerCloseDrawer();
    },
    triggerCloseDrawer() {
      if (this.preventClose) {
        this.setRequestClose(true);

        return;
      }

      this.closeDrawer();
    },
  },
});
</script>

<style lang="scss">
// header mobile is at z-index 200
// header desktop is at z-index 4
$drawer_z_index: 4;

.drawer {
  @apply ds-border-l ds-border-solid ds-border-white ds-fixed ds-right-0 ds-overflow-hidden ds-overflow-y-scroll ds-bg-white ds-transition-all;
  top: var(--top);
  z-index: var(--zIndex);
  height: calc(100% - var(--top));
}

.drawer-mask {
  @apply ds-fixed ds-inset-0 ds-h-full ds-bg-neutral-900;
  top: var(--top);
  background: black;
  z-index: var(--zIndex);
}
</style>
