<template>
  <div
    v-if="!isCheckoutPage"
    ref="rootRef"
    class="search-autocomplete"
    :class="['ds-relative', 'ds-flex', 'ds-flex-col', 'ds-text-sm']"
    data-testid="searchBar"
  >
    <div
      class="search-autocomplete__bar"
      :class="[
        'ds-fixed',
        'sm:ds-static',
        'ds-z-50',
        'ds-flex',
        'ds-items-center',
        'ds-w-full',
        'ds-h-7',
        'md:ds-p-4',
        'sm:ds-border',
        'md:ds-border-neutral-600',
        'md:ds-border-solid',
        'md:ds-rounded-md',
        'ds-shadow-md',
        'md:ds-shadow-none',
        'ds-transition-colors',
        'ds-bg-white',
        'md:ds-bg-neutral-100',
        'md:focus-within:ds-bg-white',
      ]"
    >
      <button
        data-testid="searchAutocompleteBackButton"
        :class="['ds-py-2', 'ds-px-2', 'ds-cursor-pointer', 'md:ds-px-0', 'md:ds-hidden', 'ds-rotate-180']"
        @click="$emit('close')"
      >
        <AkIcon
          size="md"
          symbol="arrow-right"
          :class="['ds-text-neutral-700']"
        />
      </button>
      <input
        ref="inputRef"
        v-model="searchInput"
        data-testid="SearchBoxInput"
        type="search"
        autocomplete="off"
        :placeholder="$t('Search for a product, brands or a theme')"
        :class="[
          'ds-py-0',
          'ds-flex-1',
          'ds-border-l',
          'ds-border-neutral-300',
          'ds-border-solid',
          'ds-p-2',
          'ds-bg-white',
          'md:ds-order-2',
          'md:ds-border-none',
          'focus:ds-outline-none',
          'sm:ds-bg-transparent',
        ]"
        @input="onChange"
        @keydown.down="moveArrowDown"
        @keydown.up="moveArrowUp"
        @keydown.enter="onEnter"
        @keydown.esc="onEsc"
        @focus="onFocus"
        @blur="onBlur"
      >
      <button
        data-testid="searchAutocompleteClearButton"
        :class="['ds-py-2', 'ds-px-2', 'ds-cursor-pointer', 'md:ds-px-0', 'md:ds-order-3', 'md:ds-border-none']"
        @click="clearSearch"
      >
        <AkIcon
          v-if="searchInput"
          size="md"
          symbol="x"
          :class="['ds-text-neutral-700']"
        />
      </button>
      <button
        data-testid="searchAutocompleteSearchButton"
        :class="[
          'ds-py-2',
          'ds-px-2',
          'ds-cursor-pointer',
          'md:ds-px-0',
          'ds-border-l',
          'ds-border-neutral-300',
          'ds-border-solid',
          'md:ds-order-1',
          'md:ds-border-none',
        ]"
        @click="onClickSeeAllResults"
      >
        <AkIcon
          size="md"
          symbol="search"
          :class="['ds-text-neutral-700']"
        />
      </button>
    </div>
    <SearchAutocompleteSuggestions
      v-if="shouldDisplaySuggestions"
      :key="`search-autocomplete-suggestions-${suggestionsKey}`"
      :class="['ds-pt-8', 'sm:ds-pt-4', 'md:ds-pt-0', 'xl:ds-absolute', 'xl:ds-top-7', 'md:ds-mt-0.5']"
      @click-see-all-results="onClickSeeAllResults"
      @click-r2b-link="onClickR2BLink"
    />
    <div
      v-if="shouldDisplaySuggestions && isDesktop"
      data-testid="searchAutocompleteOverlay"
      :class="[
        'ds-hidden',
        'ds-w-full',
        'ds-h-full',
        'ds-top-0',
        'ds-left-0',
        'ds-right-0',
        'ds-bottom-0',
        'lg:ds-block',
        'lg:ds-bg-primary',
        'lg:ds-opacity-50',
        'lg:ds-z-10',
        'lg:ds-fixed',
      ]"
      @click="resetCurrentResults"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted, nextTick } from 'vue';
import { onClickOutside } from '@vueuse/core';
import SearchAutocompleteSuggestions from './search-autocomplete-suggestions.vue';
import {
  RESET_ARROW_POSITION,
  SET_ARROW_POSITION_DOWN,
  SET_ARROW_POSITION_UP,
  SET_ORIGINAL_USER_INPUT,
  SET_RESULTS_VISIBILITY,
} from '../store/mutation-types';
import {
  BROWSE_TO_RESULT,
  DO_RESET_RESULTS,
  FETCH_RESULTS_ELASTIC,
  HANDLE_CLICK_ALL_RESULTS,
  HANDLE_ITEM_CLICK,
  HANDLE_R2B_CLICK,
  INIT_RESULTS,
  SAVE_RECENT_SEARCH,
  SET_RECENT_SEARCH_FOR_QUERY,
} from '../store/action-types';
import { debounce } from 'lodash-es';
import { AkIcon } from '@ankorstore/design-system';
import useIsCheckout from '@core/composables/use-enclosed-checkout';
import { useStore } from 'vuex';
import { useBreakpoints } from '@monolith/legacy/modules/design-system-candidates';
import SearchStoreHelpers from '../store/helpers';

import { R2B_LINK_HREF, R2B_LINK_ID, SHOW_ALL_ID } from '@bc/discovery/feature/search/constants';

let unsubscribe: () => void;

const props = withDefaults(defineProps<{ searchquery?: string }>(), {
  searchquery: '',
});
const emit = defineEmits(['close']);

const store = useStore();
const { isDesktop } = useBreakpoints();
const { isCheckoutPage } = useIsCheckout();

const {
  arrowPosition,
  isOpen,
  originalUserInput,
  currentSelectionId,
  currentSelectionName,
  currentSelectedItem,
  hasSuggestions,
} = SearchStoreHelpers.useGetters([
  'arrowPosition',
  'isOpen',
  'originalUserInput',
  'currentSelectionId',
  'currentSelectionName',
  'currentSelectedItem',
  'hasSuggestions',
]);

const {
  [RESET_ARROW_POSITION]: resetArrowPosition,
  [SET_ARROW_POSITION_UP]: setArrowPositionUp,
  [SET_ARROW_POSITION_DOWN]: setArrowPositionDown,
  [SET_ORIGINAL_USER_INPUT]: setOriginalUserInput,
  [SET_RESULTS_VISIBILITY]: setResultsVisibility,
} = SearchStoreHelpers.useMutations([
  RESET_ARROW_POSITION,
  SET_ARROW_POSITION_UP,
  SET_ARROW_POSITION_DOWN,
  SET_ORIGINAL_USER_INPUT,
  SET_RESULTS_VISIBILITY,
]);

const {
  [INIT_RESULTS]: initResults,
  [FETCH_RESULTS_ELASTIC]: fetchResultsElastic,
  [DO_RESET_RESULTS]: doResetResults,
  [HANDLE_ITEM_CLICK]: handleItemClick,
  [HANDLE_CLICK_ALL_RESULTS]: handleClickAllResults,
  [HANDLE_R2B_CLICK]: handleR2BClick,
  [SAVE_RECENT_SEARCH]: saveRecentSearch,
  [SET_RECENT_SEARCH_FOR_QUERY]: setRecentSearchForQuery,
} = SearchStoreHelpers.useActions([
  INIT_RESULTS,
  FETCH_RESULTS_ELASTIC,
  DO_RESET_RESULTS,
  BROWSE_TO_RESULT,
  HANDLE_ITEM_CLICK,
  HANDLE_CLICK_ALL_RESULTS,
  HANDLE_R2B_CLICK,
  SAVE_RECENT_SEARCH,
  SET_RECENT_SEARCH_FOR_QUERY,
]);

const rootRef = ref<HTMLDivElement>();
const inputRef = ref<HTMLInputElement>();
const searchInput = ref(props.searchquery);
const suggestionsKey = ref(0);
const isFocused = ref(false);

const shouldDisplaySuggestions = computed(() => (!isDesktop.value || isOpen.value) && hasSuggestions.value);

const resetCurrentResults = () => {
  resetArrowPosition();
  doResetResults(searchInput.value);
  setResultsVisibility(false);
};

const search = async () => {
  resetArrowPosition();
  await fetchResultsElastic({ query: searchInput.value });
  setResultsVisibility(isFocused.value);
  suggestionsKey.value = suggestionsKey.value + 1;
};

const debouncedSearch = debounce(search, 500);

const updateCurrentQuery = () => {
  searchInput.value =
    currentSelectedItem.value?.link === R2B_LINK_HREF
      ? originalUserInput.value
      : currentSelectionName.value || originalUserInput.value;
};

const clearSearch = () => {
  searchInput.value = '';
  doResetResults();
  triggerBlur();
};

const triggerBlur = async () => {
  await nextTick();
  inputRef.value?.blur();
};

const triggerFocus = async () => {
  await nextTick();
  inputRef.value?.focus();
};

const onFocus = () => {
  search();
  isFocused.value = true;
  setRecentSearchForQuery(searchInput.value);
};

const onBlur = () => {
  if (isDesktop.value) {
    isFocused.value = false;
  }
};

const onChange = (e: Event) => {
  const query = (e.target as HTMLInputElement).value;
  searchInput.value = query;
  if (!query) {
    doResetResults();
  }
  setOriginalUserInput(query);
  setRecentSearchForQuery(query);
  debouncedSearch();
};

const onResultItemClick = () => {
  handleItemClick({ item: currentSelectedItem.value });
};

const onClickSeeAllResults = () => {
  originalUserInput.value && handleClickAllResults({ searchQuery: searchInput.value });
};

const onClickR2BLink = () => {
  handleR2BClick({ href: R2B_LINK_HREF, searchQuery: searchInput.value });
};

const onEnter = () => {
  if (currentSelectedItem.value) {
    switch (currentSelectionId.value) {
      case R2B_LINK_ID: {
        onClickR2BLink();
        break;
      }
      case SHOW_ALL_ID: {
        onClickSeeAllResults();
        break;
      }
      default: {
        onResultItemClick();
      }
    }
  } else {
    onClickSeeAllResults();
  }
  saveRecentSearch({ searchQuery: searchInput.value });
  triggerBlur();
};

const onEsc = () => {
  triggerBlur();
  searchInput.value = originalUserInput.value;
  doResetResults(searchInput.value);
  setResultsVisibility(false);
};

const moveArrowUp = () => {
  setArrowPositionUp();
};

const moveArrowDown = () => {
  setArrowPositionDown();
};

watch(arrowPosition, (value, oldValue) => {
  if (value !== oldValue) {
    updateCurrentQuery();
  }
});

onMounted(() => {
  unsubscribe = store.subscribeAction({
    after: (action) => {
      if (action.type === `searchBar/${BROWSE_TO_RESULT}`) {
        emit('close');
      }
    },
  });
  !isDesktop.value && triggerFocus();
});

onUnmounted(() => {
  unsubscribe?.();
});

onClickOutside(rootRef, () => {
  isDesktop.value && resetCurrentResults();
});

setOriginalUserInput(props.searchquery);
initResults();
</script>
