<template>
  <div class="input-message">
    <AkTextarea
      v-model="input"
      :rows="4"
      class="input-message__textarea"
      :class="[{ minHeight: input.length > 20 }]"
      :placeholder="$t('Write your message here')"
    />
    <div
      class="ds-flex ds-justify-between ds-items-end"
      :class="enabledAttachments ? 'ds-flex-row' : 'ds-flex-row-reverse'"
    >
      <div
        v-if="enabledAttachments"
        class="ds-flex-col ds-relative ds-mb-5"
      >
        <div class="ds-flex ds-flex-row ds-items-center">
          <input
            id="conversation-upload-files"
            ref="fileUploadInput"
            type="file"
            multiple
            class="ds-hidden"
            @change="uploadFiles"
          >
          <label
            for="conversation-upload-files"
            class="ds-mr-3 ds-mb-0 ds-flex ds-flex-row ds-items-center"
          >
            <AkIcon
              symbol="paper-clip"
              class="ds-mr-3"
              size="md"
            />
            <span class="attachment-label">{{ $t('Add attachment') }}</span>
          </label>
          <span class="formats-label">{{
            $t('Recommended files: {formats}', {
              formats: '.png, .jpeg, .pdf  Max 10Mo',
            })
          }}</span>
        </div>
        <LoaderIcon
          v-if="loading"
          class="input-message__loader"
        />
        <div
          v-if="uploadedFiles.length"
          class="ds-flex ds-flex-row ds-flex-wrap ds-mt-7"
        >
          <div
            v-for="file in uploadedFiles"
            :key="file.name"
            class="conversation-uploaded-attachment"
            :class="isImage(file.type) ? 'conversation-uploaded-attachment--image' : 'conversation-uploaded-attachment--file'"
          >
            <button
              class="attachment-close-btn"
              data-testid="attachment-close-btn"
              @click="removeFile(file.name)"
            >
              <AkIcon
                symbol="x"
                class="close-icon"
              />
            </button>
            <img
              v-if="file.type === 'image'"
              :src="file.url"
              :alt="file.name"
              class="attachment-content--image"
            >
            <div
              v-else
              class="attachment-content--file"
            >
              <div class="file-name">
                {{ file.name }}
              </div>
              <div class="ds-uppercase ds-font-bold ds-mt-1">
                {{ getFileExtension(file.name) }}
              </div>
            </div>
          </div>
        </div>
        <p class="ds-text-warning-700">
          {{ filesErrorLabel }}
        </p>
      </div>
    </div>
    <AkParagraph
      v-if="legend !== null"
      class="input-message__legend"
    >
      {{ legend }}
    </AkParagraph>
    <div
      class="input-message__actions"
      :class="{ 'input-message__actions--centered': showAlternativeText }"
    >
      <AkButton
        v-if="hasSkip"
        link
        @click="skipConversation"
      >
        {{ $t('Skip') }}
      </AkButton>
      <AkButton
        ref="sendBtn"
        class="input-message__button"
        data-testid="conversation-send-message-btn"
        size="lg"
        :disabled="input.length === 0"
        @click="sendConversation()"
      >
        <AkIcon
          v-if="!showAlternativeText"
          symbol="telegram"
          class="ds-mr-1"
        />
        {{ showAlternativeText ? $t('Start the conversation') : $t('Send') }}
      </AkButton>
    </div>
  </div>
</template>

<script lang="ts">
import { conversationsMixin } from '@core/mixins/conversations';
import { mapActions } from 'vuex';
import Analytics from '@monolith/legacy/services/analytics';
import MessageAbandonedEvent from '@monolith/legacy/services/analytics/events/message-abandoned-event';
import { Recipient } from '@monolith/legacy/services/analytics/properties/conversation-property';
import { Conversation, UploadedFile } from '@monolith/legacy/types/conversation';
import { AkButton, AkIcon, AkTextarea } from '@ankorstore/design-system';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';
import LoaderIcon from '@monolith/legacy/components/loader-icon.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InputMessage',
  components: { AkButton, AkIcon, AkTextarea, LoaderIcon },
  mixins: [conversationsMixin],
  props: {
    conversationsId: {
      type: Number,
      required: false,
      default: null,
    },
    recipientId: {
      type: Number,
      required: false,
      default: null,
    },
    recipientType: {
      type: String,
      required: false,
      default: null,
    },
    recipientName: {
      type: String,
      required: false,
      default: null,
    },
    placeHolder: {
      type: String,
      required: false,
      default: null,
    },
    origin: {
      type: String,
      required: false,
      default: null,
    },
    enabledAttachments: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBestPriceIntro: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequestADiscount: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasSkip: {
      type: Boolean,
      required: false,
      default: false,
    },
    legend: {
      type: String,
      required: false,
      default: null,
    },
    showCloseConversation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['send', 'skip'],
  data() {
    return {
      input: '',
      hubspotAttribute: null,
      uploadedFiles: [],
      filesErrorLabel: null,
      loading: false,
    };
  },
  computed: {
    // Used to present different stuff for BPG and Request a discount features
    showAlternativeText(): boolean {
      return this.isBestPriceIntro || this.isRequestADiscount;
    },
  },
  created() {
    this.input = this.placeHolder ? this.placeHolder : '';
  },
  mounted() {
    if (!window.matchMedia('(min-width: ' + this.$breakpoints.lg + 'px)').matches) {
      const hubspotWidget = document.querySelector('div#hubspot-messages-iframe-container');
      if (hubspotWidget) {
        this.hubspotAttribute = hubspotWidget.getAttribute('style');
        hubspotWidget.setAttribute('style', 'display: none !important;');
      }
    }
  },
  beforeUnmount() {
    if (this.input != '') {
      let recipient: Recipient = {
        id: this.recipientId,
        name: this.recipientName,
        type: this.recipientType,
      };

      if (this.conversationsId) {
        const conversation: Conversation = this.fetchConversationById(this.conversationsId);
        if (conversation) {
          recipient = conversation.recipient.entity;
        }
      }

      Analytics.track(new MessageAbandonedEvent(recipient, this.input));
    }

    if (!window.matchMedia('(min-width: ' + this.$breakpoints.lg + 'px)').matches) {
      const hubspotWidget = document.querySelector('div#hubspot-messages-iframe-container');
      if (hubspotWidget && this.hubspotAttribute) {
        hubspotWidget.setAttribute('style', this.hubspotAttribute);
      }
    }
  },
  methods: {
    ...mapActions('conversations', ['addMessageAttachment']),
    newline() {
      this.value = `${this.value}\n`;
    },
    sendConversation() {
      //@ts-ignore
      this.$refs.sendBtn.$el.blur();
      if (!this.input) {
        return;
      }
      if (this.conversationsId) {
        this.$store.dispatch('conversations/sendMessage', {
          conversation: this.fetchConversationById(this.conversationsId),
          conversationId: this.conversationsId,
          content: this.input,
          origin: this.origin,
          attachments: this.uploadedFiles,
        });
      } else {
        this.$store.dispatch('conversations/createConversation', {
          id: this.recipientId,
          type: this.recipientType,
          content: this.input,
          origin: this.origin,
          attachments: this.uploadedFiles,
        });
      }
      // Used for request a discount feature in product pages and reordering
      // See BXP-1580
      if (this.isRequestADiscount) {
        Analytics.track(
          new UserClick({
            component: 'start_conversation',
            action: 'submit_message',
          })
        );
      }
      this.uploadedFiles = [];
      this.input = '';
      this.$emit('send');
    },
    skipConversation() {
      this.$emit('skip');
    },
    async uploadFiles($event) {
      this.loading = true;
      const files = $event.target.files;

      if (files.length) {
        this.filesErrorLabel = null;
        const imagesMimeTypes = ['image/png', 'image/jpg', 'image/jpeg'];
        const allowedMimeTypes = ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'];

        for (let index = 0; index < files.length; index++) {
          const file = files[index];

          if (!allowedMimeTypes.includes(file.type)) {
            this.filesErrorLabel = this.$t('Allowed file types: images (png / jpg), PDF documents');
          } else if (this.checkFileExists(file.name)) {
            this.filesErrorLabel = this.$t('You have already uploaded this file!');
          } else {
            let type = 'file';

            if (imagesMimeTypes.includes(file.type)) {
              type = 'image';
            }

            const response = await this.addMessageAttachment({ file });

            this.uploadedFiles.push({
              name: file.name,
              type,
              path: response.path,
              url: response.url,
            } as UploadedFile);
          }
        }

        this.loading = false;
        $event.target.value = null;
      }
    },
    checkFileExists(query: string) {
      return this.uploadedFiles.filter((file) => file.name === query).length > 0;
    },
    removeFile(fileName: string) {
      this.filesErrorLabel = null;
      this.uploadedFiles = this.uploadedFiles.filter(function (file) {
        return file.name !== fileName;
      });
    },
    isImage(type: string) {
      return type === 'image';
    },
    getFileExtension(value: string): string {
      const index = value.lastIndexOf('.');

      return value.slice(index + 1);
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.input-message {
  @apply ds-bg-white;
  &__legend {
    @apply ds-text-neutral-900 ds-text-sm;
  }
  &__textarea {
    @apply ds-mb-4;
  }
  &__actions {
    @apply ds-flex-col ds-flex md:ds-flex-row ds-justify-end ds-gap-3 md:ds-gap-5;
    &--centered {
      @apply ds-justify-center;
    }
  }

  .attachment-label {
    @apply ds-font-basic ds-text-sm ds-text-primary ds-underline;
  }

  .formats-label {
    @apply ds-font-basic ds-text-sm ds-text-neutral-600;
  }
}
:deep(.input-message__loader) {
  @apply ds-absolute;
  top: 35px;
  left: 30px;
}

:deep(.input-message div) {
  @apply ds-h-5 ds-w-5;
}

.conversation-uploaded-attachment {
  --file-attachment-width: 9rem;
  @apply ds-mr-4 ds-mb-4 ds-h-8 ds-relative ds-rounded-sm;

  @include media-breakpoint-down(lg) {
    @apply ds-mr-3 ds-mb-3 ds-h-7;
    --file-attachment-width: 6.75rem;
  }

  &--image {
    @apply ds-w-8;

    @include media-breakpoint-down(lg) {
      @apply ds-w-7;
    }
  }

  &--file {
    @apply ds-border ds-border-neutral-500 ds-bg-white;
    width: var(--file-attachment-width);
  }

  .file-name {
    @apply ds-w-full ds-text-left  ds-text-ellipsis ds-overflow-hidden;
  }
}

.attachment-content {
  &--image {
    @apply ds-h-full ds-w-full ds-object-cover ds-rounded-sm;
  }

  &--file {
    @apply ds-flex ds-flex-col ds-items-center ds-justify-center ds-px-4 ds-py-2 ds-w-full ds-h-full ds-text-base ds-whitespace-nowrap
      ds-rounded-sm ds-border ds-border-neutral-500 ds-border-solid ds-bg-white;

    @include media-breakpoint-down(lg) {
      @apply ds-py-0 ds-text-xs;
    }
  }
}

.attachment-close-btn {
  @apply ds-absolute ds-cursor-pointer ds--right-1.5 ds--top-2.5;

  .close-icon {
    &::before {
      @apply ds-bg-neutral-500 ds-rounded-full ds-p-0.5;
    }
  }
}

.ak-account-messages {
  .input-message {
    @apply ds-sticky ds-bottom-0;
    z-index: 29;
    @include media-breakpoint-down(lg) {
      @apply ds-relative;
      z-index: 3;
    }
  }
}
</style>
