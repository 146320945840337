import { createCampaign } from '@bc/brands/feature/campaigns-management/services/api';
import { MessageType, CampaignResource } from '@bc/brands/feature/campaigns-management/types/create-campaigns.types';
import { CreateCampaignActionParameters } from '@bc/brands/feature/campaigns-management/types/store.types';


export const actions = {
  async createCampaign(
    { rootGetters },
    payload: CreateCampaignActionParameters): Promise<CampaignResource> {

    const result = await createCampaign(
      {
        campaignName: payload.name,
        filters: rootGetters['account/contactManagement/getFiltersState'],
        defaultLanguage: payload.messages[0].language,
        messages: payload.messages.map(message => ({
          type: MessageType.Email,
          language: message.language,
          from: payload.from,
          subject: message.subject,
          body: message.body,
          footer: message.footer,
          ctaText: message.ctaText,
        }))
      },
      payload.startCampaign,
      payload.campaignId
    )

    return result;
  },
};

export default actions;
