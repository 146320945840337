import {
  GroupedResults,
  ItemResult,
  StaticItem,
  RecentSearchItem,
  DynamicResults,
  StaticResults,
  ResultId,
} from '@bc/discovery/domain/search';
import { SearchState } from './index';
import { uniq, cloneDeep } from 'lodash-es';
import { MAX_RESULTS, SHOW_ALL_ID } from '@bc/discovery/feature/search/constants';

const searchGetters = {
  arrowPosition: (state: SearchState): number => state.arrowPosition,
  originalUserInput: (state: SearchState): string => state.originalUserInput,
  results: (state: SearchState): GroupedResults => {
    const newResults = cloneDeep(state.results);
    if (newResults.history.length) {
      delete newResults.trending;
    }
    let maxHistoryItems: number;
    let maxSuggestionItems: number;
    const history = Array.from(newResults.history ?? []);
    const suggestions = Array.from(newResults.suggestions);
    if (newResults.history.length >= 5 && newResults.suggestions.length >= 5) {
      maxHistoryItems = maxSuggestionItems = MAX_RESULTS / 2;
    } else if (newResults.history.length >= 5) {
      maxSuggestionItems = newResults.suggestions.length;
      maxHistoryItems = MAX_RESULTS - maxSuggestionItems;
    } else {
      maxHistoryItems = newResults.history.length;
      maxSuggestionItems = MAX_RESULTS - maxHistoryItems;
    }
    history.splice(Math.max(maxHistoryItems, 0));
    suggestions.splice(Math.max(maxSuggestionItems, 0));
    newResults.history = history;
    newResults.suggestions = suggestions;
    return newResults;
  },
  resultsDynamic: (state: SearchState): DynamicResults => {
    const next = { ...state.results };
    if (next.history.length) {
      delete next.trending;
    }
    delete next.r2bLink;
    delete next.searchAll;
    return next;
  },
  resultsStatic: (state: SearchState): StaticResults => ({
    searchAll: state.results.searchAll,
    r2bLink: state.results.r2bLink,
  }),
  resultsIds: (state: SearchState): ResultId[] => state.resultsIds,
  isOpen: (state: SearchState): boolean => state.isOpen,
  getRecentSearches: (state: SearchState): Array<RecentSearchItem> => {
    return state.originalUserInput
      ? state.userLocalHistory?.filter(
          (h) => !uniq(state.results.suggestions?.map((x) => x.name?.toLowerCase())).includes(h.name?.toLowerCase())
        )
      : state.userLocalHistory;
  },
  hasSuggestions: (state: SearchState): boolean => {
    let hasElements = false;
    for (const prop in state.results) {
      const isNotEmptyKey = Array.isArray(state.results[prop]) ? state.results[prop].length : state.results[prop];
      if (prop !== 'r2bLink' && !!isNotEmptyKey) {
        hasElements = true;
        break;
      }
    }
    return hasElements;
  },
  currentSelectedItem: (state: SearchState, getters: typeof searchGetters) => {
    let result: ItemResult | StaticItem;
    for (const prop in state.results) {
      const resultItem = state.results[prop];
      if (Array.isArray(resultItem)) {
        result = resultItem.find((r) => r.id === getters.currentSelectionId);
      } else if (resultItem?.id === getters.currentSelectionId) {
        result = resultItem;
      }
      if (result) {
        break;
      }
    }
    return result;
  },
  currentSelectionId: (state: SearchState): number | string => state.resultsIds[state.arrowPosition],
  currentSelectionName: (state: SearchState, getters: typeof searchGetters): string =>
    getters.currentSelectionId === SHOW_ALL_ID ? state.originalUserInput : getters.currentSelectedItem?.name,
};

export default searchGetters;
export type SearchGetters = typeof searchGetters;
