import { captureRetailerCoreDiscoveryException } from '@client/monolith/core/plugins/sentry/helper';
import http from '@client/monolith/legacy/services/api/http';


export interface SavedSearch {
  context: string;
  filters: Array<{ field: string; operator: string; values: Array<string> }>;
  id: string;
  name: string;
  query: string;
  saved: boolean;
  type: string;
}

export const saveSearch = async (name: string, id: string, saved = true): Promise<SavedSearch> => {
  try {
    const { data } = await http().patch(`api/retailer-history/${id}`, { name: name, saved: saved });
    return data;
  } catch (error) {
    captureRetailerCoreDiscoveryException(error, [{ label: 'api', value: 'patch-saved-searches' }]);
    return null;
  }
};
