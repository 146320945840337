<template>
  <div
    class="popin-container"
    :class="{ 'popin-container--with-padding': withPadding }"
  >
    <template v-if="!isBestPriceFeedback">
      <div v-if="title">
        <AkParagraph
          weight="bold"
          class="popin-container__title"
        >
          {{ title }}
        </AkParagraph>
      </div>
      <div
        v-else
        class="promotion-banner"
      >
        <img
          v-if="isTypeBrand"
          class="brand-logo"
          :class="{ big: !hasHistory }"
          :alt="name"
          :src="imgCdnUrl(image, '158')"
        >
        <div
          v-else
          class="recipient-icon"
        >
          <AkParagraph class="ds-mb-0">
            {{ getInitialName(userFirstName, userLastName) }}
          </AkParagraph>
        </div>
        <h3 v-if="!hasHistory && isTypeRetailer">
          {{
            $t('Start a conversation with {firstname} {lastname} from {recipient}', {
              recipient: name,
              firstname: userFirstName,
              lastname: userLastName,
            })
          }}
        </h3>
        <h3 v-else-if="!hasHistory && isTypeBrand">
          {{
            $t('Start a conversation with {recipient}', {
              recipient: name,
            })
          }}
        </h3>
        <h3
          v-else
          class="lmb"
        >
          {{ name }}
        </h3>
      </div>
    </template>

    <div class="popin-content">
      <button
        v-if="closable"
        class="close-button account-forms"
        @click="close"
      >
        <AkIcon
          symbol="x"
          size="md"
        />
      </button>
      <slot name="default" />
      <MessageBox
        v-if="hasHistory"
        class="popin-content__history"
        :conversations="getConversationByRecipientEntityId(id)"
      />
      <InputMessage
        :place-holder="placeHolder"
        :recipient-id="id"
        :recipient-name="name"
        :recipient-type="type"
        :conversations-id="getConversationByRecipientEntityId(id) ? getConversationByRecipientEntityId(id).id : null"
        :has-skip="skippable"
        :origin="origin"
        :legend="legend"
        :is-request-a-discount="isRequestADiscount"
        :enabled-attachments="enabledAttachments"
        @send="sendMessage"
        @skip="closeModal"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { conversationsMixin } from '@core/mixins/conversations';
import { ConversationTypeMember } from '@monolith/legacy/types/conversation';
import { imgCdnUrl } from '@bc/shared';
import InputMessage from '@monolith/legacy/components/messages/input-message.vue';
import MessageBox from '@monolith/legacy/components/messages/message-box.vue';
import { AkParagraph, AkIcon } from '@ankorstore/design-system';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Chat',
  components: {
    InputMessage,
    MessageBox,
    AkParagraph,
    AkIcon,
  },
  mixins: [conversationsMixin],
  props: {
    legend: {
      type: String,
      required: false,
      default: null,
    },
    withPadding: {
      type: Boolean,
      default: true,
    },
    id: {
      type: Number,
      required: true,
    },
    /** Title of the chat */
    title: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      validator: (value) => Object.values(ConversationTypeMember).includes(value as ConversationTypeMember),
      required: true,
    },
    userFirstName: {
      type: String,
      required: false,
      default: null,
    },
    userLastName: {
      type: String,
      required: false,
      default: null,
    },
    image: {
      type: String,
      required: false,
      default: null,
    },
    placeHolder: {
      type: String,
      required: false,
      default: null,
    },
    origin: {
      type: String,
      required: false,
      default: null,
    },
    closable: {
      type: Boolean,
      required: false,
      default: true,
    },
    isBestPriceFeedback: {
      type: Boolean,
      default: false,
    },
    isRequestADiscount: {
      type: Boolean,
      required: false,
      default: false,
    },
    skippable: {
      type: Boolean,
    },
    enabledAttachments: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['close', 'send'],
  setup() {
    return {
      imgCdnUrl,
    };
  },
  data() {
    return {
      startConversation: false,
    };
  },
  computed: {
    ...mapGetters('conversations', ['getConversationByRecipientEntityId']),
    hasHistory(): boolean {
      return !!this.getConversationByRecipientEntityId(this.id);
    },
    isTypeRetailer(): boolean {
      return this.type === ConversationTypeMember.retailer;
    },
    isTypeBrand(): boolean {
      return this.type === ConversationTypeMember.brand;
    },
    bestPriceFeedBackNotSend(): boolean {
      return (this.isBestPriceFeedback || this.isRequestADiscount) && !this.startConversation;
    },
  },
  async mounted() {
    await this.fetchConversationByRecipientId(this.id, this.type as ConversationTypeMember);
  },
  methods: {
    closeModal(): void {
      this.$emit('close');
    },
    sendMessage(): void {
      this.startConversation = true;
      this.$emit('send');
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.popin-container {
  &--with-padding {
    @apply ds-pt-3 ds-pb-4 ds-px-4 md:ds-px-6;
  }
  &__description {
    @apply ds-mt-4 md:ds-mt-7 ds-text-lg ds-mb-5;
  }
  .promotion-banner {
    @apply ds-text-center;
    img {
      @apply ds-border ds-border-solid ds-border-neutral-700 ds-mb-2 ds-mx-auto ds-h-6 ds-w-6 ds-rounded-full;
      transition: all 0.3s ease;
      &.big {
        @apply ds-mt-5 ds-mb-4 ds-h-8 ds-w-8;
      }
    }
  }
}

.popin-content {
  &__history {
    @apply ds-mb-4;
  }
}

h3 {
  @apply ds-font-basic ds-text-center ds-mb-6 md:ds-mb-7 ds-text-lg;
  transition: all 0.3s ease;
  .lmb {
    @apply ds-mb-4;
  }
}

p {
  @apply ds-text-center;
}
a {
  text-decoration: underline;
}
textarea {
  @apply ds-text-sm;
}

.message-container {
  @apply ds-bg-warm-white;
}
.recipient {
  &-icon {
    @apply ds-bg-neutral-500 ds-w-6 ds-h-6 ds-flex ds-items-center ds-justify-center ds-mt-5 ds-mx-auto ds-mb-4 ds-rounded-full;
    p {
      @apply ds-font-bold ds-uppercase ds-mb-0 ds-text-white;
    }
  }
}
</style>
