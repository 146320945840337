<template>
  <div
    v-if="userIsRetailer"
    class="ds-mt-5"
  >
    <AkButton
      data-testid="save-search-button"
      v-bind="{ type: 'button', size: 'md', color: 'white', outlined: true, symbol: 'bookmark-plus' }"
      @click="openSavedSearchModal()"
    >
      {{ $t('Save search') }}
    </AkButton>
  </div>
  <AkModal
    ref="savedSearchModal"
    v-bind="{
      size: '2xl',
      closerWhite: false,
      validateButtonColor: 'primary',
      withCloser: true,
      cancelButtonIsOutlined: true,
      cancelButtonIsLink: false,
    }"
  >
    <div>
      <AkHeading3 class="ds-mb-5">
        <AkIcon
          symbol="bookmark-plus"
          size="md"
          class="ds-mr-2"
        />{{ $t('Save search') }}
      </AkHeading3>
    </div>
    <div class="mainModal--container">
      <div class="mainModal--container__section--input">
        <p class="ds-mb-2">
          {{ $t('Name') }}
        </p>
        <AkInput
          v-model="name"
          data-testid="save-search-inputName"
          :placeholder="$t('Introduce a name for your saved search')"
        />
      </div>
    </div>
    <AkButton
      type="button"
      class="ak-button ak-button--lg ak-button--white ds-mr-2"
      :outlined="true"
      data-testid="save-search-popin-cancel"
      @click="handleCancelSaveSearch"
    >
      {{ $t('Cancel') }}
    </AkButton>
    <AkButton
      :disabled="name === ''"
      type="button"
      class="ak-button ak-button--lg ak-button--primary"
      data-testid="save-search-popin-send"
      @click="handleSaveSearch"
    >
      {{ $t('Save search') }}
    </AkButton>
  </AkModal>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { AkButton, AkModal, AkHeading3 } from '@ankorstore/design-system';

import { useStore } from 'vuex';
import { saveSearch } from '../../services/saved-search-api';
const store = useStore();
const props = defineProps<{
  queryId: string;
}>();
const savedSearchModal = ref<InstanceType<typeof AkModal> | null>(null);
const name = ref<string>('');
const userIsRetailer = computed(() => store.getters['userIsRetailer']);
const handleSaveSearch = async () => {
  await saveSearch(name.value, props.queryId);
  savedSearchModal.value.close();
};
const openSavedSearchModal = () => {
  savedSearchModal.value.openModal();
};

const handleCancelSaveSearch = () => {
  savedSearchModal.value.close();
};
</script>

<style scoped lang="scss">
.mainModal--container {
  @apply ds-text-left;
  &__section {
    @apply ds-mb-4;
  }
  &__section--input {
    @apply ds-mb-5;
  }
}
</style>
