<template>
  <div class="ds-w-full ds-bg-neutral-300 ds-rounded-xl">
    <div
      class="ds-h-1.5 ds-rounded-xl"
      :class="colorStyleFn(progress)"
      :style="{ width: `${progress}%` }"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ProgressBarStatic',
  props: {
    progress: {
      type: Number,
      required: true,
    },
    colorStyleFn: {
      type: Function as PropType<(progress: number) => Record<string, boolean>>,
      required: false,
      default: (progress: number) => {
        return {
          'ds-bg-accent-700': progress >= 100,
          'ds-bg-warning-700': progress < 100,
        };
      },
    },
  },
});
</script>
