<template>
  <div
    class="banner-layout"
    :style="cssVars"
    :class="{
      'banner-layout--discovery': props.typeLeftBlock === 'discovery',
      'banner-layout--recommendations': props.typeLeftBlock === 'recommendations',
    }"
  >
    <div
      :class="{
        'banner-layout__left-block': true,
        'banner-layout__left-block--with-background-image': props.backgroundImages.length > 0,
      }"
    >
      <slot name="header" />
      <div class="banner-layout__left-block-overlay" />
    </div>
    <div class="banner-layout__right-block">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';
import { useBreakpoints } from '@monolith/legacy/modules/design-system-candidates';
import { bannerLayoutLeftRecommendedBlockFixedSize, bannerLayoutLeftBlockFixedSize } from '../constants';

const props = defineProps({
  gap: {
    type: Number,
    required: false,
    default: 24,
  },
  backgroundColor: {
    type: String,
    required: false,
    default: '#FFFFFF',
  },
  backgroundImages: {
    type: Array as PropType<{ url: string }[]>,
    required: false,
    default: () => [],
  },
  leftBlockSize: {
    type: String as PropType<'column' | 'fixed'>,
    required: false,
    default: 'column',
  },
  typeLeftBlock: {
    type: String as PropType<'recommendations' | 'discovery'>,
    required: false,
    default: 'discovery',
  },
});

const { isMobile, isXlMobile, isTablet } = useBreakpoints();

const defaultImage = computed(() => (isMobile.value || isXlMobile.value ? props.backgroundImages[1] : props.backgroundImages[0]));

const leftBlockTypeDesktopSize = computed(() =>
  props.typeLeftBlock === 'discovery' ? bannerLayoutLeftBlockFixedSize : bannerLayoutLeftRecommendedBlockFixedSize
);

const leftBlockFixedDesktopSize = computed(() =>
  props.leftBlockSize === 'fixed' ? `${leftBlockTypeDesktopSize.value}px` : 'auto'
);

const cssVars = computed(() => ({
  '--root-background-color': props.backgroundColor,
  '--left-block-background-image':
    props.typeLeftBlock === 'recommendations' ? null : defaultImage.value ? `url(${defaultImage.value.url})` : null,
  '--half-gap': isMobile.value || isXlMobile.value || isTablet.value ? '0px' : `${props.gap / 2}px`,
  '--left-block-width-lg': props.leftBlockSize === 'column' ? '33%' : leftBlockFixedDesktopSize.value,
  '--left-block-width-xl': props.leftBlockSize === 'column' ? '25%' : leftBlockFixedDesktopSize.value,
  '--left-block-fixed-desktop-size': leftBlockFixedDesktopSize,
}));
</script>

<style scoped lang="scss">
@use '@css/abstracts/design-tokens' as t;
.banner-layout {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: var(--root-background-color);
  border-radius: theme('borderRadius.md');

  @include t.lg {
    align-items: center;
    flex-direction: row;
  }

  &--recommendations {
    .banner-layout {
      &__left-block {
        border: 0;
        border-radius: 0;
      }
    }
  }

  &__left-block {
    overflow: hidden;
    position: relative;
    display: flex;
    flex: 0 0 calc(100% - var(--half-gap));
    align-items: center;
    align-self: stretch;
    border-radius: theme('borderRadius.md');
    margin-bottom: theme('spacing.5');
    color: white;
    border: 2px solid theme('colors.neutral.300');

    @include t.md {
      min-width: var(--left-block-fixed-desktop-size);
      margin-bottom: 0;
    }

    @include t.lg {
      flex: 0 0 calc(var(--left-block-width-lg) - var(--half-gap));
    }

    @include t.xl {
      flex: 0 0 calc(var(--left-block-width-xl) - var(--half-gap));
    }

    &--with-background-image {
      background-image: linear-gradient(rgb(0 0 0 / 30%), rgb(0 0 0 / 30%)), var(--left-block-background-image);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &__right-block {
    @include t.lg {
      width: 0;
      flex: 1 1 100%;
    }
  }

  &--discovery {
    .banner-layout__left-block {
      min-height: 310px;
    }
  }
}
</style>
