<template>
  <div
    class="trust-area"
    data-testid="trustArea"
  >
    <div class="trust-area__container">
      <AkParagraph class="trust-area__title">
        {{ content.title }}
      </AkParagraph>
      <div class="trust-area__logos">
        <a
          href="https://www.trustpilot.com/review/ankorstore.com"
          target="_blank"
        >
          <img
            class="trust-area__logo"
            src="/images/trust-area/trustpilot.png"
            :alt="content.trustpilot"
          >
        </a>
        <a
          :href="getGlobalConfig().language_config.google_customer_review"
          target="_blank"
        >
          <img
            class="trust-area__logo"
            src="/images/trust-area/google-customer-review.png"
            :alt="content.google"
          >
        </a>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { AkParagraph } from '@ankorstore/design-system';
import { getGlobalConfig } from '@monolith/legacy/services/global-config';

import { TrustAreaContent } from '@monolith/legacy/types/home/home';
export default defineComponent({
  name: 'TrustArea',
  components: {
    AkParagraph,
  },
  props: {
    content: {
      type: Object as () => TrustAreaContent,
      required: true,
    },
  },
  setup() {
    return {
      getGlobalConfig,
    };
  },
});
</script>
<style lang="scss">
.trust-area {
  @apply ds-py-7;
  &__container {
    @apply ds-mx-auto ds-max-w-screen-2xl ds-px-4 md:ds-px-6 ds-text-center;
  }
  &__title {
    @apply ds-mb-5 ds-text-3xl;
  }
  &__logos {
    @apply ds-flex ds-flex-col md:ds-flex-row ds-gap-5 md:ds-gap-8 ds-justify-center ds-items-center;
  }
  &__logo {
    @apply ds-w-full ds-h-auto;
    max-width: 150px;
  }
}
</style>
