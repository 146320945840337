import { getColumnFromFacet } from '@bc/brands/domain/contact-management/services/sorting.util';
import { combineArrays } from '@bc/brands/domain/contact-management/services/combineArrays.util';
import { type ContactManagementState } from '../types/store.types';
import { ValueContactFacet, ContactFilterType, type ValueContactFilter, type ContactFilter } from '../types/api.types';
import { ContactFacetType } from '../types/contact.facet.type';
import { StatsContactFacet } from '../types/stats.contact.facet';
import { ContactFilterName } from '../types/contacts.types';
import {
  WidgetTypes,
  type FilterWidgetsComponents,
  type DropdownWidgetComponent,
  type RangeWidgetComponent,
  type ToggleWidgetComponent,
  type RangeWidget,
} from '@bc/brands/domain/contact-management/types/filter.types';
import { ENABLED_FILTERS, ENABLED_FILTERS_UI } from '../constants';
import { getCurrency } from '@monolith/legacy/services/metas/currency';
import { formatPrice } from '@core/utilities/price';
import { ALL_CONTACTS_SEGMENT } from '@monolith/legacy/components/account/network/segments';

export default {
  getPagination: (state: ContactManagementState) => state.contacts.page,
  hasZeroContacts: (state: ContactManagementState) => !state.contacts.page?.totalResults,
  getContactsState: (state: ContactManagementState) => state.contacts.data,
  getFacets: (state: ContactManagementState) => state.contacts.facets,
  getPaginationFrom: (state: ContactManagementState) =>
    state.contacts.page.totalResults ? (state.contacts.page.current - 1) * state.contacts.page.size + 1 : 0,
  getPaginationTo: (state: ContactManagementState) =>
    Math.min(state.contacts.page.totalResults, state.contacts.page.current * state.contacts.page.size),
  getSearchQuery: (state: ContactManagementState) => state.searchParameters.searchQuery,
  getFiltersState: (state: ContactManagementState) => state.searchParameters.filters,
  getFiltersStateForAnalytics: (state: ContactManagementState) => {
    let segmentFilter = null;
    const filters = Object.keys(state.searchParameters.filters)
      .map((key) => {
        const filter = state.searchParameters.filters[key];
        if (filter.type === 'value' || filter.type === 'boolean') {
          if (key === 'segment') {
            segmentFilter = filter.value;
          }
          return {
            name: filter.name,
            value: filter.value,
          };
        } else if (filter.type === 'range') {
          return {
            name: filter.name,
            value: {
              min: filter.min,
              exclusiveMax: filter.exclusiveMax,
            },
          };
        }
        return null;
      })
      .filter(Boolean);

    const output = {
      filters: filters,
      segment: segmentFilter,
    };

    return output;
  },
  noFiltersApplied: (_state: ContactManagementState, getters) => {
    const filtersCount = Object.keys(getters.getFiltersState).length;
    return (filtersCount === 1 && getters.getCurrentSegment === ALL_CONTACTS_SEGMENT) || filtersCount === 0;
  },
  getSelectedFiltersCount: (_state: ContactManagementState, getters) => {
    const filtersState = getters.getFiltersState;
    const filtersArray: ContactFilter[] = filtersState && typeof filtersState === 'object' ? Object.values(filtersState) : [];
    return filtersArray?.filter((item): boolean => item.name !== ContactFilterName.Segment).length;
  },
  getCurrentSegment: (state: ContactManagementState) =>
    (state.searchParameters.filters.segment as ValueContactFilter)?.value?.[0] || null,
  getColumnSort: (state: ContactManagementState) => {
    const facet = Object.keys(state.searchParameters.sort)?.[0];
    const col = getColumnFromFacet(facet);
    return {
      col,
      order: state.searchParameters.sort?.[facet],
    };
  },
  getFiltersComponents: (state: ContactManagementState): FilterWidgetsComponents[] => {
    const facets = state.contacts.facets;
    const filtersData = state.filters;
    const components = ENABLED_FILTERS.map((filterId: string) => {
      const relatedFilter = filtersData?.find((item) => item.id === filterId);
      const filterAttributes = relatedFilter?.attributes;

      if (filterAttributes?.type === WidgetTypes.RangeWidget) {
        const relatedFacet = facets?.find(
          (item) => item.name === filterId && item.type === ContactFacetType.Stats
        ) as StatsContactFacet;
        return {
          ...filterAttributes,
          attributes: relatedFacet?.data,
        } as RangeWidgetComponent;
      }
      if (filterAttributes?.type === WidgetTypes.DropdownWidget) {
        const relatedFacet = facets?.find(
          (item) => item.name === filterId && item.type === ContactFacetType.Value
        ) as ValueContactFacet;
        const values = combineArrays(filterAttributes.values, relatedFacet?.data);
        return {
          ...filterAttributes,
          type: filterAttributes.multiple ? WidgetTypes.MultiSelectWidget : WidgetTypes.DropdownWidget,
          values: values,
        } as DropdownWidgetComponent;
      }
      if (filterAttributes?.type === WidgetTypes.ToggleWidget) {
        return {
          ...filterAttributes,
          value: false,
        } as ToggleWidgetComponent;
      }
      return filterAttributes;
    }).filter(Boolean) as FilterWidgetsComponents[];
    return components;
  },

  getGroupedFilters: (_state: ContactManagementState, getters) => {
    const components = getters?.getFiltersComponents;
    const groupedFiltersWithNames = [];
    ENABLED_FILTERS_UI.forEach((group) => {
      const groupedComponents = group.items.map((item) => {
        const componentsInGroup = [];
        components.forEach((component) => {
          const found = item.filterNames.find((filterName) => filterName === component.attributeName);
          if (found) {
            componentsInGroup.push(component);
          }
        });
        return { groupName: item.groupName, components: componentsInGroup };
      });
      groupedFiltersWithNames.push({ groupName: group.groupName, items: groupedComponents });
    });
    return groupedFiltersWithNames;
  },
  getSelectedFilters: (state: ContactManagementState) => {
    const filtersArray: ContactFilter[] =
      state.searchParameters.filters && typeof state.searchParameters.filters === 'object'
        ? Object.values(state.searchParameters.filters)
        : [];
    return filtersArray
      .map((item) => {
        const filterComponent = state.filters.find((filter) => filter.id === item.name);
        if (!filterComponent?.attributes) {
          return null;
        }

        const title = filterComponent.attributes.label;
        let valueLabel = '';

        if (item.type === ContactFilterType.Range) {
          const relativeFacet = state.contacts.facets.find((facet) => facet.name === item.name) as StatsContactFacet;
          const min = formatPrice({ amount: (item.min as number) || relativeFacet?.data?.min || 0, currency: getCurrency() });
          const max = formatPrice({ amount: (item.exclusiveMax as number) || relativeFacet?.data?.max, currency: getCurrency() });
          valueLabel = `${min} ${(filterComponent.attributes as RangeWidget).separatorLabel} ${max}`;
          return { id: item.name, label: `${title}: ${valueLabel}` };
        }

        if (
          filterComponent.attributes.type === WidgetTypes.DropdownWidget ||
          filterComponent.attributes.type === WidgetTypes.ListWidget
        ) {
          if (Array.isArray(item.value) && item.value.length > 1) {
            return { id: item.name, label: `${title} (${item.value.length})` };
          } else if (Array.isArray(item.value) && item.value.length === 1) {
            const filterValue = filterComponent?.attributes.values?.find((value) => item.value[0] === `${value.value}`);
            return { id: item.name, label: `${title}: ${filterValue?.label}` };
          }
        }

        if (filterComponent.attributes.type === WidgetTypes.ToggleWidget) {
          return item.value ? { id: item.name, label: title } : null;
        }

        return title;
      })
      .filter(Boolean);
  },
  getLanguageFacets: (state: ContactManagementState) =>
    state.contacts.facets.find((facet) => facet.name === 'language') as ValueContactFacet,
  getSegmentsFacets: (state: ContactManagementState) =>
    state.contacts.facets.find((facet) => facet.name === 'segment') as ValueContactFacet,
  getShopTypes: (state: ContactManagementState) => {
    const shopTypeFilter = state.filters?.find((item) => {
      return item.id === 'retailer.shopType';
    });

    return (shopTypeFilter?.attributes as DropdownWidgetComponent)?.values;
  },
  getPlusRetailersCount: (state: ContactManagementState) => {
    const facet = state.contacts.facets.find((facet) => facet.name === 'retailer.aksPlus.active') as ValueContactFacet;
    const facetData = facet?.data?.find((data) => parseInt(data.value, 10) === 1);

    return facetData?.count;
  },
  getRetentionReorderLoyaltyCount: (state: ContactManagementState) => {
    const cardsFacet = state.contacts.facets.find((facet) => facet.name === 'cards_facets') as ValueContactFacet;
    const retentionReorderLoyalty = cardsFacet?.data?.find((cardFacet) => cardFacet.value === 'retention_reorder_loyalty');
    return retentionReorderLoyalty?.count;
  },
  getLastOrder3monthsCount: (state: ContactManagementState) => {
    const facet = state.contacts.facets.find((facet) => facet.name === 'ordering.lastOrderedAt') as ValueContactFacet;
    const facetData = facet?.data?.find((data) => data.value === 'more_than_3_months');

    return facetData?.count;
  },
  getLastOrder6monthsCount: (state: ContactManagementState) => {
    const facet = state.contacts.facets.find((facet) => facet.name === 'ordering.lastOrderedAt') as ValueContactFacet;
    const facetData = facet?.data?.find((data) => data.value === 'more_than_6_months');

    return facetData?.count;
  },
  getCartActivity30daysCount: (state: ContactManagementState) => {
    const facet = state.contacts.facets.find((facet) => facet.name === 'hasProductsInCart') as ValueContactFacet;
    const facetData = facet?.data?.find((data) => data.value === 'has_cart_activity_in_last_30_days');

    return facetData?.count;
  },
};
