<template>
  <div>
    <AkHeading4>
      {{ i18n.t('brands.catalogIntegration.gsheet.gsheetDataEntry.heading') }}
    </AkHeading4>
    <div class="gsheet-data-entry__top">
      <AkParagraph class="gsheet-data-entry__subheading">
        {{ i18n.t('brands.catalogIntegration.gsheet.gsheetDataEntry.upload.subHeading') }}
      </AkParagraph>
    </div>

    <GsheetBanner
      v-if="isBannerDisplayed"
      @updated="gsheetUpdated"
    />

    <div class="gsheet-data-entry__content">
      <div class="gsheet-data-entry__box">
        <div class="gsheet-data-entry__box-header">
          <div>
            <div class="gsheet-data-entry__box-step">
              {{ i18n.t('brands.catalogIntegration.gsheet.gsheetDataEntry.step') }} 1
            </div>
            <div class="gsheet-data-entry__box-title">
              {{ i18n.t('brands.catalogIntegration.gsheet.gsheetDataEntry.gsheetDescription.title') }}
            </div>
          </div>
          <img
            src="/images/account/gsheet-modal/google-sheet-icon.png"
            alt="Google Sheets"
            class="gsheet-data-entry__box-icon"
          >
        </div>
        <ul class="gsheet-data-entry__list">
          <li>
            <div class="gsheet-data-entry__bullet">
              1
            </div>
            {{ i18n.t('brands.catalogIntegration.gsheet.gsheetDataEntry.gsheetDescription.bulletPoint1') }}
          </li>
          <li>
            <div class="gsheet-data-entry__bullet">
              2
            </div>
            {{ i18n.t('brands.catalogIntegration.gsheet.gsheetDataEntry.gsheetDescription.bulletPoint2') }}
          </li>
          <li>
            <div class="gsheet-data-entry__bullet">
              3
            </div>
            {{ i18n.t('brands.catalogIntegration.gsheet.gsheetDataEntry.gsheetDescription.bulletPoint3') }}
          </li>
        </ul>
        <AkButton
          size="md"
          color="white"
          :outlined="true"
          symbol="external-link"
          class="ds-self-end"
          data-testid="openSpreadsheetBtn"
          @click="openSpreadsheet"
        >
          {{ i18n.t('brands.catalogIntegration.gsheet.gsheetDataEntry.openTemplate') }}
        </AkButton>
      </div>

      <div
        v-if="isImagesFolderDisplayed"
        class="gsheet-data-entry__box"
        data-testid="google-drive-box"
      >
        <div class="gsheet-data-entry__box-header">
          <div>
            <div class="gsheet-data-entry__box-step">
              {{ i18n.t('brands.catalogIntegration.gsheet.gsheetDataEntry.step') }} 2
            </div>
            <div class="gsheet-data-entry__box-title">
              {{ i18n.t('brands.catalogIntegration.gsheet.gsheetDataEntry.gdrive.title') }}
            </div>
          </div>
          <img
            src="/images/account/gsheet-modal/google-drive-icon.png"
            alt="Google Drive"
            class="gsheet-data-entry__box-icon"
          >
        </div>
        <ul class="gsheet-data-entry__list">
          <li>
            <div class="gsheet-data-entry__bullet">
              1
            </div>
            {{ i18n.t('brands.catalogIntegration.gsheet.gsheetDataEntry.gdrive.bulletPoint1') }}
          </li>
          <li>
            <div class="gsheet-data-entry__bullet">
              2
            </div>
            {{ i18n.t('brands.catalogIntegration.gsheet.gsheetDataEntry.gdrive.bulletPoint2') }}
          </li>
          <li>
            <div class="gsheet-data-entry__bullet">
              3
            </div>
            {{ i18n.t('brands.catalogIntegration.gsheet.gsheetDataEntry.gdrive.bulletPoint3') }}
          </li>
        </ul>
        <AkButton
          size="md"
          color="white"
          outlined
          symbol="external-link"
          class="ds-self-end"
          data-testid="openImgFolderBtn"
          @click="openImageFolder"
        >
          {{ i18n.t('brands.catalogIntegration.gsheet.gsheetDataEntry.openImageFolder') }}
        </AkButton>
      </div>
    </div>

    <AkButton
      link
      symbol="trash"
      size="lg"
      data-testid="startOverBtn"
      @click="startOver"
    >
      {{ i18n.t('brands.catalogIntegration.gsheet.gsheetDataEntry.startOver') }}
    </AkButton>
  </div>
</template>
<script lang="ts">
import { type Ref, ref, computed, defineComponent, type ComputedRef } from 'vue';
import { AkParagraph, AkHeading4, AkButton } from '@ankorstore/design-system';
import Analytics from '@monolith/legacy/services/analytics';
import UserClick from '@monolith/legacy/services/analytics/events/user-click.event';
import { ImagesImportMethod } from '@bc/brands/feature/catalog-integration/types/gsheet.types';
import { useStore } from '@core/composables/use-store';
import { Brand } from '@monolith/legacy/types/api/brand';
import useI18n from '@core/composables/use-i18n';
import GsheetBanner from './gsheet-banner.vue';

export default defineComponent({
  name: 'GsheetDataEntry',
  components: {
    AkParagraph,
    AkHeading4,
    AkButton,
    GsheetBanner,
  },
  props: {
    isImagesFolderDisplayed: {
      type: Boolean,
      default: true,
    },
    isBannerDisplayed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['startOver', 'updated'],
  setup(_, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const brand: Ref<Brand> = computed(() => store.getters['brand']);
    const importSpreadsheet: Ref<string> = computed(
      () => store.getters['account/productsIntegration/getSettings']?.generated_google_spreadsheet?.data_sheet_url
    );
    const driveFolderUrl: ComputedRef<string> = computed(
      () => store.getters['account/productsIntegration/getSettings']?.images_google_folder_url
    );
    const isDeleteButtonLoading = ref(false);

    const openSpreadsheet = () => {
      window.open(importSpreadsheet.value, '_blank');
      Analytics.track(
        new UserClick({
          component: 'brand_bo_catalogue_management',
          action: 'open_spreadsheet',
          brand_id: brand.value.id,
          brand_name: brand.value.name,
        })
      );
    };

    const openImageFolder = () => {
      Analytics.track(
        new UserClick({
          component: 'brand_bo_catalogue_integration_tool_add_multiple_products',
          action: 'open_images_folder',
          brand_id: brand.value.id,
          brand_name: brand.value.name,
        })
      );
      window.open(driveFolderUrl.value, '_blank');
    };

    const startOver = () => {
      isDeleteButtonLoading.value = true;
      Analytics.track(
        new UserClick({
          component: 'brand_bo_catalogue_integration_tool_add_multiple_products',
          action: 'start_over',
          brand_id: brand.value.id,
          brand_name: brand.value.name,
        })
      );
      emit('startOver');
    };

    const gsheetUpdated = () => {
      emit('updated');
    };

    return {
      openSpreadsheet,
      openImageFolder,
      startOver,
      gsheetUpdated,
      ImagesImportMethod,
      i18n,
    };
  },
});
</script>
<style scoped lang="scss">
@import '@css/vue-import';

.gsheet-data-entry {
  &__top {
    @apply ds-flex ds-items-center ds-flex-col;
  }

  &__content {
    @apply ds-flex ds-items-stretch ds-justify-center ds-mb-2 ds-gap-4;
  }

  &__subheading {
    @apply ds-py-2 ds-text-neutral-900 ds-mb-5;
  }

  &__box {
    @apply ds-bg-white ds-flex ds-flex-wrap ds-items-center ds-border ds-border-neutral-500 ds-border-solid ds-rounded-md ds-px-4 ds-py-5 ds-text-left;
    flex-basis: 320px;
  }

  &__box-step {
    @apply ds-text-xs ds-text-neutral-700 ds-uppercase;
  }

  &__box-header {
    @apply ds-w-full ds-flex ds-justify-between ds-font-bold ds-flex-1;
  }

  &__box-title {
    @apply ds-text-base ds-mb-5;
  }

  &__box-icon {
    @apply ds-w-5 ds-h-5;
  }

  &__list {
    @apply ds-list-none ds-flex ds-flex-col ds-gap-4 ds-text-sm ds-text-neutral-900 ds-mb-6;

    li {
      @apply ds-flex ds-items-center ds-gap-2;
    }
  }

  &__bullet {
    @apply ds-mr-1 ds-flex ds-items-center ds-justify-center ds-border ds-border-solid ds-border-accent-700 ds-rounded-full ds-text-xs ds-w-4 ds-h-4;
    font-size: 9px;
    min-width: 1rem;
  }
}
</style>
