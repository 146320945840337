import { InjectedContentRepository } from '@monolith/legacy/types/injected-content-repository';
import {
  DynamicMetaTagName,
  GlobalMetaTagName,
  InitialStateConfig,
  StateMetaTagName,
} from '@monolith/legacy/services/initial-state';
import { captureException } from '@sentry/browser';

export class InjectedContentRepositoryMeta implements InjectedContentRepository {
  constructor(private readonly document: Document) {}

  get<T>(name: GlobalMetaTagName | StateMetaTagName | DynamicMetaTagName) {
    try {
      const isEncoded = InitialStateConfig[name];
      const content = this.document.querySelector(`meta[name='${name}']`)?.getAttribute('content')?.trim();

      if (!content) {
        return null;
      }

      if (isEncoded) {
        return JSON.parse(globalThis.atob(content)) as T;
      }

      return content as T;
    } catch (err) {
      captureException(
        new Error(`Error while parsing inital content meta tag ${name}`, {
          cause: err,
        })
      );
      return null;
    }
  }
}
